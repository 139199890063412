var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "form-group" }, [
        _c("h6", [
          _vm._v("Nº de cambios de nombre: " + _vm._s(_vm.numTickets)),
        ]),
      ]),
      _vm._v(" "),
      _vm._l(_vm.sale_tickets, function (ticket) {
        return _c(
          "div",
          { key: ticket.ticket_id, staticClass: "form-group" },
          [
            _c("h7", [
              _vm._v(
                _vm._s(ticket.event.name) +
                  " " +
                  _vm._s(ticket.ticket_event.name_ticket) +
                  " + " +
                  _vm._s(ticket.ticket_event.rename_price) +
                  "€"
              ),
            ]),
          ],
          1
        )
      }),
      _vm._v(" "),
      _c("div", { staticClass: "form-group" }, [
        _c("h6", [
          _vm._v(
            "\n            Gastos de gestión:\n            " +
              _vm._s(parseFloat(_vm.commission).toFixed(2)) +
              "€\n        "
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-group" }, [
        _c("h6", [
          _vm._v(
            "\n            Total:\n            " +
              _vm._s(parseFloat(_vm.total).toFixed(2)) +
              "€\n        "
          ),
        ]),
      ]),
      _vm._v(" "),
      _vm.isUSDTPayment
        ? _c("div", { staticClass: "form-group" }, [
            _c("h6", [
              _vm._v("Cantidad USDT: " + _vm._s(_vm.priceUSDT) + " USDT"),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.user_credit > 0
        ? _c("div", { staticClass: "form-group mt-5" }, [
            _c("label", { staticClass: "font-weight-bold color-darkblue" }, [
              _vm._v("Usar saldo regalo:"),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "input-group mb-2" }, [
              _c("div", { staticClass: "input-group-prepend" }, [
                _c("div", { staticClass: "input-group-text" }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.user_credit.toFixed(2)) +
                      "€\n                "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.consumed_credit,
                    expression: "consumed_credit",
                  },
                ],
                staticClass: "form-control",
                attrs: {
                  type: "text",
                  id: "saldo_regalo_a_usar",
                  placeholder: "Saldo de regalo a usar",
                },
                domProps: { value: _vm.consumed_credit },
                on: {
                  input: [
                    function ($event) {
                      if ($event.target.composing) return
                      _vm.consumed_credit = $event.target.value
                    },
                    _vm.onTotalChange,
                  ],
                  keydown: function ($event) {
                    return _vm.isNumber($event)
                  },
                },
              }),
              _vm._v(" "),
              _c(
                "small",
                { staticClass: "w-100 mt-2", staticStyle: { color: "gray" } },
                [
                  _vm._v(
                    "(Los gastos de gestión no se descontarán del total del pedido)"
                  ),
                ]
              ),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.totalPending > 0
        ? _c("div", { staticClass: "form-group" }, [
            _vm._m(1),
            _vm._v(" "),
            _c("div", { staticClass: "form-group" }, [
              _c(
                "div",
                {
                  class:
                    "border-rounded-green d-flex p-2 cursor-pointer select-method-pay-card " +
                    (_vm.payment_method === 1 ? "selected" : ""),
                  on: {
                    click: function ($event) {
                      return _vm.setPaymentMethod(1)
                    },
                  },
                },
                [
                  _c("img", {
                    staticClass: "mx-2 pe-none",
                    attrs: {
                      src: _vm.publicPath + "/svg/tarjeta.svg",
                      width: "20px",
                      height: "auto",
                    },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "font-weight-bold pe-none" }, [
                    _vm._v("Tarjeta de crédito"),
                  ]),
                ]
              ),
            ]),
            _vm._v(" "),
            1 === 0
              ? _c("div", { staticClass: "form-group" }, [
                  _c(
                    "div",
                    {
                      class:
                        "border-rounded-green d-flex p-2 cursor-pointer select-method-pay-card " +
                        (_vm.payment_method === 3 ? "selected" : ""),
                      on: {
                        click: function ($event) {
                          return _vm.setPaymentMethod(3)
                        },
                      },
                    },
                    [
                      _c("img", {
                        staticClass: "mx-2 pe-none",
                        attrs: {
                          src: _vm.publicPath + "/svg/coinbase.svg",
                          width: "20px",
                          height: "auto",
                        },
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "font-weight-bold pe-none" }, [
                        _vm._v("Coinbase Commerce"),
                      ]),
                    ]
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            1 === 0
              ? _c("div", { staticClass: "form-group" }, [
                  _c(
                    "div",
                    {
                      class:
                        "border-rounded-green d-flex p-2 cursor-pointer select-method-pay-card " +
                        (_vm.payment_method === 4 ? "selected" : ""),
                      on: {
                        click: function ($event) {
                          return _vm.setPaymentMethod(4)
                        },
                      },
                    },
                    [
                      _c("img", {
                        staticClass: "mx-2 pe-none",
                        attrs: {
                          src: _vm.publicPath + "/svg/metamask.svg",
                          width: "20px",
                          height: "auto",
                        },
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "font-weight-bold pe-none" }, [
                        _vm._v("Metamask"),
                      ]),
                    ]
                  ),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "mb-4" }, [
      _c("h5", { staticClass: "font-weight-bolder color-darkblue" }, [
        _vm._v("\n            Resumen de pago\n        "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "mb-4 mt-5" }, [
      _c("h5", { staticClass: "font-weight-bolder color-darkblue" }, [
        _vm._v("\n                Selecciona un método de pago\n            "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }