<template>
    <div
        class="modal fade"
        id="country_url_modal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="country_url_modalTitle"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-centered" role="document">
            <form>
                <div class="modal-content">
                    <div class="modal-body">
                        <div class="mb-4">
                            <h5 class="font-weight-bolder color-darkblue">
                                Elige tu ciudad
                            </h5>
                        </div>

                        <div class="form-group">
                            <label for="search">Buscar ciudad</label>
                            <input
                                @keyup="getCountriesUrls()"
                                class="form-control"
                                name="search"
                                v-model="search"
                                placeholder="Buscar ciudad..."
                                type="text"
                            />
                        </div>

                        <div class="countries">
                            <div
                                class="mt-3"
                                v-for="country_url in countries_urls"
                                :key="country_url.id"
                            >
                                <a class="h3" target="_blank" :href="country_url.url">{{
                                    country_url.name
                                }}</a>
                            </div>
                        </div>
                    </div>

                    <div class="modal-footer justify-content-between">
                        <button
                            type="button"
                            class="btn btn-cancel"
                            data-dismiss="modal"
                            @click="closeModal()"
                        >
                            Cancelar
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            countries_urls: null,
            search: null,
        };
    },
    methods: {
        getCountriesUrls() {
            axios
                .post("/get-countries-urls-active", { search: this.search })
                .then((response) => {
                    this.countries_urls = response.data;
                })
                .catch((error) => {
                    // handle error
                    console.log(error);
                });
        },
        closeModal() {
            $("#country_url_modal").modal("hide");
        },
    },
    mounted() {
        this.getCountriesUrls();
    },
};
</script>

<style lang="scss" scoped>
.countries {
    max-height: 20rem;
    overflow: auto;
}
</style>
