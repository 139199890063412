<template>
	<div class="offcanvas bg offcanvas-end p-2" tabindex="1" id="offcanvasFilters" aria-labelledby="offcanvasFiltersLabel">
        <div class="offcanvas-header border-bottom-white align-items-center">
            <h5 id="offcanvasFiltersLabel" class="color-white m-0">Personaliza tus filtros</h5>
            <div>
                <img :src="publicPath+'/svg/filtros.svg'" height="20px"/>
                <a class="btn" data-bs-dismiss="offcanvas" aria-label="Close"><img class="cerrar-hover" :src="publicPath+'/svg/cerrar.svg'" height="20px"/></a>
            </div>
        </div>
        <div class="offcanvas-body">
            <div class="pb-4">
                <div class="form-group">
                    <label class="font-weight-bolder color-green">Categoria:</label>
                    <select class="form-control selectpicker select-green w-100" name="category" id="select_categories" v-model="select_categories" data-style="select-green">
                        <option :value="'0'" selected>Selecciona una categoria</option>
                        <option v-for="category in array_categories" :value="category.id" :key="category.id" v-text="category.name"></option>
                    </select>
                </div>
                <div class="form-group">
                    <label class="font-weight-bolder color-green">Rango de precio:</label>
                    <label class="color-lightgreen">Establece el rango que mejor se adapta a tus necesidades</label>
                    <div class="p-2 border rounded">
                        <div class="d-flex justify-content-between mb-2">
                            <span class="color-lightgreen" id="kt_slider_basic_min"></span>
                            <span class="color-lightgreen" id="kt_slider_basic_max"></span>
                        </div>
                        <div id="kt_slider_basic"></div>
                    </div>
                </div>
                <div class="form-group">
                    <label class="font-weight-bolder color-green">Tus fechas:</label>
                    <label class="color-lightgreen">Establece la fecha en la que quieres disfrutar del evento</label>
                    <input class="form-control form-date-dark h-auto" v-model="date" type="date" name="date">
                </div>
            </div>
        </div>
        <div class="text-end p-2 border-top-white">
            <button v-on:click="applyFilters()" type="button" class="btn btn-green">Aplicar filtros</button>
        </div>
    </div>
</template>

<script>
    export default {
        name: "FiltersComponent",
         data(){
            return{                
                array_categories:[],
                select_categories: '',
                value_slider_min: 0,
                value_slider_max: 100,
                date: '',
                data_url: '',
                publicPath: window.location.origin
            }
        },
        methods:{
            getCategories(){
                let me =this;
                let url = '/list_categories';
                axios.get(url).then(function (response) {
                    me.array_categories = response.data.array_categories;
                })
                .catch(function (error) {
                    // handle error
                    console.log(error);
                });
            },
            applyFilters(){
                var params = '';
                if(this.select_categories != 0){
                    params += 'id_category=' + this.select_categories;
                }

                if(this.value_slider_min != '' && this.value_slider_max != ''){
                    if(params != ''){
                        params += '&';
                    }
                    params += 'value_min=' + this.value_slider_min + '&value_max=' + this.value_slider_max;
                }

                if(this.date != ''){
                    if(params != ''){
                        params += '&';
                    }
                    params += 'date=' + this.date   ;
                }
                
                if(params != ''){
                    document.location.search = params;
                }
            },
            initializeSlider(){
                let me = this;
                var slider = document.querySelector("#kt_slider_basic");
                var valueMin = document.querySelector("#kt_slider_basic_min");
                var valueMax = document.querySelector("#kt_slider_basic_max");

                noUiSlider.create(slider, {
                    start: [me.value_slider_min, me.value_slider_max],
                    connect: true,
                    step: 10,
                    range: {
                        "min": 0,
                        "max": 1000
                    }
                });

                slider.noUiSlider.on("update", function (values, handle) {
                    if (handle) {
                        valueMax.innerHTML = values[handle]+" €";
                        me.value_slider_max = values[handle];
                    } else {
                        valueMin.innerHTML = values[handle]+" €";
                        me.value_slider_min = values[handle];
                    }
                });
            },
            getValueQuery(){
                let me = this;
                if(this.$route.query.id_category != undefined){
                    me.select_categories = this.$route.query.id_category;
                }
                if(this.$route.query.value_min != undefined){
                    me.value_slider_min = this.$route.query.value_min;
                }
                if(this.$route.query.value_max != undefined){
                    me.value_slider_max = this.$route.query.value_max;
                }
                if(this.$route.query.date != undefined){
                    me.date = this.$route.query.date;
                }
            }
        },
        mounted() {
            this.getCategories();  
            this.getValueQuery();
            this.data_url = this.$route.query;
            this.initializeSlider();
        },
        updated() {
            if(this.select_categories == 0){
                $('#select_categories').val(0);
            }
            $("#select_categories").selectpicker("refresh");
        }
    }
</script>
