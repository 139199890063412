<template>
    <div class="p-4">
        <div class="container">
            <div class="justify-content-center">
                <div class="wizard wizard-4">
                    <div class="wizard-nav">
                        <div
                            class="wizard-steps"
                            data-total-steps="3"
                            :class="{'not-logged-steps': !logged}"
                        >
                            <div
                                class="wizard-step cursor-pointer mycollection-tab"
                                data-wizard-type="step"
                                id="tag_step1"
                                data-wizard-state="current"
                                @click="selectTab($event)"
                            >
                                <div
                                    class="wizard-wrapper justify-content-center"
                                >
                                    <span><b>FAQs</b></span>
                                </div>
                            </div>
                            <div
                                class="wizard-step cursor-pointer mycollection-tab"
                                data-wizard-type="step"
                                id="tag_step2"
                                data-wizard-state="pending"
                                @click="selectTab($event)"
                            >
                                <div
                                    class="wizard-wrapper justify-content-center"
                                >
                                    <span><b>Documentos</b></span>
                                </div>
                            </div>
                            <div
                                class="wizard-step cursor-pointer mycollection-tab"
                                data-wizard-type="step"
                                id="tag_step3"
                                data-wizard-state="pending"
                                @click="selectTab($event)"
                                v-if="logged"
                            >
                                <div
                                    class="wizard-wrapper justify-content-center"
                                >
                                    <span><b>Consultas</b></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        class="card card-custom card-shadowless rounded-top-0 div-content-tabs"
                    >
                        <div class="card-body">
                            <div
                                class="step"
                                id="step_events"
                                data-wizard-type="step-content"
                                data-wizard-state="current"
                            >
                                <div
                                    class="card card-custom shadow-none border-0"
                                >
                                    <div class="card-body body-tab-step">
                                        <FAQsComponent />
                                    </div>
                                </div>
                            </div>
                            <div
                                class="step"
                                id="step_songs"
                                data-wizard-type="step-content"
                                data-wizard-state="pending"
                            >
                                <div
                                    class="card card-custom shadow-none border-0"
                                >
                                    <div class="card-body body-tab-step">
                                        <TableMyDocumentsComponent
                                            ref="helpDocuments"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div
                                class="step"
                                id="step_nfts"
                                data-wizard-type="step-content"
                                data-wizard-state="pending"
                                v-if="logged"
                            >
                                <div
                                    class="card card-custom shadow-none border-0"
                                >
                                    <div class="card-body body-tab-step">
                                        <QueriesComponent />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import FAQsComponent from "./FAQsComponent.vue";
import TableMyDocumentsComponent from "./TableMyDocumentsComponent.vue";
import QueriesComponent from "./QueriesComponent.vue";

export default {
    name: "MyCollectionTabsTableComponent",
    components: {
        FAQsComponent,
        TableMyDocumentsComponent,
        QueriesComponent,
    },
    data() {
        return {
            publicPath: window.location.origin,
            id_tab: "tag_step1",
            logged: window.Laravel.isLoggedin,
        };
    },

    methods: {
        selectTab(event) {
            $("#tag_step1").attr("data-wizard-state", "pending");
            $("#tag_step2").attr("data-wizard-state", "pending");
            $("#tag_step3").attr("data-wizard-state", "pending");
            $(event.currentTarget).attr("data-wizard-state", "current");

            this.id_tab = $(event.currentTarget).attr("id");
            this.navegationTabs(this.id_tab);
        },
        navegationTabs(id_tab) {
            if (id_tab == "tag_step1") {
                $("#step_events").attr("data-wizard-state", "current");
                $("#step_songs").attr("data-wizard-state", "pending");
                $("#step_nfts").attr("data-wizard-state", "pending");
            } else if (id_tab == "tag_step2") {
                $("#step_events").attr("data-wizard-state", "pending");
                $("#step_songs").attr("data-wizard-state", "current");
                $("#step_nfts").attr("data-wizard-state", "pending");
                this.$refs.helpDocuments.listDocuments();
                this.$refs.helpDocuments.searchDocuments();
            } else if (id_tab == "tag_step3") {
                $("#step_events").attr("data-wizard-state", "pending");
                $("#step_songs").attr("data-wizard-state", "pending");
                $("#step_nfts").attr("data-wizard-state", "current");
            }
        },
    },
    mounted() {},
};
</script>

<style lang="scss" scoped>
.card-body.body-tab-step {
    padding: 0;
}

.not-logged-steps {
    justify-content: flex-start !important;
    gap: 0.5rem;
}
</style>
