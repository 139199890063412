var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.event.active == 1
    ? _c(
        "div",
        { staticClass: "transform-content" },
        [
          _c("div", { staticClass: "bg-header-general" }, [
            _c("div", { staticClass: "container pt-4 mt-4 px-2" }, [
              _vm.event != ""
                ? _c(
                    "div",
                    { staticClass: "col-md-6 d-flex align-items-end" },
                    [
                      _c("img", {
                        staticStyle: {
                          "object-fit": "cover",
                          "border-radius": "0.5rem",
                          width: "200px",
                          height: "auto",
                          "max-width": "30vw",
                          "aspect-ratio": "1 / 1",
                        },
                        attrs: {
                          src: "/files/images/events/" + _vm.event.img_event,
                        },
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "px-4 pt-8" }, [
                        _c(
                          "h2",
                          { staticClass: "color-white font-weight-bolder" },
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s(_vm.event.name) +
                                "\n                    "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "d-flex align-items-center" },
                          [
                            _c("img", {
                              attrs: {
                                src:
                                  _vm.publicPath + "/svg/calendario-blanco.svg",
                                width: "15px",
                                height: "auto",
                              },
                            }),
                            _vm._v(" "),
                            _c("span", { staticClass: "color-white mx-1" }, [
                              _vm._v(_vm._s(_vm.custom_date)),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _vm.isFinished
                          ? _c(
                              "div",
                              { staticClass: "d-flex align-items-center" },
                              [
                                _c(
                                  "span",
                                  { staticClass: "color-white mx-1" },
                                  [_vm._v(_vm._s("FINALIZADO"))]
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]),
                    ]
                  )
                : _vm._e(),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "container px-2" }, [
            _c(
              "div",
              { staticClass: "d-flex flex-wrap" },
              [
                _c("EventSummary", {
                  attrs: { ticket_types: _vm.array_tickets, event: _vm.event },
                }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "buy-tickets col-12 col-md-6 position-relative order-0 order-sm-0 order-md-1",
                    attrs: { id: "realiza-tu-compra" },
                  },
                  [
                    _c("BuyTicketSelection", {
                      attrs: {
                        "is-finished": _vm.isFinished,
                        ticket_types: _vm.array_dates[0].array_tickets,
                      },
                      on: { ticketCartFinish: _vm.processCart },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("BuyCheckout", {
            attrs: {
              ticket_cart: _vm.ticket_cart,
              event: _vm.event,
              value_usdt: parseFloat(_vm.value_usdt),
            },
            on: {
              onTransaccionSuccess: (id_transaction) =>
                _vm.setTransaction(id_transaction),
            },
          }),
          _vm._v(" "),
          _vm.transaction_id > 0
            ? _c("AdminEventTicketRenamingDialog", {
                attrs: { transaction_id: _vm.transaction_id },
                on: { onFinishRename: (success) => _vm.setTransaction(0) },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.ticket_image != null
            ? _c("ZoomImage", {
                ref: "zoomImage",
                attrs: {
                  src: "/files/images/seatings/" + _vm.ticket_image,
                  alt: "Imagen Ticket",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _c("div", {
            domProps: { innerHTML: _vm._s(_vm.event.meta_pixel_code) },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }