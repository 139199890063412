var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "transform-content" },
    [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "container px-2" }, [
        _c("form", [
          _c("div", { staticClass: "d-flex flex-wrap" }, [
            _c("div", { staticClass: "events col-12 col-md-6" }, [
              _c("div", { staticClass: "card card-custom gutter-b" }, [
                _vm._m(1),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "card-body px-4 px-sm-4 px-md-8 py-4 py-sm-4 py-md-4",
                  },
                  [
                    _c("div", { staticClass: "form-group d-flex flex-wrap" }, [
                      _c(
                        "label",
                        { staticClass: "font-weight-bold color-darkblue" },
                        [_vm._v("Evento:")]
                      ),
                      _vm._v(" "),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.event_id,
                              expression: "event_id",
                            },
                          ],
                          staticClass: "form-control selectpicker w-100",
                          attrs: {
                            id: "eventsPicker",
                            "data-style": "select-lightgreen",
                          },
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.event_id = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            },
                          },
                        },
                        [
                          _c(
                            "option",
                            {
                              attrs: { selected: "" },
                              domProps: { value: "0" },
                            },
                            [
                              _vm._v(
                                "\n                                        Selecciona un evento\n                                    "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _vm._l(
                            _vm.publicRelationsEvents,
                            function (publicRelationsEvent) {
                              return _c(
                                "option",
                                {
                                  key: publicRelationsEvent.event.id,
                                  domProps: {
                                    value: publicRelationsEvent.event.id,
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                                        " +
                                      _vm._s(publicRelationsEvent.event.name) +
                                      " (" +
                                      _vm._s(publicRelationsEvent.event.city) +
                                      ")\n                                    "
                                  ),
                                ]
                              )
                            }
                          ),
                        ],
                        2
                      ),
                    ]),
                    _vm._v(" "),
                    _vm.selectedEvent && _vm.eventDates.length > 1
                      ? _c(
                          "div",
                          { staticClass: "form-group d-flex flex-wrap" },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "font-weight-bold color-darkblue",
                              },
                              [_vm._v("Fechas:")]
                            ),
                            _vm._v(" "),
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.date_id,
                                    expression: "date_id",
                                  },
                                ],
                                staticClass: "form-control selectpicker w-100",
                                attrs: {
                                  id: "datesPicker",
                                  "data-style": "select-lightgreen",
                                },
                                on: {
                                  change: function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.date_id = $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  },
                                },
                              },
                              [
                                _c(
                                  "option",
                                  {
                                    attrs: { selected: "" },
                                    domProps: { value: "0" },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                        Selecciona una fecha\n                                    "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _vm._l(_vm.eventDates, function (date) {
                                  return _c(
                                    "option",
                                    {
                                      key: date.id,
                                      domProps: { value: date.id },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                        " +
                                          _vm._s(date.date_event) +
                                          "\n                                    "
                                      ),
                                    ]
                                  )
                                }),
                              ],
                              2
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.selectedDate
                      ? _c(
                          "div",
                          { staticClass: "form-group d-flex flex-wrap" },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "font-weight-bold color-darkblue",
                              },
                              [_vm._v("Tipos de entradas:")]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "table-responsive border rounded",
                              },
                              [
                                _c(
                                  "table",
                                  {
                                    staticClass:
                                      "table table-striped table-hover mb-0",
                                  },
                                  [
                                    _vm._m(2),
                                    _vm._v(" "),
                                    _c(
                                      "tbody",
                                      [
                                        _vm.ticketTypes.length === 0
                                          ? _c("tr", [
                                              _c(
                                                "td",
                                                { attrs: { colspan: "3" } },
                                                [
                                                  _vm._v(
                                                    "Seleccione un evento y una fecha"
                                                  ),
                                                ]
                                              ),
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm._l(
                                          _vm.ticketTypes,
                                          function (ticket) {
                                            return _c(
                                              "tr",
                                              {
                                                key: ticket.id,
                                                class:
                                                  ticket.tickets_available <= 0
                                                    ? "table-danger text-danger disabled "
                                                    : _vm.ticket_type_id ===
                                                      ticket.id
                                                    ? "table-success"
                                                    : "",
                                                style:
                                                  ticket.tickets_available <= 0
                                                    ? "cursor: not-allowed"
                                                    : "cursor: pointer;",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.selectTicketType(
                                                      ticket
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "td",
                                                  {
                                                    staticClass: "pr-0",
                                                    staticStyle: {
                                                      width: "28px",
                                                    },
                                                  },
                                                  [
                                                    _c("input", {
                                                      attrs: {
                                                        type: "radio",
                                                        disabled:
                                                          ticket.tickets_available <=
                                                          0,
                                                        readonly: "",
                                                      },
                                                      domProps: {
                                                        checked:
                                                          _vm.ticket_type_id ===
                                                          ticket.id,
                                                      },
                                                    }),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "td",
                                                  { staticClass: "px-0 py-2" },
                                                  [
                                                    ticket.tickets_available <=
                                                    0
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "badge bg-danger text-white",
                                                          },
                                                          [_vm._v("Agotadas")]
                                                        )
                                                      : _vm._e(),
                                                    _vm._v(
                                                      "\n                                                " +
                                                        _vm._s(
                                                          ticket.name_ticket
                                                        ) +
                                                        "\n                                                "
                                                    ),
                                                    ticket.name_ticket !==
                                                    ticket.description_ticket
                                                      ? [
                                                          _c("br"),
                                                          _c("small", [
                                                            _vm._v(
                                                              _vm._s(
                                                                ticket.description_ticket
                                                              )
                                                            ),
                                                          ]),
                                                        ]
                                                      : _vm._e(),
                                                  ],
                                                  2
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "td",
                                                  {
                                                    staticStyle: {
                                                      "text-align": "right",
                                                      width: "80px",
                                                    },
                                                  },
                                                  [
                                                    _c("strong", [
                                                      _vm._v(
                                                        _vm._s(
                                                          ticket.num_tickets_pack
                                                            ? (
                                                                ticket.num_tickets_pack *
                                                                ticket.price_ticket
                                                              ).toFixed(2)
                                                            : ticket.price_ticket
                                                        ) + "€"
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                      ],
                                      2
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.selectedTicketType && !_vm.isNumberedTicketType
                      ? _c("div", { staticClass: "form-group" }, [
                          _c(
                            "label",
                            { staticClass: "font-weight-bold color-darkblue" },
                            [_vm._v("Número de entradas:")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex justify-content-between align-items-center",
                            },
                            [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-primary",
                                  attrs: {
                                    type: "button",
                                    disabled: _vm.tickets === 0,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.subtractTickets()
                                    },
                                  },
                                },
                                [_vm._v("-")]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "text-center" }, [
                                _c("strong", [
                                  _vm._v(_vm._s(_vm.tickets) + " entradas"),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-primary",
                                  attrs: {
                                    type: "button",
                                    disabled:
                                      _vm.tickets === _vm.max_num_tickets,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.addTickets()
                                    },
                                  },
                                },
                                [_vm._v("+")]
                              ),
                            ]
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.selectedTicketType && _vm.isNumberedTicketType
                      ? _c(
                          "div",
                          { staticClass: "form-group d-flex flex-wrap" },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex flex-column justify-content-center w-100",
                              },
                              [
                                _vm.ticket_image != null
                                  ? _c("img", {
                                      staticClass: "custom-image mx-auto mt-3",
                                      staticStyle: {
                                        width: "100%",
                                        height: "auto",
                                      },
                                      attrs: {
                                        src:
                                          "/files/images/seatings/" +
                                          _vm.ticket_image,
                                        alt: "Imagen Ticket",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.zoomImage()
                                        },
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.ticket_image != null
                                  ? _c("small", { staticClass: "mx-auto" }, [
                                      _vm._v("Pulse en la imagen para ampliar"),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("label", [
                                  _vm._v("Selecciona el número de asiento"),
                                ]),
                                _vm._v(" "),
                                !_vm.isTicketOne
                                  ? _c(
                                      "select",
                                      {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.ticket_number_select,
                                            expression: "ticket_number_select",
                                          },
                                        ],
                                        staticClass:
                                          "form-control w-100 selectpicker",
                                        attrs: {
                                          name: "ticket_number_select",
                                          id: "tickets_number_select_one",
                                          "data-style": "select-lightgreen",
                                        },
                                        on: {
                                          change: function ($event) {
                                            var $$selectedVal =
                                              Array.prototype.filter
                                                .call(
                                                  $event.target.options,
                                                  function (o) {
                                                    return o.selected
                                                  }
                                                )
                                                .map(function (o) {
                                                  var val =
                                                    "_value" in o
                                                      ? o._value
                                                      : o.value
                                                  return val
                                                })
                                            _vm.ticket_number_select = $event
                                              .target.multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "option",
                                          {
                                            attrs: { disabled: "" },
                                            domProps: { value: 0 },
                                          },
                                          [_vm._v("Seleccione el número")]
                                        ),
                                        _vm._v(" "),
                                        _vm._l(
                                          _vm.array_numbered_tickets,
                                          function (ticket, index) {
                                            return [
                                              !_vm.selected_numbered_tickets.includes(
                                                ticket
                                              )
                                                ? _c(
                                                    "option",
                                                    {
                                                      key: index,
                                                      domProps: {
                                                        value: ticket,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                                " +
                                                          _vm._s(ticket) +
                                                          "\n                                            "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          }
                                        ),
                                      ],
                                      2
                                    )
                                  : _c(
                                      "select",
                                      {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.ticket_number_select,
                                            expression: "ticket_number_select",
                                          },
                                        ],
                                        staticClass:
                                          "form-control w-100 selectpicker",
                                        attrs: {
                                          name: "ticket_number_select",
                                          id: "tickets_number_select",
                                          disabled:
                                            _vm.selected_numbered_tickets
                                              .length > 0,
                                          "data-style": "select-lightgreen",
                                        },
                                        on: {
                                          change: function ($event) {
                                            var $$selectedVal =
                                              Array.prototype.filter
                                                .call(
                                                  $event.target.options,
                                                  function (o) {
                                                    return o.selected
                                                  }
                                                )
                                                .map(function (o) {
                                                  var val =
                                                    "_value" in o
                                                      ? o._value
                                                      : o.value
                                                  return val
                                                })
                                            _vm.ticket_number_select = $event
                                              .target.multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "option",
                                          {
                                            attrs: { disabled: "" },
                                            domProps: { value: 0 },
                                          },
                                          [_vm._v("Seleccione el número")]
                                        ),
                                        _vm._v(" "),
                                        _vm._l(
                                          _vm.array_numbered_tickets,
                                          function (ticket, index) {
                                            return [
                                              !_vm.selected_numbered_tickets.includes(
                                                ticket
                                              )
                                                ? _c(
                                                    "option",
                                                    {
                                                      key: index,
                                                      domProps: {
                                                        value: ticket,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                                " +
                                                          _vm._s(ticket) +
                                                          "\n                                            "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          }
                                        ),
                                      ],
                                      2
                                    ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "selected-tickets mt-3 d-flex g-2 flex-wrap",
                              },
                              _vm._l(
                                _vm.selected_numbered_tickets,
                                function (selected_ticket, index) {
                                  return _c(
                                    "div",
                                    {
                                      key: index,
                                      staticClass:
                                        "cursor-pointer badge bg-primary text-white d-flexalign-items-center",
                                      on: {
                                        click: function ($event) {
                                          return _vm.removeSelectedNumberedTicket(
                                            selected_ticket
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                        Asiento: " +
                                          _vm._s(selected_ticket) +
                                          "\n                                        "
                                      ),
                                      _c("img", {
                                        staticClass: "ml-2",
                                        attrs: {
                                          src: "/svg/accion-eliminar-hover.svg",
                                          height: "20px",
                                        },
                                      }),
                                    ]
                                  )
                                }
                              ),
                              0
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "card card-custom gutter-b" }, [
                _vm._m(3),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "card-body px-4 px-sm-4 px-md-8 py-4 py-sm-4 py-md-4",
                  },
                  [
                    _vm.ticket_type_id !== 0 &&
                    (_vm.selected_numbered_tickets.length > 0 ||
                      _vm.tickets > 0)
                      ? _c("div", [
                          _c(
                            "h6",
                            { staticClass: "d-flex justify-content-between" },
                            [
                              _c("strong", [_vm._v("Fecha:")]),
                              _c("span", [
                                _vm._v(_vm._s(_vm.selectedDate.date_event)),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "h6",
                            { staticClass: "d-flex justify-content-between" },
                            [
                              _c("strong", [_vm._v("Horario de apertura:")]),
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.selectedDate.time_event_since)
                                ),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "h6",
                            { staticClass: "d-flex justify-content-between" },
                            [
                              _c("strong", [_vm._v("Ticket individual:")]),
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.price_ticket.toFixed(2)) + "€"
                                ),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "h6",
                            { staticClass: "d-flex justify-content-between" },
                            [
                              _c("strong", [_vm._v("Nº de tickets:")]),
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.isNumberedTicketType
                                      ? _vm.selected_numbered_tickets.length
                                      : _vm.tickets
                                  ) + " ticket(s)"
                                ),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "h6",
                            { staticClass: "d-flex justify-content-between" },
                            [
                              _c("strong", [_vm._v("Gastos de gestión:")]),
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.purchase_price.commission.toFixed(2)
                                  ) + "€"
                                ),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "h4",
                            {
                              staticClass:
                                "d-flex justify-content-between text-primary",
                            },
                            [
                              _c("strong", [_vm._v("Total:")]),
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    (
                                      _vm.purchase_price.price +
                                      _vm.purchase_price.commission
                                    ).toFixed(2)
                                  ) + "€"
                                ),
                              ]),
                            ]
                          ),
                        ])
                      : _c(
                          "div",
                          {
                            staticClass:
                              "d-flex flex-column justify-content-center",
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: "/svg/entradas.svg",
                                height: "48px",
                                alt: "ticket",
                              },
                            }),
                            _vm._v(" "),
                            _c("p", { staticClass: "h4 text-primary mt-4" }, [
                              _vm._v(
                                "\n                                    Selecciona un evento, un tipo de entrada y marca cuantas entradas quieres.\n                                "
                              ),
                            ]),
                          ]
                        ),
                  ]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "user-data col-12 col-md-6" }, [
              _vm.ticket_type_id !== 0 &&
              (_vm.selected_numbered_tickets.length > 0 || _vm.tickets > 0)
                ? _c("div", { staticClass: "card card-custom gutter-b" }, [
                    _vm._m(4),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "card-body px-4 px-sm-4 px-md-8 py-4 py-sm-4 py-md-4 text-primary",
                      },
                      [
                        _c("div", { staticClass: "form-group" }, [
                          _vm._m(5),
                          _vm._v(" "),
                          _c("div", { staticClass: "d-flex" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.user.email,
                                  expression: "user.email",
                                },
                              ],
                              staticClass: "form-control flex-fill",
                              attrs: {
                                id: "email_register",
                                name: "email",
                                type: "email",
                                required: "",
                                autofocus: "",
                                autocomplete: "off",
                                placeholder: "Email*",
                              },
                              domProps: { value: _vm.user.email },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.user,
                                    "email",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-secondary",
                                attrs: {
                                  type: "button",
                                  disabled: !_vm.user_email_is_valid,
                                },
                                on: { click: _vm.checkEmailExists },
                              },
                              [_vm._v("Buscar")]
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "fv-plugins-message-container" },
                            [
                              _vm.user.email !== "" && !_vm.user_email_is_valid
                                ? _c("div", { staticClass: "fv-help-block" }, [
                                    _vm._v(
                                      "La dirección introducida no es válida"
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _vm.user_email_is_valid && !_vm.user_email_exists
                          ? _c("div", { staticClass: "form-group" }, [
                              _vm._m(6),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.user.email_confirmation,
                                    expression: "user.email_confirmation",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  id: "email_register",
                                  name: "email_confirmation",
                                  type: "email",
                                  required: "",
                                  autofocus: "",
                                  autocomplete: "off",
                                  placeholder: "Confirmación de email",
                                },
                                domProps: {
                                  value: _vm.user.email_confirmation,
                                },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.user,
                                      "email_confirmation",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _vm.user.email_confirmation !== "" &&
                              !_vm.user_email_confirmation_is_valid
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "fv-plugins-message-container",
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "fv-help-block" },
                                        [
                                          _vm._v(
                                            "Las direcciones de correo no coinciden"
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-group" }, [
                          _vm._m(7),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.user.name,
                                expression: "user.name",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              id: "name",
                              name: "name",
                              type: "text",
                              required: "",
                              autofocus: "",
                              autocomplete: "off",
                              placeholder: "Nombre",
                            },
                            domProps: { value: _vm.user.name },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(_vm.user, "name", $event.target.value)
                              },
                            },
                          }),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-group" }, [
                          _vm._m(8),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.user.surnames,
                                expression: "user.surnames",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              id: "surnames",
                              name: "surnames",
                              type: "text",
                              required: "",
                              autofocus: "",
                              autocomplete: "off",
                              placeholder: "Apellidos",
                            },
                            domProps: { value: _vm.user.surnames },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.user,
                                  "surnames",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-group" }, [
                          _vm._m(9),
                          _vm._v(" "),
                          _c(
                            "div",
                            [
                              _c("form-date", {
                                ref: "birthdate",
                                attrs: { id: "birthdate", name: "birthdate" },
                                model: {
                                  value: _vm.user.birthdate,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.user, "birthdate", $$v)
                                  },
                                  expression: "user.birthdate",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "fv-plugins-message-container" },
                            [
                              _vm.date_message !== "" &&
                              !_vm.user_is_over_16_years
                                ? _c("div", { staticClass: "fv-help-block" }, [
                                    _vm._v(
                                      "\n                                        " +
                                        _vm._s(_vm.date_message) +
                                        "\n                                    "
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-group" }, [
                          _vm._m(10),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.user.phone,
                                expression: "user.phone",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              id: "phone",
                              name: "phone",
                              type: "tel",
                              maxlength: "20",
                              required: "",
                              autofocus: "",
                              autocomplete: "off",
                              placeholder: "Teléfono",
                            },
                            domProps: { value: _vm.user.phone },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(_vm.user, "phone", $event.target.value)
                              },
                            },
                          }),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-group" }, [
                          _vm._m(11),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.user.zipcode,
                                expression: "user.zipcode",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              id: "zipcode",
                              name: "zipcode",
                              type: "text",
                              maxlength: "5",
                              required: "",
                              autofocus: "",
                              autocomplete: "off",
                              placeholder: "Código postal",
                            },
                            domProps: { value: _vm.user.zipcode },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.user,
                                  "zipcode",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                          _vm._v(" "),
                          !_vm.user_zipcode_is_valid
                            ? _c("div", {
                                staticClass: "fv-plugins-message-container",
                              })
                            : _vm._e(),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "form-check d-flex align-items-center my-4",
                          },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.terms_and_conditions,
                                  expression: "terms_and_conditions",
                                },
                              ],
                              staticClass: "form-check-input",
                              attrs: {
                                type: "checkbox",
                                id: "terms-and-conditions",
                              },
                              domProps: {
                                checked: Array.isArray(_vm.terms_and_conditions)
                                  ? _vm._i(_vm.terms_and_conditions, null) > -1
                                  : _vm.terms_and_conditions,
                              },
                              on: {
                                change: function ($event) {
                                  var $$a = _vm.terms_and_conditions,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        (_vm.terms_and_conditions = $$a.concat([
                                          $$v,
                                        ]))
                                    } else {
                                      $$i > -1 &&
                                        (_vm.terms_and_conditions = $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1)))
                                    }
                                  } else {
                                    _vm.terms_and_conditions = $$c
                                  }
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "label",
                              {
                                staticClass: "form-check-label ml-2",
                                attrs: { for: "terms-and-conditions" },
                              },
                              [
                                _vm._v(
                                  "\n                                    Acepto los\n                                    "
                                ),
                                _c(
                                  "router-link",
                                  {
                                    staticStyle: { "font-weight": "bold" },
                                    attrs: {
                                      to: { path: "/terms-and-conditions" },
                                      target: "_blank",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                        términos y condiciones\n                                    "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _vm.selected_numbered_tickets.length > 0 ||
                        _vm.tickets > 0
                          ? _c(
                              "div",
                              { staticClass: "form-group text-center mt-8" },
                              [
                                (_vm.ticket_type_id === 0 &&
                                  _vm.selected_numbered_tickets.length > 1) ||
                                _vm.max_num_tickets === 0
                                  ? _c(
                                      "button",
                                      {
                                        staticClass:
                                          "btn btn-block btn-blue cursor-no-pointer",
                                        attrs: {
                                          type: "button",
                                          "data-toggle": "modal",
                                          disabled: "",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                    Entradas agotadas\n                                "
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-block btn-blue",
                                        attrs: {
                                          type: "button",
                                          disabled: !_vm.form_is_valid,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.purchase()
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                    " +
                                            _vm._s(
                                              _vm.loading
                                                ? "Realizando compra, espere..."
                                                : (
                                                    _vm.purchase_price.price +
                                                    _vm.purchase_price
                                                      .commission
                                                  ).toFixed(2) + "€ - Comprar"
                                            ) +
                                            "\n                                "
                                        ),
                                      ]
                                    ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    ),
                  ])
                : _vm._e(),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _vm.ticket_image != null
        ? _c("ZoomImage", {
            ref: "zoomImage",
            attrs: {
              src: "/files/images/seatings/" + _vm.ticket_image,
              alt: "Imagen Ticket",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "bg-header-general d-flex align-items-end" },
      [
        _c("div", { staticClass: "container pt-4" }, [
          _c("div", { staticClass: "col-md-6 d-flex align-items-end" }, [
            _c("div", { staticClass: "p-4", staticStyle: { height: "60px" } }, [
              _c(
                "div",
                { staticStyle: { position: "absolute", bottom: "10px" } },
                [
                  _c("h3", { staticClass: "color-white" }, [
                    _vm._v("Venta de entradas"),
                  ]),
                ]
              ),
            ]),
          ]),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "card-header bg-color-white border-0 px-4 px-sm-4 px-md-8",
      },
      [
        _c("div", { staticClass: "card-title" }, [
          _c(
            "h5",
            { staticClass: "card-label font-weight-bolder color-darkblue" },
            [
              _vm._v(
                "\n                                    Eventos\n                                "
              ),
            ]
          ),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", { attrs: { scope: "col", colspan: "2" } }, [_vm._v("Tipos")]),
        _vm._v(" "),
        _c(
          "th",
          { staticStyle: { "text-align": "right" }, attrs: { scope: "col" } },
          [_vm._v("Precio")]
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "card-header bg-color-white border-0 px-4 px-sm-4 px-md-8",
      },
      [
        _c("div", { staticClass: "card-title" }, [
          _c("h5", { staticClass: "font-weight-bolder color-darkblue" }, [
            _vm._v("Resumen de pago"),
          ]),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "card-header bg-color-white border-0 px-4 px-sm-4 px-md-8",
      },
      [
        _c("div", { staticClass: "card-title" }, [
          _c("h5", { staticClass: "font-weight-bolder color-darkblue" }, [
            _vm._v("Datos del comprador"),
          ]),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "font-weight-bold color-darkblue" }, [
      _vm._v("Email"),
      _c("sup", [_vm._v("*")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "font-weight-bold color-darkblue" }, [
      _vm._v("Confirmación de email"),
      _c("sup", [_vm._v("*")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "font-weight-bold color-darkblue" }, [
      _vm._v("Nombre"),
      _c("sup", [_vm._v("*")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "font-weight-bold color-darkblue" }, [
      _vm._v("Apellidos"),
      _c("sup", [_vm._v("*")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "font-weight-bold color-darkblue" }, [
      _vm._v("Fecha de nacimiento"),
      _c("sup", [_vm._v("*")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "font-weight-bold color-darkblue" }, [
      _vm._v("Teléfono"),
      _c("sup", [_vm._v("*")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "font-weight-bold color-darkblue" }, [
      _vm._v("Código postal"),
      _c("sup", [_vm._v("*")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }