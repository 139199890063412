<template>
	<div class="transform-content">
		<HeaderMyCollectionComponent
			:me_collection="me_collection"
		></HeaderMyCollectionComponent>
		<MyCollectionTabsTablesComponent
			ref="my_collection_tabs_table"
		></MyCollectionTabsTablesComponent>
	</div>
</template>

<script>
import HeaderMyCollectionComponent from "./HeaderMyCollectionComponent.vue";
import MyCollectionTabsTablesComponent from "./MyCollectionTabsTablesComponent.vue";
export default {
	components: {
		HeaderMyCollectionComponent,
		MyCollectionTabsTablesComponent,
	},
	data() {
		return {
			me_collection: "",
			address: "",
			test: "",
		};
	},
	methods: {
		updateAddress(address) {
			let me = this;
			me.address = address;
			me.$refs.my_collection_tabs_table.updateAddress(me.address);
		},
	},
	mounted() {
		this.me_collection = this;
	},
};
</script>
