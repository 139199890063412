<template>
    <div v-if="event.active == 1" class="transform-content">
        <div class="bg-header-general">
            <div class="container pt-4 mt-4 px-2">
                <div class="col-md-6 d-flex align-items-end" v-if="event != ''">
                    <img
                        v-bind:src="'/files/images/events/' + event.img_event"
                        style="object-fit: cover; border-radius: 0.5rem; width: 200px; height: auto; max-width: 30vw; aspect-ratio: 1 / 1;"
                    />
                    <div class="px-4 pt-8">
                        <h2 class="color-white font-weight-bolder">
                            <!--Concierto intrumentos de cuerda al aire libre-->
                            {{ event.name }}
                        </h2>
                        <div class="d-flex align-items-center">
                            <img
                                :src="publicPath + '/svg/calendario-blanco.svg'"
                                width="15px"
                                height="auto"
                            />
                            <!--<span class="color-white mx-1">Viernes, 25 de Febrero</span>-->
                            <span class="color-white mx-1">{{ custom_date }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container px-2">
            <div class="d-flex flex-wrap">
                <div class="description col-12 col-md-6 order-1 order-sm-1 order-md-0">
                    <div class="p-2">
                        <div class="d-flex mt-4 mb-6">
                            <p v-html="event.description" class="color-white description-event-file"></p>
                        </div>
                        <div class="mt-4">
                            <div><span class="color-white font-weight-bolder">Entradas</span></div>
                            <div class="mb-3"><span class="color-white mb-3">Tipos de entradas:</span></div>
                            <ul class="color-white">
                                <li class="mb-3" v-for="ticket in array_tickets" :key="ticket.id" v-if="ticket.show_description">
                                    {{ ticket.name_ticket }} ({{ ticket.description_ticket }})
                                </li>
                            </ul>
                        </div>

                        <div class="direccion mt-4">
                            <div>
								<span class="color-white font-weight-bolder">Dirección</span><br/>
                                <span class="color-white">{{ event.address }}</span>
                            </div>
                        </div>

                        <div class="mt-10">
                            <span class="color-white font-weight-bolder">Cómo llegar</span>
                            <div class="my-2"><div id="map"></div></div>
                        </div>
                    </div>
                </div>

                <div id="realiza-tu-compra" class="buy-tickets col-12 col-md-6 position-relative order-0 order-sm-0 order-md-1">
                    <div class="custom-card-absolute">
                        <div class="card card-custom gutter-b">
                            <div class="card-header bg-color-white border-0 px-4 px-sm-4 px-md-8">
                                <div class="card-title">
                                    <h5 class="card-label font-weight-bolder color-darkblue">
                                        Realiza tu compra
                                    </h5>
                                </div>
                            </div>
                            <div class="card-body px-4 px-sm-4 px-md-8 py-4 py-sm-4 py-md-8">
                                <div class="form-group" style="display: none">
                                    <label class="font-weight-bold color-darkblue">Selecciona una fecha:</label>
                                    <select
                                        class="form-control selectpicker w-100 select-date"
                                        v-model="select_date_event"
                                        name="select_date_event"
                                        id="select_date_event"
                                        data-style="select-lightgreen"
                                        @change="onChangeSelectTypeDate($event)"
                                    >
                                        <option
                                            v-for="date in array_dates"
                                            :key="date.id"
                                            :value="date.id"
                                        >
                                            {{ date.date_event }}
                                        </option>
                                    </select>
                                </div>
                                <div
                                    class="form-group d-flex flex-wrap"
                                    id="div_select_type_ticket"
                                >
                                    <label class="font-weight-bold color-darkblue">Tipo de entrada:</label>
<!--                                    <select-->
<!--                                        class="form-control selectpicker w-100"-->
<!--                                        name="type_ticket"-->
<!--                                        id="select_type_ticket"-->
<!--                                        v-model="select_type_ticket"-->
<!--                                        data-style="select-lightgreen"-->
<!--                                    >-->
<!--                                        <option-->
<!--                                            v-if="length_array_tickets > 1"-->
<!--                                            :value="'0'"-->
<!--                                            selected-->
<!--                                        >-->
<!--                                            Selecciona un tipo de entrada-->
<!--                                        </option>-->
<!--                                        <option-->
<!--                                            :data-price="ticket.price"-->
<!--                                            :data-commission="ticket.commission"-->
<!--                                            v-for="ticket in array_tickets_select"-->
<!--                                            :key="ticket.id"-->
<!--                                            :value="ticket.id"-->
<!--                                        >-->
<!--                                            {{ ticket.name_ticket }}-->
<!--                                        </option>-->
<!--                                    </select>-->

                                    <div class="table-responsive border rounded">
                                        <table class="table table-striped table-hover mb-0">
                                            <thead>
                                            <tr>
                                                <th scope="col" colspan="2">Tipos</th>
                                                <th scope="col" style="text-align: right">Precio</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr
                                                v-for="ticket in array_tickets_select"
                                                :key="ticket.id"
                                                :style="ticket.tickets_available <= 0 ? 'cursor: not-allowed' : 'cursor: pointer;'"
                                                :class="ticket.tickets_available <= 0 ? 'table-danger text-danger disabled ' : (select_type_ticket === ticket.id ? 'table-success' : '')"
                                                @click="selectTicketType(ticket)"
                                            >
                                                <td class="pr-0" style="width:28px"><input type="radio" :checked="select_type_ticket === ticket.id" :disabled="ticket.tickets_available <= 0" readonly></td>
                                                <td class="px-0 py-2">
                                                    <span class="badge bg-danger text-white" v-if="ticket.tickets_available <= 0">Agotadas</span>
                                                    {{ ticket.name_ticket }}
                                                    <template v-if="ticket.name_ticket !== ticket.description_ticket"><br><small>{{ ticket.description_ticket }}</small></template>
                                                </td>
                                                <td style="text-align: right; width: 80px;"><strong>{{ ticket.num_tickets_pack ? (ticket.num_tickets_pack * ticket.price_ticket).toFixed(2) : ticket.price_ticket }}€</strong></td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <div v-if="numbered_ticket" class="d-flex flex-column justify-content-center w-100">
                                        <img
                                            @click="zoomImage()"
                                            v-if="ticket_image != null"
                                            :src="'/files/images/seatings/' + ticket_image"
                                            alt="Imagen Ticket"
                                            class="custom-image mx-auto mt-3"
                                        />
                                        <small class="mx-auto" v-if="ticket_image != null">Pulse en la imagen para ampliar</small>
                                    </div>
                                </div>
                                <div v-if="!numbered_ticket">
                                    <div v-if="anticipado == 0">
                                        <div class="form-group touchspin-custom">
                                            <input
                                                id="touchspin_num_tickets"
                                                type="text"
                                                value="1"
                                                class="form-control text-align-end"
                                                name="num_tickets"
                                            />
                                        </div>
                                        <div
                                            class="form-group text-center mt-8"
                                            v-if="select_type_ticket != 0"
                                        >
                                            <button
                                                type="button"
                                                class="btn btn-blue cursor-no-pointer"
                                                data-toggle="modal"
                                                v-if="
													(select_type_ticket == 0 &&
														length_array_tickets > 1) ||
													max_num_tickets == 0
												"
                                                disabled
                                            >
                                                Entradas agotadas
                                            </button>
                                            <!--											<button-->
                                            <!--												type="button"-->
                                            <!--												class="btn btn-blue"-->
                                            <!--												data-toggle="modal"-->
                                            <!--												data-bs-toggle="offcanvas"-->
                                            <!--												data-bs-target="#offcanvasLogin"-->
                                            <!--												v-on:click="showAlertLogin()"-->
                                            <!--												v-else-if="!is_login"-->
                                            <!--											>-->
                                            <!--												{{ price_ticket }}€ - Comprar-->
                                            <!--											</button>-->
                                            <button
                                                type="button"
                                                class="btn btn-blue"
                                                data-toggle="modal"
                                                data-target="#buyModal"
                                                v-else
                                            >
                                                {{ price_ticket }}€ - Comprar
                                            </button>
                                        </div>
                                    </div>
                                    <div v-else>
                                        <div class="form-group">
                                            <label class="font-weight-bold color-darkblue"
                                            >Código de acceso antincipado</label
                                            >
                                            <input
                                                v-model="code_check"
                                                id="code_check"
                                                type="text"
                                                class="form-control"
                                                name="code_check"
                                            />
                                            <div class="form-group text-center mt-8">
                                                <button
                                                    v-on:click="checkCodeEarlyCheck()"
                                                    type="button"
                                                    class="btn btn-blue"
                                                    data-toggle="modal"
                                                >
                                                    Validar
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    v-else-if="
										array_numbered_tickets.length || selected_tickets.length
									"
                                >
                                    <!-- <p>Seleccione el número de entradas que desea comprar</p> -->
                                    <select
                                        v-if="!ticket_one"
                                        class="form-control w-100"
                                        name="ticket_number_select"
                                        v-model="ticket_number_select"
                                    >
                                        <option disabled :value="null">Seleccione el número</option>
                                        <option
                                            v-for="(ticket, index) in array_numbered_tickets"
                                            :key="index"
                                            :value="ticket"
                                        >
                                            {{ ticket }}
                                        </option>
                                    </select>
                                    <select
                                        v-else
                                        class="form-control w-100"
                                        name="ticket_number_select"
                                        v-model="ticket_number_select"
                                        :disabled="selected_tickets.length > 0"
                                    >
                                        <option disabled :value="null">Seleccione el número</option>
                                        <option
                                            v-for="(ticket, index) in array_numbered_tickets"
                                            :key="index"
                                            :value="ticket"
                                        >
                                            {{ ticket }}
                                        </option>
                                    </select>
                                    <div class="selected-tickets mt-3">
                                        <div
                                            class="
												cursor-pointer
												badge
												bg-primary
												text-white
												d-flexalign-items-center
											"
                                            @click="removeSelectedTicket(selected_ticket)"
                                            v-for="(selected_ticket, index) in selected_tickets"
                                            :key="index"
                                        >
                                            Asiento: {{ selected_ticket }}
                                            <img
                                                src="/svg/accion-eliminar-hover.svg"
                                                height="20px"
                                                class="ml-2"
                                            />
                                        </div>
                                    </div>
                                    <div
                                        class="d-flex justify-content-center mt-3"
                                        v-if="selected_tickets.length"
                                    >
<!--                                        <button-->
<!--                                            type="button"-->
<!--                                            class="btn btn-blue"-->
<!--                                            data-toggle="modal"-->
<!--                                            data-bs-toggle="offcanvas"-->
<!--                                            data-bs-target="#offcanvasLogin"-->
<!--                                            v-on:click="showAlertLogin()"-->
<!--                                            v-if="!is_login"-->
<!--                                        >-->
<!--                                            {{ price_ticket }}€ - Comprar prueba 3-->
<!--                                        </button>-->
                                        <button
                                            type="button"
                                            class="btn btn-blue"
                                            data-toggle="modal"
                                            data-target="#buyModal"
                                            @click="setCustomData()"
                                        >
                                            {{ ticket_price.toFixed(2)  }}€ - Comprar
                                        </button>
                                    </div>
                                </div>
                                <div class="d-flex justify-content-center mt-3" v-else>
                                    <button
                                        type="button"
                                        class="btn btn-blue cursor-no-pointer"
                                        data-toggle="modal"
                                        v-if="
											(select_type_ticket == 0 && length_array_tickets > 1) ||
											max_num_tickets == 0
										"
                                        disabled
                                    >
                                        Entradas agotadas
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div
            class="modal fade"
            ref="buyModal"
            id="buyModal"
            tabindex="-1"
            role="dialog"
            aria-labelledby="buyModalTitle"
            aria-hidden="true"
            style="z-index: 99999"
        >
            <div :class="!is_login ? 'modal-dialog modal-dialog-scrollable modal-dialog-centered modal-lg' : 'modal-dialog modal-dialog-scrollable modal-dialog-centered'" role="document">
                <form
                    :id="'form_buy'"
                    :action="redsys_url"
                    method="POST"
                >
                    <input
                        id="Ds_SignatureVersion"
                        type="hidden"
                        name="Ds_SignatureVersion"
                        v-model="redsys_ds_signature_version"
                    />
                    <input
                        id="Ds_MerchantParameters"
                        type="hidden"
                        name="Ds_MerchantParameters"
                        value=""
                    />
                    <input id="Ds_Signature" type="hidden" name="Ds_Signature" value=""/>
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">Comprar entradas</h5>
                        </div>


                        <div class="modal-body">

                            <div :key="event.id" class="d-flex flex-wrap">
                                <!-- Personal data -->
                                <div class="col-12 col-md-6" v-if="!is_login">
                                    <div class="alert alert-danger" role="alert" v-if="error">
                                        {{ error }}
                                    </div>

                                    <ul class="nav nav-tabs nav-line-tabs mb-5 fs-6">
                                        <li class="nav-item">
                                            <a class="nav-link active" data-bs-toggle="tab" href="#tab_register_user">Invitado</a>
                                        </li>
                                        <li class="nav-item">
                                            <a class="nav-link" data-bs-toggle="tab" href="#tab_login_user">Ya tengo cuenta</a>
                                        </li>
                                    </ul>

                                    <div class="tab-content" id="myTabContent">
                                        <div class="tab-pane fade show active" id="tab_register_user" role="tabpanel">
                                            <div class="form-group">
                                                <label class="font-weight-bold color-darkblue">Nombre<sup>*</sup></label>
                                                <input id="name" name="name" type="text" class="form-control" v-model="user.name"
                                                       required autofocus autocomplete="off" placeholder="Nombre">
<!--                                                <div class="fv-plugins-message-container"-->
<!--                                                     v-if="!user_name_is_valid">-->
<!--                                                    <div class="fv-help-block">El nombre es obligatorio</div>-->
<!--                                                </div>-->
                                            </div>
                                            <div class="form-group">
                                                <label class="font-weight-bold color-darkblue">Apellidos<sup>*</sup></label>
                                                <input id="surnames" name="surnames" type="text" class="form-control"
                                                       v-model="user.surnames" required autofocus autocomplete="off"
                                                       placeholder="Apellidos">
<!--                                                <div class="fv-plugins-message-container"-->
<!--                                                     v-if="!user_surnames_is_valid">-->
<!--                                                    <div class="fv-help-block">Los apellidos son obligatorios</div>-->
<!--                                                </div>-->
                                            </div>
                                            <div class="form-group">
                                                <label class="font-weight-bold color-darkblue">Fecha de nacimiento<sup>*</sup></label>
                                                <div>
                                                    <form-date id="birthday" name="birthday" v-model="user.birthday"/>
                                                </div>
<!--                                                <input id="birthday" name="birthday" type="date" class="form-control"-->
<!--                                                       v-model="user.birthday" required autofocus autocomplete="off"-->
<!--                                                       placeholder="Email">-->
                                                <div class="fv-plugins-message-container">
<!--                                                    <div class="fv-help-block" v-if="user.birthday === ''">La fecha de nacimiento es obligatoria</div>-->
                                                    <div class="fv-help-block" v-if="date_message !== '' && !user_is_over_16_years">{{date_message}}</div>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label class="font-weight-bold color-darkblue">Email<sup>*</sup></label>
                                                <input id="email_register" name="email" type="email" class="form-control"
                                                       v-model="user.email" required autofocus autocomplete="off" placeholder="Email">
                                                <div class="fv-plugins-message-container">
<!--                                                    <div class="fv-help-block" v-if="user.email === ''">El email es obligatorio</div>-->
                                                    <div class="fv-help-block" v-if="user.email !== '' && !user_email_is_valid">La dirección introducida no es válida</div>
                                                    <div class="fv-help-block" v-if="user.email !== '' && user_email_is_valid && user_email_exists">La dirección introducida ya existe</div>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label class="font-weight-bold color-darkblue">Confirmación de email<sup>*</sup></label>
                                                <input id="email_register" name="email_confirmation" type="email" class="form-control"
                                                       v-model="user.email_confirmation" required autofocus autocomplete="off"
                                                       placeholder="Confirmación de email">
                                                <div class="fv-plugins-message-container"
                                                     v-if="user.email_confirmation !== '' && !user_email_confirmation_is_valid">
                                                    <div class="fv-help-block">Las direcciones de correo no coinciden</div>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label class="font-weight-bold color-darkblue">Teléfono<sup>*</sup></label>
                                                <input id="phone" name="phone" type="tel" class="form-control" maxlength="20"
                                                       v-model="user.phone" required autofocus autocomplete="off"
                                                       placeholder="Teléfono">
<!--                                                <div class="fv-plugins-message-container"-->
<!--                                                     v-if="!user_phone_is_valid">-->
<!--                                                    <div class="fv-help-block">El teléfono es obligatorio</div>-->
<!--                                                </div>-->
                                            </div>
                                            <div class="form-group">
                                                <label class="font-weight-bold color-darkblue">Código postal<sup>*</sup></label>
                                                <input id="zipcode" name="zipcode" type="text" class="form-control" maxlength="5"
                                                       v-model="user.zipcode" required autofocus autocomplete="off"
                                                       placeholder="Código postal">
                                                <div class="fv-plugins-message-container"
                                                     v-if="!user_zipcode_is_valid">
<!--                                                    <div class="fv-help-block">El código postal es obligatorio</div>-->
                                                </div>
                                            </div>
                                        </div>
                                        <div class="tab-pane fade" id="tab_login_user" role="tabpanel">
                                            <div class="form-group">
                                                <label class="font-weight-bolder color-darkblue">Email:</label>
                                                <input
                                                    class="form-control"
                                                    type="text"
                                                    name="email"
                                                    v-model="email"
                                                    required
                                                    autofocus
                                                    autocomplete="off"
                                                    placeholder="Escribe tu email"
                                                />
                                            </div>
                                            <div class="form-group">
                                                <div
                                                    class="
                                                        d-flex
                                                        justify-content-between
                                                        align-items-center
                                                        text-center
                                                    "
                                                >
                                                    <label class="font-weight-bolder color-darkblue">Contraseña:</label>
                                                    <a  href="javascript:;"
                                                        role="button"
                                                        class="color-darkgreen"
                                                        data-toggle="modal"
                                                        data-target="#forgetpasswordModal"
                                                    >¿Has olvidado tu contraseña?</a>
                                                </div>
                                                <input
                                                    class="form-control"
                                                    type="password"
                                                    name="password"
                                                    v-model="password"
                                                    required
                                                    autocomplete="off"
                                                    placeholder="Escribe tu contraseña"
                                                />
                                            </div>
                                            <div class="d-flex flex-wrap">
                                                <button
                                                    type="submit"
                                                    class="btn btn-green w-100 mb-3"
                                                    @click="handleSubmit"
                                                >
                                                    Iniciar sesión
                                                </button>
                                                <button
                                                    class="
                                                        facebook-btn
                                                        w-100
                                                        btn
                                                        d-flex
                                                        align-items-center
                                                        justify-content-center
                                                        p-3
                                                    "
                                                    @click="authProvider()"
                                                    type="button"
                                                >
                                                    <img
                                                        src="/media/misc/facebook.png"
                                                        alt="NFT My Ticket - Facebook"
                                                        width="25px"
                                                        class="mr-2"
                                                    />
                                                    <span>Iniciar sesión con Facebook</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Payment summary -->
                                <div :class="!is_login ? 'col-12 col-md-6' : 'col-12'">
                                    <div class="mb-4">
                                        <h5 class="font-weight-bolder color-darkblue">
                                            Resumen de pago
                                        </h5>
                                    </div>
                                    <div class="form-group">
                                        <h6>
                                            Ticket individual:
                                            {{ price_ticket_individual }}€
                                        </h6>
                                    </div>
                                    <div class="form-group">
                                        <h6>Nº de tickets: {{ num_tickets }} ticket(s)</h6>
                                    </div>
                                    <div class="form-group">
                                        <h6>
                                            Gastos de gestión:
                                            {{ parseFloat(commission_ticket).toFixed(2) }}€
                                        </h6>
                                    </div>
                                    <div class="form-group">
                                        <h6>
                                            Total:
                                            {{ parseFloat(price_commission).toFixed(2) }}€
                                        </h6>
                                    </div>
                                    <div class="form-group" v-if="pay_selected == 3">
                                        <h6>Cantidad USDT: {{ price_usdt }} USDT</h6>
                                    </div>

                                    <div class="form-group mt-5" v-if="total_gifts > 0">
                                        <label class="font-weight-bold color-darkblue">Usar saldo regalo:</label>
                                        <div class="input-group mb-2">
                                            <div class="input-group-prepend">
                                                <div class="input-group-text">
                                                    {{ total_gifts.toFixed(2) }}€
                                                </div>
                                            </div>
                                            <input
                                                type="text"
                                                class="form-control"
                                                id="saldo_regalo_a_usar"
                                                placeholder="Saldo de regalo a usar"
                                                v-model="saldo_regalo_a_usar"
                                                @keypress="comprobarSaldo($event)"
                                            />
                                            <small class="w-100 mt-2" style="color: gray"
                                            >(Los gastos de gestión no se descontarán del total del
                                                pedido)</small
                                            >
                                        </div>
                                    </div>

                                    <div v-if="price_commission != 0">
                                        <div class="mb-4 mt-5">
                                            <h5 class="font-weight-bolder color-darkblue">
                                                Selecciona un método de pago
                                            </h5>
                                        </div>
                                        <div class="form-group">
                                            <div
                                                class="selected border-rounded-green d-flex p-2 cursor-pointer select-method-pay-card"
                                                v-on:click="selectedItemPayEvents('card', $event)"
                                            >
                                                <img
                                                    class="mx-2 pe-none"
                                                    :src="publicPath + '/svg/tarjeta.svg'"
                                                    width="20px"
                                                    height="auto"
                                                />
                                                <span class="font-weight-bold pe-none">Tarjeta de crédito</span>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <div
                                                class="border-rounded-green d-flex p-2 cursor-pointer select-method-pay-bono_cultural"
                                                v-on:click="selectedItemPayEvents('bono_cultural', $event)"
                                            >
                                                <img
                                                    class="mx-2 pe-none"
                                                    :src="publicPath + '/svg/bono_cultural.svg'"
                                                    width="20px"
                                                    height="auto"
                                                />
                                                <span class="font-weight-bold pe-none">Bono Cultural</span>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <div
                                                class="border-rounded-green d-flex p-2 cursor-pointer"
                                                v-on:click="selectedItemPayEvents('coinbase', $event)"
                                            >
                                                <img
                                                    class="mx-2 pe-none"
                                                    :src="publicPath + '/svg/coinbase.svg'"
                                                    width="20px"
                                                    height="auto"
                                                />
                                                <span class="font-weight-bold pe-none">Coinbase Commerce</span>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <div
                                                class="border-rounded-green d-flex p-2 cursor-pointer"
                                                v-on:click="selectedItemPayEvents('metamask', $event)"
                                            >
                                                <img
                                                    class="mx-2 pe-none"
                                                    :src="publicPath + '/svg/metamask.svg'"
                                                    width="20px"
                                                    height="auto"
                                                />
                                                <span class="font-weight-bold pe-none">Metamask</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-check">
                                        <input
                                            type="checkbox"
                                            class="form-check-input"
                                            id="terms-and-conditions"
                                            v-model="terms_and_conditions"
                                        />
                                        <label class="form-check-label" for="terms-and-conditions">
                                            Acepto los
                                            <router-link
                                                style="font-weight: bold;"
                                                @click.native="goToTermsAndConditions()"
                                                :to="{ path: '/terms-and-conditions' }"
                                            >
                                                términos y condiciones
                                            </router-link>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="modal-footer justify-content-between">
                            <button
                                type="button"
                                id="cancel_modal_buy"
                                class="btn btn-cancel btn-cancel-buy"
                                data-dismiss="modal"
                            >
                                Cancelar
                            </button>
                            <button
                                v-if="!loading"
                                type="button"
                                v-on:click="payEvent()"
                                class="btn btn-blue"
                                :disabled="!form_is_valid"
                            >
                                {{ parseFloat(price_commission).toFixed(2) }}€ - Comprar
                            </button>
                            <button
                                v-else
                                type="button"
                                class="btn btn-blue d-flex align-items-center"
                            >
                                Cargando
                                <div class="loading-transaction"></div>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>

        <ZoomImage
            ref="zoomImage"
            v-if="ticket_image != null"
            :src="'/files/images/seatings/' + ticket_image"
            alt="Imagen Ticket"
        />
    </div>
</template>

<script>
import json_abi_polygon from "../../../public/abi_usdt_polygon.json";
import ZoomImage from "./ZoomImageComponent.vue";
import c from '../const.js';
import validateBirtday from "./util/dateUtils";
import FormDate from "./util/FormDate.vue";


export default {
    components: {
        ZoomImage,
        FormDate
    },
    data() {
        return {
            redsys_url: window.REDSYS_URL,
            redsys_ds_signature_version: window.REDSYS_DS_SIGNATUREVERSION ?? 'HMAC_SHA256_V1',
            publicPath: window.location.origin,
            pay_selected: 1,
            id_event: "",
            event: "",
            array_tickets: [],
            length_array_tickets: 0,
            array_dates: [],
            custom_date: "",
            select_type_ticket: 0,
            price_ticket: 0,
            price_ticket_individual: 0,
            num_tickets: 1,
            id_type_ticket: 0,
            select_date_event: 0,
            commission_ticket: 0,
            price_commission: 0,
            account_wallet: "",
            abi: JSON.stringify(json_abi_polygon),
            web3: "",
            value_usdt: 0,
            price_usdt: 0,
            is_login: window.Laravel.isLoggedin,
            map: "",
            max_num_tickets: 0,
            min_num_tickets: 1,
            innerWidth: window.innerWidth,
            array_tickets_select: [],
            saldo_regalo_a_usar: "",
            total_gifts: 0,
            terms_and_conditions: false,
            loading: false,
            eventHasNFT: false,
            ticket_image: null,
            all_tickets_sold_out: false,
            anticipado: 0,
            code_check: "",

            new_ticket_price: 0,
            numbered_ticket: false,
            ticket_one: false,
            ticket_number_select: null,
            max_numbered_ticket: 0,
            array_numbered_tickets: [],
            selected_tickets: [],

            // Preregistration user
            error: false,
            user: {
                name: '',
                surnames: '',
                email: '',
                email_confirmation: '',
                birthday: '',
                phone: '',
                zipcode: ''
            },
            date_message: '',
            user_email_exists: false,
            email: "",
            password: "",
            reloadAfterLogin: false
        }
    },
    computed: {
        user_name_is_valid() {
            return this.user.name !== ''
        },
        user_surnames_is_valid() {
            return this.user.surnames !== ''
        },
        user_phone_is_valid() {
            return this.user.phone !== '' && this.user.phone.length <= 20
        },
        user_zipcode_is_valid() {
            return this.user.zipcode !== '' && this.user.zipcode.length === 5
        },
        user_is_over_16_years() {
            const dateValid = validateBirtday(this.user.birthday);
            this.date_message = dateValid.message;
            return dateValid.message === '';
        },
        user_email_confirmation_is_valid() {
            let is_empty = this.user.email_confirmation === '';
            let is_valid = !is_empty && this.emailValid(this.user.email_confirmation)
            return is_valid && this.user.email === this.user.email_confirmation
        },
        user_email() {
            return this.user.email
        },
        user_email_is_valid() {
            let is_empty = this.user.email === '';
            return !is_empty && this.emailValid(this.user.email)
        },
        form_is_valid() {
            return this.is_login || (
                this.user_name_is_valid
                && this.user_surnames_is_valid
                && this.user_phone_is_valid
                && this.user_zipcode_is_valid
                && this.user_is_over_16_years
                && this.user_email_is_valid
                && !this.user_email_exists
                && this.user_email_confirmation_is_valid
            )
        },
        ticket_price() {
            return this.new_ticket_price * this.selected_tickets.length;
        },
    },
    watch: {
        async user_email() {
            if (this.user_email_is_valid) {
                this.user_email_exists = await this.checkEmailExists(this.user.email)
            }
        },
        select_type_ticket() {
            this.selected_tickets = [];
            this.array_tickets_select.forEach((ticket, index) => {
                if (ticket.id == this.select_type_ticket) {
                    this.new_ticket_price = parseFloat(ticket.price_ticket);
                    this.array_numbered_tickets = [];
                    this.numbered_ticket = ticket.numbered_ticket == "1";
                    this.ticket_one = ticket.ticket_one == "1";
                    for (
                        let index = parseInt(ticket.ticket_range_since);
                        index < parseInt(ticket.ticket_range_until) + 1;
                        index++
                    ) {
                        this.array_numbered_tickets.push(index);
                    }

                    ticket.sales.forEach((sale) => {
                        if (
                            this.array_numbered_tickets.includes(parseInt(sale.num_ticket))
                        ) {
                            const id = this.array_numbered_tickets.indexOf(
                                parseInt(sale.num_ticket)
                            );
                            this.array_numbered_tickets.splice(id, 1);
                        }
                    });

                    this.ticket_image = ticket.image_seating;
                }
            });
        },
        all_tickets_sold_out() {
            // alert("Todas las entradas vendidas");
        },
        ticket_number_select() {
            if (this.ticket_number_select != null) {
                this.selected_tickets.push(this.ticket_number_select);
                this.selected_tickets.sort(function (a, b) {
                    return a - b;
                });

                const index = this.array_numbered_tickets.indexOf(
                    this.ticket_number_select
                );
                this.array_numbered_tickets.splice(index, 1);

                this.ticket_number_select = null;
            }
        },
    },
    methods: {
        log(item) {
            console.log(item)
        },
        getYears(birthday) {
            let today = new Date();
            let birthdate = new Date(birthday);
            let years = today.getFullYear() - birthdate.getFullYear();
            let month = today.getMonth() - birthdate.getMonth();
            if (month < 0 || (month === 0 && today.getDate() < birthdate.getDate())) {
                years--;
            }
            return years
        },
        zoomImage() {
            this.$refs.zoomImage.show();
        },
        getInfoUser() {
            let me = this;
            axios
                .get("/get_info_user")
                .then(function (response) {
                    if (response.data.code == 1000) {
                        me.total_gifts = response.data.gift;

                        me.is_login = true;
                        me.Laravel = {
                            isLoggedin: true,
                            user: response.data.user
                        }
                    }
                })
                .catch(function (error) {
                    // handle error
                    console.error(error);
                });
        },
        selectTicketType(ticket) {
            if (ticket.tickets_available > 0) {
                this.select_type_ticket = ticket.id
            }
        },
        showBidModal() {
            $(".value-bid").val(this.nft.price);
            $("#bidModal").modal("toggle");
        },
        selectedItemPayEvents(type, event) {
            let me = this;
            if (type == "card") {
                $(".border-rounded-green").removeClass("selected");
                $(event.target).addClass("selected");
                me.pay_selected = 1;
                document.getElementById("form_buy").action = me.redsys_url;
            }

            if (type == "coinbase") {
                $(".border-rounded-green").removeClass("selected");
                $(event.target).addClass("selected");
                me.pay_selected = 2;
                document.getElementById("form_buy").action = "";
            }

            if (type == "metamask") {
                $(".border-rounded-green").removeClass("selected");
                $(event.target).addClass("selected");
                me.pay_selected = 3;
                document.getElementById("form_buy").action = "";
            }

            if (type == "bono_cultural") {
                $(".border-rounded-green").removeClass("selected");
                $(event.target).addClass("selected");
                me.pay_selected = 4;
                document.getElementById("form_buy").action = me.redsys_url;
            }
        },
        getInfoEvent() {
            let me = this;
            let url = "/get_info_event/" + me.id_event;
            axios
                .get(url)
                .then(function (response) {
                    var html_description = "";
                    var description = response.data.event.description;
                    html_description += description;
                    me.event = response.data.event;
                    me.event.description = html_description;

                    //response.data.array_dates[0].array_tickets[0].tickets_available = 0

                    me.array_tickets = response.data.array_tickets;
                    me.array_tickets_select = response.data.array_dates[0].array_tickets
                        .filter(ticketEvent => !ticketEvent.hidden);

                    me.length_array_tickets = me.array_tickets_select.length;
                    me.array_dates = response.data.array_dates;
                    me.custom_date = me.array_dates[0].custom_date;

                    if (new Date(me.array_dates[0].date_event + " " + me.array_dates[0].time_event_end_sell) < new Date()) {
                        me.$router.push("/");
                    }

                    me.select_date_event = me.array_dates[0].id;
                    me.array_dates.forEach(function (value, index, array) {
                        if (me.select_date_event == value.id) {
                            me.anticipado = value.anticipado;
                        }
                    });

                    me.value_usdt = response.data.value_usdt;

                    let contador_tickets_restantes = 0;
                    me.array_dates[0].array_tickets.forEach((ticket) => {
                        contador_tickets_restantes += ticket.tickets_available;
                    });

                    me.all_tickets_sold_out = contador_tickets_restantes == 0;

                    if (me.length_array_tickets == 1) {
                        me.price_ticket = parseFloat(response.data.array_tickets[0].price_ticket).toFixed(2);
                        me.select_type_ticket = response.data.array_tickets[0].id;
                        me.max_num_tickets = me.array_dates[0].array_tickets[0].tickets_available;

                        $("#touchspin_num_tickets").trigger("touchspin.updatesettings", {
                            max: Math.min.apply(Math, [me.max_num_tickets, c.maxTicketsPerBuy]),
                        });

                        if (me.max_num_tickets == 0) {
                            me.min_num_tickets = 0;
                            $("#touchspin_num_tickets").trigger("touchspin.updatesettings", {
                                min: me.min_num_tickets,
                            });
                        }

                        me.new_ticket_price = response.data.array_tickets[0].price_ticket;
                        me.numbered_ticket = me.array_tickets[0].numbered_ticket == "1";
                        me.ticket_one = me.array_tickets[0].ticket_one == "1";

                        for (
                            let index = parseInt(me.array_tickets[0].ticket_range_since);
                            index < parseInt(me.array_tickets[0].ticket_range_until) + 1;
                            index++
                        ) {
                            me.array_numbered_tickets.push(index);
                        }

                        me.array_tickets[0].sales.forEach((sale) => {
                            if (
                                me.array_numbered_tickets.includes(parseInt(sale.num_ticket))
                            ) {
                                const id = me.array_numbered_tickets.indexOf(
                                    parseInt(sale.num_ticket)
                                );
                                me.array_numbered_tickets.splice(id, 1);
                            }
                        });
                    }

                    //me.initMap(parseFloat(response.data.event.latitude), parseFloat(response.data.event.longitude));

                    setTimeout(function () {
                        me.initMap(
                            parseFloat(response.data.event.latitude),
                            parseFloat(response.data.event.longitude)
                        );
                    }, 500);
                })
                .catch(function (error) {
                    // handle error
                    console.error(error);
                });
        },
        // Initialize and add the map
        initMap(lat, lng) {
            // The location of Uluru
            try{
                const uluru = {lat: parseFloat(lat), lng: parseFloat(lng)};
                // The map, centered at Uluru
                let mapa = new google.maps.Map(document.getElementById("map"), {
                    zoom: 10,
                    center: {lat: parseFloat(lat), lng: parseFloat(lng)},
                });
                // The marker, positioned at Uluru
                const marker = new google.maps.Marker({
                    position: {lat: parseFloat(lat), lng: parseFloat(lng)},
                    map: mapa,
                });

                google.maps.event.addListener(marker, "click", function () {
                    window
                        .open("https://maps.google.com/?q=" + lat + "," + lng, "_blank")
                        .focus();
                });
            }catch (e) {
            }

        },
        changePriceTickets() {
            var me = this;
            let price_comission_aux = 0;

            me.array_tickets.forEach(function (ticket, index, array) {
                if (ticket.id == me.select_type_ticket) {
                    me.commission_ticket = ticket.commission_ticket * me.num_tickets;
                    me.price_ticket_individual = ticket.price_ticket;

                    let price_ticket_aux = ticket.price_ticket * me.num_tickets;

                    price_comission_aux = parseFloat(
                        price_ticket_aux + me.commission_ticket
                    ).toFixed(2);

                    me.price_ticket = parseFloat(price_ticket_aux.toFixed(2));

                    if (me.saldo_regalo_a_usar != "") {
                        price_comission_aux = price_comission_aux - me.saldo_regalo_a_usar;
                        if (price_comission_aux < 0) {
                            price_comission_aux = 0;
                        }
                    }

                    me.price_commission = price_comission_aux;

                    me.price_usdt =
                        Math.round((me.price_commission / me.value_usdt) * 1000000) /
                        1000000;
                }
            });
        },
        createPayMetamask() {
            this.loading = false;
            let me = this;
            // our decimal number
            if (typeof window.ethereum !== "undefined") {
                //Conectar MetaMask con la web y sacar su dirección
                if (window.ethereum.chainId == "0x89") {
                    me.getAccount();
                } else {
                    swal("", "Connect to Polygon network", "warning");
                }
            } else {
                swal("Install the MetaMask extension", "", "warning");
                swal({
                    title: "Install the MetaMask extension",
                    type: "warning",
                    text: 'Si tiene alguna duda con este proceso puede revisar nuestros documentos explicativos en <a href="http://www.externalwebsite.com" target="_blank">AYUDA</a>',
                    html: true,
                });
            }
        },
        // async resetPassword(showMessage = true) {
        //     let me = this;
        //     console.log('Reset password...', me.Laravel.user.email)
        //     try {
        //         await axios.get('/sanctum/csrf-cookie')
        //         let response = await axios.post('/api/reset_password_api', {
        //             email: me.Laravel.user.email,
        //         })
        //         if (response.data.code === 1000 || response.data.code === 1001) {
        //             if (showMessage) {
        //                 swal(
        //                     "Olvidé mi contraseña",
        //                     "Hemos enviado un correo para establecer tu contraseña. Si pasados unos minutos no has recibido nada, revisa tu bandeja de SPAM.",
        //                     "success"
        //                 );
        //             }
        //             $('#forgetpasswordModal .btn-cancel').trigger('click');
        //             // $('#loginModal').modal('toggle');
        //         } else {
        //             this.error = response.data.message
        //         }
        //     } catch (error) {
        //         console.error(error);
        //         swal(
        //             "Se ha producido un error al restablecer la contraseña. Por favor inténtalo de nuevo más tarde. Si el error persiste ponte en contacto con nosotros.",
        //             "error"
        //         );
        //     }
        // },
        getValueQuery() {
            if (localStorage.getItem("show-transaction-success-alert") !== "false" && localStorage.getItem("show-transaction-success-alert") !== null) {
                if (this.$route.query.type !== undefined && this.$route.query.type === "success") {
                    if (localStorage.getItem('send-confirmation-email-on-finish') === 'true') {
                        localStorage.setItem('send-confirmation-email-on-finish', 'false');
                        // this.resetPassword();
                        swal(
                            "Compra realizada correctamente",
                            "Hemos enviado un correo con la factura de compra. Puedes encontrar tu entrada en MI COLECCIÓN",
                            "success"
                        );
                    } else {
                        swal(
                            "Compra realizada correctamente",
                            "Hemos enviado a tu correo la factura de compra y puedes encontrar tu entrada en MI COLECCIÓN",
                            "success"
                        );
                    }
                    //this.$router.push({ path: '/mycollection', query: null }).then()
                    this.$router.push({ path: this.$route.path, query: {'':''} }).then()
                }
                if (this.$route.query.type !== undefined && this.$route.query.type === "error") {
                    if (localStorage.getItem('send-confirmation-email-on-finish') === 'true') {
                        localStorage.setItem('send-confirmation-email-on-finish', 'false');
                        // this.resetPassword();
                        swal(
                            "Error en el proceso de compra",
                            "Se ha producido un error en el proceso de compra.",
                            "error"
                        );
                    } else {
                        swal(
                            "Error en el proceso de compra",
                            "Se ha producido un error en el proceso de compra.",
                            "error"
                        );
                    }
                    this.$router.push({ path: this.$route.path, query: {'':''} }).then()
                }
                localStorage.setItem("show-transaction-success-alert", "false");
            }
        },
        async getAccount() {
            // This function detects most providers injected at window.ethereum
            let me = this;
            var accounts = await ethereum.request({
                method: "eth_requestAccounts",
            });

            me.account_wallet = accounts[0];
            if (me.account_wallet != "") {
                if (window.ethereum.selectedAddress == null) {
                    swal("", "First connect Metamask with the platform", "warning");
                    return;
                }

                var address_obj = await ethereum.request({
                    method: "eth_requestAccounts",
                });

                var address = address_obj[0];
                var address_to = address;
                var amount_m = this.toFixed(me.price_usdt * 1000000);
                var smart_contract = "0xc2132D05D31c914a87C6611C10748AEb04B58e8F";

                var contract = new me.web3.eth.Contract(
                    JSON.parse(me.abi),
                    smart_contract
                );

                let extraData = await contract.methods.transfer(address_to, amount_m);

                var data = extraData.encodeABI();

                var params = {
                    from: address,
                    to: smart_contract,
                    data: data,
                    gas: "71305",
                };

                try {
                    // Request account access if needed
                    // Accounts now exposed, use them

                    ethereum
                        .request({
                            method: "eth_sendTransaction",
                            params: [params],
                        })
                        .then((result) => {
                            //Guardamos el hash de la firma
                            // The result varies by by RPC method.
                            // For example, this method will return a transaction hash hexadecimal string on success.
                            this.payEventMetamask(result, address);
                        })
                        .catch((error) => {
                            console.error(error);
                            swal(
                                "",
                                "Parece que ha habido un error, inténtalo de nuevo más tarde1",
                                "error"
                            );
                            // If the request fails, the Promise will reject with an error.
                        });
                } catch (error) {
                    console.error(error);
                    //deleteToken(id_token)
                    swal(
                        "",
                        "Parece que ha habido un error, inténtalo de nuevo más tarde2",
                        "error"
                    );
                    // User denied account access
                }
            }
        },
        emailValid($email = '') {
            return /^\w+([\.+-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test($email)
        },
        async checkEmailExists($email = '') {
            try {
                let response = await axios
                    .post("/check_email_exists", {email: $email})
                return response && response.data.code === 1000 && response.data.exists
            } catch (e) {
                return false
            }
        },
        async payEvent() {
            let is_valid = true;

            if (this.saldo_regalo_a_usar > this.total_gifts) {
                swal("", "No puede usar más saldo del disponible", "warning");
                this.saldo_regalo_a_usar = this.total_gifts;
                is_valid = false;
            }

            if (
                parseFloat(this.price_ticket + this.commission_ticket).toFixed(2) <
                parseFloat(this.saldo_regalo_a_usar)
            ) {
                swal("", "No puede descontar más del total de los tickets", "warning");
                this.saldo_regalo_a_usar = parseFloat(
                    this.price_ticket + this.commission_ticket
                ).toFixed(2);
                is_valid = false;
            }

            if (this.saldo_regalo_a_usar < 0) {
                swal("", "El saldo regalo no es válido", "warning");
                this.saldo_regalo_a_usar = 0;
                is_valid = false;
            }

            if (this.saldo_regalo_a_usar.toString().includes(",")) {
                this.saldo_regalo_a_usar = this.saldo_regalo_a_usar.replace(",", ".");
            }

            if (!this.terms_and_conditions) {
                swal("", "Debe aceptar nuestros términos y condiciones", "warning");
                is_valid = false;
            }

            this.array_dates.forEach((date) => {
                if (date.id == this.select_date_event) {
                    if (new Date(date.date_event_end_sell) < new Date()) {
                        swal(
                            "",
                            "El plazo para comprar esta entrada ya terminó",
                            "warning"
                        );

                        is_valid = false;
                    }
                }
            });

            if (is_valid) {
                this.loading = true;
                //Register user if not loggedin
                try {
                    if (!this.is_login) {
                        this.user.birthdate = this.user.birthday / 1000;
                        let userRegistrationResponse = await axios.post('/preregister_user', this.user)
                        if (userRegistrationResponse && userRegistrationResponse.data.code === 1000) {
                            localStorage.setItem("send-confirmation-email-on-finish", "true");
                            this.is_login = true;
                            this.Laravel = {
                                isLoggedin: true,
                                user: userRegistrationResponse.data.user
                            }
                            this.reloadAfterLogin = true;
                            //await this.resetPassword(false)
                        }
                    }

                    const params = {
                        type_ticket_id: this.select_type_ticket,
                        selected_tickets: this.selected_tickets,
                        num_tickets: this.num_tickets,
                    };
                    await axios.post("/check_tickets_selected", params)
                    if (this.price_commission === 0) {
                        this.payEventFree();
                    } else {
                        localStorage.setItem("show-transaction-success-alert", "true");
                        switch (this.pay_selected) {
                            case 1: //Card
                                this.payEventCard();
                                break;
                            case 2: //Coinbase
                                this.payEventCoinbase();
                                break;
                            case 3: //Metamask
                                this.createPayMetamask();
                                break;
                            case 4: //Bono Cultural
                                this.payEventBonoCultural();
                        }
                    }

                } catch (error) {
                    console.error(error);
                    if (error.response?.data?.message.length) {
                        swal("", error.response.data.message, "warning");
                    } else {
                        swal(
                            "",
                            "Parece que ha habido un error, intentalo de nuevo más tarde",
                            "warning"
                        );
                    }
                }
                this.loading = false;
            }
        },
        payEventCard() {
            let me = this;
            axios
                .post("/pay_event_redsys", {
                    id_event: me.id_event,
                    id_ticket_event: me.select_type_ticket,
                    num_tickets: me.num_tickets,
                    price: me.price_commission,
                    date: me.select_date_event,
                    saldo_regalo_a_usar: parseFloat(me.saldo_regalo_a_usar).toFixed(2),
                    selected_tickets: this.selected_tickets,
                })
                .then((response) => {
                    this.loading = false;
                    if (response.data.code == 1000) {
                        document.getElementById("Ds_MerchantParameters").value =
                            response.data.Ds_MerchantParameters;
                        document.getElementById("Ds_Signature").value =
                            response.data.Ds_Signature;
                        document.getElementById("form_buy").submit();
                    } else if (response.data.code > 1000) {
                        swal(
                            "",
                            "Parece que ha habido un error, intentalo de nuevo más tarde",
                            "warning"
                        );
                    }
                })
                .catch(function (error) {
                    this.loading = false;
                    console.error(error);
                });
        },
        payEventBonoCultural() {
            let me = this;
            axios
                .post("/pay_event_bono_cultural", {
                    id_event: me.id_event,
                    id_ticket_event: me.select_type_ticket,
                    num_tickets: me.num_tickets,
                    price: me.price_commission,
                    date: me.select_date_event,
                    saldo_regalo_a_usar: parseFloat(me.saldo_regalo_a_usar).toFixed(2),
                    selected_tickets: this.selected_tickets,
                })
                .then((response) => {
                    this.loading = false;
                    if (response.data.code == 1000) {
                        document.getElementById("Ds_MerchantParameters").value =
                            response.data.Ds_MerchantParameters;
                        document.getElementById("Ds_Signature").value =
                            response.data.Ds_Signature;
                        document.getElementById("form_buy").submit();
                    } else if (response.data.code > 1000) {
                        swal(
                            "",
                            "Parece que ha habido un error, intentalo de nuevo más tarde",
                            "warning"
                        );
                    }
                })
                .catch(function (error) {
                    this.loading = false;
                    console.error(error);
                });
        },
        payEventMetamask(hash, address) {
            let me = this;
            axios
                .post("/pay_event_metamask", {
                    id_event: me.id_event,
                    id_ticket_event: me.select_type_ticket,
                    num_tickets: me.num_tickets,
                    price: me.price_commission,
                    date: me.select_date_event,
                    hash: hash,
                    address: address,
                    amount_token: me.price_usdt,
                    saldo_regalo_a_usar: parseFloat(me.saldo_regalo_a_usar).toFixed(2),
                    selected_tickets: this.selected_tickets,
                })
                .then((response) => {
                    if (response.data.code == 1000) {
                        document.getElementById("cancel_modal_buy").click();
                        swal(
                            "Compra realizada correctamente",
                            "Hemos enviado a tu correo la factura de compra y puedes encontrar tu entrada en MI COLECCIÓN",
                            "success"
                        );
                    } else if (response.data.code > 1000) {
                        swal(
                            "",
                            "Parece que ha habido un error, intentalo de nuevo más tarde",
                            "warning"
                        );
                    }
                })
                .catch(function (error) {
                    console.error(error);
                });
        },
        payEventCoinbase() {
            let me = this;
            axios
                .post("/pay_event_coinbase", {
                    id_event: me.id_event,
                    id_ticket_event: me.select_type_ticket,
                    num_tickets: me.num_tickets,
                    price: me.price_commission,
                    date: me.select_date_event,
                    saldo_regalo_a_usar: parseFloat(me.saldo_regalo_a_usar).toFixed(2),
                    selected_tickets: this.selected_tickets,
                })
                .then((response) => {
                    this.loading = false;
                    if (response.data.code == 1000) {
                        window.open(response.data.url);
                    } else if (response.data.code > 1000) {
                        swal(
                            "",
                            "Parece que ha habido un error, intentalo de nuevo más tarde",
                            "warning"
                        );
                    }
                })
                .catch(function (error) {
                    this.loading = false;
                    console.error(error);
                });
        },
        payEventFree() {
            let me = this;
            axios
                .post("/pay_event_free", {
                    id_event: me.id_event,
                    id_ticket_event: me.select_type_ticket,
                    num_tickets: me.num_tickets,
                    price: me.price_commission,
                    date: me.select_date_event,
                    saldo_regalo_a_usar: parseFloat(me.saldo_regalo_a_usar).toFixed(2),
                    selected_tickets: this.selected_tickets,
                })
                .then((response) => {
                    this.loading = false;
                    swal(
                        "",
                        "Su compra se ha realizado con éxito. Muchas gracias.",
                        "success"
                    );

                    $("#cancel_modal_buy").click();
                    this.getInfoUser();
                    this.saldo_regalo_a_usar = "";
                })
                .catch(function (error) {
                    this.loading = false;
                    swal(
                        "",
                        "Ha ocurrido un error. Inténtelo de nuevo más tarde",
                        "error"
                    );
                    console.error(error);
                });
        },
        onChangeSelectTypeDate(event) {
            let me = this;
            var id_date = event.target.value;
            me.array_dates.forEach(function (value, index, array) {
                if (value.id == id_date) {
                    me.array_tickets_select = value.array_tickets;
                    if (id_date == value.id) {
                        me.anticipado = value.anticipado;
                        if (me.anticipado == 1) {
                            document.getElementById("div_select_type_ticket").classList.add("d-none");
                            document.getElementById("div_select_type_ticket").classList.remove("d-flex");
                        }

                        if (me.anticipado == 0) {
                            document.getElementById("div_select_type_ticket").classList.remove("d-none");
                            document.getElementById("div_select_type_ticket").classList.add("d-flex");
                        }
                    }
                    $("#select_date_event").selectpicker("refresh");
                }
            });
        },
        checkCodeEarlyCheck() {
            let me = this;

            if (
                me.select_date_event == 0 ||
                me.select_date_event == undefined ||
                me.code_check == ""
            ) {
                swal("", "Rellena todos los datos", "warning");
                return;
            }

            let params = {
                id_date_event: me.select_date_event,
                code: me.code_check,
            };

            axios
                .post("/check_code_early_check", params)
                .then((response) => {
                    if (response.data.code == 1000) {
                        me.anticipado = 0;
                        document.getElementById("div_select_type_ticket").classList.remove("d-none");
                        document.getElementById("div_select_type_ticket").classList.add("d-flex");
                        me.code_check = "";
                        me.array_dates.forEach(function (value, index, array) {
                            if (value.id == me.select_date_event) {
                                me.array_dates[index].anticipado = 0;
                                me.select_type_ticket = 0;
                            }
                        });
                    } else if (response.data.code == 1004) {
                        swal("", "El código que has introducido no es válido", "warning");
                    } else {
                        swal("", "Rellena todos los datos", "warning");
                    }
                })
                .catch(function (error) {
                });
        },

        //UTILS
        toFixed(x) {
            if (Math.abs(x) < 1.0) {
                var e = parseInt(x.toString().split("e-")[1]);
                if (e) {
                    x *= Math.pow(10, e - 1);
                    x = "0." + new Array(e).join("0") + x.toString().substring(2);
                }
            } else {
                var e = parseInt(x.toString().split("+")[1]);
                if (e > 20) {
                    e -= 20;
                    x /= Math.pow(10, e);
                    x += new Array(e + 1).join("0");
                }
            }
            return x;
        },
        showAlertLogin() {
            swal(
                "",
                "Para realizar una compra debes iniciar sesión o registrarte",
                "warning"
            );

            $(".perfil-hover").parent().click();
        },
        closeModalBuyEvent() {
            console.error('Closing modal...')
            let me = this;
            $(".border-rounded-green").removeClass("selected");
            $(".select-method-pay-card").addClass("selected");
            me.pay_selected = 1;
            me.terms_and_conditions = 0;
            this.user = {
                name: '',
                surnames: '',
                email: '',
                email_confirmation: '',
                birthday: '',
                phone: '',
                zipcode: ''
            }
            this.date_message = '';
            if (this.reloadAfterLogin) {
                window.location.reload()
            }
        },
        openModalBuyEvent() {
            let me = this;
            $(".border-rounded-green").removeClass("selected");
            $(".select-method-pay-card").addClass("selected");
            me.pay_selected = 1;
            me.terms_and_conditions = 0;
            this.user = {
                name: '',
                surnames: '',
                email: '',
                email_confirmation: '',
                birthday: '',
                phone: '',
                zipcode: ''
            }
        },
        comprobarSaldo($event) {
            if (isNaN($event.key) && $event.key != ".") {
                $event.preventDefault();
            }
        },
        goToTermsAndConditions() {
            $(".modal-backdrop").remove();
        },
        removeSelectedTicket(selected_ticket) {
            this.array_numbered_tickets.push(selected_ticket);
            this.array_numbered_tickets.sort(function (a, b) {
                return a - b;
            });

            const index = this.selected_tickets.indexOf(selected_ticket);
            this.selected_tickets.splice(index, 1);
        },
        setCustomData() {
            this.num_tickets = this.selected_tickets.length;
        },
        handleSubmit(e) {
            e.preventDefault();
            if (this.password.length > 0) {
                axios.get("/sanctum/csrf-cookie").then((response) => {
                    axios
                        .post("/api/login", {
                            email: this.email,
                            password: this.password,
                        })
                        .then((response) => {
                            if (response.data.success) {
                                swal("", "Bienvenido/a de nuevo", "success");
                                this.reloadAfterLogin = true;
                                this.getInfoUser()
                                //window.location.reload();
                                // this.$router.go();
                            } else if (response.data.message == "Not found user") {
                                swal("", "El usuario o la contraseña es incorrecto", "error");
                            } else if (
                                response.data.message ==
                                "Must verified your account. Email sent"
                            ) {
                                swal("", "Debes verificar primero tu email", "warning");
                            } else if (response.data.message == "Unauthorised") {
                                swal("", "El usuario o la contraseña es incorrecto", "error");
                            } else {
                                this.error = response.data.message;
                            }
                        })
                        .catch(function (error) {
                            console.error(error);
                            this.error = error.data.message;
                        });
                });
            }
        },
        authProvider() {
            this.$auth
                .authenticate("facebook")
                .then((response) => {
                    this.socialLogin("facebook", response);
                })
                .catch((error) => {
                    console.error(
                        "🚀 ~ file: LoginComponent.vue:177 ~ authProvider ~ error",
                        error
                    );
                });
        },
        socialLogin(provider, response) {
            axios
                .post("/sociallogin/" + provider, response)
                .then(() => {
                    this.reloadAfterLogin = true;
                    swal("", "Bienvenido/a de nuevo", "success");
                    this.getInfoUser()
                })
                .catch((error) => {
                    this.showError(error);
                });
        },
    },
    mounted() {
        const me = this;
        this.web3 = new Web3(window.ethereum);
        this.id_event = this.$route.params.id;
        this.getInfoEvent();
        this.getValueQuery();
        this.getInfoUser();
        let div_select_type_ticket = document.getElementById("div_select_type_ticket");
        if (me.anticipado === 1 && div_select_type_ticket) {
            div_select_type_ticket.classList.add("d-none");
            div_select_type_ticket.classList.remove("d-flex");
        }

        if (me.anticipado === 0 && div_select_type_ticket) {
            div_select_type_ticket.classList.remove("d-none");
            div_select_type_ticket.classList.add("d-flex");
        }
        $(this.$refs.buyModal).on("hide.bs.modal", this.closeModalBuyEvent);
        $(this.$refs.buyModal).on("hidden.bs.modal", this.closeModalBuyEvent);
        $(this.$refs.buyModal).on("hidePrevented.bs.modal", this.closeModalBuyEvent);
        $(this.$refs.buyModal).on("show.bs.modal", this.openModalBuyEvent);

        // Si es vista móvil, colocamos el cuadro de realizar compra justo debajo de la dirección
        // if (this.innerWidth < 500) {
        //     setTimeout(() => {
        //         $("#realiza-tu-compra").appendTo(".direccion");
        //     }, 500);
        // }
    },
    updated() {
        let me = this;
        //this.getValueQuery();
        if (me.length_array_tickets == 1) {
            $("#select_type_ticket").val(me.array_tickets_select[0].id);
            me.select_type_ticket = this.array_tickets_select[0].id;

            me.array_tickets_select.forEach(function (value_tickets, index, array) {
                if (value_tickets.id == me.select_type_ticket) {
                    me.max_num_tickets = value_tickets.tickets_available;

                    $("#touchspin_num_tickets").trigger("touchspin.updatesettings", {
                        max: Math.min.apply(Math, [me.max_num_tickets, c.maxTicketsPerBuy]),
                    });

                    if (me.max_num_tickets == 0) {
                        me.min_num_tickets = 0;
                    } else {
                        me.min_num_tickets = 1;
                    }

                    if (me.array_tickets_select[0].ticket_pack == 1) {
                        $("#touchspin_num_tickets").trigger("touchspin.updatesettings", {
                            step: me.array_tickets_select[0].num_tickets_pack,
                        });
                        $("#touchspin_num_tickets").trigger("touchspin.updatesettings", {
                            min: me.array_tickets_select[0].num_tickets_pack,
                        });
                    } else {
                        $("#touchspin_num_tickets").trigger("touchspin.updatesettings", {
                            step: 1,
                        });
                        $("#touchspin_num_tickets").trigger("touchspin.updatesettings", {
                            min: me.min_num_tickets,
                        });
                    }
                }
            });
        } else {
            me.array_tickets_select.forEach(function (value_tickets, index, array) {
                if (value_tickets.id == me.select_type_ticket) {
                    me.max_num_tickets = value_tickets.tickets_available;

                    $("#touchspin_num_tickets").trigger("touchspin.updatesettings", {
                        max: Math.min.apply(Math, [me.max_num_tickets, c.maxTicketsPerBuy]),
                    });

                    if (me.max_num_tickets == 0) {
                        me.min_num_tickets = 0;
                    } else {
                        me.min_num_tickets = 1;
                    }

                    $("#touchspin_num_tickets").trigger("touchspin.updatesettings", {
                        min: me.min_num_tickets,
                    });

                    if (value_tickets.ticket_pack == 1) {
                        $("#touchspin_num_tickets").trigger("touchspin.updatesettings", {
                            step: value_tickets.num_tickets_pack,
                        });
                        $("#touchspin_num_tickets").trigger("touchspin.updatesettings", {
                            min: value_tickets.num_tickets_pack,
                        });
                    } else {
                        $("#touchspin_num_tickets").trigger("touchspin.updatesettings", {
                            step: 1,
                        });
                        $("#touchspin_num_tickets").trigger("touchspin.updatesettings", {
                            min: me.min_num_tickets,
                        });
                    }
                }
            });
        }

        $("#select_type_ticket").selectpicker("refresh");

        $("#select_date_event").selectpicker("refresh");

        $("#touchspin_num_tickets")
            .TouchSpin({
                buttondown_class: "btn btn-secondary",
                buttonup_class: "btn btn-secondary",
                postfix: "entradas",
                min: me.min_num_tickets,
                max: Math.min.apply(Math, [me.max_num_tickets, c.maxTicketsPerBuy]),
                step: 1,
                decimals: 0,
                boostat: 5,
                maxboostedstep: 10,
            })
            .on("change", function (e) {
                me.num_tickets = e.target.value;
                me.changePriceTickets();
            });

        me.changePriceTickets();

        if (me.anticipado == 1) {
            document.getElementById("div_select_type_ticket").classList.add("d-none");
            document.getElementById("div_select_type_ticket").classList.remove("d-flex");
        }

        if (me.anticipado == 0) {
            document.getElementById("div_select_type_ticket").classList.remove("d-none");
            document.getElementById("div_select_type_ticket").classList.add("d-flex");
        }
    },
};
</script>

<style lang="scss" scoped>
.event-page {
    transform: translateY(-7rem);
}

.loading-transaction {
    border: 3px solid white;
    border-top: 3px solid #bbbbbb;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    margin-left: 0.5rem;
    animation: spin 2s ease infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.selected-tickets {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    max-height: 5rem;
    overflow: auto;
}

.custom-image {
    border-radius: 0.5rem;
    object-fit: cover;
    width: 200px;
    height: 200px;
    margin: auto;
    cursor: pointer;
}
</style>

<style>
.input-group-addon.bootstrap-touchspin-postfix.input-group-append {
    flex: 1 1 auto;
}
.touchspin-custom .input-group > :nth-child(1),
.touchspin-custom .input-group > :nth-child(4){
    width: 40px;
}
.touchspin-custom .input-group > :nth-child(2) {
    width: calc(50% - 120px);
}
/*
.touchspin-custom .input-group :nth-child(2) {
    width: calc(50% - 40px);
}
*/
/*https://stackoverflow.com/questions/9538868/prevent-body-from-scrolling-when-a-modal-is-opened*/
/*body.modal-open {
    overflow: hidden!important;
}*/
</style>

<style lang="scss" scoped>
.facebook-btn {
    border: transparent;
    background-color: #3b5998;
    color: white;
    transition: all 0.3s ease;

    &:focus {
        box-shadow: none;
    }

    &:hover {
        background-color: darken(#3b5998, 10%);
    }
}
</style>
