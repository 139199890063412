<template>
	<div
		class="offcanvas bg offcanvas-end p-2"
		tabindex="1"
		id="offcanvasLogin"
		aria-labelledby="offcanvasLoginLabel"
        style="z-index: 99999;"
	>
		<div class="offcanvas-header border-bottom-white align-items-center">
			<h5 id="offcanvasLoginLabel" class="color-white m-0">Mi cuenta</h5>
			<div>
				<img :src="publicPath + '/svg/perfil.svg'" height="20px" />
				<a class="btn" data-bs-dismiss="offcanvas" aria-label="Close"
					><img
						class="cerrar-hover"
						:src="publicPath + '/svg/cerrar.svg'"
						height="20px"
				/></a>
			</div>
		</div>
		<div class="offcanvas-body">
			<div class="pb-4">
				<form>
					<h3 class="color-green text-center">Bienvenido</h3>
					<div
						class="
							d-flex
							justify-content-around
							align-items-center
							text-center
							mb-4
						"
					>
						<span class="color-white">¿No tienes cuenta?</span>
						<span
							role="button"
							class="color-darkgreen"
							data-toggle="modal"
							data-target="#modal_register"
							>Crear una ahora</span
						>
					</div>
					<div class="form-group">
						<label class="font-weight-bolder color-white">Email:</label>
						<input
							class="form-control"
							type="text"
							name="email"
							v-model="email"
							required
							autofocus
							autocomplete="off"
							placeholder="Escribe tu email"
						/>
					</div>
					<div class="form-group">
						<div
							class="
								d-flex
								justify-content-between
								align-items-center
								text-center
							"
						>
							<label class="font-weight-bolder color-white">Contraseña:</label>
							<a href="javascript:;"
								role="button"
								class="color-darkgreen"
								data-toggle="modal"
								data-target="#forgetpasswordModal"
								>¿Has olvidado tu contraseña?</a
							>
						</div>
						<input
							class="form-control"
							type="password"
							name="password"
							v-model="password"
							required
							autocomplete="off"
							placeholder="Escribe tu contraseña"
						/>
					</div>
					<div class="d-flex flex-wrap">
						<button
							type="submit"
							class="btn btn-green w-100 mb-3"
							@click="handleSubmit"
						>
							Iniciar sesión
						</button>
						<button
							class="
								facebook-btn
								w-100
								btn
								d-flex
								align-items-center
								justify-content-center
								p-3
							"
							@click="authProvider()"
							type="button"
						>
							<img
								src="/media/misc/facebook.png"
								alt="NFT My Ticket - Facebook"
								width="25px"
								class="mr-2"
							/>
							<span>Iniciar sesión con Facebook</span>
						</button>

                        <button
                            @click="appleLogin()"
                            style="margin-top: 10px"
                            class="
								apple-btn
								w-100
								btn
								d-flex
								align-items-center
								justify-content-center
								p-3
							"

                            type="button"
                        >
                            <img
                                src="/media/misc/apple.png"
                                alt="NFT My Ticket - Apple"
                                width="25px"
                                class="mr-2"
                            />
                            <span>Iniciar sesión con Apple</span>
                        </button>
                        <div style="display:none" id="appleid-signin" data-color="black" data-border="true" data-type="sign in"></div>


                    </div>


                    <div class="text-center mt-3">
						<router-link
							:to="{ path: '/help' }"
							@click.native="closeSidebar()"
							class="color-white"
							><span role="button" class="color-white">Ayuda</span></router-link
						>
					</div>
				</form>
			</div>
		</div>
	</div>
</template>

<script>
document.addEventListener('AppleIDSignInOnSuccess', (event) => {
    console.log("AppleIDSignInOnSuccess")
    console.log(event)

    // Handle successful response.
    console.log(event.detail.data);
});
document.addEventListener('AppleIDSignInOnFailure', (event) => {
    console.log("AppleIDSignInOnFailure")

    // Handle error.
    console.log(event.detail.error);
});
export default {
	name: "LoginComponent",
	data() {
		return {
			publicPath: window.location.origin,
			email: "",
			password: "",
			error: null,
		};
	},
	methods: {
        handleAppleIDSignInSuccess(event) {
            axios
                .post("/api/appleSignIn", event.detail)
                .then((response) => {
                    if (response.data.code === 1000) {
                        window.location.reload()
                    }
                    else {
                        swal("Error", response.data.message, "error");
                    }
                })
                .catch((error) => {
                    console.error(error)
                });
        },
        handleAppleIDSignInError(event) {
            console.error(event)
        },
		handleSubmit(e) {
			e.preventDefault();
			if (this.password.length > 0) {
				axios.get("/sanctum/csrf-cookie").then((response) => {
					axios
						.post("/api/login", {
							email: this.email,
							password: this.password,
						})
						.then((response) => {
							if (response.data.success) {
								window.location.reload();
								// this.$router.go();
							} else if (response.data.message == "Not found user") {
								swal("", "El usuario o la contraseña es incorrecto", "error");
							} else if (
								response.data.message ==
								"Must verified your account. Email sent"
							) {
								swal("", "Debes verificar primero tu email", "warning");
							} else if (response.data.message == "Unauthorised") {
								swal("", "El usuario o la contraseña es incorrecto", "error");
							} else {
								this.error = response.data.message;
							}
						})
						.catch(function (error) {
							console.error(error);
							this.error = error.data.message;
						});
				});
			}
		},
		closeSidebar() {
			$('#offcanvasLogin a[data-bs-dismiss="offcanvas"] img').trigger("click");
		},
        appleLogin() {
            $('#appleid-signin').click()
        },
		authProvider() {
			this.$auth
				.authenticate("facebook")
				.then((response) => {
					this.socialLogin("facebook", response);
				})
				.catch((error) => {
					console.error(
						"🚀 ~ file: LoginComponent.vue:177 ~ authProvider ~ error",
						error
					);
				});
		},
		socialLogin(provider, response) {
			axios
				.post("/sociallogin/" + provider, response)
				.then(() => {
					window.location.reload();
				})
				.catch((error) => {
					this.showError(error);
				});
		},
	},
	mounted() {
        document.addEventListener('AppleIDSignInOnSuccess', this.handleAppleIDSignInSuccess);
        document.addEventListener('AppleIDSignInOnFailure', this.handleAppleIDSignInError);
    },
    beforeUnmount() {
        document.removeEventListener('AppleIDSignInOnSuccess', this.handleAppleIDSignInSuccess);
        document.removeEventListener('AppleIDSignInOnFailure', this.handleAppleIDSignInError);
    },
};
</script>


<style lang="scss" scoped>
.facebook-btn {
	border: transparent;
	background-color: #3b5998;
	color: white;
	transition: all 0.3s ease;

	&:focus {
		box-shadow: none;
	}

	&:hover {
		background-color: darken(#3b5998, 10%);
	}
}
.apple-btn {
    border: transparent;
    background-color: #FFFFFF;
    color: black;
    transition: all 0.3s ease;

    &:focus {
        box-shadow: none;
    }

    &:hover {
        background-color: darken(#ffffff, 10%);
    }
}
</style>
