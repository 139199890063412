var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container px-0" }, [
    _c("div", { staticClass: "d-flex justify-content-end my-4" }, [
      _c("div", { staticClass: "col-md-4" }, [
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.id_collection,
                expression: "id_collection",
              },
            ],
            staticClass: "form-control selectpicker select-green w-100",
            attrs: {
              name: "category",
              id: "select_collections_songs_recent",
              "data-style": "select-green",
            },
            on: {
              change: [
                function ($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function (o) {
                      return o.selected
                    })
                    .map(function (o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.id_collection = $event.target.multiple
                    ? $$selectedVal
                    : $$selectedVal[0]
                },
                function ($event) {
                  return _vm.listSongsRecent(2)
                },
              ],
            },
          },
          [
            _c(
              "option",
              { attrs: { selected: "" }, domProps: { value: "0" } },
              [
                _vm._v(
                  "\n                    Selecciona una colección\n                "
                ),
              ]
            ),
            _vm._v(" "),
            _vm._l(_vm.array_collections, function (collection) {
              return _c("option", {
                key: collection.id,
                domProps: {
                  value: collection.id,
                  textContent: _vm._s(collection.name_collection),
                },
              })
            }),
          ],
          2
        ),
      ]),
    ]),
    _vm._v(" "),
    _vm._m(0),
    _vm._v(" "),
    _vm.array_songs_recent.length
      ? _c(
          "div",
          { staticClass: "d-flex flex-wrap justify-content-start" },
          _vm._l(_vm.array_songs_recent, function (song) {
            return _c(
              "div",
              { key: song.id, staticClass: "col-8 col-md-4 col-lg-3 mb-5" },
              [
                _c(
                  "div",
                  { staticClass: "evecan custom" },
                  [
                    _c(
                      "router-link",
                      { attrs: { to: { path: "/song/" + song.id } } },
                      [
                        _c("div", { staticClass: "h-100" }, [
                          _c("img", {
                            staticStyle: { "object-fit": "cover" },
                            attrs: {
                              src: "/files/images/music/" + song.img_music,
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "description p-4 d-flex flex-wrap justify-content-between align-content-between bg-blue",
                            },
                            [
                              _c("div", { staticClass: "w-100" }, [
                                _c("span", {
                                  staticClass:
                                    "event-name color-white font-weight-bold",
                                  domProps: { textContent: _vm._s(song.name) },
                                }),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "w-100 text-align-end" },
                                [
                                  _c("span", {
                                    staticClass: "h2 color-green",
                                    domProps: {
                                      textContent: _vm._s(song.price + "€"),
                                    },
                                  }),
                                ]
                              ),
                            ]
                          ),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            )
          }),
          0
        )
      : _c("div", { staticClass: "text-white h2 mt-3" }, [
          _vm._v("\n        No hay canciones disponibles\n    "),
        ]),
    _vm._v(" "),
    _vm.hidden_button
      ? _c(
          "div",
          {
            staticClass:
              "d-flex justify-content-center align-items-center my-4",
          },
          [
            _c(
              "button",
              {
                staticClass: "btn btn-green-secondary px-4",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.showMore()
                  },
                },
              },
              [_vm._v("\n            Ver más\n        ")]
            ),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "d-flex align-items-center my-4" }, [
      _c(
        "span",
        { staticClass: "col-12 mr-6 color-white font-weight-bolder h4" },
        [_vm._v("Canciones añadidas recientemente")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }