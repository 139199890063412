var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.error
      ? _c(
          "div",
          { staticClass: "alert alert-danger", attrs: { role: "alert" } },
          [_vm._v("\n        " + _vm._s(_vm.error) + "\n    ")]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "tab-content", attrs: { id: "myTabContent" } }, [
      _c(
        "div",
        {
          staticClass: "tab-pane fade show active",
          attrs: { id: "tab_register_user", role: "tabpanel" },
        },
        [
          _c("div", { staticClass: "form-group" }, [
            _vm._m(1),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.user.name,
                  expression: "user.name",
                },
              ],
              staticClass: "form-control",
              attrs: {
                id: "name",
                name: "name",
                type: "text",
                required: "",
                autofocus: "",
                autocomplete: "off",
                placeholder: "Nombre",
              },
              domProps: { value: _vm.user.name },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.user, "name", $event.target.value)
                },
              },
            }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "form-group" }, [
            _vm._m(2),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.user.surnames,
                  expression: "user.surnames",
                },
              ],
              staticClass: "form-control",
              attrs: {
                id: "surnames",
                name: "surnames",
                type: "text",
                required: "",
                autofocus: "",
                autocomplete: "off",
                placeholder: "Apellidos",
              },
              domProps: { value: _vm.user.surnames },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.user, "surnames", $event.target.value)
                },
              },
            }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "form-group" }, [
            _vm._m(3),
            _vm._v(" "),
            _c(
              "div",
              [
                _c("form-date", {
                  attrs: { id: "birthday", name: "birthday" },
                  model: {
                    value: _vm.user.birthday,
                    callback: function ($$v) {
                      _vm.$set(_vm.user, "birthday", $$v)
                    },
                    expression: "user.birthday",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "fv-plugins-message-container" }, [
              _vm.date_message !== "" && !_vm.user_is_over_16_years
                ? _c("div", { staticClass: "fv-help-block" }, [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.date_message) +
                        "\n                    "
                    ),
                  ])
                : _vm._e(),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "form-group" }, [
            _vm._m(4),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.user.email,
                  expression: "user.email",
                },
              ],
              staticClass: "form-control",
              attrs: {
                id: "email_register",
                name: "email",
                type: "email",
                required: "",
                autofocus: "",
                autocomplete: "off",
                placeholder: "Email",
              },
              domProps: { value: _vm.user.email },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.user, "email", $event.target.value)
                },
              },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "fv-plugins-message-container" }, [
              _vm.user.email !== "" && !_vm.user_email_is_valid
                ? _c("div", { staticClass: "fv-help-block" }, [
                    _vm._v(
                      "La dirección\n                        introducida no es válida\n                    "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.user.email !== "" &&
              _vm.user_email_is_valid &&
              _vm.user_email_exists
                ? _c("div", { staticClass: "fv-help-block" }, [
                    _vm._v(
                      "\n                        La dirección introducida ya existe\n                    "
                    ),
                  ])
                : _vm._e(),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "form-group" }, [
            _vm._m(5),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.user.email_confirmation,
                  expression: "user.email_confirmation",
                },
              ],
              staticClass: "form-control",
              attrs: {
                id: "email_confirmation",
                name: "email_confirmation",
                type: "email",
                required: "",
                autofocus: "",
                autocomplete: "off",
                placeholder: "Confirmación de email",
              },
              domProps: { value: _vm.user.email_confirmation },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.user, "email_confirmation", $event.target.value)
                },
              },
            }),
            _vm._v(" "),
            _vm.user.email_confirmation !== "" &&
            !_vm.user_email_confirmation_is_valid
              ? _c("div", { staticClass: "fv-plugins-message-container" }, [
                  _c("div", { staticClass: "fv-help-block" }, [
                    _vm._v("Las direcciones de correo no coinciden"),
                  ]),
                ])
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "form-group" }, [
            _vm._m(6),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.user.phone,
                  expression: "user.phone",
                },
              ],
              staticClass: "form-control",
              attrs: {
                id: "phone",
                name: "phone",
                type: "tel",
                maxlength: "20",
                required: "",
                autofocus: "",
                autocomplete: "off",
                placeholder: "Teléfono",
              },
              domProps: { value: _vm.user.phone },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.user, "phone", $event.target.value)
                },
              },
            }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "form-group" }, [
            _vm._m(7),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.user.zipcode,
                  expression: "user.zipcode",
                },
              ],
              staticClass: "form-control",
              attrs: {
                id: "zipcode",
                name: "zipcode",
                type: "text",
                maxlength: "5",
                required: "",
                autofocus: "",
                autocomplete: "off",
                placeholder: "Código postal",
              },
              domProps: { value: _vm.user.zipcode },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.user, "zipcode", $event.target.value)
                },
              },
            }),
            _vm._v(" "),
            !_vm.user_zipcode_is_valid
              ? _c("div", { staticClass: "fv-plugins-message-container" })
              : _vm._e(),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "tab-pane fade",
          attrs: { id: "tab_login_user", role: "tabpanel" },
        },
        [
          _c("div", { staticClass: "form-group" }, [
            _c("label", { staticClass: "font-weight-bolder color-darkblue" }, [
              _vm._v("Email:"),
            ]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.email,
                  expression: "email",
                },
              ],
              staticClass: "form-control",
              attrs: {
                type: "text",
                name: "email",
                required: "",
                autofocus: "",
                autocomplete: "off",
                placeholder: "Escribe tu email",
              },
              domProps: { value: _vm.email },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.email = $event.target.value
                },
              },
            }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "form-group" }, [
            _vm._m(8),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.password,
                  expression: "password",
                },
              ],
              staticClass: "form-control",
              attrs: {
                type: "password",
                name: "password",
                required: "",
                autocomplete: "off",
                placeholder: "Escribe tu contraseña",
              },
              domProps: { value: _vm.password },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.password = $event.target.value
                },
              },
            }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "d-flex flex-wrap" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-green w-100 mb-3",
                attrs: { type: "submit" },
                on: { click: _vm.handleSubmit },
              },
              [_vm._v("\n                    Iniciar sesión\n                ")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass:
                  "facebook-btn w-100 btn d-flex align-items-center justify-content-center p-3",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.authProvider()
                  },
                },
              },
              [
                _c("img", {
                  staticClass: "mr-2",
                  attrs: {
                    src: "/media/misc/facebook.png",
                    alt: "NFT My Ticket - Facebook",
                    width: "25px",
                  },
                }),
                _vm._v(" "),
                _c("span", [_vm._v("Iniciar sesión con Facebook")]),
              ]
            ),
          ]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ul", { staticClass: "nav nav-tabs nav-line-tabs mb-5 fs-6" }, [
      _c("li", { staticClass: "nav-item" }, [
        _c(
          "a",
          {
            staticClass: "nav-link active",
            attrs: { "data-bs-toggle": "tab", href: "#tab_register_user" },
          },
          [_vm._v("Invitado")]
        ),
      ]),
      _vm._v(" "),
      _c("li", { staticClass: "nav-item" }, [
        _c(
          "a",
          {
            staticClass: "nav-link",
            attrs: { "data-bs-toggle": "tab", href: "#tab_login_user" },
          },
          [_vm._v("Ya tengo cuenta")]
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "font-weight-bold color-darkblue" }, [
      _vm._v("Nombre"),
      _c("sup", [_vm._v("*")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "font-weight-bold color-darkblue" }, [
      _vm._v("Apellidos"),
      _c("sup", [_vm._v("*")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "font-weight-bold color-darkblue" }, [
      _vm._v("Fecha de nacimiento"),
      _c("sup", [_vm._v("*")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "font-weight-bold color-darkblue" }, [
      _vm._v("Email"),
      _c("sup", [_vm._v("*")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "font-weight-bold color-darkblue" }, [
      _vm._v("Confirmación de email"),
      _c("sup", [_vm._v("*")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "font-weight-bold color-darkblue" }, [
      _vm._v("Teléfono"),
      _c("sup", [_vm._v("*")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "font-weight-bold color-darkblue" }, [
      _vm._v("Código postal"),
      _c("sup", [_vm._v("*")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "d-flex justify-content-between align-items-center text-center",
      },
      [
        _c("label", { staticClass: "font-weight-bolder color-darkblue" }, [
          _vm._v("Contraseña:"),
        ]),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "color-darkgreen",
            attrs: {
              href: "javascript:;",
              role: "button",
              "data-toggle": "modal",
              "data-target": "#forgetpasswordModal",
            },
          },
          [_vm._v("¿Has olvidado tu contraseña?")]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }