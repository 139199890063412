import { render, staticRenderFns } from "./CategoriesEventsComponent.vue?vue&type=template&id=9533cb66&scoped=true&"
import script from "./CategoriesEventsComponent.vue?vue&type=script&lang=js&"
export * from "./CategoriesEventsComponent.vue?vue&type=script&lang=js&"
import style0 from "./CategoriesEventsComponent.vue?vue&type=style&index=0&id=9533cb66&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9533cb66",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/jgarciac/development/PhpStormProjects/nftmyticket/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('9533cb66')) {
      api.createRecord('9533cb66', component.options)
    } else {
      api.reload('9533cb66', component.options)
    }
    module.hot.accept("./CategoriesEventsComponent.vue?vue&type=template&id=9533cb66&scoped=true&", function () {
      api.rerender('9533cb66', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/components/CategoriesEventsComponent.vue"
export default component.exports