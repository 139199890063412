var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "ticketRenaming",
      staticClass: "modal fade",
      staticStyle: { "z-index": "99999" },
      attrs: {
        id: "ticketRenaming",
        tabindex: "-1",
        role: "dialog",
        "data-backdrop": "static",
        "data-keyboard": "false",
        "aria-labelledby": "ticketRenamingTitle",
        "aria-hidden": "true",
      },
    },
    [
      _c(
        "div",
        {
          class:
            "modal-dialog modal-dialog-scrollable modal-dialog-centered modal-lg",
          attrs: { role: "document" },
        },
        [
          _c("div", { staticClass: "modal-content" }, [
            _vm._m(0),
            _vm._v(" "),
            _vm.sale_tickets && _vm.sale_tickets.length > 0
              ? _c(
                  "div",
                  { staticClass: "modal-body" },
                  [
                    _c("AdminEventTicketRenaming", {
                      attrs: { sale_tickets: Object.values(_vm.sale_tickets) },
                      on: { onFormChanged: _vm.updateData },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "modal-footer" }, [
              _vm.sale_tickets && _vm.sale_tickets.length > 0
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: { type: "button", disabled: _vm.loading },
                      on: {
                        click: function ($event) {
                          return _vm.payDialog()
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(
                            _vm.loading
                              ? "Procesando..."
                              : _vm.payAmount + "€ - Confirmar"
                          ) +
                          "\n                "
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-danger",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.closeDialog()
                    },
                  },
                },
                [_vm._v("Cerrar")]
              ),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("TicketRenamingCheckout", {
        attrs: { value_usdt: parseFloat("0.0"), sale_tickets: _vm.data },
        on: {
          onTransaccionSuccess: (id_transaction) =>
            _vm.setTransaction(id_transaction),
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-header" }, [
      _c("h5", { staticClass: "modal-title" }, [
        _vm._v("Asignación de Tickets"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }