<template>
	<div>
		<EventsRecentComponent></EventsRecentComponent>
	</div>
</template>

<script>
import EventsRecentComponent from "./EventsRecentComponent.vue";

export default {
	components: {
		EventsRecentComponent,
	},
};
</script>
