<template>
    <div id="rename-container">
        <h2>Datos de los asistentes</h2>
        <div v-if="preregistration_email !== ''">
            <AdminEventTicketRenamingRegisterUserDialog @onUserPreregister="(email) => onPreregisterUser(email)"
                                                        @closePreregistrationDialog="(closed) => setPreregisterUser('')"
                                                        :userEmail="preregistration_email"/>
        </div>
        <div v-for="ticket in tickets" :class="'row'" style="margin-top: 20px" :key="ticket.ticket_id">
            <div :class="'col'">
                <div class="card">
                    <div class="card-header">
                        <div class="row row-header">
                            <div class="col-8">
                                <h3 class="w-75 p-3"><img width="40" height="40" :src="'/svg/entradas-seleccion.svg'" class="rounded" /> {{ header(ticket) }}</h3>
                            </div>
                            <div class="col-4 float-right row-header">
                                <div class="form-check form-switch form-check-reverse">
                                    <input
                                        @change="assignTicket($event, ticket.ticket_id, ticket.user_id, ticket.user_email)"
                                        class="form-check-input" type="checkbox" :id="'ownercb'+ticket.ticket_id">
                                    <label class="form-check-label" for="flexSwitchCheckReverse">Esta entrada es para
                                        mi</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">

                        <h5 class="card-title">{{ ticket.event?.name }}</h5>
                            <div class="row">
                                <div class="col-12">
                                    <p class="card-text">{{ ticket.ticket_event?.description_ticket }}</p>
                                    <p class="card-text">{{ ticket.event?.address }}</p>
                                </div>
                            </div>
                            <div class="row" style="margin-top: 20px">
                                <div class="col-12">
                                    <div class="input-group has-validation">
                                        <span class="input-group-text">Email del asistente</span>
                                        <input v-model="ticket.new_user_email" :disabled="isOwner(ticket)" type="text"
                                               aria-label="Email del asistente" :class="'form-control ' + validationEmailClass(ticket)"/>
                                        <div class="invalid-feedback"
                                             v-if="emailError(ticket)">
                                            La dirección introducida no es válida
                                        </div>
                                        <div class="valid-feedback" v-if="!isOwner(ticket) && ticket.new_user_id">
                                            Usuario existente
                                        </div>
                                        <button v-if="isNewUser(ticket) && isNewUserEmailValid(ticket)"
                                                @click="preregisterUser(ticket)" class="btn btn-primary" type="button"
                                                id="button-addon2">Introducir datos
                                        </button>
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import AdminEventTicketRenamingRegisterUserDialog from "./AdminEventTicketRenamingRegisterUserDialog.vue";

export default {
    name: "AdminEventTicketRenaming",
    components: {
        AdminEventTicketRenamingRegisterUserDialog
    },
    props: {
        sale_tickets: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            preregistration_email: '',
            tickets: [],
            users: {},
        }
    },
    mounted() {
        this.getSaleTickets()
    },
    updated() {

    },
    watch: {
        tickets: {
            async handler(newTickets, oldTickets) {
                for (const ticket_id of Object.keys(newTickets)) {
                    const ticket = newTickets[ticket_id];

                    const emailValid = this.emailValid(ticket.new_user_email);
                    if (!ticket.new_user_id && emailValid) {
                        const user_id = await this.checkEmailExists(ticket.new_user_email);
                        if (user_id && user_id > 0) {
                            this.setUserTicket(ticket_id, user_id, ticket.new_user_email)
                        }
                    } else if (ticket.new_user_id && !emailValid) {
                        this.setUserTicket(ticket_id, undefined, ticket.new_user_email)
                    } else if (this.users[ticket_id] !== ticket.new_user_email){
                        this.setUserTicket(ticket_id, undefined, ticket.new_user_email)
                    }
                }
                this.$emit("onFormChanged", this.isFormValid(), this.tickets)
            },
            deep: true,
            immediate: true
        }

    },
    computed: {
        emailError() {
            return ticket => !this.isOwner(ticket) && (ticket.new_user_email && ! this.isNewUserEmailValid(ticket))
        },
        validationEmailClass(){
           return ticket => !this.isOwner(ticket) ? (this.isAssigned(ticket) ? 'is-valid' : ((ticket.new_user_email && ! this.isNewUserEmailValid(ticket)) ? 'is-invalid' : ':')) : 'is-valid'
        },
        header() {
            return ticket => (parseInt(ticket.ticket_num) > 0 ? ("#" + ticket.ticket_num + "-") : "") + ticket.ticket_event?.name_ticket
        },
        isAssigned() {
            return ticket => ticket.new_user_id
        },
        isNewUser() {
            return ticket => !this.isAssigned(ticket) && ticket.new_user_email
        },
        isNewUserEmailValid() {
            return ticket => this.emailValid(ticket.new_user_email)
        },
        isOwner() {
            return ticket => ticket.new_user_id === ticket.user_id
        },
    },
    methods: {
        getSaleTickets() {
            const me = this;
            let url = "/sale_tickets/" + this.sale_tickets.join(",")
            axios
                .get(url)
                .then(function (response) {
                    me.tickets = response.data
                }).catch(function (error) {
                console.error(error);
            });
        },
        setUserTicket(ticket_id, user_id, user_email) {
            const newTickets = {...this.tickets}
            newTickets[ticket_id] = {...newTickets[ticket_id], new_user_id: user_id, new_user_email: user_email};
            this.tickets = newTickets
            const newUsers = {...this.users};
            newUsers[ticket_id] = user_email;
            this.users = newUsers;
        },
        assignTicket(event, ticket_id, user_id, user_email) {
            const checked = event.target.checked;
            this.setUserTicket(ticket_id, checked ? user_id : undefined, checked ? user_email : undefined)
        },
        emailValid(email = '') {
            return email !== '' && /^\w+([\.+-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);
        },
        async checkEmailExists($email = '') {
            try {
                let response = await axios
                    .post("/check_email_exists", {email: $email})
                return response && response.data.code === 1000 && response.data.user.id
            } catch (e) {
                return 0
            }
        },
        isFormValid() {
            return Object.values(this.tickets).filter(t => !t.new_user_id || t.new_user_id === 0).map(t => t.new_user_id).length === 0
        },
        preregisterUser(ticket) {
            this.setPreregisterUser(ticket.new_user_email)
        },
        setPreregisterUser(email) {
            this.preregistration_email = email
        },
        onPreregisterUser(email) {
            this.preregistration_email = ''
            this.tickets = {...this.tickets}
        }
    }

}
</script>

<style scoped>
.card-header {
    padding: 0.5rem 0.75rem;
}

.row-header {
    align-items: center;
    justify-content: right;
}
#rename-container {
    color: black!important;
}
</style>
