<template>
    <div class="container-fluid mt-4 mb-12">
        <div class="d-flex align-items-center mt-4">
            <router-link :to="{ path: '/songs-recent' }">
                <span class="color-white h4" style="margin-right: 20px"
                    >Canciones añadidas recientemente</span
                >
                <img src="svg/flecha-pequeña.svg" width="5px" height="auto" />
            </router-link>
        </div>

        <div
            class="evecans-carrousel content p-0"
            v-if="array_songs_recent.length"
        >
            <div class="content-principal">
                <button
                    role="button"
                    id="arrow-left-recent"
                    class="arrow-left"
                    @click="carrouselLeft()"
                >
                    <img
                        src="svg/flecha-detras.svg"
                        width="15px"
                        height="auto"
                    />
                </button>
                <div class="content-carousel" id="content-carousel-recent">
                    <div class="carousel">
                        <div
                            class="evecan custom col-8 col-md-4 col-lg-2 px-0"
                            v-for="song in array_songs_recent"
                            :key="song.id"
                        >
                            <router-link :to="{ path: '/song/' + song.id }">
                                <div class="h-100">
                                    <img
                                        v-bind:src="
                                            '/files/images/music/' +
                                            song.img_music
                                        "
                                        style="object-fit: cover"
                                    />
                                    <div
                                        class="description p-4 d-flex flex-wrap justify-content-between align-content-between"
                                    >
                                        <div class="w-100">
                                            <span
                                                class="event-name color-white font-weight-bold"
                                                v-text="song.name"
                                            ></span>
                                        </div>
                                        <div class="w-100 text-align-end">
                                            <span
                                                class="h2 color-green"
                                                v-text="song.price + '€'"
                                            ></span>
                                        </div>
                                    </div>
                                </div>
                            </router-link>
                        </div>
                    </div>
                </div>
                <button
                    role="button"
                    id="arrow-right-recent"
                    class="arrow-right"
                    @click="carrouselRight()"
                >
                    <img
                        src="svg/flecha-delante.svg"
                        width="15px"
                        height="auto"
                    />
                </button>
            </div>
        </div>
        <div class="text-white h2 mt-3" v-else>
            No hay canciones disponibles
        </div>
    </div>
</template>

<script>
export default {
    name: "SongsRecentGeneralComponent",
    data() {
        return {
            show_button_carrusel: true,
        };
    },
    props: ["array_songs_recent"],
    methods: {
        getCategories() {
            let me = this;
            let url = "/categoriesAll";
            axios
                .get(url)
                .then(function (response) {
                    me.arrayCategories = response.data;
                })
                .catch(function (error) {
                    // handle error
                    console.log(error);
                });
        },

        carrouselRight() {
            const fila = document.querySelector("#content-carousel-recent");
            fila.scrollLeft += fila.offsetWidth + 20;
        },

        carrouselLeft() {
            const fila = document.querySelector("#content-carousel-recent");
            fila.scrollLeft -= fila.offsetWidth + 20;
        },
    },
    mounted() {
        this.getCategories();
    },
};
</script>
