var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container-fluid mt-4 mb-12" }, [
    _c(
      "div",
      { staticClass: "d-flex align-items-center mt-4" },
      [
        _c("router-link", { attrs: { to: { path: "/events-nearby" } } }, [
          _c(
            "span",
            {
              staticClass: "color-white h4",
              staticStyle: { "margin-right": "20px" },
            },
            [_vm._v("Planes más cercanos")]
          ),
          _vm._v(" "),
          _c("img", {
            attrs: {
              src: "svg/flecha-pequeña.svg",
              width: "5px",
              height: "auto",
            },
          }),
        ]),
      ],
      1
    ),
    _vm._v(" "),
    _vm.array_events_nearby.length
      ? _c("div", { staticClass: "evecans-carrousel content p-0" }, [
          _c("div", { staticClass: "content-principal" }, [
            _c(
              "button",
              {
                staticClass: "arrow-left",
                attrs: { role: "button", id: "arrow-left-recent" },
                on: {
                  click: function ($event) {
                    return _vm.carrouselLeft()
                  },
                },
              },
              [
                _c("img", {
                  attrs: {
                    src: "svg/flecha-detras.svg",
                    width: "15px",
                    height: "auto",
                  },
                }),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "content-carousel",
                attrs: { id: "content-carousel-nearby" },
              },
              [
                _c(
                  "div",
                  { staticClass: "carousel" },
                  _vm._l(_vm.array_events_nearby, function (event) {
                    return _c(
                      "div",
                      {
                        key: event.id,
                        staticClass:
                          "evecan custom col-8 col-md-4 col-lg-2 px-0",
                      },
                      [
                        !event.isRestaurant
                          ? _c(
                              "router-link",
                              { attrs: { to: { path: "/event/" + event.id } } },
                              [
                                _c("div", { staticClass: "h-100" }, [
                                  _c("img", {
                                    staticStyle: { "object-fit": "cover" },
                                    attrs: {
                                      src:
                                        "/files/images/events/" +
                                        event.img_event,
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "description p-4 d-flex flex-wrap justify-content-between align-content-between",
                                    },
                                    [
                                      _c("div", { staticClass: "w-100" }, [
                                        _c("span", {
                                          staticClass: "event-name color-white",
                                          domProps: {
                                            textContent: _vm._s(event.name),
                                          },
                                        }),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "position-to-bottom w-100 d-flex justify-content-between",
                                        },
                                        [
                                          _c("div", [
                                            event.sold_out
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "h2 text-white",
                                                  },
                                                  [_vm._v("Sold out")]
                                                )
                                              : _vm._e(),
                                          ]),
                                          _vm._v(" "),
                                          _c("div", [
                                            _c(
                                              "span",
                                              { staticClass: "color-white" },
                                              [_vm._v("Desde")]
                                            ),
                                            _c("br"),
                                            _vm._v(" "),
                                            _c("span", {
                                              staticClass: "h2 color-green",
                                              domProps: {
                                                textContent: _vm._s(
                                                  event.price + "€"
                                                ),
                                              },
                                            }),
                                          ]),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]),
                              ]
                            )
                          : _c(
                              "router-link",
                              {
                                attrs: {
                                  to: { path: "/restaurant/" + event.id },
                                },
                              },
                              [
                                _c("div", { staticClass: "h-100" }, [
                                  _c("img", {
                                    staticStyle: { "object-fit": "cover" },
                                    attrs: {
                                      src: `/images/restaurants/${event.file}`,
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "description p-4 d-flex flex-wrap justify-content-between align-content-between",
                                    },
                                    [
                                      _c("div", { staticClass: "w-100" }, [
                                        _c("span", {
                                          staticClass: "event-name color-white",
                                          domProps: {
                                            textContent: _vm._s(event.name),
                                          },
                                        }),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "w-100 d-flex justify-content-end",
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "h2 color-green" },
                                            [_vm._v("Reservar")]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]),
                              ]
                            ),
                      ],
                      1
                    )
                  }),
                  0
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "arrow-right",
                attrs: { role: "button", id: "arrow-right-recent" },
                on: {
                  click: function ($event) {
                    return _vm.carrouselRight()
                  },
                },
              },
              [
                _c("img", {
                  attrs: {
                    src: "svg/flecha-delante.svg",
                    width: "15px",
                    height: "auto",
                  },
                }),
              ]
            ),
          ]),
        ])
      : _c("div", { staticClass: "text-white h2 mt-3" }, [
          _vm._v("No hay planes disponibles"),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }