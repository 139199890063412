var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container px-0" }, [
    _c("div", { staticClass: "d-flex align-items-center my-4" }, [
      _vm.events.length > 0
        ? _c("span", { staticClass: "col-12 mr-6 color-white" }, [
            _vm._v("Planes de la búsqueda"),
          ])
        : _c("span", { staticClass: "col-12 mr-6 color-white" }, [
            _vm._v("No se encontraron planes"),
          ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "d-flex flex-wrap justify-content-start" },
      _vm._l(_vm.events, function (event) {
        return _c(
          "div",
          { key: event.id, staticClass: "col-8 col-md-4 col-lg-3 mb-5" },
          [
            _c(
              "div",
              { staticClass: "evecan custom" },
              [
                _c(
                  "router-link",
                  { attrs: { to: { path: "/event/" + event.id } } },
                  [
                    _c("div", { staticClass: "h-100" }, [
                      _c("img", {
                        staticStyle: { "object-fit": "cover" },
                        attrs: {
                          src: "/files/images/events/" + event.img_event,
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "description p-4 d-flex flex-wrap justify-content-between align-content-between bg-blue",
                        },
                        [
                          _c("div", { staticClass: "w-100" }, [
                            _c("span", {
                              staticClass: "event-name color-white",
                              domProps: { textContent: _vm._s(event.name) },
                            }),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "w-100 d-flex justify-content-between",
                            },
                            [
                              _c("div", [
                                event.sold_out
                                  ? _c(
                                      "span",
                                      { staticClass: "h2 text-white" },
                                      [_vm._v("Sold out")]
                                    )
                                  : _vm._e(),
                              ]),
                              _vm._v(" "),
                              _c("div", [
                                _c("span", { staticClass: "color-white" }, [
                                  _vm._v("Desde"),
                                ]),
                                _c("br"),
                                _vm._v(" "),
                                _c("span", {
                                  staticClass: "h2 color-green",
                                  domProps: {
                                    textContent: _vm._s(
                                      parseFloat(event.min_price).toFixed(2) +
                                        "€"
                                    ),
                                  },
                                }),
                              ]),
                            ]
                          ),
                        ]
                      ),
                    ]),
                  ]
                ),
              ],
              1
            ),
          ]
        )
      }),
      0
    ),
    _vm._v(" "),
    _c("div", { staticClass: "d-flex align-items-center my-4" }, [
      _vm.songs.length > 0
        ? _c("span", { staticClass: "col-12 mr-6 color-white" }, [
            _vm._v("Canciones de la búsqueda"),
          ])
        : _c("span", { staticClass: "col-12 mr-6 color-white" }, [
            _vm._v("No se encontraron canciones"),
          ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "d-flex flex-wrap justify-content-start" },
      _vm._l(_vm.songs, function (song) {
        return _c(
          "div",
          { key: song.id, staticClass: "col-8 col-md-4 col-lg-3 mb-5" },
          [
            _c(
              "div",
              { staticClass: "evecan custom" },
              [
                _c(
                  "router-link",
                  { attrs: { to: { path: "/song/" + song.id } } },
                  [
                    _c("div", { staticClass: "h-100" }, [
                      _c("img", {
                        staticStyle: { "object-fit": "cover" },
                        attrs: { src: "/files/images/music/" + song.img_music },
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "description p-4 d-flex flex-wrap justify-content-between align-content-between bg-blue",
                        },
                        [
                          _c("div", { staticClass: "w-100" }, [
                            _c("span", {
                              staticClass:
                                "event-name color-white font-weight-bold",
                              domProps: { textContent: _vm._s(song.name) },
                            }),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "w-100 text-align-end" }, [
                            _c("span", {
                              staticClass: "h2 color-green",
                              domProps: {
                                textContent: _vm._s(song.price + "€"),
                              },
                            }),
                          ]),
                        ]
                      ),
                    ]),
                  ]
                ),
              ],
              1
            ),
          ]
        )
      }),
      0
    ),
    _vm._v(" "),
    _vm.hidden_button
      ? _c(
          "div",
          {
            staticClass:
              "d-flex justify-content-center align-items-center my-4",
          },
          [
            _vm._v("\n        >\n        "),
            _c(
              "button",
              {
                staticClass: "btn btn-green-secondary px-4",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.showMore()
                  },
                },
              },
              [_vm._v("\n            Ver más\n        ")]
            ),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }