var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "transform-content" }, [
    _c("div", { staticClass: "container px-2 mt-8" }, [
      _c("div", { staticClass: "d-flex flex-wrap" }, [
        _c("div", { staticClass: "col-12" }, [
          _c("div", { staticClass: "card card-custom gutter-b" }, [
            _c(
              "div",
              {
                staticClass:
                  "card-header bg-color-white border-0 px-4 px-sm-4 px-md-8",
              },
              [
                _c("div", { staticClass: "card-title" }, [
                  _vm.ticket && !_vm.error
                    ? _c(
                        "h5",
                        {
                          staticClass:
                            "card-label font-weight-bolder color-darkblue",
                        },
                        [
                          _vm._v(
                            "\n                                    Entrada " +
                              _vm._s(_vm.ticket.ticket_event.name_ticket) +
                              " - "
                          ),
                          _c("strong", [
                            _vm._v(_vm._s(_vm.ticket.ticket_event.event.name)),
                          ]),
                        ]
                      )
                    : _c("h5", [
                        _vm._v(
                          "\n                                    Entrada\n                                "
                        ),
                      ]),
                ]),
              ]
            ),
            _vm._v(" "),
            _vm.error
              ? _c(
                  "div",
                  {
                    staticClass:
                      "card-body px-4 px-sm-4 px-md-8 py-4 py-sm-4 py-md-4",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "alert alert-danger h4",
                        attrs: { role: "alert" },
                      },
                      [
                        _vm._v(
                          "\n                                " +
                            _vm._s(_vm.error_message) +
                            "\n                            "
                        ),
                      ]
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.ticket && !_vm.error
              ? _c(
                  "div",
                  {
                    staticClass:
                      "card-body px-4 px-sm-4 px-md-8 py-4 py-sm-4 py-md-4",
                  },
                  [
                    _c("div", { staticClass: "d-flex flex-wrap" }, [
                      _c("div", { staticClass: "col-12 col-md-4" }, [
                        !_vm.eventIsPast && _vm.seconds >= 0
                          ? _c(
                              "div",
                              { staticClass: "text-center" },
                              [
                                _c("QrcodeVue", {
                                  attrs: {
                                    value: _vm.qrValue,
                                    size: _vm.size,
                                    level: "H",
                                  },
                                }),
                                _vm._v(" "),
                                _c("p", [
                                  _vm._v("XX Este código QR será inválido en "),
                                  _c("strong", [
                                    _vm._v(_vm._s(_vm.seconds) + " segundos"),
                                  ]),
                                  _vm._v("."),
                                ]),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.eventIsPast
                          ? _c("div", { staticClass: "text-center" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "alert alert-warning h4",
                                  attrs: { role: "alert" },
                                },
                                [
                                  _vm._v(
                                    "\n                                            Este evento ya ha pasado.\n                                        "
                                  ),
                                ]
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.seconds < 0
                          ? _c("div", { staticClass: "text-center" }, [
                              _c("p", [_vm._v("Generando QR...")]),
                            ])
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-12 col-md-8" }, [
                        _c("div", { staticClass: "d-flex flex-wrap" }, [
                          _c("div", { staticClass: "col-12 col-md-8 px-0" }, [
                            _c("h3", [
                              _vm._v(
                                "Entrada " +
                                  _vm._s(_vm.ticket.ticket_event.name_ticket) +
                                  " "
                              ),
                              _vm.ticket.num_ticket
                                ? _c("strong", [
                                    _vm._v(
                                      "- Asiento " +
                                        _vm._s(_vm.ticket.num_ticket)
                                    ),
                                  ])
                                : _vm._e(),
                            ]),
                            _vm._v(" "),
                            _c("h2", { staticClass: "text-primary" }, [
                              _c("strong", [
                                _vm._v(
                                  _vm._s(_vm.ticket.ticket_event.event.name)
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("h4", [
                              _vm._v("Día "),
                              _c("strong", [
                                _vm._v(
                                  _vm._s(_vm.ticket.ticket_date.date_event)
                                ),
                              ]),
                              _vm._v(" a las "),
                              _c("strong", [
                                _vm._v(
                                  _vm._s(
                                    _vm.ticket.ticket_date.time_event_since
                                  )
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("h5", [
                              _vm._v(
                                _vm._s(
                                  (
                                    parseFloat(_vm.ticket.price) +
                                    parseFloat(_vm.ticket.commission)
                                  ).toFixed(2)
                                ) +
                                  "€ (" +
                                  _vm._s(
                                    parseFloat(_vm.ticket.price).toFixed(2)
                                  ) +
                                  "€ + " +
                                  _vm._s(
                                    parseFloat(_vm.ticket.commission).toFixed(2)
                                  ) +
                                  "€ gastos gestión) - Imp. Incl."
                              ),
                            ]),
                            _vm._v(" "),
                            _c("h6", [
                              _vm._v(
                                _vm._s(
                                  _vm.ticket.ticket_event.description_ticket
                                )
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-12 col-md-4 px-0 text-right d-none d-md-block",
                            },
                            [
                              _c("img", {
                                staticClass: "ml-4 mb-4",
                                staticStyle: {
                                  "object-fit": "cover",
                                  "border-radius": "0.5rem",
                                  width: "140px",
                                  height: "auto",
                                  "max-width": "100%",
                                  "aspect-ratio": "1 / 1",
                                },
                                attrs: {
                                  src:
                                    "/files/images/events/" +
                                    _vm.ticket.ticket_event.event.img_event,
                                },
                              }),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.ticket.ticket_event.event.description
                            ),
                          },
                        }),
                        _vm._v(" "),
                        _c("h4", [_vm._v("Organiza:")]),
                        _vm._v(" "),
                        _c("p", [
                          _c("strong", { staticClass: "text-primary" }, [
                            _vm._v(
                              _vm._s(
                                _vm.ticket.ticket_event.event.prometer.company
                              )
                            ),
                          ]),
                          _c("br"),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              attrs: {
                                href:
                                  "mailto:" +
                                  _vm.ticket.ticket_event.event.prometer.email,
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.ticket.ticket_event.event.prometer.email
                                )
                              ),
                            ]
                          ),
                          _c("br"),
                          _vm._v(
                            "\n                                        CIF: "
                          ),
                          _c("strong", [
                            _vm._v(
                              _vm._s(_vm.ticket.ticket_event.event.prometer.cif)
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("img", {
                          staticStyle: {
                            "object-fit": "cover",
                            "border-radius": "0.5rem",
                            width: "100%",
                            height: "200px",
                            "max-width": "100%",
                          },
                          attrs: {
                            src:
                              "/files/images/events/" +
                              _vm.ticket.ticket_event.event.img_event,
                          },
                        }),
                      ]),
                    ]),
                  ]
                )
              : _vm._e(),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }