<template>
	<div class="modal fade" id="forgetpasswordModal" tabindex="-1" role="dialog" aria-labelledby="forgetpasswordModalTitle" aria-hidden="true" style="z-index: 100000;">
	  <div class="modal-dialog modal-dialog-centered" role="document">
	    <div class="modal-content">
	      <div class="modal-body">
	      	<div class="alert alert-danger" role="alert" v-if="error !== null">
                {{ error }}
            </div>
			<div>
				<h5 class="font-weight-bolder color-darkblue">Olvidé mi contraseña</h5>
	        	<p class="text-muted">Introduce tu email para reestablecer tu contraseña</p>
			</div>
			<div class="form-group">
				<label class="font-weight-bold color-darkblue">Email</label>
				<input id="email" type="email" class="form-control" v-model="email" required autofocus autocomplete="off" placeholder="Email">
			</div>
	      </div>

	      <div class="modal-footer justify-content-between">
	        <button type="button" class="btn btn-cancel" @click="close()" data-dismiss="modal">Volver</button>
	        <button class="btn btn-submit" @click="handleSubmit()">Enviar</button>
	      </div>
	    </div>
	  </div>
	</div>
</template>

<script>
    export default {
        data(){
	        return {
	            email: "",
	            error: null
	        }
        },
        computed: {
            modal() {
                return document.getElementById('forgetpasswordModal')
            },
            modalClasses() {
                return this.modal ? this.modal.className : ''
            }
        },
        watch: {
            modalClasses() {
                if (this.modalClasses.includes('show')) {
                    this.email = ""
                    this.error = null
                }
            }
        },
        methods:{
            close() {
                $("#forgetpasswordModal").modal("hide");
            },
            async handleSubmit(e) {
                try {
                    this.error = null
                    await axios.get('/sanctum/csrf-cookie')
                    let response = await axios.post('/api/reset_password_api', {
                        email: this.email,
                    })
                    if (response.data.code === 1000 || response.data.code === 1001) {
                        this.error = null
                        swal(
                            "Olvidé mi contraseña",
                            "Hemos enviado un correo para establecer tu contraseña. Si pasados unos minutos no has recibido nada, revisa tu bandeja de SPAM.",
                            "success"
                        );
                        this.email = ""
                        $('#forgetpasswordModal .btn-cancel').trigger('click');
                        // $('#loginModal').modal('toggle');
                    } else {
                        this.error = response.data.message
                    }
                } catch (error) {
                    console.error(error);
                    swal(
                        "Olvidé mi contraseña",
                        "Se ha producido un error al restablecer la contraseña. Por favor inténtalo de nuevo más tarde. Si el error persiste ponte en contacto con nosotros.",
                        "error"
                    );
                }
	        }
        },
	    beforeRouteEnter(to, from, next) {
	        next();
	    }
    }
</script>
