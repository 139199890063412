var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container px-0" }, [
    _vm._m(0),
    _vm._v(" "),
    _vm.array_collections
      ? _c(
          "div",
          { staticClass: "d-flex flex-wrap justify-content-start" },
          _vm._l(_vm.array_collections, function (collection) {
            return _c(
              "div",
              {
                key: collection.id,
                staticClass: "col-8 col-md-4 col-lg-3 mb-5",
              },
              [
                _c(
                  "div",
                  { staticClass: "evecan custom" },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "hideintro",
                        attrs: {
                          to: { path: "/collections/" + collection.id },
                        },
                      },
                      [
                        _c("div", { staticClass: "h-100" }, [
                          _c("img", {
                            staticStyle: { "object-fit": "cover" },
                            attrs: {
                              src:
                                "/files/images/music/" + collection.img_music,
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "description p-4 d-flex flex-wrap justify-content-between align-content-between bg-blue",
                            },
                            [
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "w-100 event-name color-white font-weight-bold",
                                },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(collection.name_collection) +
                                      "\n                            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "w-100 text-align-end" },
                                [
                                  _c("span", { staticClass: "color-green" }, [
                                    _vm._v("Ver colecciones"),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            )
          }),
          0
        )
      : _c("div", { staticClass: "text-white h2 mt-3" }, [
          _vm._v("\n        No hay colecciones disponibles\n    "),
        ]),
    _vm._v(" "),
    _vm.hidden_button
      ? _c(
          "div",
          {
            staticClass:
              "d-flex justify-content-center align-items-center my-4",
          },
          [
            _vm._v("\n        >\n        "),
            _c(
              "button",
              {
                staticClass: "btn btn-green-secondary px-4",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.showMore()
                  },
                },
              },
              [_vm._v("\n            Ver más\n        ")]
            ),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "d-flex align-items-center my-4" }, [
      _c(
        "span",
        { staticClass: "col-12 mr-6 h4 color-white font-weight-bold" },
        [_vm._v("Colecciones")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }