<template>
    <div>
	    <SongsRecentComponent></SongsRecentComponent>
    </div>
</template>

<script>
    import SongsRecentComponent from './SongsRecentComponent.vue';
    export default {
        components: {
            SongsRecentComponent,
        },
    }
</script>
