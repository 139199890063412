/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

import axios from "axios";
import VueRouter from "vue-router";
import VueAxios from 'vue-axios';
import VueSocialauth from 'vue-social-auth';

import EventsIndex from "./components/Events.vue";
import EventsRecentIndex from "./components/EventsRecent.vue";
import EventsNearbyIndex from "./components/EventsNearby.vue";
import EventIndexOriginal from "./components/Event.vue";
import EventIndex from "./components/iten/Event/Event.vue";

import RestaurantFront from "./components/RestaurantFront.vue";

import SongsIndex from "./components/Songs.vue";
import SongsRecentIndex from "./components/SongsRecent.vue";
import SongsCollectionsIndex from "./components/SongsCollections.vue";
import SongIndex from "./components/Song.vue";
import SongsOfCollectionComponent from "./components/SongsOfCollectionComponent.vue";
import MoreResultsComponent from "./components/MoreResultsComponent.vue";

import MyCollectionIndex from "./components/MyCollection.vue";
import MyTickets from "./components/MyTickets.vue";
import Ticketing from "./components/Ticketing.vue";
import HelpIndex from "./components/Help.vue";
import LegalAdviceComponent from "./components/LegalAdviceComponent.vue";
import PrivacyPolicyComponent from "./components/PrivacyPolicyComponent.vue";
import CookiesPolicyComponent from "./components/CookiesPolicyComponent.vue";
import TermsAndConditions from "./components/TermsAndConditions.vue";
import SelectCountryUrlModalComponent from "./components/SelectCountryUrlModalComponent.vue";
import DeleteFacebookDataComponent from "./components/DeleteFacebookDataComponent.vue";
import AdminEventTicketRenaming from "./components/iten/AdminEventTicketRenaming.vue";
import ForgetPasswordComponent from "./components/ForgetPasswordComponent";
import LoginComponent from "./components/LoginComponent";

require("./bootstrap");

window.Vue = require("vue").default;

Vue.use(VueRouter);

const router = new VueRouter({
    mode: "history",
    routes: [
        {
            path: "/",
            component: EventsIndex,
        },
        {
            path: "/events-recent",
            component: EventsRecentIndex,
        },
        {
            path: "/events-nearby",
            component: EventsNearbyIndex,
        },
        {
            path: "/event/:id",
            component: EventIndex,
        },
        {
            path: "/music",
            component: SongsIndex,
        },
        {
            path: "/songs-recent",
            component: SongsRecentIndex,
        },
        {
            path: "/collections",
            component: SongsCollectionsIndex,
        },
        {
            path: "/collections/:id",
            component: SongsOfCollectionComponent,
        },
        {
            path: "/song/:id",
            component: SongIndex,
        },
        {
            path: "/more-results",
            component: MoreResultsComponent,
        },
        {
            path: "/mycollection",
            component: MyCollectionIndex,
        },
        {
            path: "/mycollection/tickets/:ticket_id",
            component: MyTickets,
        },
        {
            path: "/ticketing",
            component: Ticketing,
        },
        {
            path: "/help",
            component: HelpIndex,
        },
        {
            path: "/legal-advice",
            component: LegalAdviceComponent,
        },
        {
            path: "/forgot-password",
            component: EventsIndex
        },
        {
            path: "/change-password",
            component: EventsIndex
        },
        {
            path: "/privacy-policy",
            component: PrivacyPolicyComponent,
        },
        {
            path: "/privacypolicy",
            component: PrivacyPolicyComponent,
        },
        {
            path: "/cookies-policy",
            component: CookiesPolicyComponent,
        },
        {
            path: "/terms-and-conditions",
            component: TermsAndConditions,
        },
        {
            path: "/instrucciones-para-la-eliminacion-de-datos-de-facebook",
            component: DeleteFacebookDataComponent,
        },
        {
            path: "/restaurant/:id",
            component: RestaurantFront,
        },
        {
            path: '/auth/:provider/callback',
            component: {
                template: '<div class="auth-component"></div>'
            }
        },
    ],
});

function hasQueryParams(route) {
    return Object.keys(route.query).length;
}

router.beforeEach((to, from, next) => {
    if (!hasQueryParams(to) && hasQueryParams(from)) {
        next({path: to.path, query: from.query});
    } else {
        next();
    }
});

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

Vue.component(
    "filters-component",
    require("./components/FiltersComponent.vue").default
);
Vue.component(
    "login-component",
    require("./components/LoginComponent.vue").default
);
Vue.component(
    "user-component",
    require("./components/UserComponent.vue").default
);
Vue.component(
    "register-component",
    require("./components/RegisterComponent.vue").default
);
Vue.component(
    "forgetpassword-component",
    require("./components/ForgetPasswordComponent.vue").default
);
Vue.component(
    "changepassword-component",
    require("./components/ChangePasswordComponent.vue").default
);
Vue.component(
    "select-country-url-modal-component",
    SelectCountryUrlModalComponent
);

//Vue.component('ads-component', require('./components/AdsComponent.vue').default);
//Vue.component('claim-component', require('./components/ClaimComponent.vue').default);
//Vue.component('login-component', require('./components/LoginComponent.vue').default);
//Vue.component('register-component', require('./components/RegisterComponent.vue').default);
//Vue.component('bid-component', require('./components/BidComponent.vue').default);
//Vue.component('nftview-component', require('./components/NftviewComponent.vue').default);
//Vue.component('nfts-component', require('./components/NftsComponent.vue').default);
//Vue.component('categories-component', require('./components/CategoriesComponent.vue').default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

Vue.use(VueAxios, axios);
Vue.use(VueSocialauth, {
    providers: {
        facebook: {
            clientId: '846331410020425',
            redirectUri: window.Laravel.url + '/auth/facebook/callback',
        }
    }
});

const app = new Vue({
    el: "#app",
    components: {
        EventsIndex,
    },
    router,
    watch: {
        $route(to, from) {
            if (!localStorage.getItem("cookies-accepted")) {
                $("#modal-aceptar-cookies").modal("show");
            }
        },
    },
});
