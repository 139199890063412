<template>
    <div class="transform-content">
<!--        <div class="bg-header-general d-flex align-items-end">-->
<!--            <div class="container pt-4">-->
<!--                <div class="col-md-6 d-flex align-items-end">-->
<!--                    <div class="p-4" style="height: 60px">-->
<!--                        <div style="position: absolute; bottom: 10px">-->
<!--                            <h3 class="color-white"></h3>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->

        <div class="container px-2 mt-8">
            <div class="d-flex flex-wrap">
                <div class="col-12">
                    <div class="card card-custom gutter-b">
                        <div class="card-header bg-color-white border-0 px-4 px-sm-4 px-md-8">
                            <div class="card-title">
                                <h5 class="card-label font-weight-bolder color-darkblue" v-if="ticket && !error">
                                    Entrada {{ ticket.ticket_event.name_ticket }} - <strong>{{ ticket.ticket_event.event.name }}</strong>
                                </h5>
                                <h5 v-else>
                                    Entrada
                                </h5>
                            </div>
                        </div>
                        <div class="card-body px-4 px-sm-4 px-md-8 py-4 py-sm-4 py-md-4" v-if="error">
                            <div class="alert alert-danger h4" role="alert">
                                {{ error_message }}
                            </div>
                        </div>
                        <div class="card-body px-4 px-sm-4 px-md-8 py-4 py-sm-4 py-md-4" v-if="ticket && !error">
                            <div class="d-flex flex-wrap">
                                <div class="col-12 col-md-4">
                                    <div class="text-center" v-if="!eventIsPast && seconds >= 0">
                                        <QrcodeVue :value="qrValue" :size="size" level="H" />
                                        <p>XX Este código QR será inválido en <strong>{{ seconds }} segundos</strong>.</p>
                                    </div>
                                    <div class="text-center" v-if="eventIsPast">
                                        <div class="alert alert-warning h4" role="alert">
                                            Este evento ya ha pasado.
                                        </div>
                                    </div>
                                    <div class="text-center" v-if="seconds < 0">
                                        <p>Generando QR...</p>
                                    </div>
                                </div>

                                <div class="col-12 col-md-8">
                                    <div class="d-flex flex-wrap">
                                        <div class="col-12 col-md-8 px-0">
                                            <h3>Entrada {{ ticket.ticket_event.name_ticket }} <strong v-if="ticket.num_ticket">- Asiento {{ ticket.num_ticket }}</strong></h3>
                                            <h2 class="text-primary"><strong>{{ ticket.ticket_event.event.name }}</strong></h2>
                                            <h4>Día <strong>{{ ticket.ticket_date.date_event }}</strong> a las <strong>{{ ticket.ticket_date.time_event_since }}</strong></h4>

                                            <h5>{{ (parseFloat(ticket.price) + parseFloat(ticket.commission)).toFixed(2) }}€ ({{ parseFloat(ticket.price).toFixed(2) }}€ + {{ parseFloat(ticket.commission).toFixed(2) }}€ gastos gestión) - Imp. Incl.</h5>

                                            <h6>{{ ticket.ticket_event.description_ticket }}</h6>
                                        </div>
                                        <div class="col-12 col-md-4 px-0 text-right d-none d-md-block">
                                            <img
                                                v-bind:src="'/files/images/events/' + ticket.ticket_event.event.img_event"
                                                style="object-fit: cover; border-radius: 0.5rem; width: 140px; height: auto; max-width: 100%; aspect-ratio: 1 / 1;"
                                                class="ml-4 mb-4"
                                            />
                                        </div>
                                    </div>

                                    <div v-html="ticket.ticket_event.event.description"></div>

                                    <h4>Organiza:</h4>
                                    <p>
                                        <strong class="text-primary">{{ ticket.ticket_event.event.prometer.company }}</strong><br>
                                        <a :href="'mailto:' + ticket.ticket_event.event.prometer.email">{{ ticket.ticket_event.event.prometer.email }}</a><br>
                                        CIF: <strong>{{ ticket.ticket_event.event.prometer.cif }}</strong>
                                    </p>

                                    <img
                                        v-bind:src="'/files/images/events/' + ticket.ticket_event.event.img_event"
                                        style="object-fit: cover; border-radius: 0.5rem; width: 100%; height: 200px; max-width: 100%;"
                                    />

<!--                                    <div><pre>{{ ticket }}</pre></div>-->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import QrcodeVue from "qrcode.vue";
export default {
    name: "MyTickets",
    components: {
        QrcodeVue,
    },
    data() {
        return {
            user: null,
            id_ticket: null,
            ticket: null,
            qrValue: "",
            size: 300,
            error: false,
            error_message: "",
            seconds : -1,
            timeToExpire : 60,
            interval: 0
        }
    },
    computed: {
        dateEvent() {
            return this.ticket ? this.ticket.ticket_date.date_event : null
        },
        finishDateEvent() {
            if(this.ticket){
                let startAt = new Date(this.ticket.ticket_date.date_event + ' ' + this.ticket.ticket_date.time_event_since)
                let endsAt = new Date(this.ticket.ticket_date.date_event + ' ' + this.ticket.ticket_date.time_event_until)
                if(endsAt < startAt){
                    endsAt.setDate(endsAt.getDate() + 1);
                }
                return endsAt;
            }
            return null;
        },
        eventIsPast() {
            if (this.finishDateEvent) {
                let now = new Date();
                return this.finishDateEvent < now;
            }
            return true;
        }
    },
    methods: {
        async generateQr(id) {
            try {
                let response = await axios.post("/generate_token_url", {
                    id: id,
                })
                if (response.data.code === 1000) {
                    this.qrValue = response.data.code_qr;
                    $("#qrCodeModal").modal("show");
                } else {
                    this.error = true;
                    this.error_message = "Se ha producido un error al generar el código QR de la entrada. Inténtalo de nuevo en unos minutos. Si el error persiste, por favor ponte en contacto con nosotros."
                }
            } catch (error) {
                console.log(error);
                this.error = true;
                this.error_message = "Se ha producido un error al generar el código QR de la entrada. Inténtalo de nuevo en unos minutos. Si el error persiste, por favor ponte en contacto con nosotros."
            }
        },
        async getTicket() {
            try {
                let response = await axios.get('/get_ticket/' + this.id_ticket);
                if (response.data.code === 1000) {
                    this.ticket = response.data.ticket;
                    await this.generateQr(this.id_ticket)
                    this.interval = window.setInterval(async () => {
                        if (this.seconds > 0) {
                            this.seconds--
                        } else {
                            await this.generateQr(this.id_ticket)
                            this.seconds = this.timeToExpire;
                        }
                    }, 1000)
                } else {
                    this.error = true;
                    this.error_message = "No se ha encontrado la entrada indicada o ésta ha sido cancelada. Asegúrese de haber hecho click en una entrada comprada por ti."
                }
            } catch (error) {
                this.error = true;
                this.error_message = "No se ha encontrado la entrada indicada. Asegúrese de haber hecho click en una entrada comprada por ti. Si el error persiste, por favor ponte en contacto con nosotros."
            }

        }
    },
    mounted() {
        if (!window.Laravel.isLoggedin) {
            //window.location = '/'
        }
        this.user = window.Laravel?.user ?? null;
        if (this.user) {
            this.id_ticket = this.$route.params.ticket_id
            this.getTicket().then()
        } else {
            //window.location = '/'
            this.error_message = "Debes acceder a tu cuenta para poder ver la entrada."
            this.error = true;
            $('[data-bs-target="#offcanvasLogin"]').trigger('click');
        }
    },
    beforeDestroy() {
        window.clearInterval(this.interval);
    }
}
</script>

<style scoped>

</style>
