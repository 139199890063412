var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "d-flex flex-column" }, [
      _c("div", { staticClass: "container my-5" }, [
        _c("p", [
          _vm._v(
            "\n            La presente política de cookies tiene por finalidad informarle\n            sobre las cookies que se utilizan en la página web\n            "
          ),
          _c(
            "a",
            { attrs: { href: "www.nftmyticket.com", target: "_blank" } },
            [_vm._v("www.nftmyticket.com")]
          ),
          _vm._v(
            ", propiedad de NFT MY TICKET, S.L con N.I.F. B09863408 y\n            domicilio en Plaza Isabel II, 5, ºC, 28013, Madrid.\n        "
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n            Esta web no instala nuestras cookies en los dispositivos de los\n            usuarios hasta que estos hayan aceptado la instalación de éstas.\n        "
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n            Le informamos que en el caso de no aceptar la instalación de\n            cookies o de deshabilitar alguna de estas en la configuración,\n            es posible que ciertos servicios no estén disponibles sin la\n            utilización de estas o que no pueda acceder a determinados\n            servicios ni tampoco aprovechar por completo todo lo que este\n            sitio web le ofrece.\n        "
          ),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "seccion" }, [
          _vm._v("¿Qué son y para qué sirven las cookies?"),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n            Una cookie es un fichero que se descarga en su ordenador y que\n            permite que el sitio web recuerde información sobre su visita,\n            su idioma y otras opciones, con el fin de personalizar los\n            servicios ofrecidos a los usuarios, optimizar el funcionamiento\n            de nuestra página y facilitar la experiencia de navegación del\n            usuario.\n        "
          ),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "seccion" }, [
          _vm._v("¿Qué tipos de cookies hay?"),
        ]),
        _vm._v(" "),
        _c("p", [_vm._v("Según quién sea la entidad que las gestione:")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n            - Cookies propias: Se envían al equipo terminal del usuario\n            desde un equipo o dominio gestionado por el propio editor y\n            desde el que se presta el servicio solicitado por el usuario.\n        "
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n            - Cookies de tercero: Se envían al equipo terminal del usuario\n            desde un equipo o dominio que no es gestionado por el editor,\n            sino por otra entidad que trata los datos obtenidos través de\n            las cookies.\n        "
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n            Según la finalidad para la que se traten los datos obtenidos a\n            través de las cookies:\n        "
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n            - Cookies técnicas: Permiten al usuario la navegación a través\n            de una página web, aplicación o plataforma y la utilización de\n            las diferentes servicios que en ella existan como, por ejemplo,\n            identificar la sesión, realizar un pedido o solicitud de\n            inscripción, almacenar contenidos o compartir contenidos a\n            través de redes sociales.\n        "
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n            - Cookies de personalización: Permiten al usuario acceder al\n            servicio con algunas características de carácter general\n            predefinidas por el usuario como el idioma, el tipo de navegador\n            a través del cual accede al servicio, etc.\n        "
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n            - Cookies de análisis: Permiten al responsable de las mismas\n            seguir y analizar del comportamiento de los usuarios de los\n            sitios web a los que están vinculadas. La información recogida\n            se utiliza en la medición de la actividad de los sitios web,\n            aplicación o plataforma y para la elaboración de perfiles de\n            navegación de los usuarios de dichos sitios, aplicaciones y\n            plataformas, con el fin de introducir mejoras en función del\n            análisis de los datos de uso que hacen los usuarios del\n            servicio.\n        "
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n            - Cookies publicitarias: Son aquéllas que permiten la gestión,\n            de la forma más eficaz posible, de los espacios publicitarios\n            que, en su caso, el editor haya incluido en una página web,\n            aplicación o plataforma desde la que presta el servicio\n            solicitado en base a criterios como el contenido editado o la\n            frecuencia en la que se muestran los anuncios.\n        "
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n            - Cookies de publicidad comportamental: Son aquéllas que\n            permiten la gestión de los espacios publicitarios que, en su\n            caso, el editor haya incluido en una página web, aplicación o\n            plataforma. Estas cookies almacenan información del\n            comportamiento de los usuarios obtenida a través de la\n            observación continuada de sus hábitos de navegación, lo que\n            permite desarrollar un perfil específico para mostrar publicidad\n            en función del mismo.\n        "
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v("Según el período de tiempo que permanecen activadas:"),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n            - Cookies de sesión: Son un tipo de cookies diseñadas para\n            recabar y almacenar datos mientras el usuario accede a una\n            página web.\n        "
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n            - Cookies persistentes: Son un tipo de cookies en el que los\n            datos siguen almacenados en el terminal y pueden ser accedidos y\n            tratados durante un periodo definido por el responsable de la\n            cookie, y que puede ir de unos minutos a varios años.\n        "
          ),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "seccion" }, [
          _vm._v("¿Qué cookies utilizamos en nuestra web?"),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            '\n            De conformidad con la Ley 34/2002, de 11 de julio, de servicios\n            de la sociedad de la información y de comercio electrónico (en\n            adelante, "LSSI"), le informamos que el presente Sitio Web\n            utiliza cookies u otros dispositivos de almacenamiento y\n            recuperación de información para realizar un seguimiento de las\n            interacciones de los Usuarios con los servicios ofrecidos en el\n            Sitio Web.\n        '
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n            Las cookies permiten reconocer el navegador de un Usuario, así\n            como el tipo de dispositivo desde el que se accede al Sitio Web\n            y se utilizan para facilitar la próxima visita del Usuario y\n            hacer que el Sitio Web le resulte más útil.\n        "
          ),
        ]),
        _vm._v(" "),
        _c("p", [_vm._v("El titular de la web utiliza las cookies para:")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n            (i) Optimizar la navegación del Usuario mediante el seguimiento\n            de la sesión.\n        "
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n            (ii) Analizar y recopilar información sobre el comportamiento de\n            navegación de los Usuarios para mejorar el Sitio Web.\n        "
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v("El Sitio Web utiliza los siguientes tipos de cookies:"),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n            Técnicas o de sesión: Cookies estrictamente necesarias para\n            prestar un servicio o contenido solicitado por el usuario. éstas\n            se encargan de aspectos técnicos como identificar registros y\n            gestionar preferencias de herramientas en el uso, interacción y\n            localización del acceso a los contenidos.\n        "
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n            Estadísticas/analíticas: Estas cookies recogen información\n            relativa al uso de la página como la permanencia en página y los\n            contenidos visitados por los lectores para medir su rendimiento\n            y mejorar la navegación.\n        "
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n            Los objetivos principales que se persiguen con este tipo de\n            cookies son:\n        "
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n            Permitir la identificación anónima de los Usuarios navegantes y\n            por lo tanto la contabilización aproximada del número de\n            visitantes.\n        "
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v("Identificar de forma anónima los contenidos más visitados."),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n            Saber si el Usuario que está accediendo es nuevo o repite\n            visita.\n        "
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n            A continuación se identifican las cookies que están siendo\n            utilizadas en esta página web así como su tipología y función:\n        "
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "table-container" }, [
          _c("table", { staticClass: "table" }, [
            _c("thead", [
              _c("tr", [
                _c("th", [_vm._v("NOMBRE")]),
                _vm._v(" "),
                _c("th", [_vm._v("FINALIDAD")]),
                _vm._v(" "),
                _c("th", [_vm._v("DURACIÓN")]),
                _vm._v(" "),
                _c("th", [_vm._v("TITULAR")]),
              ]),
            ]),
            _vm._v(" "),
            _c("tbody", [
              _c("tr", [
                _c("td", { attrs: { scope: "row" } }, [
                  _vm._v("Laravel Cross Script Reference"),
                ]),
                _vm._v(" "),
                _c("td", [
                  _vm._v(
                    "\n                            This cookie is provided by the Laravel framework\n                            in order to prevent the cross-scripting attacks.\n                        "
                  ),
                ]),
                _vm._v(" "),
                _c("td", [_vm._v("2 hours")]),
                _vm._v(" "),
                _c("td", [_vm._v("NFT My Ticket")]),
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", { attrs: { scope: "row" } }, [
                  _vm._v("Laravel Session"),
                ]),
                _vm._v(" "),
                _c("td", [
                  _vm._v(
                    "\n                            This cookie is provided by the Laravel framework\n                            in order to handle sessions\n                        "
                  ),
                ]),
                _vm._v(" "),
                _c("td", [_vm._v("2 hours")]),
                _vm._v(" "),
                _c("td", [_vm._v("NFT My Ticket")]),
              ]),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "seccion" }, [
          _vm._v(
            "\n            ¿Cómo puedo modificar la configuración de las cookies?\n        "
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n            Aquellas cookies que no sean obligatorias para la navegación del\n            presente Sitio Web podrán deshabilitarse en la configuración del\n            Sitio Web. Estos ajustes se encuentran en el pie de página del\n            Sitio Web. Además, todos los navegadores permiten hacer cambios\n            para desactivar la configuración de las cookies.\n        "
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            '\n            Este es el motivo por el que la mayoría de navegadores ofrecen\n            la posibilidad de administrar las cookies, para obtener un\n            control más preciso sobre la privacidad. Estos ajustes se\n            encuentran ubicados en las "opciones" o "preferencias" del menú\n            de su navegador.\n        '
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n            A continuación, podrá encontrar los links de cada navegador para\n            deshabilitar las cookies siguiendo las instrucciones:\n        "
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "d-flex flex-column" }, [
          _c(
            "a",
            {
              attrs: {
                href: "https://support.microsoft.com/es-es/windows/eliminar-y-administrar-cookies-168dab11-0753-043d-7c16-ede5947fc64d",
                target: "_blank",
              },
            },
            [_vm._v("- Internet Explorer")]
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              attrs: {
                href: "https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias?redirectlocale=es&redirectslug=habilitar-y-deshabilitar-cookies-que-los-sitios-we",
                target: "_blank",
              },
            },
            [_vm._v("- Mozilla Firefox")]
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              attrs: {
                href: "https://support.google.com/accounts/answer/61416?hl=es",
                target: "_blank",
              },
            },
            [_vm._v("- Google Chrome")]
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              attrs: {
                href: "https://support.apple.com/es-es/guide/safari/sfri11471/mac",
                target: "_blank",
              },
            },
            [_vm._v("- Safari")]
          ),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "seccion" }, [
          _vm._v("Cookies en dispositivos móviles"),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n            El titular de la web también usa cookies u otros dispositivos de\n            almacenamiento en dispositivos móviles.\n        "
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n            Aquellas cookies que no sean obligatorias para la navegación del\n            presente Sitio Web podrán deshabilitarse en la configuración del\n            Sitio Web.\n        "
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n            Estos ajustes se encuentran en el pie de página del Sitio Web.\n            Además, al igual que sucede en los navegadores de ordenadores,\n            lo navegadores de los dispositivos móviles permiten realizar\n            cambios en las opciones o ajustes de privacidad para desactivar\n            o eliminar las cookies.\n        "
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n            Si desea modificar las opciones de privacidad siga las\n            instrucciones especificadas por el desarrollador de su navegador\n            para dispositivo móvil.\n        "
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n            A continuación, podrá encontrar algunos ejemplos de los links\n            que le guiarán para modificar las opciones de privacidad en su\n            dispositivo móvil:\n        "
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "d-flex flex-column mb-3" }, [
          _c(
            "a",
            {
              attrs: {
                href: "https://support.apple.com/es-es/HT201265",
                target: "_blank",
              },
            },
            [_vm._v("- IOS")]
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              attrs: {
                href: "https://support.microsoft.com/es-es/windows/finalizaci%C3%B3n-del-soporte-de-windows-phone-8-1-preguntas-frecuentes-7f1ef0aa-0aaf-0747-3724-5c44456778a3",
                target: "_blank",
              },
            },
            [_vm._v("- Windows Phone")]
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              attrs: {
                href: "https://support.microsoft.com/es-es/windows/finalizaci%C3%B3n-del-soporte-de-windows-phone-8-1-preguntas-frecuentes-7f1ef0aa-0aaf-0747-3724-5c44456778a3",
                target: "_blank",
              },
            },
            [_vm._v("- Chrome Mobile")]
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              attrs: {
                href: "https://help.opera.com/en/latest/web-preferences/",
                target: "_blank",
              },
            },
            [_vm._v("- Opera Mobile")]
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n            Si tienes cualquier duda sobre las cookies que utilizamos puede\n            consultarnos a través de nuestra dirección de correo electrónico\n            info@nftmyticket.com.\n        "
          ),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }