<template>
	<div>
		<CategoriesEventsComponent
			:array_categories="array_categories"
			:me_events="me_events"
		></CategoriesEventsComponent>
		<EventsRecentGeneralComponent
			:ref="'events_recent'"
			:array_events_recent="array_events_recent"
		></EventsRecentGeneralComponent>
		<EventsNearbyGeneralComponent
			:ref="'events_nearby'"
			:array_events_nearby="array_events_nearby"
		></EventsNearbyGeneralComponent>
		<div
			class="
				d-flex
				justify-content-center
				align-items-center
				my-4
				px-5
				text-center text-sm-left
			"
		>
			<span class="color-white"></span>
		</div>
	</div>
</template>

<script>
import CategoriesEventsComponent from "./CategoriesEventsComponent.vue";
import EventsRecentGeneralComponent from "./EventsRecentGeneralComponent.vue";
import EventsNearbyGeneralComponent from "./EventsNearbyGeneralComponent.vue";
export default {
	components: {
		CategoriesEventsComponent,
		EventsRecentGeneralComponent,
		EventsNearbyGeneralComponent,
	},
	data() {
		return {
			publicPath: window.location.origin,
			array_events_recent: [],
			array_events_nearby: [],
			array_categories: [],
			id_category_query: "",
			value_min_query: "",
			value_max_query: "",
			date_query: "",
			me_events: "",
			my_lat: "",
			my_lng: "",
		};
	},
	methods: {
		listEvents() {
			let me = this;
			let params = {
				id_category: me.id_category_query,
				value_min: me.value_min_query,
				value_max: me.value_max_query,
				date: me.date_query,
				my_lat: me.my_lat,
				my_lng: me.my_lng,
			};

			axios
				.post("/list_events_front", params)
				.then((response) => {
					if (response.data.code == 1000) {
						me.array_events_recent = response.data.array_events_recent;
						if (response.data.array_events_recent.length < 8) {
							me.$refs.events_recent.show_button_carrusel = false;
						}

						me.array_events_recent.forEach((event) => {
							if (!event.isRestaurant) {
								let sold_out = true;

								event.tickets.forEach((ticket) => {
									if (parseInt(ticket.num_ticket) != ticket.sales.length) {
										sold_out = false;
									}
								});
								event.sold_out = sold_out;
							}
						});

						me.array_events_nearby = response.data.array_events_nearby;

						if (response.data.array_events_nearby.length < 8) {
							me.$refs.events_nearby.show_button_carrusel_event_nearby = false;
						}

						me.array_events_nearby.forEach((event) => {
							if (!event.isRestaurant) {
								let sold_out = true;

								event.tickets.forEach((ticket) => {
									if (parseInt(ticket.num_ticket) != ticket.sales.length) {
										sold_out = false;
									}
								});
								event.sold_out = sold_out;
							}
						});
					} else {
						swal(
							"",
							"Parece que ha habido un error, inténtalo de nuevo más tarde",
							"error"
						);
					}
				})
				.catch((error) => {
					console.error("error", error);
				});
		},
		listCategories() {
			let me = this;
			let url = "/list_categories";
			axios
				.get(url)
				.then(function (response) {
					me.array_categories = response.data.array_categories;

					const new_object = new Object();
					new_object.id = 0;
					new_object.name = "Todas";
					me.array_categories = [new_object].concat(me.array_categories);
				})
				.catch(function (error) {
					// handle error
					console.log(error);
				});
		},
		getValueQuery() {
			let me = this;
			if (this.$route.query.id_category != undefined) {
				me.id_category_query = this.$route.query.id_category;
			}
			if (this.$route.query.value_min != undefined) {
				me.value_min_query = this.$route.query.value_min;
			}
			if (this.$route.query.value_max != undefined) {
				me.value_max_query = this.$route.query.value_max;
			}
			if (this.$route.query.date != undefined) {
				me.date_query = this.$route.query.date;
			}
			me.listEvents();
		},
		getCustomLocation() {
			let me = this;
			if (navigator.geolocation) {
				navigator.geolocation.getCurrentPosition(
					function (objPosition) {
						me.my_lng = objPosition.coords.longitude;
						me.my_lat = objPosition.coords.latitude;
						me.listEvents();
					},
					function (objPositionError) {
						me.listEvents();
					}
				);
			} else {
				me.listEvents();
			}
		},
	},
	mounted() {
		//this.listEvents();
		this.getCustomLocation();
		this.getValueQuery();
		this.listCategories();
		this.me_events = this;
	},
	updated() {
		//this.listEvents();
	},
};
</script>
