var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "offcanvas bg offcanvas-end p-2",
      attrs: {
        tabindex: "1",
        id: "offcanvasUser",
        "aria-labelledby": "offcanvasUserLabel",
      },
    },
    [
      _c(
        "div",
        {
          staticClass:
            "offcanvas-header border-bottom-white align-items-center",
        },
        [
          _c(
            "h5",
            {
              staticClass: "color-white m-0",
              attrs: { id: "offcanvasUserLabel" },
            },
            [_vm._v("Mi cuenta")]
          ),
          _vm._v(" "),
          _c("div", [
            _c("img", {
              attrs: {
                src: _vm.publicPath + "/svg/perfil.svg",
                height: "20px",
              },
            }),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "btn",
                attrs: {
                  "data-bs-dismiss": "offcanvas",
                  "aria-label": "Close",
                },
              },
              [
                _c("img", {
                  staticClass: "cerrar-hover",
                  attrs: {
                    src: _vm.publicPath + "/svg/cerrar.svg",
                    height: "20px",
                  },
                }),
              ]
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "offcanvas-body" }, [
        _c("div", { staticClass: "pb-4" }, [
          _c("div", { staticClass: "d-flex" }, [
            _c("img", {
              attrs: {
                src: _vm.publicPath + "/svg/usuario.svg",
                width: "100px",
                height: "auto",
              },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "mx-4" }, [
              _c("span", { staticClass: "color-white" }, [
                _vm._v(_vm._s(_vm.fullname)),
              ]),
              _c("br"),
              _vm._v(" "),
              _c("span", { staticClass: "color-white word-break-break-all" }, [
                _vm._v(_vm._s(_vm.email)),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "my-4" }, [
            _vm._m(0),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "color-white",
                    attrs: { to: { path: "/mycollection" } },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.closeSidebar.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c(
                      "span",
                      { staticClass: "color-white", attrs: { role: "button" } },
                      [_vm._v("Ver mi colección")]
                    ),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "form-group" }, [
              _c(
                "span",
                { staticClass: "color-white", attrs: { role: "button" } },
                [
                  _vm._v("Saldo de regalo\n\t\t\t\t\t\t\t"),
                  _vm.total_gifts != null
                    ? _c(
                        "span",
                        { staticClass: "btn-lightgreen saldo-regalo" },
                        [_vm._v(_vm._s(_vm.total_gifts.toFixed(2)) + "€")]
                      )
                    : _c(
                        "span",
                        { staticClass: "btn-lightgreen saldo-regalo" },
                        [_vm._v("0€")]
                      ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "color-white",
                    attrs: { to: { path: "/help" } },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.closeSidebar.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c(
                      "span",
                      { staticClass: "color-white", attrs: { role: "button" } },
                      [_vm._v("Ayuda")]
                    ),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "form-group" }, [
              _c(
                "span",
                {
                  staticClass: "color-white cursor-pointer",
                  attrs: { role: "button" },
                  on: { click: _vm.logout },
                },
                [_vm._v("Cerrar sesión")]
              ),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "form-group" }, [
      _c(
        "span",
        {
          staticClass: "color-white",
          attrs: {
            role: "button",
            "data-toggle": "modal",
            "data-target": "#changePaswordModal",
            id: "changePasswordButton",
          },
        },
        [_vm._v("Modificar contraseña")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }