import { render, staticRenderFns } from "./EventsNearbyGeneralComponent.vue?vue&type=template&id=6665ad78&"
import script from "./EventsNearbyGeneralComponent.vue?vue&type=script&lang=js&"
export * from "./EventsNearbyGeneralComponent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/jgarciac/development/PhpStormProjects/nftmyticket/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6665ad78')) {
      api.createRecord('6665ad78', component.options)
    } else {
      api.reload('6665ad78', component.options)
    }
    module.hot.accept("./EventsNearbyGeneralComponent.vue?vue&type=template&id=6665ad78&", function () {
      api.rerender('6665ad78', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/components/EventsNearbyGeneralComponent.vue"
export default component.exports