<template>
    <div class="description col-12 col-md-6 order-1 order-sm-1 order-md-0">
        <div class="p-2">
            <div class="d-flex mt-4 mb-6">
                <p v-html="event.description" class="color-white description-event-file"></p>
            </div>
            <div class="mt-4">
                <div><span class="color-white font-weight-bolder">Entradas</span></div>
                <div class="mb-3"><span class="color-white mb-3">Tipos de entradas:</span></div>
                <ul class="color-white">
                    <li class="mb-3" v-for="ticket in getDisplayTickets()" :key="ticket.id">
                        {{ ticket.name_ticket }} ({{ ticket.description_ticket }})
                    </li>
                </ul>
            </div>

            <div class="direccion mt-4">
                <div>
                    <span class="color-white font-weight-bolder">Dirección</span><br/>
                    <span class="color-white">{{ event.address }}</span>
                </div>
            </div>

            <div class="mt-10">
                <span class="color-white font-weight-bolder">Cómo llegar</span>
                <div class="my-2">
                    <div id="map"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "EventSummary",
    props: {
        event: {
            type: Object,
            required: true
        },
        ticket_types: {
            type: Array,
            required: true
        },
    },
    watch: {
        event: {
            handler(newValue, oldValue) {
                const me = this
                setTimeout(function () {
                    me.initMap(
                        parseFloat(newValue?.latitude),
                        parseFloat(newValue?.longitude)
                    );
                }, 500);
            },
            deep: true,
            immediate: true,
      }
    },
    methods: {
        getDisplayTickets(){
            return this.ticket_types.filter(t => t.show_description)
        },
        initMap(lat, lng) {
            try{
                let mapa = new google.maps.Map(document.getElementById("map"), {
                    zoom: 10,
                    center: {lat: parseFloat(lat), lng: parseFloat(lng)},
                });
                const marker = new google.maps.Marker({
                    position: {lat: parseFloat(lat), lng: parseFloat(lng)},
                    map: mapa,
                });

                google.maps.event.addListener(marker, "click", function () {
                    window
                        .open("https://maps.google.com/?q=" + lat + "," + lng, "_blank")
                        .focus();
                });
            }catch (e) {
                console.log("Error iniciando mapa", e)
            }

        },
    }
}
</script>

<style scoped>

</style>
