<template>
    <div
        class="modal fade"
        ref="ticketRenaming"
        id="ticketRenaming"
        tabindex="-1"
        role="dialog"
        data-backdrop="static"
        data-keyboard="false"
        aria-labelledby="ticketRenamingTitle"
        aria-hidden="true"
        style="z-index: 99999"
    >
        <div
            :class="'modal-dialog modal-dialog-scrollable modal-dialog-centered modal-lg'"
            role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Asignación de Tickets</h5>
                </div>

                <div class="modal-body" v-if="sale_tickets && sale_tickets.length>0">
                    <AdminEventTicketRenaming @onFormChanged="updateData" :sale_tickets="sale_tickets" />
                </div>
                <div class="modal-body" v-else-if="!error">
                    <h4>Validando transacción, por favor espere...{{retry>0 ? retry : ''}}</h4>
                </div>
                <div class="modal-body" v-if="error">
                    <h4>{{error}}</h4>
                </div>
                <div class="modal-footer">
                    <button v-if="sale_tickets && sale_tickets.length>0" type="button" @click="saveTicketRenaming()" :disabled="loading" class="btn btn-primary">{{loading ? 'Procesando...' : 'Confirmar'}}</button>
                    <button v-if="error" type="button" @click="closeDialog()" class="btn btn-danger">Cerrar</button>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AdminEventTicketRenaming from "./AdminEventTicketRenaming.vue";

export default {
    name: "AdminEventTicketRenamingDialog",
    components: {
        AdminEventTicketRenaming
    },
    props: ['transaction_id'],
    data() {
        return {
            is_form_valid: false,
            loading: false,
            data: {},
            retry: 0,
            error: '',
            sale_tickets: {
                type: Array,
                required: true
            }
        }
    },
    mounted() {
        document.getElementById('ticketRenaming').focus()
        $('#ticketRenaming').modal({backdrop: 'static', keyboard: false})
        $("#ticketRenaming").modal('show');
        this.checkTransaction()
    },
    updated() {

    },
    computed: {

    },
    methods: {
        getErrorMsg() {
            let msg = Object.keys(this.data)
                .filter(i => !this.data[i].new_user_id)
                .map((i, x) => {
                    const d = this.data[i]
                    let msg = "";
                    if (d) {
                        if (d.new_user_email && !d.new_user_id) {
                            msg = "Debe introducir los datos del usuario con email " + d.new_user_email;
                        } else if(! d.new_user_email){
                            msg = 'Indique el email para la entrada ' + (x+1);
                        } else if(d.new_user_email.length > 0) {
                            msg = d.new_user_email + " email no válido";
                        } else {
                            msg = 'Indique el usuario para la entrada ' + (x+1);
                        }
                    }
                    return msg;
                })
            console.log(msg)
            return msg.join("\n");
        },
        updateData(valid, data) {
          this.is_form_valid = valid;
          this.data = data;
        },
        closeDialog(){
            $("#ticketRenaming").modal("hide");
        },
        checkTransaction(){
            if(this.retry < 10){
                this.retry = this.retry + 1;
                axios
                    .get("/check_transaction/" + this.transaction_id)
                    .then((response) => {
                        if (response.data) {
                            if(response.data.length == 0){
                                setTimeout(this.checkTransaction, 5000);
                            }
                            this.sale_tickets = response.data;
                        } else if (response.data.message) {
                            swal("", response.data.message, "error");
                        }
                        this.loading = false;
                    })
                    .catch(function (error) {
                        this.loading = false;
                        swal("", error, "error");
                    });
            } else {
                this.error = "No se ha podido validar su transacción. Intentelo de nuevo más tarde."
            }

        },
        saveTicketRenaming(){
            if(this.is_form_valid){
                this.loading = true

                axios
                    .post("/save_ticket_renaming", {data: this.data})
                    .then((response) => {
                        if (response.data.success) {
                            swal("", "Asignación realizada correctamente", "success");
                            this.closeDialog();
                            this.$emit("onFinishRename", true)
                        } else if (response.data.message) {
                            swal("", response.data.message, "error");
                        }
                        this.loading = false;
                    })
                    .catch(function (error) {
                        this.loading = false;
                        swal("", error, "error");
                    });
            } else {
                swal(
                    "Datos incompletos",
                    this.getErrorMsg(),
                    "error"
                );

            }
        }
    }

}
</script>

<style scoped>

</style>
