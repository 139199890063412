var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container-fluid mt-4" }, [
    _c(
      "div",
      { staticClass: "d-flex align-items-center links pb-5" },
      _vm._l(_vm.array_categories, function (category) {
        return _c(
          "router-link",
          {
            key: category.id,
            staticClass: "link btn btn-categorie mr-2",
            class: { selected: _vm.id_category_query == category.id },
            attrs: {
              to: { path: "/?id_category=" + category.id + _vm.custom_query },
            },
            nativeOn: {
              click: function ($event) {
                return _vm.changeCategory()
              },
            },
          },
          [_c("span", { domProps: { textContent: _vm._s(category.name) } })]
        )
      }),
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }