var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("SongsRecentGeneralComponent", {
        ref: "songs_recent",
        attrs: { array_songs_recent: _vm.array_songs_recent },
      }),
      _vm._v(" "),
      _c("SongsCollectionsGeneralComponent", {
        ref: "collections",
        attrs: { array_collections: _vm.array_collections },
      }),
      _vm._v(" "),
      _vm._m(0),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "d-flex justify-content-center align-items-center my-4" },
      [_c("span", { staticClass: "color-white" })]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }