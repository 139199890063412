<template>
    <div class="container">
        <div class="d-flex align-items-center my-4">
            <span class="mr-6 color-white font-weight-bold">Canciones</span>
        </div>
        <div class="d-flex flex-wrap justify-content-start">
            <div
                class="p-0 col-6 col-md-4 col-lg-3 px-5 pb-10"
                v-for="song in array_songs_collection"
                :key="song.id"
            >
                <router-link :to="{ path: '/song/' + song.id }">
                    <div>
                        <img
                            v-bind:src="'/files/images/music/' + song.img_music"
                            style="object-fit: cover"
                        />
                        <div class="p-4 d-block bg-blue">
                            <div>
                                <span
                                    class="color-white font-weight-bold"
                                    v-text="song.name"
                                ></span>
                            </div>
                            <div class="my-2 text-align-end">
                                <span
                                    class="h2 color-green"
                                    v-text="song.price + '€'"
                                ></span>
                            </div>
                        </div>
                    </div>
                </router-link>
            </div>
        </div>
        <div
            class="d-flex justify-content-center align-items-center my-4"
            v-if="hidden_button"
        >
            >
            <button
                v-on:click="showMore()"
                type="button"
                class="btn btn-green-secondary px-4"
            >
                Ver más
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: "SongsOfCollectionComponent",
    data() {
        return {
            id_collection: "",
            array_songs_collection: [],
            start: 0,
            hidden_button: false,
        };
    },
    methods: {
        listSongsOfCollections() {
            let me = this;

            let params = {
                id_collection: me.id_collection,
                start: me.start,
            };

            axios
                .post("/get_songs_of_collection", params)
                .then((response) => {
                    if (response.data.code == 1000) {
                        response.data.array_songs_collection.forEach(function (
                            value,
                            index,
                            array
                        ) {
                            me.array_songs_collection.push(value);
                        });
                        me.hidden_button = response.data.hidden_button;
                    } else {
                        swal(
                            "",
                            "Parece que ha habido un error, inténtalo de nuevo más tarde",
                            "error"
                        );
                    }
                })
                .catch(function (error) {
                    swal(
                        "",
                        "Parece que ha habido un error, inténtalo de nuevo más tarde",
                        "error"
                    );
                });
        },
        showMore() {
            let me = this;
            me.start += 12;
            me.listSongsOfCollections();
        },
    },
    mounted() {
        /*$('.intro-claim').hide();
            $('.banners-list').hide();
            this.showNfts(this.$route.params.id);

            this.getCategories();   */

        this.id_collection = this.$route.params.id;
        this.listSongsOfCollections();
    },
    updated() {
        //$("#select_categories_songs_collections").selectpicker("refresh");
    },
};
</script>
