<template>
	<div v-if="song.active == 1" class="transform-content">
		<div class="bg-header-general">
			<div class="container pt-4">
				<div class="col-md-6 d-flex align-items-end">
					<img
						v-bind:src="'/files/images/music/' + song.img_music"
						style="object-fit: cover; border-radius: 0.5rem"
						width="200px"
						height="200px"
					/>

					<div class="p-4">
						<h2 class="color-white font-weight-bolder">
							{{ song.name }}
						</h2>
						<div class="d-flex align-items-center">
							<h3 class="color-white mx-1">
								{{ song.name_collection }}
							</h3>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="container">
			<div :key="song.id" class="d-flex flex-wrap">
				<div class="col-12 col-md-6">
					<div class="p-2">
						<div class="d-flex mt-4 mb-6">
							<p v-html="song.description_nft" class="color-white"></p>
						</div>
					</div>
				</div>
				<div class="col-12 col-md-6 position-relative">
					<div class="custom-card-absolute">
						<button
							type="button"
							class="btn btn-lightgreen"
							data-toggle="modal"
							data-bs-toggle="offcanvas"
							data-bs-target="#offcanvasLogin"
							v-on:click="showAlertLogin()"
							v-if="!is_login"
						>
							{{ song.price }}€ - Comprar
						</button>
						<button
							type="button"
							class="btn btn-lightgreen"
							v-else-if="num_music_available <= 0"
							disabled
						>
							Sold Out
						</button>
						<button
							type="button"
							class="btn btn-lightgreen"
							data-toggle="modal"
							data-target="#buyModal"
							v-else
						>
							{{ song.price }}€ - Comprar
						</button>
					</div>
				</div>
			</div>
		</div>

		<div
			class="modal fade"
			id="buyModal"
			ref="buyModal"
			tabindex="-1"
			role="dialog"
			aria-labelledby="buyModalTitle"
			aria-hidden="true"
		>
			<div class="modal-dialog modal-dialog-centered" role="document">
				<form
					:id="'form_buy'"
					action="https://sis-t.redsys.es:25443/sis/realizarPago"
					method="POST"
				>
					<input
						id="Ds_SignatureVersion"
						type="hidden"
						name="Ds_SignatureVersion"
						value="HMAC_SHA256_V1"
					/>
					<input
						id="Ds_MerchantParameters"
						type="hidden"
						name="Ds_MerchantParameters"
						value=""
					/>
					<input id="Ds_Signature" type="hidden" name="Ds_Signature" value="" />
					<div class="modal-content">
						<div class="modal-body">
							<div class="mb-4">
								<h5 class="font-weight-bolder color-darkblue">
									Resumen de pago
								</h5>
							</div>
							<div class="form-group">
								<h6>Canción: {{ song.price }}€</h6>
							</div>
							<div class="form-group">
								<h6>
									Gastos de gestión:
									{{ parseFloat(commission_song).toFixed(2) }}€
								</h6>
							</div>
							<div class="form-group">
								<h6>Total: {{ price_commission.toFixed(2) }}€</h6>
							</div>
							<div class="form-group" v-if="pay_selected == 3">
								<h6>Cantidad USDT: {{ price_usdt }} USDT</h6>
							</div>

							<div class="form-group mt-5" v-if="total_gifts > 0">
								<label class="font-weight-bold color-darkblue"
									>Usar saldo regalo:</label
								>
								<div class="input-group mb-2">
									<div class="input-group-prepend">
										<div class="input-group-text">
											{{ total_gifts.toFixed(2) }}€
										</div>
									</div>
									<input
										type="number"
										class="form-control"
										id="saldo_regalo_a_usar"
										placeholder="Saldo de regalo a usar"
										v-model="saldo_regalo_a_usar"
										@keypress="comprobarSaldo($event)"
									/>
									<small class="w-100 mt-2" style="color: gray"
										>(Los gastos de gestión no se descontarán del total del
										pedido)</small
									>
								</div>
							</div>

							<div v-if="price_commission != 0">
								<div class="mb-4">
									<h5 class="font-weight-bolder color-darkblue">
										Selecciona un método de pago
									</h5>
								</div>
								<div class="form-group">
									<div
										class="
											border-rounded-green
											selected
											d-flex
											p-2
											cursor-pointer
											select-method-pay-card
										"
										@click="selectedItem('card', $event)"
									>
										<img
											class="mx-2 pe-none"
											:src="publicPath + '/svg/tarjeta.svg'"
											width="20px"
											height="auto"
										/>
										<span class="font-weight-bold pe-none"
											>Tarjeta de crédito</span
										>
									</div>
								</div>
								<div class="form-group">
									<div
										class="border-rounded-green d-flex p-2 cursor-pointer"
										@click="selectedItem('coinbase', $event)"
									>
										<img
											class="mx-2 pe-none"
											:src="publicPath + '/svg/coinbase.svg'"
											width="20px"
											height="auto"
										/>
										<span class="font-weight-bold pe-none"
											>Coinbase Commerce</span
										>
									</div>
								</div>
								<div class="form-group">
									<div
										class="border-rounded-green d-flex p-2 cursor-pointer"
										@click="selectedItem('metamask', $event)"
									>
										<img
											class="mx-2 pe-none"
											:src="publicPath + '/svg/metamask.svg'"
											width="20px"
											height="auto"
										/>
										<span class="font-weight-bold pe-none">Metamask</span>
									</div>
								</div>
							</div>

							<div class="form-check">
								<input
									type="checkbox"
									class="form-check-input"
									id="terms-and-conditions"
									v-model="terms_and_conditions"
								/>
								<label class="form-check-label" for="terms-and-conditions"
									>Acepto los
									<router-link
										@click.native="goToTermsAndConditions()"
                                        style="font-weight: bold;"
										:to="{ path: '/terms-and-conditions' }"
										>términos y condiciones</router-link
									></label
								>
							</div>
						</div>

						<div class="modal-footer justify-content-between">
							<button
								type="button"
								id="cancel_modal_buy"
								class="btn btn-cancel btn-cancel-buy"
								data-dismiss="modal"
							>
								Cancelar
							</button>
							<button
								v-if="!loading"
								type="button"
								v-on:click="paySong()"
								class="btn btn-blue"
							>
								{{ price_commission.toFixed(2) }}€ - Comprar
							</button>
							<button
								v-else
								type="button"
								class="btn btn-blue d-flex align-items-center"
							>
								Cargando
								<div class="loading-transaction"></div>
							</button>
						</div>
					</div>
				</form>
			</div>
		</div>
	</div>
</template>

<script>
import json_abi_polygon from "../../../public/abi_usdt_polygon.json";
export default {
	data() {
		return {
			publicPath: window.location.origin,
			id_song: "",
			song: "",
			show: false,
			commission_song: 0,
			price_commission: 0,
			pay_selected: 1,
			value_usdt: 0,
			price_usdt: 0,
			account_wallet: "",
			abi: JSON.stringify(json_abi_polygon),
			web3: "",
			is_login: window.Laravel.isLoggedin,
			num_music_available: 0,
			total_gifts: "",
			saldo_regalo_a_usar: "",
			terms_and_conditions: false,
			loading: false,
		};
	},
	methods: {
		getInfoUser() {
			let me = this;
			axios
				.get("/get_info_user")
				.then(function (response) {
					if (response.data.code == 1000) {
						me.total_gifts = response.data.gift;
					}
				})
				.catch(function (error) {
					// handle error
					console.error(error);
				});
		},
		getSingleSong() {
			let me = this;
			let url = "/get_info_song/" + me.id_song;

			axios
				.get(url)
				.then(function (response) {
					var html_description = "";
					var description = response.data.song.description_nft;
					html_description += description;
					me.song = response.data.song;
					me.song.description_nft = html_description;
					me.commission_song = me.song.commission;
					me.price_commission = me.song.price * 1 + me.song.commission * 1; //Esto es para que se conviertan en enteros

					if (me.saldo_regalo_a_usar != "") {
						me.price_commission = me.price_commission - me.saldo_regalo_a_usar;
						if (me.price_commission < 0) {
							me.price_commission = 0;
						}
					}

					me.value_usdt = response.data.value_usdt;
					me.price_usdt =
						Math.round((me.price_commission / me.value_usdt) * 1000000) /
						1000000;

					me.num_music_available = me.song.num_music_available;
				})
				.catch(function (error) {
					// handle error
					console.log(error);
				});
		},
		showBidModal() {
			$(".value-bid").val(this.song.price);
			$("#bidModal").modal("toggle");
		},
		selectedItem(type, event) {
			let me = this;
			if (type == "card") {
				$(".border-rounded-green").removeClass("selected");
				$(event.target).addClass("selected");
				me.pay_selected = 1;
				document.getElementById("form_buy").action =
					"https://sis-t.redsys.es:25443/sis/realizarPago";
			}

			if (type == "coinbase") {
				$(".border-rounded-green").removeClass("selected");
				$(event.target).addClass("selected");
				me.pay_selected = 2;
				document.getElementById("form_buy").action = "";
			}

			if (type == "metamask") {
				$(".border-rounded-green").removeClass("selected");
				$(event.target).addClass("selected");
				me.pay_selected = 3;
				document.getElementById("form_buy").action = "";
			}
		},
		closeModalBuySong() {
			let me = this;
			$(".border-rounded-green").removeClass("selected");
			$(".select-method-pay-card").addClass("selected");
			me.pay_selected = 1;
			me.terms_and_conditions = 0;
		},
		getValueQuery() {
			if (
				localStorage.getItem("show-transaction-success-alert") != "false" &&
				(localStorage.getItem("show-transaction-success-alert") != null) !=
					null &&
				this.$route.query.type != undefined &&
				this.$route.query.type == "success"
			) {
				swal("", "Compra realizada correctamente", "success");
				localStorage.setItem("show-transaction-success-alert", "false");
			}
		},
		createPayMetamask() {
			this.loading = false;
			let me = this;
			// our decimal number
			if (typeof window.ethereum !== "undefined") {
				//Conectar MetaMask con la web y sacar su dirección
				if (window.ethereum.chainId == "0x89") {
					me.getAccount();
				} else {
					swal("", "Connect to Polygon network", "warning");
				}
			} else {
				swal({
					title: "Instala la extensión de MetaMask",
					type: "warning",
					text: 'Si tiene alguna duda con este proceso puede revisar nuestros documentos explicativos en <a href="/help" target="_blank">AYUDA</a>',
					html: true,
				});
			}
		},
		async getAccount() {
			// This function detects most providers injected at window.ethereum
			let me = this;
			var accounts = await ethereum.request({
				method: "eth_requestAccounts",
			});
			me.account_wallet = accounts[0];
			if (me.account_wallet != "") {
				if (window.ethereum.selectedAddress == null) {
					swal("", "First connect Metamask with the platform", "warning");
					return;
				}

				var address_obj = await ethereum.request({
					method: "eth_requestAccounts",
				});
				var address = address_obj[0];
				var address_to = address;

				var amount_m = this.toFixed(me.price_usdt * 1000000);
				var smart_contract = "0xc2132D05D31c914a87C6611C10748AEb04B58e8F";

				var contract = new me.web3.eth.Contract(
					JSON.parse(me.abi),
					smart_contract
				);
				let extraData = await contract.methods.transfer(address_to, amount_m);
				var data = extraData.encodeABI();

				var params = {
					from: address,
					to: smart_contract,
					data: data,
					gas: "71305",
				};
				localStorage.setItem("show-transaction-success-alert", "true");

				try {
					// Request account access if needed
					// Accounts now exposed, use them

					ethereum
						.request({
							method: "eth_sendTransaction",
							params: [params],
						})
						.then((result) => {
							//Guardamos el hash de la firma
							// The result varies by by RPC method.
							// For example, this method will return a transaction hash hexadecimal string on success.
							this.paySongMetamask(result, address);
						})
						.catch((error) => {
							swal(
								"",
								"Parece que ha habido un error, inténtalo de nuevo más tarde",
								"error"
							);
							// If the request fails, the Promise will reject with an error.
						});
				} catch (error) {
					//deleteToken(id_token)
					swal(
						"",
						"Parece que ha habido un error, inténtalo de nuevo más tarde",
						"error"
					);
					// User denied account access
				}
			}
		},
		paySong() {
			let is_valid = true;

			if (parseFloat(this.saldo_regalo_a_usar) > parseFloat(this.total_gifts)) {
				is_valid = false;
				swal("", "No puede usar más saldo de disponible", "warning");
			}

			if (
				parseFloat(this.song.price) + parseFloat(this.commission_song) <
				parseFloat(this.saldo_regalo_a_usar)
			) {
				swal(
					"",
					"No puede descontar más del total del precio de la canción",
					"warning"
				);

				this.saldo_regalo_a_usar =
					parseFloat(this.song.price) + parseFloat(this.commission_song);
				is_valid = false;
			}

			if (parseFloat(this.saldo_regalo_a_usar) < 0) {
				swal("", "El saldo regalo no es válido", "warning");

				this.saldo_regalo_a_usar = 0;

				is_valid = false;
			}

			if (!this.terms_and_conditions) {
				swal("", "Debe aceptar nuestros términos y condiciones", "warning");

				is_valid = false;
			}

			if (is_valid) {
				this.loading = true;

				if (this.price_commission == 0) {
					this.paySongFree();
				} else {
					//Stripe
					localStorage.setItem("show-transaction-success-alert", "true");
					if (this.pay_selected == 1) {
						this.paySongStripe();
					}

					//Coinbase
					if (this.pay_selected == 2) {
						this.paySongCoinbase();
					}

					//Metamask
					if (this.pay_selected == 3) {
						this.createPayMetamask();
					}
				}
			}
		},
		paySongFree() {
			let me = this;
			axios
				.post("/pay_song_free", {
					id_song: me.id_song,
					price: me.price_commission,
					date: "2022-04-12",
					saldo_regalo_a_usar: me.saldo_regalo_a_usar,
				})
				.then((response) => {
					swal(
						"",
						"Su compra se ha realizado con éxito. Muchas gracias.",
						"success"
					);

					this.num_music_available = 0;
					this.loading = false;
					$("#cancel_modal_buy").click();
				})
				.catch(function (error) {
					swal(
						"",
						"Ha ocurrido un error. Inténtelo de nuevo más tarde",
						"error"
					);
					this.loading = false;
					console.error(error);
				});
		},
		paySongStripe() {
			let me = this;
			axios
				//.post("/pay_song_stripe", {
				.post("/pay_song_redsys", {
					id_song: me.id_song,
					price: me.price_commission,
					date: "2022-04-12",
					saldo_regalo_a_usar: me.saldo_regalo_a_usar,
				})
				.then((response) => {
					this.loading = false;
					if (response.data.code == 1000) {
						/*stripe.redirectToCheckout({
                            sessionId: response.data.session_id,
                        });*/
						document.getElementById("Ds_MerchantParameters").value =
							response.data.Ds_MerchantParameters;
						document.getElementById("Ds_Signature").value =
							response.data.Ds_Signature;
						document.getElementById("form_buy").submit();
					} else if (response.data.code > 1000) {
						swal(
							"",
							"Parece que ha habido un error, intentalo de nuevo más tarde",
							"warning"
						);
					}
				})
				.catch(function (error) {
					this.loading = false;
					console.error(error);
				});
		},
		paySongMetamask(hash, address) {
			let me = this;
			axios
				.post("/pay_music_metamask", {
					id_song: me.id_song,
					price: me.price_commission,
					date: "2022-04-12",
					hash: hash,
					address: address,
					amount_token: me.price_usdt,
					saldo_regalo_a_usar: me.saldo_regalo_a_usar,
				})
				.then((response) => {
					this.loading = false;
					if (response.data.code == 1000) {
						document.getElementById("cancel_modal_buy").click();
						swal("", "Compra realizada correctamente", "success");
					} else if (response.data.code > 1000) {
						swal(
							"",
							"Parece que ha habido un error, intentalo de nuevo más tarde",
							"warning"
						);
					}
				})
				.catch(function (error) {
					this.loading = false;
					console.error(error);
				});
		},
		paySongCoinbase() {
			let me = this;
			axios
				.post("/pay_music_coinbase", {
					id_song: me.id_song,
					price: me.price_commission,
					date: "2022-04-12",
					saldo_regalo_a_usar: me.saldo_regalo_a_usar,
				})
				.then((response) => {
					this.loading = false;
					if (response.data.code == 1000) {
						window.open(response.data.url);
					} else if (response.data.code > 1000) {
						swal(
							"",
							"Parece que ha habido un error, intentalo de nuevo más tarde",
							"warning"
						);
					}
				})
				.catch(function (error) {
					this.loading = false;
					console.error(error);
				});
		},
		//UTILS
		toFixed(x) {
			if (Math.abs(x) < 1.0) {
				var e = parseInt(x.toString().split("e-")[1]);
				if (e) {
					x *= Math.pow(10, e - 1);
					x = "0." + new Array(e).join("0") + x.toString().substring(2);
				}
			} else {
				var e = parseInt(x.toString().split("+")[1]);
				if (e > 20) {
					e -= 20;
					x /= Math.pow(10, e);
					x += new Array(e + 1).join("0");
				}
			}
			return x;
		},
		showAlertLogin() {
			swal(
				"",
				"Para realizar una compra debes iniciar sesión o registrarte",
				"warning"
			);

			$(".perfil-hover").parent().click();
		},

		comprobarSaldo($event) {
			if (isNaN($event.key)) {
				$event.preventDefault();
			}
		},
		goToTermsAndConditions() {
			$(".modal-backdrop").remove();
		},
	},
	mounted() {
		$(".intro-claim").hide();
		$(".banners-list").hide();
		this.web3 = new Web3(window.ethereum);
		this.id_song = this.$route.params.id;
		this.getValueQuery();
		this.getSingleSong();
		this.getInfoUser();
		$(this.$refs.buyModal).on("hidden.bs.modal", this.closeModalBuySong);
	},
	watch: {
		saldo_regalo_a_usar() {
			if (this.saldo_regalo_a_usar != "") {
				this.price_commission =
					this.song.price * 1 +
					this.song.commission * 1 -
					parseFloat(this.saldo_regalo_a_usar);
			} else {
				this.price_commission = this.song.price * 1 + this.song.commission * 1;
			}
			this.price_usdt =
				Math.round((this.price_commission / this.value_usdt) * 1000000) /
				1000000;
		},
	},
};
</script>

<style lang="scss" scoped>
.loading-transaction {
	border: 3px solid white;
	border-top: 3px solid #bbbbbb;
	border-radius: 50%;
	width: 20px;
	height: 20px;
	margin-left: 0.5rem;
	animation: spin 2s ease infinite;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
</style>
