<template>
	<div>
		<div class="d-flex flex-wrap justify-content-start">
			<div
				class="
					card card-custom
					col-12 col-md-6 col-lg-4 col-xl-3
					pt-2
					pb-4
					bg-transparent
				"
				v-for="nft in array_user_nfts"
				:key="nft.id_users_nfts"
			>
				<!-- NFT is not from reservation -->
				<div
					v-if="!nft.isReservation"
					class="card-body p-0 pb-8 overlay element-nft"
				>
					<div class="overlay-wrapper">
						<img
							v-if="nft.type_file != 'mp4'"
							v-bind:src="nft.img_nft_custom"
							width="100%"
							height="250px"
							style="object-fit: cover"
						/>
						<video
							v-else-if="nft.type_file == 'mp4'"
							width="100%"
							height="250px"
							playsinline
							autoplay
							loop
							muted
							style="object-fit: cover"
						>
							<source v-bind:src="nft.img_nft_custom" type="video/mp4" />
						</video>
						<div
							class="d-flex flex-wrap align-content-between"
							style="height: 120px"
						>
							<h5
								class="w-100 nft-name color-white px-4 my-2 font-weight-bolder"
								v-text="nft.name_nft"
							></h5>
							<div class="w-100">
								<h5
									class="
										w-100
										event-name
										color-white
										px-4
										my-2
										font-weight-bolder
									"
									v-text="nft.name_event"
								></h5>
<!--								<div-->
<!--									v-if="nft.status == 0"-->
<!--									class="w-100 text-center bg-color-red p-1 mt-2"-->
<!--								>-->
<!--									<span-->
<!--										class="font-weight-bolder color-darkblue"-->
<!--										v-text="'PENDIENTE RECLAMAR'"-->
<!--									></span>-->
<!--								</div>-->
<!--								<div-->
<!--									v-if="nft.status == 1"-->
<!--									class="w-100 text-center bg-color-green p-1"-->
<!--								>-->
<!--									<span-->
<!--										class="font-weight-bold color-darkblue"-->
<!--										v-text="'RECLAMADO'"-->
<!--									></span>-->
<!--								</div>-->
<!--								<div-->
<!--									v-if="nft.status == 2"-->
<!--									class="w-100 text-center bg-color-lightgreen p-1"-->
<!--								>-->
<!--									<span-->
<!--										class="font-weight-bold color-darkblue"-->
<!--										v-text="'WALLET EXTERNO'"-->
<!--									></span>-->
<!--								</div>-->
							</div>
						</div>
					</div>
<!--					<div class="overlay-layer nft" v-if="nft.status == 0">-->
<!--						<button-->
<!--							v-on:click="reclaimNft(nft.id_users_nfts, nft.type_nft)"-->
<!--							type="button"-->
<!--							class="btn btn-lightgreen font-weight-bold cursor-pointer"-->
<!--						>-->
<!--							RECLAMAR-->
<!--						</button>-->
<!--					</div>-->
				</div>

				<!-- NFT is from reservation -->
				<div v-else class="card-body p-0 pb-8 overlay element-nft">
					<div class="overlay-wrapper">
						<img
							v-if="nft.type_file != 'mp4'"
							v-bind:src="nft.nft_base64"
							width="100%"
							height="250px"
							style="object-fit: cover"
						/>
						<video
							v-else
							width="100%"
							height="250px"
							playsinline
							autoplay
							loop
							muted
							style="object-fit: cover"
						>
							<source :src="nft.nft_base64" type="video/mp4" />
						</video>
						<div
							class="d-flex flex-wrap align-content-between"
							style="height: 120px"
						>
							<h5
								class="w-100 nft-name color-white px-4 my-2 font-weight-bolder"
							>
								{{ nft.nft.name }}
							</h5>
							<h5
								v-if="nft.reservation.restaurant.prometer.collection != null"
								class="w-100 nft-name color-white px-4 my-2 font-weight-bolder"
							>
								{{ nft.reservation.restaurant.prometer.collection.name }}
							</h5>
<!--							<div class="w-100">-->
<!--								<div-->
<!--									v-if="nft.status == 'pending'"-->
<!--									class="w-100 text-center bg-color-red p-1 mt-2"-->
<!--								>-->
<!--									<span-->
<!--										class="font-weight-bolder color-darkblue"-->
<!--										v-text="'PENDIENTE RECLAMAR'"-->
<!--									></span>-->
<!--								</div>-->
<!--								<div v-else class="w-100 text-center bg-color-green p-1">-->
<!--									<span-->
<!--										class="font-weight-bold color-darkblue"-->
<!--										v-text="'RECLAMADO'"-->
<!--									></span>-->
<!--								</div>-->
<!--							</div>-->
						</div>
					</div>
<!--					<div class="overlay-layer nft" v-if="nft.status == 'pending'">-->
<!--						<button-->
<!--							@click="claimRestaurantNFT(nft.id)"-->
<!--							type="button"-->
<!--							class="btn btn-lightgreen font-weight-bold cursor-pointer"-->
<!--						>-->
<!--							RECLAMAR-->
<!--						</button>-->
<!--					</div>-->
				</div>
			</div>
			<div
				class="d-flex justify-content-center align-items-center my-4 w-100"
				v-if="hidden_button"
			>
				<button
					v-on:click="showMore()"
					type="button"
					class="btn btn-lightgreen font-weight-bold px-4"
				>
					Ver más
				</button>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "GridMyNFTsComponent",
	data() {
		return {
			publicPath: window.location.origin,
			category: "",
			array_user_nfts: [],
			start: 8,
			hidden_button: false,
			address: "",
			reservations: [],
		};
	},
	methods: {
		listUserNfts() {
			axios
				.post("/list_collection_nfts", {
					start: this.start,
				})
				.then((response) => {
					if (response.data.code == 1000) {
						this.array_user_nfts = response.data.array_user_nft;
						this.hidden_button = response.data.hasMore;
                    } else {
						swal(
							"",
							"Parece que ha habido un error, inténtalo de nuevo más tarde",
							"error"
						);
					}
				})
				.catch((error) => {
					swal(
						"",
						"Parece que ha habido un error, inténtalo de nuevo más tarde",
						"error"
					);
				});
		},
		showMore() {
			this.start += 8;
			this.listUserNfts();
		},
		async reclaimNft(id, type_nft) {
			if (this.address == "") {
				swal({
					title:
						"Debes conectar y registrar tu dirección de metamask con la plataforma. Puedes hacerlo pulsando en CONECTAR WALLET, al lado de tu foto de perfil",
					type: "warning",
					text: 'Si tiene alguna duda con este proceso puede revisar nuestros documentos explicativos en <a href="/help" target="_blank">AYUDA</a>',
					html: true,
				});
				return;
			}

			const params = {
				id_nft: id,
				type_nft: type_nft,
			};

			axios
				.post("/claim_nft", params)
				.then((response) => {
					if (response.data.code == 1000) {
						this.array_user_nfts.forEach(function (value, index, array) {
							if (value.id_users_nfts == id && value.type_nft == type_nft) {
								value.status = 1;
							}
						});

						swal({
							title: "NFT reclamado correctamente",
							type: "success",
							text: 'Puede revisar nuestros documentos explicativos en <a href="/help" target="_blank">AYUDA</a> donde le resolveremos los errores y problemas más comunes',
							html: true,
						});
					} else {
						swal(
							"",
							"En estos momentos no se puede reclamar el NFT porque la red de Polygon está saturada. Inténtelo de nuevo más tarde",
							"error"
						);
					}
				})
				.catch((error) => {
					console.log(error);

					swal(
						"",
						"En estos momentos no se puede reclamar el NFT porque la red de Polygon está saturada. Inténtelo de nuevo más tarde",
						"error"
					);
				});
		},
		claimRestaurantNFT(id) {
			axios
				.post("/claim-restaurant-nft", {
					nft_id: id,
				})
				.then((response) => {
					this.array_user_nfts.forEach((value) => {
						if (value.isReservation) {
							if (value.id == id) {
								value.status = "claimed";
							}
						} else {
							if (value.id_users_nfts == id && value.type_nft == type_nft) {
								value.status = 1;
							}
						}
					});

					swal({
						title: "NFT reclamado correctamente",
						type: "success",
						text: 'Puede revisar nuestros documentos explicativos en <a href="/help" target="_blank">AYUDA</a> donde le resolveremos los errores y problemas más comunes',
						html: true,
					});
				})
				.catch((error) => {
					console.log(error);

					swal(
						"",
						"En estos momentos no se puede reclamar el NFT porque la red de Polygon está saturada. Inténtelo de nuevo más tarde",
						"error"
					);
				});
		},
		updateAddress(address) {
			this.address = address;
		},
	},
	mounted() {
		this.listUserNfts();
	},
};
</script>

<style lang="scss" scoped>
.nft-name {
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	// height: 50px;
}

.event-name {
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	overflow: hidden;
	// height: 20px;
}
</style>
