<template>
	<div class="container-fluid mt-4">
		<div class="d-flex align-items-center links pb-5">
            <router-link v-for="(category) in array_categories" :key="category.id" @click.native="changeCategory()" :to="{ path: '/?id_category='+ category.id + custom_query}" class="link btn btn-categorie mr-2" :class="{selected: id_category_query == category.id}">
                <span v-text="category.name"></span>
            </router-link>
            <!-- <div class="evecans-carrousel content p-0">
                <div class="content-principal">
                    <button role="button" id="arrow-left-recent" class="arrow-left" @click="carrouselLeft()"><img src="svg/flecha-detras.svg" width="15px" height="auto"></button>
                    <div class="content-carousel" id="content-carousel-recent">
                        <div class="carousel">
                            <div class="evecan evecan-category" v-for="(category) in array_categories" :key="category.id">
                                <router-link @click.native="changeCategory()" :to="{ path: '/?id_category='+ category.id}" class="btn btn-categorie" :class="'selected'" v-if="id_category_query == category.id">
                                    <span v-text="category.name"></span>
                                </router-link>
                                <router-link @click.native="changeCategory()" :to="{ path: '/?id_category='+ category.id}" class="btn btn-categorie" v-else>
                                    <span v-text="category.name"></span>
                                </router-link>
                            </div>
                        </div> 
                    </div>
                    <button role="button" id="arrow-right-recent" class="arrow-right" @click="carrouselRight()"><img src="svg/flecha-delante.svg" width="15px" height="auto"></button>
                </div>
            </div> -->
        </div>
	</div>
</template>

<script>
    export default {
        name: "CategoriesEventsComponent",
        data(){
            return{                
                arrayCategories:[],
                show_button_carrusel: true,
                id_category_query: '',
                custom_query: ''
            }
        },
        props: ['array_categories', 'me_events'],
        methods:{
            changeCategory(){
                let me = this;
                if(this.$route.query.id_category != undefined){
                    me.id_category_query = this.$route.query.id_category;
                    $('#select_categories').val(me.id_category_query);
                    $('#select_categories').selectpicker("refresh");
                }
                me.me_events.getValueQuery();
            },
            carrouselRight(){
                const fila = document.querySelector('#content-carousel-recent');
                fila.scrollLeft += fila.offsetWidth + 20;
            },

            carrouselLeft(){
                const fila = document.querySelector('#content-carousel-recent');
                fila.scrollLeft -= fila.offsetWidth + 20;
            }
        },
        mounted() {
            let me = this;
            if(this.$route.query.id_category != undefined){
                me.id_category_query = this.$route.query.id_category;
            }
            if(this.$route.query.value_min != undefined){
                me.custom_query += '&value_min=' + this.$route.query.value_min;
            }
            if(this.$route.query.value_max != undefined){
                me.custom_query += '&value_max=' + this.$route.query.value_max;
            }
            if(this.$route.query.date != undefined){
                me.custom_query += '&date=' + this.$route.query.date;
            }
        },
    }
</script>


<style lang="scss" scoped>
.links {
    overflow-x: auto;
    max-width: 100vw;
    
    .link {
        white-space: nowrap;
    }
}
</style>