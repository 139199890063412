const validateBirtday = function(birthday, minYears = 16) {
    let isEmpty = birthday === '';
    let years = 0;
    let message = "";
    if (!isEmpty) {
        if(birthday === -1){
            message = "La fecha introducida no es válida";
        }
        else{
            let today = new Date();
            let birthdate = new Date(birthday);

            if(birthdate.getDate() > 31 || birthdate.getDate() < 1){
                message = "Debe introducir un día válido";
            } else {
                if(birthdate.getMonth() > 11 || birthdate.getMonth() < 0){
                    message = "Debe introducir un mes válido";
                } else {
                    if(birthdate.getFullYear() < 1900 || birthdate.getFullYear() > today.getFullYear()){
                        message = "Debe introducir un año entre 1900 y la fecha actual";
                    } else {
                        years = today.getFullYear() - birthdate.getFullYear();
                        let month = today.getMonth() - birthdate.getMonth();
                        if (month < 0 || (month === 0 && today.getDate() < birthdate.getDate())) {
                            years--;
                        }
                        if(years < minYears){
                            message = "Debe tener al menos " + minYears + " años";
                        }
                    }
                }
            }
        }
    }
    else{
        message = "Debe rellenar el campo fecha";
    }

    return {
        valid: years >= minYears,
        message: message
    };
};

export const validateDate = function(day, month, year) {
    // Create a list of days of a month
    let ListofDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    if (month === 1 || month > 2) {
        if (day > ListofDays[month - 1]) {
            //to check if the date is out of range
            return false;
        }
    } else if (month === 2) {
        let leapYear = false;
        if (((year % 4 === 0) && year % 100) || (year % 400 === 0)) leapYear = true;
        if ((leapYear === false) && (day >= 29)) {
            return false;
        }
        else
        if ((leapYear === true) && (day > 29)) {
            return false;
        }
    }

    return true;
};

export default validateBirtday;

