<template>
    <div>
        <div class="mb-4">
            <h5 class="font-weight-bolder color-darkblue">
                Resumen de pago
            </h5>
        </div>
        <div class="form-group">
            <h6>Nº de cambios de nombre: {{ numTickets }}</h6>
        </div>
        <div class="form-group" v-for="ticket in sale_tickets" :key="ticket.ticket_id">
           <h7>{{ticket.event.name}} {{ticket.ticket_event.name_ticket}} + {{ticket.ticket_event.rename_price}}€</h7>
        </div>
        <div class="form-group">
            <h6>
                Gastos de gestión:
                {{ parseFloat(commission).toFixed(2) }}€
            </h6>
        </div>
        <div class="form-group">
            <h6>
                Total:
                {{ parseFloat(total).toFixed(2) }}€
            </h6>
        </div>
        <div class="form-group" v-if="isUSDTPayment">
            <h6>Cantidad USDT: {{ priceUSDT }} USDT</h6>
        </div>

        <div class="form-group mt-5" v-if="user_credit > 0">
            <label class="font-weight-bold color-darkblue">Usar saldo regalo:</label>
            <div class="input-group mb-2">
                <div class="input-group-prepend">
                    <div class="input-group-text">
                        {{ user_credit.toFixed(2) }}€
                    </div>
                </div>
                <input
                    type="text"
                    class="form-control"
                    id="saldo_regalo_a_usar"
                    placeholder="Saldo de regalo a usar"
                    v-model="consumed_credit"
                    @input="onTotalChange"
                    @keydown="isNumber($event)"
                />
                <small class="w-100 mt-2" style="color: gray">(Los gastos de gestión no se descontarán del total del pedido)</small>
            </div>
        </div>

        <div class="form-group" v-if="totalPending > 0">
            <div class="mb-4 mt-5">
                <h5 class="font-weight-bolder color-darkblue">
                    Selecciona un método de pago
                </h5>
            </div>
            <div class="form-group">
                <div
                    v-bind:class="'border-rounded-green d-flex p-2 cursor-pointer select-method-pay-card ' + (payment_method === 1 ? 'selected' : '')"
                    v-on:click="setPaymentMethod(1)"
                >
                    <img
                        class="mx-2 pe-none"
                        :src="publicPath + '/svg/tarjeta.svg'"
                        width="20px"
                        height="auto"
                    />
                    <span class="font-weight-bold pe-none">Tarjeta de crédito</span>
                </div>
            </div>
            <div class="form-group" v-if="1===0">
                <div
                    v-bind:class="'border-rounded-green d-flex p-2 cursor-pointer select-method-pay-card ' + (payment_method === 3 ? 'selected' : '')"
                    v-on:click="setPaymentMethod(3)"
                >
                    <img
                        class="mx-2 pe-none"
                        :src="publicPath + '/svg/coinbase.svg'"
                        width="20px"
                        height="auto"
                    />
                    <span class="font-weight-bold pe-none">Coinbase Commerce</span>
                </div>
            </div>
            <div class="form-group" v-if="1===0">
                <div
                    v-bind:class="'border-rounded-green d-flex p-2 cursor-pointer select-method-pay-card ' + (payment_method === 4 ? 'selected' : '')"
                    v-on:click="setPaymentMethod(4)"
                >
                    <img
                        class="mx-2 pe-none"
                        :src="publicPath + '/svg/metamask.svg'"
                        width="20px"
                        height="auto"
                    />
                    <span class="font-weight-bold pe-none">Metamask</span>
                </div>
            </div>
        </div>
    </div>

</template>

<script>

export default {
    name: "TicketRenamingCartSummary",
    props: {
        sale_tickets: {
            type: Object,
            required: true
        },
        user_credit: {
            type: Number,
            required: true
        },
        usdt_value: {
            type: Number,
            required: true
        }
    },
    created() {
    },
    mounted() {
        this.onTotalChange();
    },
    updated() {
        this.onTotalChange();
    },
    computed: {
        commission() {
            return Object.values(this.sale_tickets)
                .filter(c => c.new_user_id && c.new_user_id !== c.user_id)
                .reduce((p, c, i) => p + parseFloat(c.ticket_event.rename_commission), 0)
        },
        numTickets() {
            return Object.values(this.sale_tickets).length
        },
        isUSDTPayment() {
            return this.payment_method === 3
        },
        priceUSDT() {
            return Math.round((this.totalPending / this.usdt_value) * 1000000) / 1000000;
        },
        totalPending() {
            const consumedCredit = (!this.consumed_credit || isNaN(this.consumed_credit)) ? 0 : parseFloat(this.consumed_credit);
            return Math.max.apply(Math, [this.total - consumedCredit, 0])
        },
        total() {
            return Object.values(this.sale_tickets)
                .filter(c => c.new_user_id && c.new_user_id !== c.user_id)
                .reduce((p, c, i) => p + c.ticket_event.rename_price + c.ticket_event.rename_commission, 0)
        },
    },
    data: function () {
        return {
            publicPath: window.location.origin,
            payment_method: 1,
            is_login: window.Laravel.isLoggedin,
            consumed_credit: 0
        }
    },
    methods: {
        setPaymentMethod(type) {
            this.payment_method = type
        },
        onTotalChange() {
            if (this.consumed_credit > this.total) {
                this.consumed_credit = this.total;
            }
            const newCart = {sale_tickets: {...this.sale_tickets}, payment_method: this.payment_method, consumed_credit: this.consumed_credit, total_pending: this.totalPending}
            this.$emit('onCartChanged', newCart)
        },
        isNumber: function (evt) {
            evt = (evt) ? evt : window.event;
            let charCode = (evt.which) ? evt.which : evt.keyCode;
            if (charCode !== 190 && (charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        }
    }
}
</script>

<style scoped>

</style>
