var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("CategoriesEventsComponent", {
        attrs: {
          array_categories: _vm.array_categories,
          me_events: _vm.me_events,
        },
      }),
      _vm._v(" "),
      _c("EventsRecentGeneralComponent", {
        ref: "events_recent",
        attrs: { array_events_recent: _vm.array_events_recent },
      }),
      _vm._v(" "),
      _c("EventsNearbyGeneralComponent", {
        ref: "events_nearby",
        attrs: { array_events_nearby: _vm.array_events_nearby },
      }),
      _vm._v(" "),
      _vm._m(0),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "d-flex justify-content-center align-items-center my-4 px-5 text-center text-sm-left",
      },
      [_c("span", { staticClass: "color-white" })]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }