<template>
    <div class="container px-0">
        <div class="d-flex align-items-center my-4">
            <span class="col-12 mr-6 h4 color-white font-weight-bold"
                >Colecciones</span
            >
        </div>
        <div
            class="d-flex flex-wrap justify-content-start"
            v-if="array_collections"
        >
            <div
                class="col-8 col-md-4 col-lg-3 mb-5"
                v-for="collection in array_collections"
                :key="collection.id"
            >
                <div class="evecan custom">
                    <router-link
                        :to="{ path: '/collections/' + collection.id }"
                        class="hideintro"
                    >
                        <div class="h-100">
                            <img
                                v-bind:src="
                                    '/files/images/music/' +
                                    collection.img_music
                                "
                                style="object-fit: cover"
                            />
                            <div
                                class="description p-4 d-flex flex-wrap justify-content-between align-content-between bg-blue"
                            >
                                <p
                                    class="w-100 event-name color-white font-weight-bold"
                                >
                                    {{ collection.name_collection }}
                                </p>
                                <div class="w-100 text-align-end">
                                    <span class="color-green"
                                        >Ver colecciones</span
                                    >
                                </div>
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
        <div class="text-white h2 mt-3" v-else>
            No hay colecciones disponibles
        </div>
        <div
            class="d-flex justify-content-center align-items-center my-4"
            v-if="hidden_button"
        >
            >
            <button
                v-on:click="showMore()"
                type="button"
                class="btn btn-green-secondary px-4"
            >
                Ver más
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: "SongsCollectionsComponent",
    data() {
        return {
            category: "",
            array_collections: [],
            start: 0,
            hidden_button: false,
        };
    },
    methods: {
        listCollections() {
            let me = this;

            let params = {
                start: me.start,
            };

            axios
                .post("/list_collections", params)
                .then((response) => {
                    if (response.data.code == 1000) {
                        response.data.array_collections.forEach(function (
                            value,
                            index,
                            array
                        ) {
                            me.array_collections.push(value);
                        });
                        me.hidden_button = response.data.hidden_button;
                    } else {
                        swal(
                            "",
                            "Parece que ha habido un error, inténtalo de nuevo más tarde",
                            "error"
                        );
                    }
                })
                .catch(function (error) {
                    swal(
                        "",
                        "Parece que ha habido un error, inténtalo de nuevo más tarde",
                        "error"
                    );
                });
        },
        showMore() {
            let me = this;
            me.start += 12;
            me.listCollections();
        },
    },
    mounted() {
        /*$('.intro-claim').hide();
            $('.banners-list').hide();
            this.showNfts(this.$route.params.id);

            this.getCategories();   */
        this.listCollections();
    },
    updated() {
        //$("#select_categories_songs_collections").selectpicker("refresh");
    },
};
</script>
