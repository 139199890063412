var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "rename-container" } },
    [
      _c("h2", [_vm._v("Datos de los asistentes")]),
      _vm._v(" "),
      _vm.preregistration_email !== ""
        ? _c(
            "div",
            [
              _c("AdminEventTicketRenamingRegisterUserDialog", {
                attrs: { userEmail: _vm.preregistration_email },
                on: {
                  onUserPreregister: (email) => _vm.onPreregisterUser(email),
                  closePreregistrationDialog: (closed) =>
                    _vm.setPreregisterUser(""),
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.tickets, function (ticket) {
        return _c(
          "div",
          {
            key: ticket.ticket_id,
            class: "row",
            staticStyle: { "margin-top": "20px" },
          },
          [
            _c("div", { class: "col" }, [
              _c("div", { staticClass: "card" }, [
                _c("div", { staticClass: "card-header" }, [
                  _c("div", { staticClass: "row row-header" }, [
                    _c("div", { staticClass: "col-8" }, [
                      _c("h3", { staticClass: "w-75 p-3" }, [
                        _c("img", {
                          staticClass: "rounded",
                          attrs: {
                            width: "40",
                            height: "40",
                            src: "/svg/entradas-seleccion.svg",
                          },
                        }),
                        _vm._v(" " + _vm._s(_vm.header(ticket))),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-4 float-right row-header" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "form-check form-switch form-check-reverse",
                        },
                        [
                          _c("input", {
                            staticClass: "form-check-input",
                            attrs: {
                              type: "checkbox",
                              id: "ownercb" + ticket.ticket_id,
                            },
                            on: {
                              change: function ($event) {
                                return _vm.assignTicket(
                                  $event,
                                  ticket.ticket_id,
                                  ticket.user_id,
                                  ticket.user_email
                                )
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "label",
                            {
                              staticClass: "form-check-label",
                              attrs: { for: "flexSwitchCheckReverse" },
                            },
                            [
                              _vm._v(
                                "Esta entrada es para\n                                    mi"
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "card-body" }, [
                  _c("h5", { staticClass: "card-title" }, [
                    _vm._v(_vm._s(ticket.event?.name)),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-12" }, [
                      _c("p", { staticClass: "card-text" }, [
                        _vm._v(_vm._s(ticket.ticket_event?.description_ticket)),
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "card-text" }, [
                        _vm._v(_vm._s(ticket.event?.address)),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "row",
                      staticStyle: { "margin-top": "20px" },
                    },
                    [
                      _c("div", { staticClass: "col-12" }, [
                        _c(
                          "div",
                          { staticClass: "input-group has-validation" },
                          [
                            _c("span", { staticClass: "input-group-text" }, [
                              _vm._v("Email del asistente"),
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: ticket.new_user_email,
                                  expression: "ticket.new_user_email",
                                },
                              ],
                              class:
                                "form-control " +
                                _vm.validationEmailClass(ticket),
                              attrs: {
                                disabled: _vm.isOwner(ticket),
                                type: "text",
                                "aria-label": "Email del asistente",
                              },
                              domProps: { value: ticket.new_user_email },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    ticket,
                                    "new_user_email",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _vm._v(" "),
                            _vm.emailError(ticket)
                              ? _c("div", { staticClass: "invalid-feedback" }, [
                                  _vm._v(
                                    "\n                                        La dirección introducida no es válida\n                                    "
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.isOwner(ticket) && ticket.new_user_id
                              ? _c("div", { staticClass: "valid-feedback" }, [
                                  _vm._v(
                                    "\n                                        Usuario existente\n                                    "
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.isNewUser(ticket) &&
                            _vm.isNewUserEmailValid(ticket)
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-primary",
                                    attrs: {
                                      type: "button",
                                      id: "button-addon2",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.preregisterUser(ticket)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "Introducir datos\n                                    "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        ),
                      ]),
                    ]
                  ),
                ]),
              ]),
            ]),
          ]
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }