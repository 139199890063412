<template>
	<div
		class="offcanvas bg offcanvas-end p-2"
		tabindex="1"
		id="offcanvasUser"
		aria-labelledby="offcanvasUserLabel"
	>
		<div class="offcanvas-header border-bottom-white align-items-center">
			<h5 id="offcanvasUserLabel" class="color-white m-0">Mi cuenta</h5>
			<div>
				<img :src="publicPath + '/svg/perfil.svg'" height="20px" />
				<a class="btn" data-bs-dismiss="offcanvas" aria-label="Close"
					><img
						class="cerrar-hover"
						:src="publicPath + '/svg/cerrar.svg'"
						height="20px"
				/></a>
			</div>
		</div>
		<div class="offcanvas-body">
			<div class="pb-4">
				<div class="d-flex">
					<img
						:src="publicPath + '/svg/usuario.svg'"
						width="100px"
						height="auto"
					/>
					<div class="mx-4">
						<span class="color-white">{{ fullname }}</span
						><br />
						<span class="color-white word-break-break-all">{{ email }}</span>
					</div>
				</div>
				<div class="my-4">
					<div class="form-group">
						<span
							role="button"
							class="color-white"
							data-toggle="modal"
							data-target="#changePaswordModal"
                            id="changePasswordButton"
							>Modificar contraseña</span
						>
					</div>
					<div class="form-group">
						<router-link
							:to="{ path: '/mycollection' }"
							@click.native="closeSidebar"
							class="color-white"
							><span role="button" class="color-white"
								>Ver mi colección</span
							></router-link
						>
					</div>
					<div class="form-group">
						<span role="button" class="color-white"
							>Saldo de regalo
							<span
								class="btn-lightgreen saldo-regalo"
								v-if="total_gifts != null"
								>{{ total_gifts.toFixed(2) }}€</span
							><span class="btn-lightgreen saldo-regalo" v-else>0€</span>
						</span>
					</div>
					<div class="form-group">
						<router-link
							:to="{ path: '/help' }"
							@click.native="closeSidebar"
							class="color-white"
							><span role="button" class="color-white">Ayuda</span></router-link
						>
					</div>
					<div class="form-group">
						<span
							role="button"
							class="color-white cursor-pointer"
							@click="logout"
							>Cerrar sesión</span
						>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "UserComponent",
	data() {
		return {
			publicPath: window.location.origin,
			email: "",
			fullname: "",
			error: null,
			total_gifts: 0,
			is_login: window.Laravel.isLoggedin,
		};
	},
	methods: {
		logout(e) {
			e.preventDefault();
			let me = this;
			axios
				.get("/api/logout")
				.then(function (response) {
					//me.$router.go("/");
					window.location.href = "/";
				})
				.catch(function (error) {
					// handle error
					console.log(error);
				});
		},
		getInfoUser() {
			let me = this;
			axios
				.get("/get_info_user")
				.then(function (response) {
					if (response.data.code == 1000) {
						me.email = response.data.email;
						me.fullname = response.data.fullname;
						me.total_gifts = response.data.gift;
					}
				})
				.catch(function (error) {
					// handle error
					console.log(error);
				});
		},
		closeSidebar() {
			$('#offcanvasUser a[data-bs-dismiss="offcanvas"] img').trigger("click");
		},
	},
	mounted() {
		if (this.is_login) {
			this.getInfoUser();
		}
        if (this.$route.query && this.$route.query.changePassword && this.$route.query.changePassword === 'true') {
            if (this.is_login) {
                $("#changePasswordButton").trigger('click')
                this.$router.push({ query : { '' : '' } })
            } else {
                $('[data-bs-target="#offcanvasLogin"]').trigger('click');
            }

        }
	},
};
</script>

<style lang="scss" scoped>
.saldo-regalo {
	margin-left: 0.5rem;
	font-weight: bold;
	padding: 0.3rem;
	border-radius: 5px;
}
</style>
