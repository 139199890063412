var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.event.active == 1
    ? _c(
        "div",
        { staticClass: "transform-content" },
        [
          _c("div", { staticClass: "bg-header-general" }, [
            _c("div", { staticClass: "container pt-4 mt-4 px-2" }, [
              _vm.event != ""
                ? _c(
                    "div",
                    { staticClass: "col-md-6 d-flex align-items-end" },
                    [
                      _c("img", {
                        staticStyle: {
                          "object-fit": "cover",
                          "border-radius": "0.5rem",
                          width: "200px",
                          height: "auto",
                          "max-width": "30vw",
                          "aspect-ratio": "1 / 1",
                        },
                        attrs: {
                          src: "/files/images/events/" + _vm.event.img_event,
                        },
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "px-4 pt-8" }, [
                        _c(
                          "h2",
                          { staticClass: "color-white font-weight-bolder" },
                          [
                            _vm._v(
                              "\n                            " +
                                _vm._s(_vm.event.name) +
                                "\n                        "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "d-flex align-items-center" },
                          [
                            _c("img", {
                              attrs: {
                                src:
                                  _vm.publicPath + "/svg/calendario-blanco.svg",
                                width: "15px",
                                height: "auto",
                              },
                            }),
                            _vm._v(" "),
                            _c("span", { staticClass: "color-white mx-1" }, [
                              _vm._v(_vm._s(_vm.custom_date)),
                            ]),
                          ]
                        ),
                      ]),
                    ]
                  )
                : _vm._e(),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "container px-2" }, [
            _c("div", { staticClass: "d-flex flex-wrap" }, [
              _c(
                "div",
                {
                  staticClass:
                    "description col-12 col-md-6 order-1 order-sm-1 order-md-0",
                },
                [
                  _c("div", { staticClass: "p-2" }, [
                    _c("div", { staticClass: "d-flex mt-4 mb-6" }, [
                      _c("p", {
                        staticClass: "color-white description-event-file",
                        domProps: { innerHTML: _vm._s(_vm.event.description) },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "mt-4" }, [
                      _vm._m(0),
                      _vm._v(" "),
                      _vm._m(1),
                      _vm._v(" "),
                      _c(
                        "ul",
                        { staticClass: "color-white" },
                        _vm._l(_vm.array_tickets, function (ticket) {
                          return ticket.show_description
                            ? _c(
                                "li",
                                { key: ticket.id, staticClass: "mb-3" },
                                [
                                  _vm._v(
                                    "\n                                    " +
                                      _vm._s(ticket.name_ticket) +
                                      " (" +
                                      _vm._s(ticket.description_ticket) +
                                      ")\n                                "
                                  ),
                                ]
                              )
                            : _vm._e()
                        }),
                        0
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "direccion mt-4" }, [
                      _c("div", [
                        _c(
                          "span",
                          { staticClass: "color-white font-weight-bolder" },
                          [_vm._v("Dirección")]
                        ),
                        _c("br"),
                        _vm._v(" "),
                        _c("span", { staticClass: "color-white" }, [
                          _vm._v(_vm._s(_vm.event.address)),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _vm._m(2),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "buy-tickets col-12 col-md-6 position-relative order-0 order-sm-0 order-md-1",
                  attrs: { id: "realiza-tu-compra" },
                },
                [
                  _c("div", { staticClass: "custom-card-absolute" }, [
                    _c("div", { staticClass: "card card-custom gutter-b" }, [
                      _vm._m(3),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "card-body px-4 px-sm-4 px-md-8 py-4 py-sm-4 py-md-8",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "form-group",
                              staticStyle: { display: "none" },
                            },
                            [
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "font-weight-bold color-darkblue",
                                },
                                [_vm._v("Selecciona una fecha:")]
                              ),
                              _vm._v(" "),
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.select_date_event,
                                      expression: "select_date_event",
                                    },
                                  ],
                                  staticClass:
                                    "form-control selectpicker w-100 select-date",
                                  attrs: {
                                    name: "select_date_event",
                                    id: "select_date_event",
                                    "data-style": "select-lightgreen",
                                  },
                                  on: {
                                    change: [
                                      function ($event) {
                                        var $$selectedVal =
                                          Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function (o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function (o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                        _vm.select_date_event = $event.target
                                          .multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      },
                                      function ($event) {
                                        return _vm.onChangeSelectTypeDate(
                                          $event
                                        )
                                      },
                                    ],
                                  },
                                },
                                _vm._l(_vm.array_dates, function (date) {
                                  return _c(
                                    "option",
                                    {
                                      key: date.id,
                                      domProps: { value: date.id },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                            " +
                                          _vm._s(date.date_event) +
                                          "\n                                        "
                                      ),
                                    ]
                                  )
                                }),
                                0
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "form-group d-flex flex-wrap",
                              attrs: { id: "div_select_type_ticket" },
                            },
                            [
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "font-weight-bold color-darkblue",
                                },
                                [_vm._v("Tipo de entrada:")]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "table-responsive border rounded",
                                },
                                [
                                  _c(
                                    "table",
                                    {
                                      staticClass:
                                        "table table-striped table-hover mb-0",
                                    },
                                    [
                                      _vm._m(4),
                                      _vm._v(" "),
                                      _c(
                                        "tbody",
                                        _vm._l(
                                          _vm.array_tickets_select,
                                          function (ticket) {
                                            return _c(
                                              "tr",
                                              {
                                                key: ticket.id,
                                                class:
                                                  ticket.tickets_available <= 0
                                                    ? "table-danger text-danger disabled "
                                                    : _vm.select_type_ticket ===
                                                      ticket.id
                                                    ? "table-success"
                                                    : "",
                                                style:
                                                  ticket.tickets_available <= 0
                                                    ? "cursor: not-allowed"
                                                    : "cursor: pointer;",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.selectTicketType(
                                                      ticket
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "td",
                                                  {
                                                    staticClass: "pr-0",
                                                    staticStyle: {
                                                      width: "28px",
                                                    },
                                                  },
                                                  [
                                                    _c("input", {
                                                      attrs: {
                                                        type: "radio",
                                                        disabled:
                                                          ticket.tickets_available <=
                                                          0,
                                                        readonly: "",
                                                      },
                                                      domProps: {
                                                        checked:
                                                          _vm.select_type_ticket ===
                                                          ticket.id,
                                                      },
                                                    }),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "td",
                                                  { staticClass: "px-0 py-2" },
                                                  [
                                                    ticket.tickets_available <=
                                                    0
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "badge bg-danger text-white",
                                                          },
                                                          [_vm._v("Agotadas")]
                                                        )
                                                      : _vm._e(),
                                                    _vm._v(
                                                      "\n                                                    " +
                                                        _vm._s(
                                                          ticket.name_ticket
                                                        ) +
                                                        "\n                                                    "
                                                    ),
                                                    ticket.name_ticket !==
                                                    ticket.description_ticket
                                                      ? [
                                                          _c("br"),
                                                          _c("small", [
                                                            _vm._v(
                                                              _vm._s(
                                                                ticket.description_ticket
                                                              )
                                                            ),
                                                          ]),
                                                        ]
                                                      : _vm._e(),
                                                  ],
                                                  2
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "td",
                                                  {
                                                    staticStyle: {
                                                      "text-align": "right",
                                                      width: "80px",
                                                    },
                                                  },
                                                  [
                                                    _c("strong", [
                                                      _vm._v(
                                                        _vm._s(
                                                          ticket.num_tickets_pack
                                                            ? (
                                                                ticket.num_tickets_pack *
                                                                ticket.price_ticket
                                                              ).toFixed(2)
                                                            : ticket.price_ticket
                                                        ) + "€"
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _vm.numbered_ticket
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex flex-column justify-content-center w-100",
                                    },
                                    [
                                      _vm.ticket_image != null
                                        ? _c("img", {
                                            staticClass:
                                              "custom-image mx-auto mt-3",
                                            attrs: {
                                              src:
                                                "/files/images/seatings/" +
                                                _vm.ticket_image,
                                              alt: "Imagen Ticket",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.zoomImage()
                                              },
                                            },
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.ticket_image != null
                                        ? _c(
                                            "small",
                                            { staticClass: "mx-auto" },
                                            [
                                              _vm._v(
                                                "Pulse en la imagen para ampliar"
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          ),
                          _vm._v(" "),
                          !_vm.numbered_ticket
                            ? _c("div", [
                                _vm.anticipado == 0
                                  ? _c("div", [
                                      _vm._m(5),
                                      _vm._v(" "),
                                      _vm.select_type_ticket != 0
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "form-group text-center mt-8",
                                            },
                                            [
                                              (_vm.select_type_ticket == 0 &&
                                                _vm.length_array_tickets > 1) ||
                                              _vm.max_num_tickets == 0
                                                ? _c(
                                                    "button",
                                                    {
                                                      staticClass:
                                                        "btn btn-blue cursor-no-pointer",
                                                      attrs: {
                                                        type: "button",
                                                        "data-toggle": "modal",
                                                        disabled: "",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                                Entradas agotadas\n                                            "
                                                      ),
                                                    ]
                                                  )
                                                : _c(
                                                    "button",
                                                    {
                                                      staticClass:
                                                        "btn btn-blue",
                                                      attrs: {
                                                        type: "button",
                                                        "data-toggle": "modal",
                                                        "data-target":
                                                          "#buyModal",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                                " +
                                                          _vm._s(
                                                            _vm.price_ticket
                                                          ) +
                                                          "€ - Comprar\n                                            "
                                                      ),
                                                    ]
                                                  ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ])
                                  : _c("div", [
                                      _c("div", { staticClass: "form-group" }, [
                                        _c(
                                          "label",
                                          {
                                            staticClass:
                                              "font-weight-bold color-darkblue",
                                          },
                                          [
                                            _vm._v(
                                              "Código de acceso antincipado"
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.code_check,
                                              expression: "code_check",
                                            },
                                          ],
                                          staticClass: "form-control",
                                          attrs: {
                                            id: "code_check",
                                            type: "text",
                                            name: "code_check",
                                          },
                                          domProps: { value: _vm.code_check },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing)
                                                return
                                              _vm.code_check =
                                                $event.target.value
                                            },
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "form-group text-center mt-8",
                                          },
                                          [
                                            _c(
                                              "button",
                                              {
                                                staticClass: "btn btn-blue",
                                                attrs: {
                                                  type: "button",
                                                  "data-toggle": "modal",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.checkCodeEarlyCheck()
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                                    Validar\n                                                "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ]),
                              ])
                            : _vm.array_numbered_tickets.length ||
                              _vm.selected_tickets.length
                            ? _c("div", [
                                !_vm.ticket_one
                                  ? _c(
                                      "select",
                                      {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.ticket_number_select,
                                            expression: "ticket_number_select",
                                          },
                                        ],
                                        staticClass: "form-control w-100",
                                        attrs: { name: "ticket_number_select" },
                                        on: {
                                          change: function ($event) {
                                            var $$selectedVal =
                                              Array.prototype.filter
                                                .call(
                                                  $event.target.options,
                                                  function (o) {
                                                    return o.selected
                                                  }
                                                )
                                                .map(function (o) {
                                                  var val =
                                                    "_value" in o
                                                      ? o._value
                                                      : o.value
                                                  return val
                                                })
                                            _vm.ticket_number_select = $event
                                              .target.multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "option",
                                          {
                                            attrs: { disabled: "" },
                                            domProps: { value: null },
                                          },
                                          [_vm._v("Seleccione el número")]
                                        ),
                                        _vm._v(" "),
                                        _vm._l(
                                          _vm.array_numbered_tickets,
                                          function (ticket, index) {
                                            return _c(
                                              "option",
                                              {
                                                key: index,
                                                domProps: { value: ticket },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                            " +
                                                    _vm._s(ticket) +
                                                    "\n                                        "
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                      ],
                                      2
                                    )
                                  : _c(
                                      "select",
                                      {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.ticket_number_select,
                                            expression: "ticket_number_select",
                                          },
                                        ],
                                        staticClass: "form-control w-100",
                                        attrs: {
                                          name: "ticket_number_select",
                                          disabled:
                                            _vm.selected_tickets.length > 0,
                                        },
                                        on: {
                                          change: function ($event) {
                                            var $$selectedVal =
                                              Array.prototype.filter
                                                .call(
                                                  $event.target.options,
                                                  function (o) {
                                                    return o.selected
                                                  }
                                                )
                                                .map(function (o) {
                                                  var val =
                                                    "_value" in o
                                                      ? o._value
                                                      : o.value
                                                  return val
                                                })
                                            _vm.ticket_number_select = $event
                                              .target.multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "option",
                                          {
                                            attrs: { disabled: "" },
                                            domProps: { value: null },
                                          },
                                          [_vm._v("Seleccione el número")]
                                        ),
                                        _vm._v(" "),
                                        _vm._l(
                                          _vm.array_numbered_tickets,
                                          function (ticket, index) {
                                            return _c(
                                              "option",
                                              {
                                                key: index,
                                                domProps: { value: ticket },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                            " +
                                                    _vm._s(ticket) +
                                                    "\n                                        "
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                      ],
                                      2
                                    ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "selected-tickets mt-3" },
                                  _vm._l(
                                    _vm.selected_tickets,
                                    function (selected_ticket, index) {
                                      return _c(
                                        "div",
                                        {
                                          key: index,
                                          staticClass:
                                            "cursor-pointer badge bg-primary text-white d-flexalign-items-center",
                                          on: {
                                            click: function ($event) {
                                              return _vm.removeSelectedTicket(
                                                selected_ticket
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                            Asiento: " +
                                              _vm._s(selected_ticket) +
                                              "\n                                            "
                                          ),
                                          _c("img", {
                                            staticClass: "ml-2",
                                            attrs: {
                                              src: "/svg/accion-eliminar-hover.svg",
                                              height: "20px",
                                            },
                                          }),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                                _vm._v(" "),
                                _vm.selected_tickets.length
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex justify-content-center mt-3",
                                      },
                                      [
                                        _c(
                                          "button",
                                          {
                                            staticClass: "btn btn-blue",
                                            attrs: {
                                              type: "button",
                                              "data-toggle": "modal",
                                              "data-target": "#buyModal",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.setCustomData()
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                            " +
                                                _vm._s(
                                                  _vm.ticket_price.toFixed(2)
                                                ) +
                                                "€ - Comprar\n                                        "
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ])
                            : _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex justify-content-center mt-3",
                                },
                                [
                                  (_vm.select_type_ticket == 0 &&
                                    _vm.length_array_tickets > 1) ||
                                  _vm.max_num_tickets == 0
                                    ? _c(
                                        "button",
                                        {
                                          staticClass:
                                            "btn btn-blue cursor-no-pointer",
                                          attrs: {
                                            type: "button",
                                            "data-toggle": "modal",
                                            disabled: "",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                        Entradas agotadas\n                                    "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              ),
                        ]
                      ),
                    ]),
                  ]),
                ]
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              ref: "buyModal",
              staticClass: "modal fade",
              staticStyle: { "z-index": "99999" },
              attrs: {
                id: "buyModal",
                tabindex: "-1",
                role: "dialog",
                "aria-labelledby": "buyModalTitle",
                "aria-hidden": "true",
              },
            },
            [
              _c(
                "div",
                {
                  class: !_vm.is_login
                    ? "modal-dialog modal-dialog-scrollable modal-dialog-centered modal-lg"
                    : "modal-dialog modal-dialog-scrollable modal-dialog-centered",
                  attrs: { role: "document" },
                },
                [
                  _c(
                    "form",
                    {
                      attrs: {
                        id: "form_buy",
                        action: _vm.redsys_url,
                        method: "POST",
                      },
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.redsys_ds_signature_version,
                            expression: "redsys_ds_signature_version",
                          },
                        ],
                        attrs: {
                          id: "Ds_SignatureVersion",
                          type: "hidden",
                          name: "Ds_SignatureVersion",
                        },
                        domProps: { value: _vm.redsys_ds_signature_version },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.redsys_ds_signature_version =
                              $event.target.value
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("input", {
                        attrs: {
                          id: "Ds_MerchantParameters",
                          type: "hidden",
                          name: "Ds_MerchantParameters",
                          value: "",
                        },
                      }),
                      _vm._v(" "),
                      _c("input", {
                        attrs: {
                          id: "Ds_Signature",
                          type: "hidden",
                          name: "Ds_Signature",
                          value: "",
                        },
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "modal-content" }, [
                        _vm._m(6),
                        _vm._v(" "),
                        _c("div", { staticClass: "modal-body" }, [
                          _c(
                            "div",
                            {
                              key: _vm.event.id,
                              staticClass: "d-flex flex-wrap",
                            },
                            [
                              !_vm.is_login
                                ? _c(
                                    "div",
                                    { staticClass: "col-12 col-md-6" },
                                    [
                                      _vm.error
                                        ? _c(
                                            "div",
                                            {
                                              staticClass: "alert alert-danger",
                                              attrs: { role: "alert" },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                        " +
                                                  _vm._s(_vm.error) +
                                                  "\n                                    "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm._m(7),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "tab-content",
                                          attrs: { id: "myTabContent" },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "tab-pane fade show active",
                                              attrs: {
                                                id: "tab_register_user",
                                                role: "tabpanel",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "form-group" },
                                                [
                                                  _vm._m(8),
                                                  _vm._v(" "),
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value: _vm.user.name,
                                                        expression: "user.name",
                                                      },
                                                    ],
                                                    staticClass: "form-control",
                                                    attrs: {
                                                      id: "name",
                                                      name: "name",
                                                      type: "text",
                                                      required: "",
                                                      autofocus: "",
                                                      autocomplete: "off",
                                                      placeholder: "Nombre",
                                                    },
                                                    domProps: {
                                                      value: _vm.user.name,
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        )
                                                          return
                                                        _vm.$set(
                                                          _vm.user,
                                                          "name",
                                                          $event.target.value
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "form-group" },
                                                [
                                                  _vm._m(9),
                                                  _vm._v(" "),
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          _vm.user.surnames,
                                                        expression:
                                                          "user.surnames",
                                                      },
                                                    ],
                                                    staticClass: "form-control",
                                                    attrs: {
                                                      id: "surnames",
                                                      name: "surnames",
                                                      type: "text",
                                                      required: "",
                                                      autofocus: "",
                                                      autocomplete: "off",
                                                      placeholder: "Apellidos",
                                                    },
                                                    domProps: {
                                                      value: _vm.user.surnames,
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        )
                                                          return
                                                        _vm.$set(
                                                          _vm.user,
                                                          "surnames",
                                                          $event.target.value
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "form-group" },
                                                [
                                                  _vm._m(10),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    [
                                                      _c("form-date", {
                                                        attrs: {
                                                          id: "birthday",
                                                          name: "birthday",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.user.birthday,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.user,
                                                              "birthday",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "user.birthday",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "fv-plugins-message-container",
                                                    },
                                                    [
                                                      _vm.date_message !== "" &&
                                                      !_vm.user_is_over_16_years
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "fv-help-block",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.date_message
                                                                )
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "form-group" },
                                                [
                                                  _vm._m(11),
                                                  _vm._v(" "),
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value: _vm.user.email,
                                                        expression:
                                                          "user.email",
                                                      },
                                                    ],
                                                    staticClass: "form-control",
                                                    attrs: {
                                                      id: "email_register",
                                                      name: "email",
                                                      type: "email",
                                                      required: "",
                                                      autofocus: "",
                                                      autocomplete: "off",
                                                      placeholder: "Email",
                                                    },
                                                    domProps: {
                                                      value: _vm.user.email,
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        )
                                                          return
                                                        _vm.$set(
                                                          _vm.user,
                                                          "email",
                                                          $event.target.value
                                                        )
                                                      },
                                                    },
                                                  }),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "fv-plugins-message-container",
                                                    },
                                                    [
                                                      _vm.user.email !== "" &&
                                                      !_vm.user_email_is_valid
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "fv-help-block",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "La dirección introducida no es válida"
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      _vm.user.email !== "" &&
                                                      _vm.user_email_is_valid &&
                                                      _vm.user_email_exists
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "fv-help-block",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "La dirección introducida ya existe"
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "form-group" },
                                                [
                                                  _vm._m(12),
                                                  _vm._v(" "),
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          _vm.user
                                                            .email_confirmation,
                                                        expression:
                                                          "user.email_confirmation",
                                                      },
                                                    ],
                                                    staticClass: "form-control",
                                                    attrs: {
                                                      id: "email_register",
                                                      name: "email_confirmation",
                                                      type: "email",
                                                      required: "",
                                                      autofocus: "",
                                                      autocomplete: "off",
                                                      placeholder:
                                                        "Confirmación de email",
                                                    },
                                                    domProps: {
                                                      value:
                                                        _vm.user
                                                          .email_confirmation,
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        )
                                                          return
                                                        _vm.$set(
                                                          _vm.user,
                                                          "email_confirmation",
                                                          $event.target.value
                                                        )
                                                      },
                                                    },
                                                  }),
                                                  _vm._v(" "),
                                                  _vm.user
                                                    .email_confirmation !==
                                                    "" &&
                                                  !_vm.user_email_confirmation_is_valid
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "fv-plugins-message-container",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "fv-help-block",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Las direcciones de correo no coinciden"
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "form-group" },
                                                [
                                                  _vm._m(13),
                                                  _vm._v(" "),
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value: _vm.user.phone,
                                                        expression:
                                                          "user.phone",
                                                      },
                                                    ],
                                                    staticClass: "form-control",
                                                    attrs: {
                                                      id: "phone",
                                                      name: "phone",
                                                      type: "tel",
                                                      maxlength: "20",
                                                      required: "",
                                                      autofocus: "",
                                                      autocomplete: "off",
                                                      placeholder: "Teléfono",
                                                    },
                                                    domProps: {
                                                      value: _vm.user.phone,
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        )
                                                          return
                                                        _vm.$set(
                                                          _vm.user,
                                                          "phone",
                                                          $event.target.value
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "form-group" },
                                                [
                                                  _vm._m(14),
                                                  _vm._v(" "),
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value: _vm.user.zipcode,
                                                        expression:
                                                          "user.zipcode",
                                                      },
                                                    ],
                                                    staticClass: "form-control",
                                                    attrs: {
                                                      id: "zipcode",
                                                      name: "zipcode",
                                                      type: "text",
                                                      maxlength: "5",
                                                      required: "",
                                                      autofocus: "",
                                                      autocomplete: "off",
                                                      placeholder:
                                                        "Código postal",
                                                    },
                                                    domProps: {
                                                      value: _vm.user.zipcode,
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        )
                                                          return
                                                        _vm.$set(
                                                          _vm.user,
                                                          "zipcode",
                                                          $event.target.value
                                                        )
                                                      },
                                                    },
                                                  }),
                                                  _vm._v(" "),
                                                  !_vm.user_zipcode_is_valid
                                                    ? _c("div", {
                                                        staticClass:
                                                          "fv-plugins-message-container",
                                                      })
                                                    : _vm._e(),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "tab-pane fade",
                                              attrs: {
                                                id: "tab_login_user",
                                                role: "tabpanel",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "form-group" },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "font-weight-bolder color-darkblue",
                                                    },
                                                    [_vm._v("Email:")]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value: _vm.email,
                                                        expression: "email",
                                                      },
                                                    ],
                                                    staticClass: "form-control",
                                                    attrs: {
                                                      type: "text",
                                                      name: "email",
                                                      required: "",
                                                      autofocus: "",
                                                      autocomplete: "off",
                                                      placeholder:
                                                        "Escribe tu email",
                                                    },
                                                    domProps: {
                                                      value: _vm.email,
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        )
                                                          return
                                                        _vm.email =
                                                          $event.target.value
                                                      },
                                                    },
                                                  }),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "form-group" },
                                                [
                                                  _vm._m(15),
                                                  _vm._v(" "),
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value: _vm.password,
                                                        expression: "password",
                                                      },
                                                    ],
                                                    staticClass: "form-control",
                                                    attrs: {
                                                      type: "password",
                                                      name: "password",
                                                      required: "",
                                                      autocomplete: "off",
                                                      placeholder:
                                                        "Escribe tu contraseña",
                                                    },
                                                    domProps: {
                                                      value: _vm.password,
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        )
                                                          return
                                                        _vm.password =
                                                          $event.target.value
                                                      },
                                                    },
                                                  }),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-wrap",
                                                },
                                                [
                                                  _c(
                                                    "button",
                                                    {
                                                      staticClass:
                                                        "btn btn-green w-100 mb-3",
                                                      attrs: { type: "submit" },
                                                      on: {
                                                        click: _vm.handleSubmit,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                                    Iniciar sesión\n                                                "
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "button",
                                                    {
                                                      staticClass:
                                                        "facebook-btn w-100 btn d-flex align-items-center justify-content-center p-3",
                                                      attrs: { type: "button" },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.authProvider()
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("img", {
                                                        staticClass: "mr-2",
                                                        attrs: {
                                                          src: "/media/misc/facebook.png",
                                                          alt: "NFT My Ticket - Facebook",
                                                          width: "25px",
                                                        },
                                                      }),
                                                      _vm._v(" "),
                                                      _c("span", [
                                                        _vm._v(
                                                          "Iniciar sesión con Facebook"
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  class: !_vm.is_login
                                    ? "col-12 col-md-6"
                                    : "col-12",
                                },
                                [
                                  _vm._m(16),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "form-group" }, [
                                    _c("h6", [
                                      _vm._v(
                                        "\n                                            Ticket individual:\n                                            " +
                                          _vm._s(_vm.price_ticket_individual) +
                                          "€\n                                        "
                                      ),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "form-group" }, [
                                    _c("h6", [
                                      _vm._v(
                                        "Nº de tickets: " +
                                          _vm._s(_vm.num_tickets) +
                                          " ticket(s)"
                                      ),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "form-group" }, [
                                    _c("h6", [
                                      _vm._v(
                                        "\n                                            Gastos de gestión:\n                                            " +
                                          _vm._s(
                                            parseFloat(
                                              _vm.commission_ticket
                                            ).toFixed(2)
                                          ) +
                                          "€\n                                        "
                                      ),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "form-group" }, [
                                    _c("h6", [
                                      _vm._v(
                                        "\n                                            Total:\n                                            " +
                                          _vm._s(
                                            parseFloat(
                                              _vm.price_commission
                                            ).toFixed(2)
                                          ) +
                                          "€\n                                        "
                                      ),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _vm.pay_selected == 3
                                    ? _c("div", { staticClass: "form-group" }, [
                                        _c("h6", [
                                          _vm._v(
                                            "Cantidad USDT: " +
                                              _vm._s(_vm.price_usdt) +
                                              " USDT"
                                          ),
                                        ]),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.total_gifts > 0
                                    ? _c(
                                        "div",
                                        { staticClass: "form-group mt-5" },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass:
                                                "font-weight-bold color-darkblue",
                                            },
                                            [_vm._v("Usar saldo regalo:")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "input-group mb-2" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "input-group-prepend",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "input-group-text",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                                    " +
                                                          _vm._s(
                                                            _vm.total_gifts.toFixed(
                                                              2
                                                            )
                                                          ) +
                                                          "€\n                                                "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.saldo_regalo_a_usar,
                                                    expression:
                                                      "saldo_regalo_a_usar",
                                                  },
                                                ],
                                                staticClass: "form-control",
                                                attrs: {
                                                  type: "text",
                                                  id: "saldo_regalo_a_usar",
                                                  placeholder:
                                                    "Saldo de regalo a usar",
                                                },
                                                domProps: {
                                                  value:
                                                    _vm.saldo_regalo_a_usar,
                                                },
                                                on: {
                                                  keypress: function ($event) {
                                                    return _vm.comprobarSaldo(
                                                      $event
                                                    )
                                                  },
                                                  input: function ($event) {
                                                    if ($event.target.composing)
                                                      return
                                                    _vm.saldo_regalo_a_usar =
                                                      $event.target.value
                                                  },
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "small",
                                                {
                                                  staticClass: "w-100 mt-2",
                                                  staticStyle: {
                                                    color: "gray",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "(Los gastos de gestión no se descontarán del total del\n                                                pedido)"
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.price_commission != 0
                                    ? _c("div", [
                                        _vm._m(17),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "form-group" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "selected border-rounded-green d-flex p-2 cursor-pointer select-method-pay-card",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.selectedItemPayEvents(
                                                      "card",
                                                      $event
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("img", {
                                                  staticClass: "mx-2 pe-none",
                                                  attrs: {
                                                    src:
                                                      _vm.publicPath +
                                                      "/svg/tarjeta.svg",
                                                    width: "20px",
                                                    height: "auto",
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "font-weight-bold pe-none",
                                                  },
                                                  [_vm._v("Tarjeta de crédito")]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "form-group" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "border-rounded-green d-flex p-2 cursor-pointer select-method-pay-bono_cultural",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.selectedItemPayEvents(
                                                      "bono_cultural",
                                                      $event
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("img", {
                                                  staticClass: "mx-2 pe-none",
                                                  attrs: {
                                                    src:
                                                      _vm.publicPath +
                                                      "/svg/bono_cultural.svg",
                                                    width: "20px",
                                                    height: "auto",
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "font-weight-bold pe-none",
                                                  },
                                                  [_vm._v("Bono Cultural")]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "form-group" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "border-rounded-green d-flex p-2 cursor-pointer",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.selectedItemPayEvents(
                                                      "coinbase",
                                                      $event
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("img", {
                                                  staticClass: "mx-2 pe-none",
                                                  attrs: {
                                                    src:
                                                      _vm.publicPath +
                                                      "/svg/coinbase.svg",
                                                    width: "20px",
                                                    height: "auto",
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "font-weight-bold pe-none",
                                                  },
                                                  [_vm._v("Coinbase Commerce")]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "form-group" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "border-rounded-green d-flex p-2 cursor-pointer",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.selectedItemPayEvents(
                                                      "metamask",
                                                      $event
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("img", {
                                                  staticClass: "mx-2 pe-none",
                                                  attrs: {
                                                    src:
                                                      _vm.publicPath +
                                                      "/svg/metamask.svg",
                                                    width: "20px",
                                                    height: "auto",
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "font-weight-bold pe-none",
                                                  },
                                                  [_vm._v("Metamask")]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "form-check" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.terms_and_conditions,
                                          expression: "terms_and_conditions",
                                        },
                                      ],
                                      staticClass: "form-check-input",
                                      attrs: {
                                        type: "checkbox",
                                        id: "terms-and-conditions",
                                      },
                                      domProps: {
                                        checked: Array.isArray(
                                          _vm.terms_and_conditions
                                        )
                                          ? _vm._i(
                                              _vm.terms_and_conditions,
                                              null
                                            ) > -1
                                          : _vm.terms_and_conditions,
                                      },
                                      on: {
                                        change: function ($event) {
                                          var $$a = _vm.terms_and_conditions,
                                            $$el = $event.target,
                                            $$c = $$el.checked ? true : false
                                          if (Array.isArray($$a)) {
                                            var $$v = null,
                                              $$i = _vm._i($$a, $$v)
                                            if ($$el.checked) {
                                              $$i < 0 &&
                                                (_vm.terms_and_conditions =
                                                  $$a.concat([$$v]))
                                            } else {
                                              $$i > -1 &&
                                                (_vm.terms_and_conditions = $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1)))
                                            }
                                          } else {
                                            _vm.terms_and_conditions = $$c
                                          }
                                        },
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "label",
                                      {
                                        staticClass: "form-check-label",
                                        attrs: { for: "terms-and-conditions" },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                            Acepto los\n                                            "
                                        ),
                                        _c(
                                          "router-link",
                                          {
                                            staticStyle: {
                                              "font-weight": "bold",
                                            },
                                            attrs: {
                                              to: {
                                                path: "/terms-and-conditions",
                                              },
                                            },
                                            nativeOn: {
                                              click: function ($event) {
                                                return _vm.goToTermsAndConditions()
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                                términos y condiciones\n                                            "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "modal-footer justify-content-between",
                          },
                          [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-cancel btn-cancel-buy",
                                attrs: {
                                  type: "button",
                                  id: "cancel_modal_buy",
                                  "data-dismiss": "modal",
                                },
                              },
                              [
                                _vm._v(
                                  "\n                                Cancelar\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            !_vm.loading
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-blue",
                                    attrs: {
                                      type: "button",
                                      disabled: !_vm.form_is_valid,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.payEvent()
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(
                                          parseFloat(
                                            _vm.price_commission
                                          ).toFixed(2)
                                        ) +
                                        "€ - Comprar\n                            "
                                    ),
                                  ]
                                )
                              : _c(
                                  "button",
                                  {
                                    staticClass:
                                      "btn btn-blue d-flex align-items-center",
                                    attrs: { type: "button" },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                Cargando\n                                "
                                    ),
                                    _c("div", {
                                      staticClass: "loading-transaction",
                                    }),
                                  ]
                                ),
                          ]
                        ),
                      ]),
                    ]
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _vm.ticket_image != null
            ? _c("ZoomImage", {
                ref: "zoomImage",
                attrs: {
                  src: "/files/images/seatings/" + _vm.ticket_image,
                  alt: "Imagen Ticket",
                },
              })
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("span", { staticClass: "color-white font-weight-bolder" }, [
        _vm._v("Entradas"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "mb-3" }, [
      _c("span", { staticClass: "color-white mb-3" }, [
        _vm._v("Tipos de entradas:"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "mt-10" }, [
      _c("span", { staticClass: "color-white font-weight-bolder" }, [
        _vm._v("Cómo llegar"),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "my-2" }, [_c("div", { attrs: { id: "map" } })]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "card-header bg-color-white border-0 px-4 px-sm-4 px-md-8",
      },
      [
        _c("div", { staticClass: "card-title" }, [
          _c(
            "h5",
            { staticClass: "card-label font-weight-bolder color-darkblue" },
            [
              _vm._v(
                "\n                                        Realiza tu compra\n                                    "
              ),
            ]
          ),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", { attrs: { scope: "col", colspan: "2" } }, [_vm._v("Tipos")]),
        _vm._v(" "),
        _c(
          "th",
          { staticStyle: { "text-align": "right" }, attrs: { scope: "col" } },
          [_vm._v("Precio")]
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "form-group touchspin-custom" }, [
      _c("input", {
        staticClass: "form-control text-align-end",
        attrs: {
          id: "touchspin_num_tickets",
          type: "text",
          value: "1",
          name: "num_tickets",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-header" }, [
      _c("h5", { staticClass: "modal-title" }, [_vm._v("Comprar entradas")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ul", { staticClass: "nav nav-tabs nav-line-tabs mb-5 fs-6" }, [
      _c("li", { staticClass: "nav-item" }, [
        _c(
          "a",
          {
            staticClass: "nav-link active",
            attrs: { "data-bs-toggle": "tab", href: "#tab_register_user" },
          },
          [_vm._v("Invitado")]
        ),
      ]),
      _vm._v(" "),
      _c("li", { staticClass: "nav-item" }, [
        _c(
          "a",
          {
            staticClass: "nav-link",
            attrs: { "data-bs-toggle": "tab", href: "#tab_login_user" },
          },
          [_vm._v("Ya tengo cuenta")]
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "font-weight-bold color-darkblue" }, [
      _vm._v("Nombre"),
      _c("sup", [_vm._v("*")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "font-weight-bold color-darkblue" }, [
      _vm._v("Apellidos"),
      _c("sup", [_vm._v("*")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "font-weight-bold color-darkblue" }, [
      _vm._v("Fecha de nacimiento"),
      _c("sup", [_vm._v("*")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "font-weight-bold color-darkblue" }, [
      _vm._v("Email"),
      _c("sup", [_vm._v("*")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "font-weight-bold color-darkblue" }, [
      _vm._v("Confirmación de email"),
      _c("sup", [_vm._v("*")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "font-weight-bold color-darkblue" }, [
      _vm._v("Teléfono"),
      _c("sup", [_vm._v("*")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "font-weight-bold color-darkblue" }, [
      _vm._v("Código postal"),
      _c("sup", [_vm._v("*")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "d-flex justify-content-between align-items-center text-center",
      },
      [
        _c("label", { staticClass: "font-weight-bolder color-darkblue" }, [
          _vm._v("Contraseña:"),
        ]),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "color-darkgreen",
            attrs: {
              href: "javascript:;",
              role: "button",
              "data-toggle": "modal",
              "data-target": "#forgetpasswordModal",
            },
          },
          [_vm._v("¿Has olvidado tu contraseña?")]
        ),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "mb-4" }, [
      _c("h5", { staticClass: "font-weight-bolder color-darkblue" }, [
        _vm._v(
          "\n                                            Resumen de pago\n                                        "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "mb-4 mt-5" }, [
      _c("h5", { staticClass: "font-weight-bolder color-darkblue" }, [
        _vm._v(
          "\n                                                Selecciona un método de pago\n                                            "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }