var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "row" },
      [
        _c("div", { staticClass: "col-12 mb-2" }, [
          _c(
            "button",
            {
              staticClass: "btn nueva-consulta",
              on: { click: _vm.nuevaConsulta },
            },
            [_vm._v("\n                Nueva consulta\n            ")]
          ),
        ]),
        _vm._v(" "),
        _vm._l(_vm.queries, function (query, index) {
          return _c(
            "div",
            { key: index, staticClass: "col-12 col-md-6 mb-5" },
            [
              _c("div", { staticClass: "card" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "card-header p-3 d-flex align-items-center justify-content-between",
                    attrs: { id: "heading" + index },
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "badge",
                        class: [
                          !query.status ? "badge-warning" : "badge-primary",
                        ],
                      },
                      [
                        _vm._v(
                          _vm._s(!query.status ? "Pendiente" : "Finalizado")
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("span", { staticClass: "text-dark" }, [
                      _vm._v(_vm._s(query.format_date)),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "card-body p-3" }, [
                  _c("p", { staticClass: "text-dark" }, [
                    _c("b", [_vm._v(_vm._s(query.subject))]),
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "text-dark" }, [
                    _vm._v(_vm._s(query.answer)),
                  ]),
                ]),
              ]),
            ]
          )
        }),
        _vm._v(" "),
        _vm.queries.length > 0
          ? _c(
              "div",
              { staticClass: "col-12 d-flex justify-content-center mb-2" },
              [
                _c(
                  "button",
                  { staticClass: "btn ver-mas", on: { click: _vm.verMas } },
                  [_vm._v("\n                Ver más\n            ")]
                ),
              ]
            )
          : _vm._e(),
      ],
      2
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "modal fade",
        attrs: {
          id: "newSubjectModal",
          tabindex: "-1",
          role: "dialog",
          "aria-labelledby": "newSubjectModalLabel",
          "aria-hidden": "true",
        },
      },
      [
        _c(
          "div",
          { staticClass: "modal-dialog", attrs: { role: "document" } },
          [
            _c("div", { staticClass: "modal-content" }, [
              _vm._m(0),
              _vm._v(" "),
              _c("div", { staticClass: "modal-body" }, [
                _c("div", { staticClass: "form-group" }, [
                  _c("label", { attrs: { for: "asunto" } }, [_vm._v("Asunto")]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.asunto,
                        expression: "asunto",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "text",
                      id: "asunto",
                      placeholder: "Escribe un asunto",
                    },
                    domProps: { value: _vm.asunto },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.asunto = $event.target.value
                      },
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _c("label", { attrs: { for: "mensaje" } }, [
                    _vm._v("Mensaje"),
                  ]),
                  _vm._v(" "),
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.mensaje,
                        expression: "mensaje",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      rows: "5",
                      id: "mensaje",
                      placeholder: "Escribe un mensaje",
                    },
                    domProps: { value: _vm.mensaje },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.mensaje = $event.target.value
                      },
                    },
                  }),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "modal-footer d-flex align-items-center justify-content-between",
                },
                [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-secondary btn-cancelar",
                      attrs: { type: "button", "data-dismiss": "modal" },
                      on: { click: _vm.closeModal },
                    },
                    [
                      _vm._v(
                        "\n                        Cancelar\n                    "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary btn-enviar",
                      attrs: { type: "button" },
                      on: { click: _vm.sendConsulta },
                    },
                    [
                      _vm._v(
                        "\n                        Enviar\n                    "
                      ),
                    ]
                  ),
                ]
              ),
            ]),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-header" }, [
      _c(
        "h5",
        { staticClass: "modal-title", attrs: { id: "newSubjectModalLabel" } },
        [
          _vm._v(
            "\n                        Nueva consulta\n                    "
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            type: "button",
            "data-dismiss": "modal",
            "aria-label": "Close",
          },
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }