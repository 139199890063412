<template>
	<div>
		<div class="row mb-4">
			<div class="col-md-12 col-lg-3">
				<div class="input-group search-input">
					<div class="input-group-prepend">
						<span
							class="
								input-group-text
								bg-white
								border-radius-left border-0
								h-100
							"
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="16"
								height="16"
								fill="#ffffff"
								class="bi bi-search"
								viewBox="0 0 16 16"
							>
								<path
									d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"
								/>
							</svg>
						</span>
					</div>
					<input
						v-model="search_tickets"
						id="search_tickets"
						type="text"
						class="form-control bg-white color-green border-0 h-100"
						placeholder="Buscar..."
						aria-describedby="search-addon"
					/>
				</div>
			</div>
            <div class="col-md-12 col-lg-3">
                <div class="d-flex align-items-center m-4 m-md-0" style="margin-bottom: 10px">
                    <button class="btn btn-primary btn-block" :disabled="renamingTickets" @click="openRenameTicketDialog()">
                        <span>Cambio de nombre <span v-if="selectedTickets.length">{{ selectedTickets.length }}</span></span>
                    </button>
                </div>
            </div>
		</div>
        <TicketRenamingDialog @onTransaccionSuccess="(transaction) => setTransaction(transaction)"
                              @onFinishRename="(success) => closeRenameTicketDialog(success)"
                              v-if="showRenameDialog" :sale_tickets="Object.values(selectedTickets)"/>
        <div
			class="datatable datatable-bordered datatable-head-custom"
			id="list_tickets"
			style="width: 100%"
		></div>
		<div class="row justify-content-center">
			<button v-if="!is_hidden" @click="verMas" class="btn ver-mas">
				Ver más
			</button>
		</div>

		<div
			class="modal fade"
			id="qrCodeModal"
			tabindex="-1"
			role="dialog"
			aria-labelledby="qrCodeModalLabel"
			aria-hidden="true"
			style="z-index: 9999999999999999999999"
		>
			<div class="modal-dialog" role="document">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title" id="qrCodeModalLabel">Código QR</h5>
						<button
							type="button"
							class="close"
							data-dismiss="modal"
							aria-label="Close"
						>
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div
						class="modal-body d-flex align-items-center justify-content-center"
					>
						<QrcodeVue :value="qrValue" :size="size" level="H" />
					</div>
					<div class="d-flex justify-content-center" style="color: #afb1bb">
						Este código QR dejará de ser válido en {{qrTimerCount}} segundos
					</div>
					<div
						class="
							modal-footer
							d-flex
							align-items-center
							justify-content-between
						"
					>
						<button
							@click="closeModal"
							type="button"
							class="btn btn-secondary btn-cancelar"
							data-dismiss="modal"
						>
							Cerrar
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import AdminEventTicketRenamingDialog from "./iten/AdminEventTicketRenamingDialog.vue";

var url_img = window.location.origin + "/svg";
var url_files = window.location.origin + "/files/files/";

import QrcodeVue from "qrcode.vue";
import TicketRenamingDialog from "./iten/TicketRenamingDialog.vue";

export default {
	name: "TableMyticketsComponent",
	components: {
        TicketRenamingDialog,
        AdminEventTicketRenamingDialog,
		QrcodeVue,
	},
	data() {
		return {
			publicPath: window.location.origin,
			array_helps: [],
			search: "",
			paginate: 4,
			search_tickets: "",
			basketAddSuccess: true,
			qrValue: "",
            qrRefreshInterval: 60,
            qrTimerCount: 60,
            qrInterval: null,
			size: 300,
			type_list: 1,
			is_hidden: false,
            selectedTickets: [],
            showRenameDialog: false,
            renamingTickets: false,
            id_transaction: 0,
		};
	},
	methods: {
        openRenameTicketDialog() {
            if(this.selectedTickets.length === 0){
                swal("", "Selecciona la/s entrada/s que quieres cambiar de nombre", "warning");
            } else {
                this.showRenameDialog = true
            }
        },
        setTransaction(transaction){
            this.transaction_id = transaction;
        },
        closeRenameTicketDialog(reload = false) {
            this.showRenameDialog = false
            if(reload){
                this.initComponent()
            }
        },
        onCheck(ids, checked) {
            const me = this;
            ids.forEach(function(id){
                if(checked){
                    me.selectedTickets.push(id);
                } else {
                    const index = me.selectedTickets.indexOf(id);
                    if(index>=0){
                        me.selectedTickets.splice(index, 1);
                    }
                }
            });
        },
		searchtickets() {
			// Get the input box
			let input = document.getElementById("search_tickets");

			// Init a timeout variable to be used below
			let timeout = null;
			let self = this;

			// Listen for keystroke events
			input.addEventListener("keyup", function (e) {
				clearTimeout(timeout);

				timeout = setTimeout(function () {
					self.listTickets(this.type_list);
				}, 500);
			});
		},
        initComponent() {
            this.searchtickets();
            this.listTickets(this.type_list);
            const me = this;

            $(document).on("click", ".qr-download", function () {
                me.verQr($(this).data("id"));
            });
            $("#list_tickets tbody .row-to-hide td:first-child label").hide();
            this.selectedTickets = []
        },
		listTickets(type) {
            const me = this;
			$("#list_tickets").KTDatatable("destroy");
			$("#list_tickets").KTDatatable("init");

			$("#list_tickets").KTDatatable({
				data: {
					type: "remote",
					source: {
						read: {
							url: this.publicPath + "/list_tickets",
							headers: {
								"X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
							},
							data: {
								pagination: {
									page: 1,
									pages: 18,
									perpage: this.paginate,
								},
								query: {
									search_tickets: $("#search_tickets").val(),
								},
								sort: {
									sort: "asc",
								},
								type_list: type,
							},
						},
					},
					pageSize: 10,
					serverPaging: !0,
					serverFiltering: !0,
					serverSorting: !0,
				},
				layout: {
					scroll: true,
					customScrollbar: true,
					scrollX: true,
					footer: !1,
					spinner: {
						color: "#FFF",
					},
				},
				sortable: 1,
				pagination: 0,
				translate: {
					records: {
						processing: "Cargando...",
						noRecords: "Sin resultados",
					},
					toolbar: {
						pagination: {
							items: {
								info: "Mostrando {{start}} - {{end}} de {{total}} resultados",
							},
						},
					},
				},
				rows: {
					autoHide: false,
                    afterTemplate: function(row, data, index) {
                        if(data.status !== 0 || !data.ticket_event.allow_rename){
                            row.addClass('row-to-hide')
                        }
                    }
				},
				columns: [
                {
                    field: 'id',
                    title: '',
                    sortable: false,
                    width: 20,
                    type: 'number',
                    textAlign: 'center',
                    selector: { class: ''}
                },
					{
						field: "ticket_event.event.name",
						title: "nombre festival",
						sortable: !1,
						textAlign: "center",
					},
                    {
                        field: "#",
                        title: "acciones",
                        sortable: !1,
                        textAlign: "center",
                        class: "acciones",
                        template: function (row, data, index) {
                            let acciones = "";
                            //acciones += `<div><a href="${url_files}${row.file}" download><img class="download-hover" src="${url_img}/accion-descargar.svg" height="30px" width="auto"></a></div>`;
                            //acciones += `<div><img class="cursor-pointer nft-hover" src="${url_img}/ver-nft.svg" height="30px" width="auto"></div>`;
                            if (row.status == 0) {
                                acciones += `<div><span class="cursor-pointer qr-download" data-id="${row.id}" style="color: #9aeba2 !important;">CANJEAR ENTRADA</span></div>`;
                            }

                            return acciones;
                        },
                    },
                    {
						field: "ticket_event.date_event.date_event",
						title: "fecha",
						sortable: !1,
						textAlign: "center",
						template: function (row, data, index) {
							var d = new Date(row.ticket_event.date_event.date_event),
								dformat = [
									d.getDate().toString().padStart(2, "0"),
									(d.getMonth() + 1).toString().padStart(2, "0"),
									d.getFullYear(),
								].join("/");

                            if(row.ticket_event.date_event.date_event_until){
                                var d2 = new Date(row.ticket_event.date_event.date_event_until),
                                    dformat2 = [
                                        d2.getDate().toString().padStart(2, "0"),
                                        (d2.getMonth() + 1).toString().padStart(2, "0"),
                                        d2.getFullYear(),
                                    ].join("/");

                                return (
                                    dformat + " - " +  dformat2 + " " + row.ticket_event.date_event.time_event_since
                                );
                            }
                            else{
                                return (
                                    dformat + " " + row.ticket_event.date_event.time_event_since
                                );
                            }
						},
					},
					{
						field: "ticket_event.name_ticket",
						title: "tipo de entrada",
						sortable: !1,
						textAlign: "center",
					},
					{
						field: "num_ticket",
						title: "número entrada/asiento",
						sortable: !1,
						textAlign: "center",
					},
					{
						field: "status_txt",
						title: "estado",
						sortable: !1,
						textAlign: "center",
					},
				],
			});
            $("#list_tickets").on("datatable-on-check", function (event, args){
                me.onCheck(args, true)
            });
            $("#list_tickets").on("datatable-on-uncheck", function (event, args){
                me.onCheck(args, false)
            });
            $("#list_tickets").on("datatable-on-layout-updated", function (event, args){
                $("#list_tickets thead th:first-child label").remove();
                $("#list_tickets tbody .row-to-hide td:first-child label").remove();
            });
            $("#list_tickets").on("click", ".nft-hover", function () {
				document.getElementById("tag_step3").click();
			});
        },
        startCountdown(onFinishTimer) {
            if(this.qrInterval){
                clearInterval(this.qrInterval);
            }
            this.qrInterval = setInterval(() => {
                if (this.qrTimerCount === 0) {
                    if(onFinishTimer){
                        onFinishTimer();
                    }
                    clearInterval(this.qrInterval)
                } else {
                    this.qrTimerCount--
                }
            }, 1000)
        },
		verMas() {
			this.paginate += 4;
			this.type_list = 2;
			this.is_hidden = true;
			this.listTickets(this.type_list);
		},
		verQr(id) {
			this.generateQr(id);
		},
		generateQr(id) {
			let me = this;
			let params = {
				id: id,
			};

			axios
				.post("/generate_token_url", params)
				.then((response) => {
					if (response.data.code == 1000) {
						me.qrValue = response.data.code_qr;
						me.qrTimerCount = response.data.refresh_interval;
						$("#qrCodeModal").modal("show");
                        $("#qrCodeModal").on('hidden.bs.modal', function () {
                            if(me.qrInterval){
                                clearInterval(me.qrInterval)
                            }
                        })
						me.startCountdown(() => me.generateQr(id));
					} else {
						swal(
							"",
							"Parece que ha habido un error, inténtalo de nuevo más tarde",
							"error"
						);
					}
				})
				.catch(function (error) {
					swal(
						"",
						"Parece que ha habido un error, inténtalo de nuevo más tarde",
						"error"
					);
				});
		},
		closeModal() {
			$("#qrCodeModal").modal("hide");
		},
        getValueQuery() {
            if (localStorage.getItem("show-transaction-success-alert") !== "false" && localStorage.getItem("show-transaction-success-alert") !== null) {
                if (this.$route.query.type !== undefined && this.$route.query.type === "success") {
                    if (localStorage.getItem('send-confirmation-email-on-finish') === 'true') {
                        localStorage.setItem('send-confirmation-email-on-finish', 'false');
                        // this.resetPassword();
                        swal(
                            "Cambio de nombre realizado correctamente",
                            "Hemos enviado un correo con la asignación de las entrada/s",
                            "success"
                        );
                        if(this.$route.query.transaction_id){
                            this.transaction_id = this.$route.query.transaction_id
                        }
                    } else {
                        swal(
                            "Cambio de nombre realizado correctamente",
                            "Hemos enviado un correo con la asignación de las entrada/s",
                            "success"
                        );
                        if(this.$route.query.transaction_id){
                            this.transaction_id = this.$route.query.transaction_id
                        }
                    }
                    //this.$router.push({ path: '/mycollection', query: null }).then()
                    this.$router.push({path: this.$route.path, query: {'': ''}}).then()
                }
                if (this.$route.query.type !== undefined && this.$route.query.type === "error") {
                    if (localStorage.getItem('send-confirmation-email-on-finish') === 'true') {
                        localStorage.setItem('send-confirmation-email-on-finish', 'false');
                        // this.resetPassword();
                        swal(
                            "Error en el proceso de compra",
                            "Se ha producido un error en el proceso de compra.",
                            "error"
                        );
                    } else {
                        swal(
                            "Error en el proceso de compra",
                            "Se ha producido un error en el proceso de compra.",
                            "error"
                        );
                    }
                    this.$router.push({path: this.$route.path, query: {'': ''}}).then()
                }
                localStorage.setItem("show-transaction-success-alert", "false");
            }
        },

    },
	mounted() {
		this.initComponent();
        this.getValueQuery();
	},
};
</script>

<style lang="scss">
$primary: #45c4b0;

.input-group.search-input {
	background-color: transparent;
	border: 1px solid white;
	border-radius: 5px;

	.input-group-text {
		background-color: transparent !important;
	}

	#search_tickets {
		background-color: transparent !important;

		&::placeholder {
			color: white;
		}
	}
}

.datatable-head-custom table {
	background-color: transparent !important;

	tr th {
		span {
			color: white !important;
		}
	}

	tr td {
		span {
			color: $primary !important;
		}
	}

	.acciones span {
		display: flex !important;
		align-items: center;
		justify-content: center;
		gap: 10px;
	}
}

.ver-mas {
	background-color: $primary;
	color: darken($primary, 30%);
	font-weight: bold;
	padding: 0.5rem 1rem;
}
</style>
