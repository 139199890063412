var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "ticketRenamingBuyModal",
      staticClass: "modal fade",
      staticStyle: { "z-index": "99999" },
      attrs: {
        id: "ticketRenamingBuyModal",
        tabindex: "-1",
        role: "dialog",
        "aria-labelledby": "ticketRenamingBuyModalTitle",
        "aria-hidden": "true",
      },
    },
    [
      _c(
        "div",
        {
          class: !_vm.is_login
            ? "modal-dialog modal-dialog-scrollable modal-dialog-centered modal-lg"
            : "modal-dialog modal-dialog-scrollable modal-dialog-centered",
          attrs: { role: "document" },
        },
        [
          _c(
            "form",
            {
              attrs: { id: "form_buy", action: _vm.redsys_url, method: "POST" },
            },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.redsys_ds_signature_version,
                    expression: "redsys_ds_signature_version",
                  },
                ],
                attrs: {
                  id: "Ds_SignatureVersion",
                  type: "hidden",
                  name: "Ds_SignatureVersion",
                },
                domProps: { value: _vm.redsys_ds_signature_version },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.redsys_ds_signature_version = $event.target.value
                  },
                },
              }),
              _vm._v(" "),
              _c("input", {
                attrs: {
                  id: "Ds_MerchantParameters",
                  type: "hidden",
                  name: "Ds_MerchantParameters",
                  value: "",
                },
              }),
              _vm._v(" "),
              _c("input", {
                attrs: {
                  id: "Ds_Signature",
                  type: "hidden",
                  name: "Ds_Signature",
                  value: "",
                },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "modal-content" }, [
                _vm._m(0),
                _vm._v(" "),
                _c("div", { staticClass: "modal-body" }, [
                  _c("div", { staticClass: "d-flex flex-wrap" }, [
                    !_vm.is_login
                      ? _c(
                          "div",
                          { staticClass: "col-12 col-md-6" },
                          [
                            _c("BuyUserRegistration", {
                              on: {
                                onUserLogin: (reload) => this.getInfoUser(),
                                onUserChanged: this.setUser,
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      { class: !_vm.is_login ? "col-12 col-md-6" : "col-12" },
                      [
                        Object.keys(_vm.sale_tickets).length > 0
                          ? _c("TicketRenamingCartSummary", {
                              attrs: {
                                sale_tickets: _vm.sale_tickets,
                                usdt_value: parseFloat(_vm.value_usdt),
                                user_credit: _vm.user_credit,
                              },
                              on: {
                                onCartChanged: (payload) =>
                                  (this.cart = payload),
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-check" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.terms_and_conditions,
                                expression: "terms_and_conditions",
                              },
                            ],
                            staticClass: "form-check-input",
                            attrs: {
                              type: "checkbox",
                              id: "terms-and-conditions",
                            },
                            domProps: {
                              checked: Array.isArray(_vm.terms_and_conditions)
                                ? _vm._i(_vm.terms_and_conditions, null) > -1
                                : _vm.terms_and_conditions,
                            },
                            on: {
                              change: function ($event) {
                                var $$a = _vm.terms_and_conditions,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      (_vm.terms_and_conditions = $$a.concat([
                                        $$v,
                                      ]))
                                  } else {
                                    $$i > -1 &&
                                      (_vm.terms_and_conditions = $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1)))
                                  }
                                } else {
                                  _vm.terms_and_conditions = $$c
                                }
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "label",
                            {
                              staticClass: "form-check-label",
                              attrs: { for: "terms-and-conditions" },
                            },
                            [
                              _vm._v(
                                "\n                                    Acepto los\n                                    "
                              ),
                              _c(
                                "router-link",
                                {
                                  staticStyle: { "font-weight": "bold" },
                                  attrs: {
                                    to: { path: "/terms-and-conditions" },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                                        términos y condiciones\n                                    "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ],
                      1
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "modal-footer justify-content-between" },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-cancel btn-cancel-buy",
                        attrs: {
                          type: "button",
                          id: "cancel_modal_buy",
                          "data-dismiss": "modal",
                        },
                        on: { click: _vm.closeCheckout },
                      },
                      [
                        _vm._v(
                          "\n                        Cancelar\n                    "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    !_vm.loading
                      ? _c(
                          "button",
                          {
                            staticClass: "btn btn-blue",
                            attrs: {
                              type: "button",
                              disabled: !_vm.is_valid_user,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.payRename()
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s(parseFloat(_vm.totalPrice).toFixed(2)) +
                                "€ - Comprar\n                    "
                            ),
                          ]
                        )
                      : _c(
                          "button",
                          {
                            staticClass:
                              "btn btn-blue d-flex align-items-center",
                            attrs: { type: "button" },
                          },
                          [
                            _vm._v(
                              "\n                        Cargando\n                        "
                            ),
                            _c("div", { staticClass: "loading-transaction" }),
                          ]
                        ),
                  ]
                ),
              ]),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-header" }, [
      _c("h5", { staticClass: "modal-title" }, [_vm._v("Reasignar entradas")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }