<template>
    <div class="container-fluid mt-4 mb-12">
        <div class="d-flex align-items-center mt-4">
            <router-link :to="{ path: '/collections' }">
                <span class="color-white h4" style="margin-right: 20px"
                    >Colecciones</span
                >
                <img src="svg/flecha-pequeña.svg" width="5px" height="auto" />
            </router-link>
        </div>

        <div
            class="evecans-carrousel content p-0"
            v-if="array_collections.length"
        >
            <div class="content-principal">
                <button
                    role="button"
                    id="arrow-left-recent"
                    class="arrow-left"
                    @click="carrouselLeft()"
                >
                    <img
                        src="svg/flecha-detras.svg"
                        width="15px"
                        height="auto"
                    />
                </button>
                <div class="content-carousel" id="content-carousel-nearby">
                    <div class="carousel">
                        <div
                            class="evecan custom col-8 col-md-4 col-lg-2 px-0"
                            v-for="colleciton in array_collections"
                            :key="colleciton.id"
                        >
                            <router-link
                                :to="{ path: '/collections/' + colleciton.id }"
                                class="hideintro h-100"
                            >
                                <div class="h-100">
                                    <img
                                        v-bind:src="
                                            '/files/images/music/' +
                                            colleciton.img_music
                                        "
                                        style="object-fit: cover"
                                    />
                                    <div
                                        class="description p-4 d-flex flex-wrap justify-content-between align-content-between"
                                    >
                                        <p
                                            class="w-100 event-name color-white font-weight-bold"
                                        >
                                            {{ colleciton.name_collection }}
                                        </p>
                                        <div class="w-100 text-align-end">
                                            <span class="color-green"
                                                >Ver colecciones</span
                                            >
                                        </div>
                                    </div>
                                </div>
                            </router-link>
                        </div>
                    </div>
                </div>
                <button
                    role="button"
                    id="arrow-right-recent"
                    class="arrow-right"
                    @click="carrouselRight()"
                >
                    <img
                        src="svg/flecha-delante.svg"
                        width="15px"
                        height="auto"
                    />
                </button>
            </div>
        </div>
        <div class="text-white h2 mt-3" v-else>
            No hay colecciones disponibles
        </div>
    </div>
</template>

<script>
export default {
    name: "SongsCollectionsGeneralComponent",
    data() {
        return {
            show_button_carrusel: true,
        };
    },
    props: ["array_collections"],
    methods: {
        carrouselRight() {
            const fila = document.querySelector("#content-carousel-nearby");
            fila.scrollLeft += fila.offsetWidth;
        },

        carrouselLeft() {
            const fila = document.querySelector("#content-carousel-nearby");
            fila.scrollLeft -= fila.offsetWidth;
        },
    },
    mounted() {
        //this.getCategories();
    },
};
</script>
