<template>
    <div
        class="modal fade"
        ref="buyModal"
        id="buyModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="buyModalTitle"
        aria-hidden="true"
        style="z-index: 99999"
    >
        <div
            :class="!is_login ? 'modal-dialog modal-dialog-scrollable modal-dialog-centered modal-lg' : 'modal-dialog modal-dialog-scrollable modal-dialog-centered'"
            role="document">
            <form
                :id="'form_buy'"
                :action="redsys_url"
                method="POST"
            >
                <input
                    id="Ds_SignatureVersion"
                    type="hidden"
                    name="Ds_SignatureVersion"
                    v-model="redsys_ds_signature_version"
                />
                <input
                    id="Ds_MerchantParameters"
                    type="hidden"
                    name="Ds_MerchantParameters"
                    value=""
                />
                <input id="Ds_Signature" type="hidden" name="Ds_Signature" value=""/>
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Comprar entradas</h5>
                    </div>


                    <div class="modal-body">

                        <div :key="event.id" class="d-flex flex-wrap">
                            <!-- Personal data -->
                            <div class="col-12 col-md-6" v-if="!is_login">
                                <BuyUserRegistration    :event="event"
                                                        @onUserLogin="(reload) => this.getInfoUser()"
                                                        @onUserChanged="this.setUser"/>
                            </div>
                            <!-- Payment summary -->
                            <div :class="!is_login ? 'col-12 col-md-6' : 'col-12'">
                                <BuyCartSummary v-if="Object.keys(ticket_cart).length>0"
                                                @onTicketCartChanged="(payload) => this.cart = payload"
                                                :ticket_cart="ticket_cart"
                                                :event="event"
                                                :usdt_value="parseFloat(value_usdt)"
                                                :user_credit="user_credit"/>

                                <div class="form-check">
                                    <input
                                        type="checkbox"
                                        class="form-check-input"
                                        id="terms-and-conditions"
                                        v-model="terms_and_conditions"
                                    />
                                    <label class="form-check-label" for="terms-and-conditions">
                                        Acepto los
                                        <router-link
                                            style="font-weight: bold;"
                                            :to="{ path: '/terms-and-conditions' }"
                                        >
                                            términos y condiciones
                                        </router-link>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="modal-footer justify-content-between">
                        <button
                            type="button"
                            id="cancel_modal_buy"
                            @click="closeCheckout"
                            class="btn btn-cancel btn-cancel-buy"
                            data-dismiss="modal"
                        >
                            Cancelar
                        </button>
                        <button
                            v-if="!loading"
                            type="button"
                            v-on:click="payEvent()"
                            class="btn btn-blue"
                            :disabled="!is_valid_user"
                        >
                            {{ parseFloat(totalPrice).toFixed(2) }}€ - Comprar
                        </button>
                        <button
                            v-else
                            type="button"
                            :disabled="true"
                            class="btn btn-blue d-flex align-items-center"
                        >
                            Cargando
                            <div class="loading-transaction"></div>
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>

</template>

<script>
import BuyUserRegistration from "./BuyUserRegistration";
import BuyCartSummary from "./BuyCartSummary";
import json_abi_polygon from "../../../../../public/abi_usdt_polygon.json";
export default {
    name: "BuyCheckout",
    components: {
      BuyUserRegistration, BuyCartSummary
    },
    props: {
        event: {
            type: Object,
            required: true
        },
        ticket_cart: {
            type: Object,
            required: true
        },
        value_usdt: {
            type: Number,
            required: true
        }
    },

    computed: {
        totalPrice() {
            return this.cart.total_pending
        }
    },
    data: function () {
        return {
            redsys_url: window.REDSYS_URL,
            redsys_ds_signature_version: window.REDSYS_DS_SIGNATUREVERSION ?? 'HMAC_SHA256_V1',
            is_login: window.Laravel.isLoggedin,
            is_valid_user: window.Laravel.isLoggedin,
            user_credit: 0,
            loading: false,
            account_wallet: "",
            abi: JSON.stringify(json_abi_polygon),
            web3: "",
            terms_and_conditions: false,
            cart: {...this.ticket_cart},
            user: {}
        }
    },
    methods: {
        setUser(valid, newUser){
            this.is_valid_user = valid;
            this.user = newUser
        },
        closeCheckout() {
            $("#buyModal").modal("hide");
        },
        getInfoUser() {
            let me = this;
            axios
                .get("/get_info_user")
                .then(function (response) {
                    if (response.data.code == 1000) {
                        me.user_credit = response.data.gift;
                        me.is_login = true;
                        me.is_valid_user = true;
                        me.Laravel = {
                            isLoggedin: true,
                            user: response.data.user
                        }
                    }
                })
                .catch(function (error) {
                    console.error(error);
                });
        },
        async payEvent() {
            let is_valid = true;

            if (this.cart.consumed_credit > this.user_credit) {
                swal("", "No puede usar más saldo del disponible", "warning");
                this.cart.consumed_credit = this.user_credit;
                is_valid = false;
            }

            if (this.cart.total_pending < this.cart.consumed_credit) {
                swal("", "No puede descontar más del total de los tickets", "warning");
                is_valid = false;
            }

            if (this.cart.consumed_credit < 0) {
                swal("", "El saldo regalo no es válido", "warning");
                is_valid = false;
            }

            if (!this.terms_and_conditions) {
                swal("", "Debe aceptar nuestros términos y condiciones", "warning");
                is_valid = false;
            }

            //TODO: Check if date is valid
            /*
            this.array_dates.forEach((date) => {
                if (date.id == this.select_date_event) {
                    if (new Date(date.date_event_end_sell) < new Date()) {
                        swal(
                            "",
                            "El plazo para comprar esta entrada ya terminó",
                            "warning"
                        );

                        is_valid = false;
                    }
                }
            });*/

            if (is_valid) {
                this.loading = true;
                //Register user if not loggedin
                try {
                    if (!this.is_login) {
                        console.log("user")
                        console.log(this.user)
                        let userRegistrationResponse = await axios.post('/preregister_user', this.user)
                        if (userRegistrationResponse && userRegistrationResponse.data.code === 1000) {
                            localStorage.setItem("send-confirmation-email-on-finish", "true");
                            this.is_login = true;
                            this.Laravel = {
                                isLoggedin: true,
                                user: userRegistrationResponse.data.user
                            }
                            this.reloadAfterLogin = true;
                        }
                    }
                    const me = this;
                    await axios.post("/check_tickets_selected", this.cart)
                    if (this.totalPrice === 0) {
                        this.payEventFree();
                    } else {
                        localStorage.setItem("show-transaction-success-alert", "true");
                        switch (me.cart.payment_method) {
                            case 1: //Card
                                me.payEventCard();
                                break;
                            case 2: //Bono Cultural
                                me.payEventBonoCultural();
                                break;
                            case 3: //Coinbase
                                me.payEventCoinbase();
                            case 4: //Metamask
                                me.createPayMetamask();
                                break;
                        }
                    }

                } catch (error) {
                    console.error(error);
                    if (error.response?.data?.message.length) {
                        swal("", error.response.data.message, "warning");
                    } else {
                        swal(
                            "",
                            "Parece que ha habido un error, intentalo de nuevo más tarde",
                            "warning"
                        );
                    }
                }
                this.loading = false;
            }
        },
        createPayMetamask() {
            this.loading = false;
            let me = this;
            // our decimal number
            if (typeof window.ethereum !== "undefined") {
                //Conectar MetaMask con la web y sacar su dirección
                if (window.ethereum.chainId == "0x89") {
                    me.getAccount();
                } else {
                    swal("", "Connect to Polygon network", "warning");
                }
            } else {
                swal("Install the MetaMask extension", "", "warning");
                swal({
                    title: "Install the MetaMask extension",
                    type: "warning",
                    text: 'Si tiene alguna duda con este proceso puede revisar nuestros documentos explicativos en <a href="http://www.externalwebsite.com" target="_blank">AYUDA</a>',
                    html: true,
                });
            }
        },
        payEventCard() {
            let me = this;
            const params = {...this.cart, id_event: this.event.id}
            axios
                .post("/pay_event_redsys", params)
                .then((response) => {
                    this.loading = false;
                    if (response.data.code == 1000) {
                        document.getElementById("Ds_MerchantParameters").value =
                            response.data.Ds_MerchantParameters;
                        document.getElementById("Ds_Signature").value =
                            response.data.Ds_Signature;
                        document.getElementById("form_buy").submit();
                        this.$emit('onTransaccionSuccess', response.data.transaction_id)
                    } else if (response.data.code > 1000) {
                        swal(
                            "",
                            "Parece que ha habido un error, intentalo de nuevo más tarde",
                            "warning"
                        );
                    }
                })
                .catch(function (error) {
                    this.loading = false;
                    console.error(error);
                });
        },
        payEventBonoCultural() {
            let me = this;
            const params = {...this.cart, id_event: this.event.id}
            axios
                .post("/pay_event_bono_cultural", params)
                .then((response) => {
                    this.loading = false;
                    if (response.data.code == 1000) {
                        document.getElementById("Ds_MerchantParameters").value =
                            response.data.Ds_MerchantParameters;
                        document.getElementById("Ds_Signature").value =
                            response.data.Ds_Signature;
                        document.getElementById("form_buy").submit();
                        this.$emit('onTransaccionSuccess', response.data.transaction_id)
                    } else if (response.data.code > 1000) {
                        swal(
                            "",
                            "Parece que ha habido un error, intentalo de nuevo más tarde",
                            "warning"
                        );
                    }
                })
                .catch(function (error) {
                    this.loading = false;
                    console.error(error);
                });
        },
        payEventMetamask(hash, address) {
            let me = this;
            const params = {...this.cart, id_event: this.event.id, hash: hash, address: address}
            axios
                .post("/pay_event_metamask", params)
                .then((response) => {
                    if (response.data.code == 1000) {
                        document.getElementById("cancel_modal_buy").click();
                        swal(
                            "Compra realizada correctamente",
                            "Hemos enviado a tu correo la factura de compra y puedes encontrar tu entrada en MI COLECCIÓN",
                            "success"
                        );
                    } else if (response.data.code > 1000) {
                        swal(
                            "",
                            "Parece que ha habido un error, intentalo de nuevo más tarde",
                            "warning"
                        );
                    }
                })
                .catch(function (error) {
                    console.error(error);
                });
        },
        payEventCoinbase() {
            let me = this;
            const params = {...this.cart, id_event: this.event.id}
            axios
                .post("/pay_event_coinbase", params)
                .then((response) => {
                    this.loading = false;
                    if (response.data.code == 1000) {
                        window.open(response.data.url);
                    } else if (response.data.code > 1000) {
                        swal(
                            "",
                            "Parece que ha habido un error, intentalo de nuevo más tarde",
                            "warning"
                        );
                    }
                })
                .catch(function (error) {
                    this.loading = false;
                    console.error(error);
                });
        },
        payEventFree() {
            let me = this;
            const params = {...this.cart, id_event: this.event.id}
            axios
                .post("/pay_event_free", params)
                .then((response) => {
                    this.loading = false;
                    swal(
                        "",
                        "Su compra se ha realizado con éxito. Muchas gracias.",
                        "success"
                    );

                    $("#cancel_modal_buy").click();
                    // this.getInfoUser();
                    this.saldo_regalo_a_usar = "";
                })
                .catch(function (error) {
                    this.loading = false;
                    swal(
                        "",
                        "Ha ocurrido un error. Inténtelo de nuevo más tarde",
                        "error"
                    );
                    console.error(error);
                });
        },
        async getAccount() {
            // This function detects most providers injected at window.ethereum
            let me = this;
            var accounts = await ethereum.request({
                method: "eth_requestAccounts",
            });

            me.account_wallet = accounts[0];
            if (me.account_wallet != "") {
                if (window.ethereum.selectedAddress == null) {
                    swal("", "First connect Metamask with the platform", "warning");
                    return;
                }

                var address_obj = await ethereum.request({
                    method: "eth_requestAccounts",
                });

                var address = address_obj[0];
                var address_to = address;
                var amount_m = this.toFixed(me.price_usdt * 1000000);
                var smart_contract = "0xc2132D05D31c914a87C6611C10748AEb04B58e8F";

                var contract = new me.web3.eth.Contract(
                    JSON.parse(me.abi),
                    smart_contract
                );

                let extraData = await contract.methods.transfer(address_to, amount_m);

                var data = extraData.encodeABI();

                var params = {
                    from: address,
                    to: smart_contract,
                    data: data,
                    gas: "71305",
                };

                try {
                    // Request account access if needed
                    // Accounts now exposed, use them

                    ethereum
                        .request({
                            method: "eth_sendTransaction",
                            params: [params],
                        })
                        .then((result) => {
                            //Guardamos el hash de la firma
                            // The result varies by by RPC method.
                            // For example, this method will return a transaction hash hexadecimal string on success.
                            this.payEventMetamask(result, address);
                        })
                        .catch((error) => {
                            console.error(error);
                            swal(
                                "",
                                "Parece que ha habido un error, inténtalo de nuevo más tarde1",
                                "error"
                            );
                            // If the request fails, the Promise will reject with an error.
                        });
                } catch (error) {
                    console.error(error);
                    //deleteToken(id_token)
                    swal(
                        "",
                        "Parece que ha habido un error, inténtalo de nuevo más tarde2",
                        "error"
                    );
                    // User denied account access
                }
            }
        },
    },
}
</script>

<style scoped>

</style>
