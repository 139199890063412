<template>
	<div class="d-flex flex-column">
		<div class="container my-5">
			<p>
				NFT My Ticket Login es una aplicación de inicio de sesión de Facebook y no
				guardamos sus datos personales en nuestro servidor. De acuerdo con la
				política de Facebook, debemos proporcionar la URL de devolución de
				llamada de eliminación de datos del usuario o la URL de instrucciones de
				eliminación de datos.
			</p>

			<p>
				Si desea eliminar sus actividades para la aplicación de inicio de sesión
				NFT My Ticket, puede eliminar su información siguiendo estos pasos:
			</p>
			<p>
				1. Vaya a Configuración y privacidad de su cuenta de Facebook. Haga clic
				en "Configuración"
			</p>
			<p>
				2. Busque "Aplicaciones y sitios web" y verá todas las aplicaciones y
				sitios web que vinculó con su Facebook.
			</p>
			<p>3. Busque y haga clic en "NFT My Ticket" en la barra de búsqueda.</p>
			<p>4. Desplácese y haga clic en "Eliminar".</p>
			<p>
				5. Felicitaciones, ha eliminado con éxito las actividades de su
				aplicación.
			</p>
		</div>
	</div>
</template>

<script>
export default {
};
</script>

<style lang="scss" scoped>
p,
h1 {
	color: white;

	&.seccion {
		margin-top: 3rem;
		font-weight: bold;
	}
}

a {
	color: #009f9b;
}
</style>
