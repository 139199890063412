<template>
    <div class="container px-0">
        <!-- Eventos -->
        <div class="d-flex align-items-center my-4">
            <span class="col-12 mr-6 color-white" v-if="events.length > 0"
                >Planes de la búsqueda</span
            >
            <span class="col-12 mr-6 color-white" v-else
                >No se encontraron planes</span
            >
        </div>
        <div class="d-flex flex-wrap justify-content-start">
            <div
                class="col-8 col-md-4 col-lg-3 mb-5"
                v-for="event in events"
                :key="event.id"
            >
                <div class="evecan custom">
                    <router-link :to="{ path: '/event/' + event.id }">
                        <div class="h-100">
                            <img
                                v-bind:src="
                                    '/files/images/events/' + event.img_event
                                "
                                style="object-fit: cover"
                            />
                            <div
                                class="description p-4 d-flex flex-wrap justify-content-between align-content-between bg-blue"
                            >
                                <div class="w-100">
                                    <span
                                        class="event-name color-white"
                                        v-text="event.name"
                                    ></span>
                                </div>
                                <div
                                    class="w-100 d-flex justify-content-between"
                                >
                                    <div>
                                        <span
                                            class="h2 text-white"
                                            v-if="event.sold_out"
                                            >Sold out</span
                                        >
                                    </div>
                                    <div>
                                        <span class="color-white">Desde</span
                                        ><br />
                                        <span
                                            class="h2 color-green"
                                            v-text="
                                                parseFloat(
                                                    event.min_price
                                                ).toFixed(2) + '€'
                                            "
                                        ></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>

        <!-- Canciones -->
        <div class="d-flex align-items-center my-4">
            <span class="col-12 mr-6 color-white" v-if="songs.length > 0"
                >Canciones de la búsqueda</span
            >
            <span class="col-12 mr-6 color-white" v-else
                >No se encontraron canciones</span
            >
        </div>
        <div class="d-flex flex-wrap justify-content-start">
            <div
                class="col-8 col-md-4 col-lg-3 mb-5"
                v-for="song in songs"
                :key="song.id"
            >
                <div class="evecan custom">
                    <router-link :to="{ path: '/song/' + song.id }">
                        <div class="h-100">
                            <img
                                v-bind:src="
                                    '/files/images/music/' + song.img_music
                                "
                                style="object-fit: cover"
                            />
                            <div
                                class="description p-4 d-flex flex-wrap justify-content-between align-content-between bg-blue"
                            >
                                <div class="w-100">
                                    <span
                                        class="event-name color-white font-weight-bold"
                                        v-text="song.name"
                                    ></span>
                                </div>
                                <div class="w-100 text-align-end">
                                    <span
                                        class="h2 color-green"
                                        v-text="song.price + '€'"
                                    ></span>
                                </div>
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>

        <!-- Cargar más resultados -->
        <div
            class="d-flex justify-content-center align-items-center my-4"
            v-if="hidden_button"
        >
            >
            <button
                v-on:click="showMore()"
                type="button"
                class="btn btn-green-secondary px-4"
            >
                Ver más
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: "EventsNearbyComponent",
    data() {
        return {
            events: [],
            songs: [],
            hidden_button: false,
            my_lat: "",
            my_lng: "",
            searchText: "",
            general_search_input: null,
            general_search_container: null,
        };
    },
    methods: {
        listEvents() {
            const params = {
                searchText: $("#general-search-input").val(),
            };

            axios
                .post("/list_events_more_results", params)
                .then((response) => {
                    this.events = response.data;

                    let sold_out = true;

                    this.events.forEach((event) => {
                        event.tickets.forEach((ticket) => {
                            if (
                                parseInt(ticket.num_ticket) !=
                                ticket.sales.length
                            ) {
                                sold_out = false;
                            }
                        });
                        event.sold_out = sold_out;
                    });
                })
                .catch(function (error) {
                    swal(
                        "",
                        "Parece que ha habido un error, inténtalo de nuevo más tarde",
                        "error"
                    );
                });
        },
        listSongs() {
            const params = {
                searchText: $("#general-search-input").val(),
            };

            axios
                .post("/list_songs_more_results", params)
                .then((response) => {
                    this.songs = response.data;
                })
                .catch(function (error) {
                    swal(
                        "",
                        "Parece que ha habido un error, inténtalo de nuevo más tarde",
                        "error"
                    );
                });
        },
        showMore() {
            this.start += 12;
            this.listEvents();
            this.listSongs();
        },
        initSearch() {
            let timeout = null;

            this.general_search_input.on("keyup", () => {
                const search_string = this.general_search_input.val();
                clearTimeout(timeout);

                timeout = setTimeout(() => {
                    if (search_string != "" && search_string.length > 2) {
                        this.listEvents();
                        this.listSongs();
                    } else {
                        this.ocultarSearch();
                    }
                }, 300);
            });
        },
        ocultarSearch() {
            this.general_search_container.find(".results").removeClass("show");
            this.general_search_container
                .find(".events .event, .musics .music")
                .remove();
        },
    },
    mounted() {
        this.general_search_input = $("#general-search-input");
        this.general_search_container = $("#general-search-container");

        this.listEvents();
        this.listSongs();
        this.initSearch();
    },
};
</script>
