var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "zoom-image d-flex align-items-center justify-content-center",
      class: [_vm.show_image ? "show" : ""],
      on: {
        click: function ($event) {
          return _vm.hide()
        },
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "close-image",
          on: {
            click: function ($event) {
              return _vm.hide()
            },
          },
        },
        [_c("i", { staticClass: "fas fa-times" })]
      ),
      _vm._v(" "),
      _c("img", { attrs: { src: _vm.src, alt: _vm.alt } }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }