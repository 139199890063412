<template>

    <div class="custom-card-absolute">
        <div class="card card-custom gutter-b">
            <div class="card-header bg-color-white border-0 px-4 px-sm-4 px-md-8">
                <div class="card-title">
                    <h5 class="card-label font-weight-bolder color-darkblue">
                        Realiza tu compras
                    </h5>
                </div>
            </div>
            <div class="card-body px-4 px-sm-4 px-md-8 py-4 py-sm-4 py-md-8">
                <div
                    class="form-group d-flex flex-wrap"
                    id="div_select_type_ticket"
                >
                    <label class="font-weight-bold color-darkblue">Tipos de entrada:</label>

                    <div class="table-responsive border rounded">
                        <table class="table table-striped table-hover mb-0">
                            <thead>
                            <tr>
                                <th :style="'width: ' + (isMobile() ? '58%' : '75%')" scope="col">Tipo</th>
                                <th scope="col" style="text-align: right">Cantidad</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr
                                v-for="ticket in ticket_types"
                                :key="ticket.id"
                                :style="ticket.tickets_available <= 0 || ticket.tickets_reserved>=ticket.tickets_available ? 'cursor: not-allowed' : 'cursor: pointer;'"
                                :class="ticket.tickets_available <= 0 ? 'table-danger text-danger disabled ' : (ticket.tickets_reserved>=ticket.tickets_available ? 'table-warning text-warning disabled' : '')"
                            >
                                <td class="px-2 py-2 center-vertical-td">
                                    {{ ticket.name_ticket }}
                                    <span class="badge bg-blue text-white" v-if="priceTicket(ticket) > 0">{{priceTicket(ticket)}}€</span>
                                    <template v-if="ticket.name_ticket !== ticket.description_ticket">
                                        <br><small>{{ ticket.description_ticket }}</small></template><br>

                                </td>
                                <td class="center-vertical-td center-horizontal-td">
                                    <span class="badge bg-danger text-white" v-if="isFinished">Finalizado</span>
                                    <span class="badge bg-danger text-white" v-else-if="ticket.tickets_available <= 0">Agotadas</span>
                                    <span class="badge bg-warning text-white" v-else-if="ticket.tickets_reserved>=ticket.tickets_available">Reservadas</span>
                                    <div v-else class="form-group touchspin-custom">
                                        <input
                                            type="text"
                                            value="0"
                                            class="form-control text-align-center"
                                            name="num_tickets"
                                            :id="ticket.id"
                                            v-model="ticket_cart[ticket.id].quantity"
                                        />
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>

                    <div class="d-flex flex-column justify-content-center w-100">
                        <img
                            @click="zoomImage()"
                            v-if="ticket_image != null"
                            :src="'/files/images/seatings/' + ticket_image"
                            alt="Imagen Ticket"
                            class="custom-image mx-auto mt-3"
                        />
                        <small class="mx-auto" v-if="ticket_image != null">Pulse en la imagen para ampliar</small>
                    </div>
                </div>

                <div
                    class="form-group text-center mt-8"
                    v-if="total > 0"
                >

                    <button
                        type="button"
                        class="btn btn-blue"
                        data-toggle="modal"
                        @click="buy()"
                    >
                        {{ total }}€ - Comprar
                    </button>
                </div>
            </div>
        </div>
    </div>

</template>

<script>

import ZoomImage from "../../ZoomImageComponent";
import c from "../../../const";

export default {
    components: {
        ZoomImage,
    },
    name: "TicketSelection",
    props: {
        isFinished: Boolean,
        ticket_types: {
            type: Array,
            required: true,
        },
        ticket_image: {
            type: String,
            required: false,
        }
    },
    created: function () {

    },
    data: function () {
        return {
            ticket_list: this.ticket_types.reduce((p, c, i) => {
                p[c.id] = c;
                return p;
            }, {}),
            ticket_cart: this.initCart()
        }
    },
    computed: {
        total() {
            return Object.keys(this.ticket_cart).reduce((p, c, i) => p + (this.ticket_cart[c].quantity * this.ticket_cart[c].ticket.price_ticket), 0);
        },
        priceTicket() {
            return ticket => ticket.num_tickets_pack ? (ticket.num_tickets_pack * ticket.price_ticket).toFixed(2) : ticket.price_ticket
        },
    },
    methods: {
        zoomImage() {
            this.$refs.zoomImage.show();
        },
        initCart() {
            return this.ticket_types.reduce((p, c, i) => {
                p[c.id] = {
                    ticket: c,
                    quantity: 0
                }
                return p;
            }, {})
        },
        buy() {
            const cart = {items: this.ticket_cart}
            this.$emit('ticketCartFinish', cart)
        },
        isMobile() {
            if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        }
    },
    mounted: function () {
        const me = this;
        $('input[name="num_tickets"]').each(function(index){
            const ticket_id = $(this).attr('id')
            const ticket = me.ticket_list[ticket_id]
            const vertical = false;//me.isMobile()
            if(ticket){
                const step = ticket.ticket_pack ? ticket.num_tickets_pack : 1
                $(this).TouchSpin({
                    verticalbuttons: vertical,
                    buttondown_class: "btn btn-secondary",
                    buttonup_class: "btn btn-secondary",
                    min: 0,
                    max: Math.min.apply(Math, [me.ticket_list[ticket_id].tickets_available - me.ticket_list[ticket_id].tickets_reserved, c.maxTicketsPerBuy]),
                    step: step,
                    decimals: 0,
                    boostat: 5,
                    maxboostedstep: 10,
                }).on("change", function (e) {
                    me.ticket_cart[ticket_id].quantity = parseInt(e.target.value);
                });
            }
        })
        $(".touchspin-custom").find("span").addClass("align-self-center")

    }
}
</script>

<style scoped>

</style>
