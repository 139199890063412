<template>
    <div>
        <div class="row">
            <div class="col-12 mb-2">
                <button @click="nuevaConsulta" class="btn nueva-consulta">
                    Nueva consulta
                </button>
            </div>
            <div
                class="col-12 col-md-6 mb-5"
                v-for="(query, index) in queries"
                :key="index"
            >
                <div class="card">
                    <div
                        class="card-header p-3 d-flex align-items-center justify-content-between"
                        :id="'heading' + index"
                    >
                        <span
                            v-bind:class="[
                                !query.status
                                    ? 'badge-warning'
                                    : 'badge-primary',
                            ]"
                            class="badge"
                            >{{
                                !query.status ? "Pendiente" : "Finalizado"
                            }}</span
                        >
                        <span class="text-dark">{{ query.format_date }}</span>
                    </div>

                    <div class="card-body p-3">
                        <p class="text-dark">
                            <b>{{ query.subject }}</b>
                        </p>
                        <p class="text-dark">{{ query.answer }}</p>
                    </div>
                </div>
            </div>
            <div class="col-12 d-flex justify-content-center mb-2" v-if="queries.length > 0">
                <button @click="verMas" class="btn ver-mas">
                    Ver más
                </button>
            </div>
        </div>

        <div
            class="modal fade"
            id="newSubjectModal"
            tabindex="-1"
            role="dialog"
            aria-labelledby="newSubjectModalLabel"
            aria-hidden="true"
        >
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="newSubjectModalLabel">
                            Nueva consulta
                        </h5>
                        <button
                            type="button"
                            class="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="form-group">
                            <label for="asunto">Asunto</label>
                            <input
                                type="text"
                                class="form-control"
                                id="asunto"
                                v-model="asunto"
                                placeholder="Escribe un asunto"
                            />
                        </div>
                        <div class="form-group">
                            <label for="mensaje">Mensaje</label>
                            <textarea
                                rows="5"
                                class="form-control"
                                v-model="mensaje"
                                id="mensaje"
                                placeholder="Escribe un mensaje"
                            />
                        </div>
                    </div>
                    <div
                        class="modal-footer d-flex align-items-center justify-content-between"
                    >
                        <button
                            @click="closeModal"
                            type="button"
                            class="btn btn-secondary btn-cancelar"
                            data-dismiss="modal"
                        >
                            Cancelar
                        </button>
                        <button
                            @click="sendConsulta"
                            type="button"
                            class="btn btn-primary btn-enviar"
                        >
                            Enviar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "QueriesComponent",
    data() {
        return {
            queries: [],
            asunto: "",
            mensaje: "",
            paginate: 0,
        };
    },
    methods: {
        getQueries() {
            let url = "/queries";

            const data = {
                paginate: this.paginate,
            };

            axios
                .post(url, data)
                .then((response) => {
                    if (response.data.queries.length > 0) {
                        response.data.queries.forEach((query) => {
                            this.queries.push(query);
                        });
                    } else if (response.data.total_queries != 0) {
                        swal("", "No tiene más consultas para mostrar", "info");
                    }
                })
                .catch(function (error) {
                    this.error = response.data.message;
                });
        },
        verMas() {
            this.paginate += 4;
            this.getQueries();
        },
        nuevaConsulta() {
            this.asunto = "";
            this.mensaje = "";
            $("#newSubjectModal").modal("show");
        },
        closeModal() {
            $("#newSubjectModal").modal("hide");
        },
        sendConsulta() {
            if (this.validForm()) {
                const data = {
                    asunto: this.asunto,
                    mensaje: this.mensaje,
                };

                axios
                    .post("/send-query", data)
                    .then((response) => {
                        swal(
                            "",
                            "Se ha creado su consulta, muchas gracias",
                            "success"
                        );
                        $("#newSubjectModal").modal("hide");
                        this.paginate = 0
                        this.queries = []
                        this.getQueries();
                    })
                    .catch(function (error) {
                        swal(
                            "",
                            "Ha ocurrido un error al intentar crear la consulta",
                            "error"
                        );
                    });
            }
        },
        validForm() {
            let is_valid = true;

            if (this.mensaje == "") {
                is_valid = false;
                swal("", "Debe indicar un mensaje", "warning");
            }

            if (this.asunto == "") {
                is_valid = false;
                swal("", "Debe indicar un asunto", "warning");
            }

            return is_valid;
        }
    },
    mounted() {
        this.getQueries();
    },
};
</script>

<style lang="scss" scoped>
$primary: #45c4b0;

.card {
    background-color: transparent;
    border: none;
    border-radius: 5px;

    .card-header,
    .card-body {
        background-color: #b3e0d7;
        border: none;
        padding-bottom: 0 !important;
    }
}

.btn {
    background-color: $primary;
    color: darken($primary, 30%);
    font-weight: bold;
    padding: 0.5rem 1rem;
    border: none;
    outline: none;

    &:hover {
        background-color: darken($primary, 10%) !important;
    }
}

.btn-enviar {
    background-color: #136783;
    color: #ffffff;

    &:hover {
        background-color: darken(#136783, 10%) !important;
    }
}

.modal {
    .modal-header {
        padding-bottom: 0;
        border: none;

        .modal-title {
            color: #136783;
            font-weight: bold;
        }
    }

    .modal-body {
        textarea {
            border: 1px solid #d7f4ee;
        }
    }

    .modal-footer {
        padding-top: 0;
        border: none;
    }
}
</style>
