var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "row mb-4" }, [
      _c("div", { staticClass: "col-12 col-md-6 col-lg-3" }, [
        _c("div", { staticClass: "input-group search-input" }, [
          _c("div", { staticClass: "input-group-prepend" }, [
            _c(
              "span",
              {
                staticClass:
                  "input-group-text bg-white border-radius-left border-0 h-100",
              },
              [
                _c(
                  "svg",
                  {
                    staticClass: "bi bi-search",
                    attrs: {
                      xmlns: "http://www.w3.org/2000/svg",
                      width: "16",
                      height: "16",
                      fill: "#ffffff",
                      viewBox: "0 0 16 16",
                    },
                  },
                  [
                    _c("path", {
                      attrs: {
                        d: "M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z",
                      },
                    }),
                  ]
                ),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.search_documents,
                expression: "search_documents",
              },
            ],
            staticClass: "form-control bg-white color-green border-0 h-100",
            attrs: {
              id: "search_documents",
              type: "text",
              placeholder: "Buscar...",
              "aria-describedby": "search-addon",
            },
            domProps: { value: _vm.search_documents },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.search_documents = $event.target.value
              },
            },
          }),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", {
      staticClass: "datatable datatable-bordered datatable-head-custom",
      staticStyle: { width: "100%" },
      attrs: { id: "list_documents" },
    }),
    _vm._v(" "),
    _c("div", { staticClass: "row justify-content-center" }, [
      _c("button", { staticClass: "btn ver-mas", on: { click: _vm.verMas } }, [
        _vm._v("Ver más"),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }