var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "d-flex flex-wrap justify-content-start" },
      [
        _vm._l(_vm.array_user_nfts, function (nft) {
          return _c(
            "div",
            {
              key: nft.id_users_nfts,
              staticClass:
                "card card-custom col-12 col-md-6 col-lg-4 col-xl-3 pt-2 pb-4 bg-transparent",
            },
            [
              !nft.isReservation
                ? _c(
                    "div",
                    { staticClass: "card-body p-0 pb-8 overlay element-nft" },
                    [
                      _c("div", { staticClass: "overlay-wrapper" }, [
                        nft.type_file != "mp4"
                          ? _c("img", {
                              staticStyle: { "object-fit": "cover" },
                              attrs: {
                                src: nft.img_nft_custom,
                                width: "100%",
                                height: "250px",
                              },
                            })
                          : nft.type_file == "mp4"
                          ? _c(
                              "video",
                              {
                                staticStyle: { "object-fit": "cover" },
                                attrs: {
                                  width: "100%",
                                  height: "250px",
                                  playsinline: "",
                                  autoplay: "",
                                  loop: "",
                                  muted: "",
                                },
                                domProps: { muted: true },
                              },
                              [
                                _c("source", {
                                  attrs: {
                                    src: nft.img_nft_custom,
                                    type: "video/mp4",
                                  },
                                }),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex flex-wrap align-content-between",
                            staticStyle: { height: "120px" },
                          },
                          [
                            _c("h5", {
                              staticClass:
                                "w-100 nft-name color-white px-4 my-2 font-weight-bolder",
                              domProps: { textContent: _vm._s(nft.name_nft) },
                            }),
                            _vm._v(" "),
                            _c("div", { staticClass: "w-100" }, [
                              _c("h5", {
                                staticClass:
                                  "w-100 event-name color-white px-4 my-2 font-weight-bolder",
                                domProps: {
                                  textContent: _vm._s(nft.name_event),
                                },
                              }),
                            ]),
                          ]
                        ),
                      ]),
                    ]
                  )
                : _c(
                    "div",
                    { staticClass: "card-body p-0 pb-8 overlay element-nft" },
                    [
                      _c("div", { staticClass: "overlay-wrapper" }, [
                        nft.type_file != "mp4"
                          ? _c("img", {
                              staticStyle: { "object-fit": "cover" },
                              attrs: {
                                src: nft.nft_base64,
                                width: "100%",
                                height: "250px",
                              },
                            })
                          : _c(
                              "video",
                              {
                                staticStyle: { "object-fit": "cover" },
                                attrs: {
                                  width: "100%",
                                  height: "250px",
                                  playsinline: "",
                                  autoplay: "",
                                  loop: "",
                                  muted: "",
                                },
                                domProps: { muted: true },
                              },
                              [
                                _c("source", {
                                  attrs: {
                                    src: nft.nft_base64,
                                    type: "video/mp4",
                                  },
                                }),
                              ]
                            ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex flex-wrap align-content-between",
                            staticStyle: { height: "120px" },
                          },
                          [
                            _c(
                              "h5",
                              {
                                staticClass:
                                  "w-100 nft-name color-white px-4 my-2 font-weight-bolder",
                              },
                              [
                                _vm._v(
                                  "\n\t\t\t\t\t\t\t\t" +
                                    _vm._s(nft.nft.name) +
                                    "\n\t\t\t\t\t\t\t"
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            nft.reservation.restaurant.prometer.collection !=
                            null
                              ? _c(
                                  "h5",
                                  {
                                    staticClass:
                                      "w-100 nft-name color-white px-4 my-2 font-weight-bolder",
                                  },
                                  [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\t\t" +
                                        _vm._s(
                                          nft.reservation.restaurant.prometer
                                            .collection.name
                                        ) +
                                        "\n\t\t\t\t\t\t\t"
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        ),
                      ]),
                    ]
                  ),
            ]
          )
        }),
        _vm._v(" "),
        _vm.hidden_button
          ? _c(
              "div",
              {
                staticClass:
                  "d-flex justify-content-center align-items-center my-4 w-100",
              },
              [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-lightgreen font-weight-bold px-4",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.showMore()
                      },
                    },
                  },
                  [_vm._v("\n\t\t\t\t\tVer más\n\t\t\t\t")]
                ),
              ]
            )
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }