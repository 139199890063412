<template>
    <div>
        <div class="alert alert-danger" role="alert" v-if="error">
            {{ error }}
        </div>

        <ul class="nav nav-tabs nav-line-tabs mb-5 fs-6">
            <li class="nav-item">
                <a class="nav-link active" data-bs-toggle="tab" href="#tab_register_user">Invitado</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" data-bs-toggle="tab" href="#tab_login_user">Ya tengo cuenta</a>
            </li>
        </ul>

        <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade show active" id="tab_register_user" role="tabpanel">
                <div class="form-group">
                    <label class="font-weight-bold color-darkblue">Nombre<sup>*</sup></label>
                    <input id="name" name="name" type="text" class="form-control" v-model="user.name"
                           required autofocus autocomplete="off" placeholder="Nombre">
                </div>
                <div class="form-group">
                    <label class="font-weight-bold color-darkblue">Apellidos<sup>*</sup></label>
                    <input id="surnames" name="surnames" type="text" class="form-control"
                           v-model="user.surnames" required autofocus autocomplete="off"
                           placeholder="Apellidos">
                </div>
                <div class="form-group">
                    <label class="font-weight-bold color-darkblue">Fecha de nacimiento<sup>*</sup></label>
                    <div>
                        <form-date id="birthday" name="birthday" v-model="user.birthday"/>
                    </div>

                    <div class="fv-plugins-message-container">
                        <div class="fv-help-block" v-if="date_message !== '' && !user_is_over_16_years">
                            {{ date_message }}
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label class="font-weight-bold color-darkblue">Email<sup>*</sup></label>
                    <input id="email_register" name="email" type="email" class="form-control"
                           v-model="user.email" required autofocus autocomplete="off" placeholder="Email">
                    <div class="fv-plugins-message-container">
                        <div class="fv-help-block" v-if="user.email !== '' && !user_email_is_valid">La dirección
                            introducida no es válida
                        </div>
                        <div class="fv-help-block" v-if="user.email !== '' && user_email_is_valid && user_email_exists">
                            La dirección introducida ya existe
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label class="font-weight-bold color-darkblue">Confirmación de email<sup>*</sup></label>
                    <input id="email_confirmation" name="email_confirmation" type="email" class="form-control"
                           v-model="user.email_confirmation" required autofocus autocomplete="off"
                           placeholder="Confirmación de email">
                    <div class="fv-plugins-message-container"
                         v-if="user.email_confirmation !== '' && !user_email_confirmation_is_valid">
                        <div class="fv-help-block">Las direcciones de correo no coinciden</div>
                    </div>
                </div>
                <div class="form-group">
                    <label class="font-weight-bold color-darkblue">Teléfono<sup>*</sup></label>
                    <input id="phone" name="phone" type="tel" class="form-control" maxlength="20"
                           v-model="user.phone" required autofocus autocomplete="off"
                           placeholder="Teléfono">
                </div>
                <div class="form-group">
                    <label class="font-weight-bold color-darkblue">Código postal<sup>*</sup></label>
                    <input id="zipcode" name="zipcode" type="text" class="form-control" maxlength="5"
                           v-model="user.zipcode" required autofocus autocomplete="off"
                           placeholder="Código postal">
                    <div class="fv-plugins-message-container"
                         v-if="!user_zipcode_is_valid">
                    </div>
                </div>
            </div>
            <div class="tab-pane fade" id="tab_login_user" role="tabpanel">
                <div class="form-group">
                    <label class="font-weight-bolder color-darkblue">Email:</label>
                    <input
                        class="form-control"
                        type="text"
                        name="email"
                        v-model="email"
                        required
                        autofocus
                        autocomplete="off"
                        placeholder="Escribe tu email"
                    />
                </div>
                <div class="form-group">
                    <div
                        class="d-flex
                               justify-content-between
                               align-items-center
                               text-center"
                    >
                        <label class="font-weight-bolder color-darkblue">Contraseña:</label>
                        <a href="javascript:;"
                           role="button"
                           class="color-darkgreen"
                           data-toggle="modal"
                           data-target="#forgetpasswordModal"
                        >¿Has olvidado tu contraseña?</a>
                    </div>
                    <input
                        class="form-control"
                        type="password"
                        name="password"
                        v-model="password"
                        required
                        autocomplete="off"
                        placeholder="Escribe tu contraseña"
                    />
                </div>
                <div class="d-flex flex-wrap">
                    <button
                        type="submit"
                        class="btn btn-green w-100 mb-3"
                        @click="handleSubmit"
                    >
                        Iniciar sesión
                    </button>
                    <button
                        class="
                                                        facebook-btn
                                                        w-100
                                                        btn
                                                        d-flex
                                                        align-items-center
                                                        justify-content-center
                                                        p-3
                                                    "
                        @click="authProvider()"
                        type="button"
                    >
                        <img
                            src="/media/misc/facebook.png"
                            alt="NFT My Ticket - Facebook"
                            width="25px"
                            class="mr-2"
                        />
                        <span>Iniciar sesión con Facebook</span>
                    </button>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import validateBirtday from "../../util/dateUtils";
import FormDate from "../../util/FormDate.vue";

export default {
    name: "BuyUserRegistration",
    components: {
        FormDate
    },
    props: {
        event: {
            type: Object,
            required: false
        },
    },
    data: function(){
        return {
            error: false,
            date_message: '',
            user: {
                name: '',
                surnames: '',
                email: '',
                email_confirmation: '',
                birthday: '',
                phone: '',
                zipcode: ''
            },
            email: "",
            password: "",
            user_email_exists: false,
        }
    },
    watch: {
        async user_email() {
            if (this.user_email_is_valid) {
                this.user_email_exists = await this.checkEmailExists(this.user.email)
            }
        },
        user: {
            handler(newValue, oldValue){
                this.$emit('onUserChanged', this.form_is_valid, newValue)
            },
            deep: true
        }
    },
    computed: {
        user_name_is_valid() {
            return this.user.name !== ''
        },
        user_surnames_is_valid() {
            return this.user.surnames !== ''
        },
        user_phone_is_valid() {
            return this.user.phone !== '' && this.user.phone.length <= 20
        },
        user_zipcode_is_valid() {
            return this.user.zipcode !== '' && this.user.zipcode.length === 5
        },
        user_is_over_16_years() {
            const dateValid = validateBirtday(this.user.birthday, this.event.min_age);
            this.date_message = dateValid.message;
            return dateValid.message === '';
        },
        user_email_confirmation_is_valid() {
            let is_empty = this.user.email_confirmation === '';
            let is_valid = !is_empty && this.emailValid(this.user.email_confirmation)
            return is_valid && this.user.email === this.user.email_confirmation
        },
        user_email() {
            return this.user.email
        },
        user_email_is_valid() {
            let is_empty = this.user.email === '';
            return !is_empty && this.emailValid(this.user.email)
        },
        form_is_valid() {
            return this.is_login || (
                this.user_name_is_valid
                && this.user_surnames_is_valid
                && this.user_phone_is_valid
                && this.user_zipcode_is_valid
                && this.user_is_over_16_years
                && this.user_email_is_valid
                && !this.user_email_exists
                && this.user_email_confirmation_is_valid
            )
        },
    },
    methods: {
        getYears(birthday) {
            let today = new Date();
            let birthdate = new Date(birthday);
            let years = today.getFullYear() - birthdate.getFullYear();
            let month = today.getMonth() - birthdate.getMonth();
            if (month < 0 || (month === 0 && today.getDate() < birthdate.getDate())) {
                years--;
            }
            return years
        },
        emailValid($email = '') {
            return /^\w+([\.+-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test($email)
        },
        async checkEmailExists($email = '') {
            try {
                let response = await axios
                    .post("/check_email_exists", {email: $email})
                return response && response.data.code === 1000 && response.data.exists
            } catch (e) {
                return false
            }
        },
        authProvider() {
            this.$auth
                .authenticate("facebook")
                .then((response) => {
                    this.socialLogin("facebook", response);
                })
                .catch((error) => {
                    console.error(
                        "🚀 ~ file: LoginComponent.vue:177 ~ authProvider ~ error",
                        error
                    );
                });
        },
        socialLogin(provider, response) {
            axios
                .post("/sociallogin/" + provider, response)
                .then(() => {
                    swal("", "Bienvenido/a de nuevo", "success");
                    this.$emit("onUserLogin", true);
                })
                .catch((error) => {
                    this.showError(error);
                });
        },
        handleSubmit(e) {
            e.preventDefault();
            if (this.password.length > 0) {
                axios.get("/sanctum/csrf-cookie").then((response) => {
                    axios
                        .post("/api/login", {
                            email: this.email,
                            password: this.password,
                        })
                        .then((response) => {
                            if (response.data.success) {
                                swal("", "Bienvenido/a de nuevo", "success");
                                this.$emit("onUserLogin", false);
                            } else if (response.data.message === "Not found user") {
                                swal("", "El usuario o la contraseña es incorrecto", "error");
                            } else if (
                                response.data.message ===
                                "Must verified your account. Email sent"
                            ) {
                                swal("", "Debes verificar primero tu email", "warning");
                            } else if (response.data.message === "Unauthorised") {
                                swal("", "El usuario o la contraseña es incorrecto", "error");
                            } else {
                                this.error = response.data.message;
                            }
                        })
                        .catch(function (error) {
                            console.error(error);
                            this.error = error.data.message;
                        });
                });
            }
        }
    }
}
</script>

<style scoped>

</style>
