var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "custom-card-absolute" }, [
    _c("div", { staticClass: "card card-custom gutter-b" }, [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "card-body px-4 px-sm-4 px-md-8 py-4 py-sm-4 py-md-8" },
        [
          _c(
            "div",
            {
              staticClass: "form-group d-flex flex-wrap",
              attrs: { id: "div_select_type_ticket" },
            },
            [
              _c("label", { staticClass: "font-weight-bold color-darkblue" }, [
                _vm._v("Tipos de entrada:"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "table-responsive border rounded" }, [
                _c(
                  "table",
                  { staticClass: "table table-striped table-hover mb-0" },
                  [
                    _c("thead", [
                      _c("tr", [
                        _c(
                          "th",
                          {
                            style: "width: " + (_vm.isMobile() ? "58%" : "75%"),
                            attrs: { scope: "col" },
                          },
                          [_vm._v("Tipo")]
                        ),
                        _vm._v(" "),
                        _c(
                          "th",
                          {
                            staticStyle: { "text-align": "right" },
                            attrs: { scope: "col" },
                          },
                          [_vm._v("Cantidad")]
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "tbody",
                      _vm._l(_vm.ticket_types, function (ticket) {
                        return _c(
                          "tr",
                          {
                            key: ticket.id,
                            class:
                              ticket.tickets_available <= 0
                                ? "table-danger text-danger disabled "
                                : ticket.tickets_reserved >=
                                  ticket.tickets_available
                                ? "table-warning text-warning disabled"
                                : "",
                            style:
                              ticket.tickets_available <= 0 ||
                              ticket.tickets_reserved >=
                                ticket.tickets_available
                                ? "cursor: not-allowed"
                                : "cursor: pointer;",
                          },
                          [
                            _c(
                              "td",
                              { staticClass: "px-2 py-2 center-vertical-td" },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(ticket.name_ticket) +
                                    "\n                                "
                                ),
                                _vm.priceTicket(ticket) > 0
                                  ? _c(
                                      "span",
                                      {
                                        staticClass: "badge bg-blue text-white",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.priceTicket(ticket)) + "€"
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                ticket.name_ticket !== ticket.description_ticket
                                  ? [
                                      _c("br"),
                                      _c("small", [
                                        _vm._v(
                                          _vm._s(ticket.description_ticket)
                                        ),
                                      ]),
                                    ]
                                  : _vm._e(),
                                _c("br"),
                              ],
                              2
                            ),
                            _vm._v(" "),
                            _c(
                              "td",
                              {
                                staticClass:
                                  "center-vertical-td center-horizontal-td",
                              },
                              [
                                _vm.isFinished
                                  ? _c(
                                      "span",
                                      {
                                        staticClass:
                                          "badge bg-danger text-white",
                                      },
                                      [_vm._v("Finalizado")]
                                    )
                                  : ticket.tickets_available <= 0
                                  ? _c(
                                      "span",
                                      {
                                        staticClass:
                                          "badge bg-danger text-white",
                                      },
                                      [_vm._v("Agotadas")]
                                    )
                                  : ticket.tickets_reserved >=
                                    ticket.tickets_available
                                  ? _c(
                                      "span",
                                      {
                                        staticClass:
                                          "badge bg-warning text-white",
                                      },
                                      [_vm._v("Reservadas")]
                                    )
                                  : _c(
                                      "div",
                                      {
                                        staticClass:
                                          "form-group touchspin-custom",
                                      },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.ticket_cart[ticket.id]
                                                  .quantity,
                                              expression:
                                                "ticket_cart[ticket.id].quantity",
                                            },
                                          ],
                                          staticClass:
                                            "form-control text-align-center",
                                          attrs: {
                                            type: "text",
                                            value: "0",
                                            name: "num_tickets",
                                            id: ticket.id,
                                          },
                                          domProps: {
                                            value:
                                              _vm.ticket_cart[ticket.id]
                                                .quantity,
                                          },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing)
                                                return
                                              _vm.$set(
                                                _vm.ticket_cart[ticket.id],
                                                "quantity",
                                                $event.target.value
                                              )
                                            },
                                          },
                                        }),
                                      ]
                                    ),
                              ]
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "d-flex flex-column justify-content-center w-100",
                },
                [
                  _vm.ticket_image != null
                    ? _c("img", {
                        staticClass: "custom-image mx-auto mt-3",
                        attrs: {
                          src: "/files/images/seatings/" + _vm.ticket_image,
                          alt: "Imagen Ticket",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.zoomImage()
                          },
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.ticket_image != null
                    ? _c("small", { staticClass: "mx-auto" }, [
                        _vm._v("Pulse en la imagen para ampliar"),
                      ])
                    : _vm._e(),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _vm.total > 0
            ? _c("div", { staticClass: "form-group text-center mt-8" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-blue",
                    attrs: { type: "button", "data-toggle": "modal" },
                    on: {
                      click: function ($event) {
                        return _vm.buy()
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.total) +
                        "€ - Comprar\n                "
                    ),
                  ]
                ),
              ])
            : _vm._e(),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "card-header bg-color-white border-0 px-4 px-sm-4 px-md-8",
      },
      [
        _c("div", { staticClass: "card-title" }, [
          _c(
            "h5",
            { staticClass: "card-label font-weight-bolder color-darkblue" },
            [
              _vm._v(
                "\n                    Realiza tu compras\n                "
              ),
            ]
          ),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }