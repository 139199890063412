var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container px-0" }, [
    _c("div", { staticClass: "d-flex justify-content-end my-4" }, [
      _c("div", { staticClass: "col-md-4" }, [
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.id_category_query,
                expression: "id_category_query",
              },
            ],
            staticClass: "form-control selectpicker select-green w-100",
            attrs: {
              name: "category",
              id: "select_categories_events_recent",
              "data-style": "select-green",
              "data-live-search": "true",
            },
            on: {
              change: [
                function ($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function (o) {
                      return o.selected
                    })
                    .map(function (o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.id_category_query = $event.target.multiple
                    ? $$selectedVal
                    : $$selectedVal[0]
                },
                function ($event) {
                  return _vm.changeCategory()
                },
              ],
            },
          },
          [
            _c(
              "option",
              { attrs: { selected: "" }, domProps: { value: "0" } },
              [_vm._v("Selecciona una categoria")]
            ),
            _vm._v(" "),
            _vm._l(_vm.array_categories, function (category) {
              return _c("option", {
                key: category.id,
                domProps: {
                  value: category.id,
                  textContent: _vm._s(category.name),
                },
              })
            }),
          ],
          2
        ),
      ]),
    ]),
    _vm._v(" "),
    _vm._m(0),
    _vm._v(" "),
    _vm.array_events.length
      ? _c(
          "div",
          { staticClass: "d-flex flex-wrap justify-content-start" },
          _vm._l(_vm.array_events, function (event) {
            return _c(
              "div",
              { key: event.id, staticClass: "col-8 col-md-4 col-lg-3 mb-5" },
              [
                _c(
                  "div",
                  { staticClass: "evecan custom" },
                  [
                    !event.isRestaurant
                      ? _c(
                          "router-link",
                          { attrs: { to: { path: "/event/" + event.id } } },
                          [
                            _c("div", { staticClass: "h-100" }, [
                              _c("img", {
                                staticStyle: { "object-fit": "cover" },
                                attrs: {
                                  src:
                                    "/files/images/events/" + event.img_event,
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "description p-4 d-flex flex-wrap justify-content-between align-content-between bg-blue",
                                },
                                [
                                  _c("div", { staticClass: "w-100" }, [
                                    _c("span", {
                                      staticClass: "event-name color-white",
                                      domProps: {
                                        textContent: _vm._s(event.name),
                                      },
                                    }),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "position-to-bottom w-100 d-flex justify-content-between",
                                    },
                                    [
                                      _c("div", [
                                        event.sold_out
                                          ? _c(
                                              "span",
                                              { staticClass: "h2 text-white" },
                                              [_vm._v("Sold out")]
                                            )
                                          : _vm._e(),
                                      ]),
                                      _vm._v(" "),
                                      _c("div", [
                                        _c(
                                          "span",
                                          { staticClass: "color-white" },
                                          [_vm._v("Desde")]
                                        ),
                                        _c("br"),
                                        _vm._v(" "),
                                        _c("span", {
                                          staticClass: "h2 color-green",
                                          domProps: {
                                            textContent: _vm._s(
                                              event.price + "€"
                                            ),
                                          },
                                        }),
                                      ]),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                          ]
                        )
                      : _c(
                          "router-link",
                          {
                            attrs: { to: { path: "/restaurant/" + event.id } },
                          },
                          [
                            _c("div", { staticClass: "h-100" }, [
                              _c("img", {
                                staticStyle: { "object-fit": "cover" },
                                attrs: {
                                  src: `/images/restaurants/${event.file}`,
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "description p-4 d-flex flex-wrap justify-content-between align-content-between",
                                },
                                [
                                  _c("div", { staticClass: "w-100" }, [
                                    _c("span", {
                                      staticClass: "event-name color-white",
                                      domProps: {
                                        textContent: _vm._s(event.name),
                                      },
                                    }),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "w-100 d-flex justify-content-end",
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "h2 color-green" },
                                        [_vm._v("Reservar")]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                          ]
                        ),
                  ],
                  1
                ),
              ]
            )
          }),
          0
        )
      : _c("div", { staticClass: "text-white h2 mt-3" }, [
          _vm._v("No hay planes disponibles"),
        ]),
    _vm._v(" "),
    _vm.hidden_button
      ? _c(
          "div",
          {
            staticClass:
              "d-flex justify-content-center align-items-center my-4",
          },
          [
            _c(
              "button",
              {
                staticClass: "btn btn-green-secondary px-4",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.showMore()
                  },
                },
              },
              [_vm._v("\n\t\t\tVer más\n\t\t")]
            ),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "d-flex align-items-center my-4" }, [
      _c("span", { staticClass: "col-12 mr-6 color-white h4" }, [
        _vm._v("Planes añadidos recientemente"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }