<template>
	<div>
		<EventsNearbyComponent></EventsNearbyComponent>
	</div>
</template>

<script>
import EventsNearbyComponent from "./EventsNearbyComponent.vue";
export default {
	components: {
		EventsNearbyComponent,
	},
};
</script>
