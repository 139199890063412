<template>
    <div
        :class="[show_image ? 'show' : '']"
        class="zoom-image d-flex align-items-center justify-content-center"
        @click="hide()"
    >
        <div class="close-image" @click="hide()">
            <i class="fas fa-times"></i>
        </div>
        <img :src="src" :alt="alt" />
    </div>
</template>

<script>
export default {
    props: {
        src: {
            type: String,
            required: true,
        },
        alt: {
            type: String,
            required: false,
            default: "",
        },
    },
    data() {
        return {
            show_image: false,
        };
    },
    methods: {
        show() {
            this.show_image = true;
        },
        hide() {
            this.show_image = false;
        },
    },
};
</script>

<style lang="scss" scoped>
.zoom-image {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease;

    .close-image {
        position: absolute;
        top: 1rem;
        right: 1rem;
        cursor: pointer;
        transition: all 0.3s ease;

        i {
            color: white;
            font-size: 50px;

            &:hover {
                color: #ddd;
            }
        }
    }

    &.show {
        opacity: 1;
        visibility: visible;
    }

    img {
        max-width: 90vw;
        max-height: 90vh;
        width: 800px;
    }
}
</style>
