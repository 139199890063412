var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "bg-header-general d-flex align-items-end" }, [
      _c("div", { staticClass: "container pt-4" }, [
        _c("div", { staticClass: "col-md-6 d-flex align-items-end" }, [
          _c("img", {
            staticStyle: { "object-fit": "cover" },
            attrs: {
              src: _vm.publicPath + "/svg/usuario.svg",
              width: "auto",
              height: "180px",
            },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "p-4", staticStyle: { height: "180px" } }, [
            _c("div", { staticStyle: { position: "absolute", top: "10px" } }, [
              _vm.address_wallet == ""
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-green mr-auto mb-auto",
                      on: {
                        click: function ($event) {
                          return _vm.connectMetamask()
                        },
                      },
                    },
                    [_vm._v("\n\t\t\t\t\t\t\tConectar wallet\n\t\t\t\t\t\t")]
                  )
                : _c(
                    "h5",
                    {
                      staticClass:
                        "color-white mb-auto address-wallet-container",
                    },
                    [
                      _c("div", [_vm._v("Dirección wallet:")]),
                      _vm._v(" "),
                      _c("div", { staticClass: "address-wallet" }, [
                        _vm._v(
                          "\n\t\t\t\t\t\t\t\t" +
                            _vm._s(_vm.address_wallet) +
                            "\n\t\t\t\t\t\t\t"
                        ),
                      ]),
                    ]
                  ),
            ]),
            _vm._v(" "),
            _vm._m(0),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticStyle: { position: "absolute", bottom: "10px" } },
      [_c("h3", { staticClass: "color-white" }, [_vm._v("Mis colecciones")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }