<template>
    <div>
	    <HelpTabsTablesComponent />
    </div>
</template>

<script>
    import HelpTabsTablesComponent from './HelpTabsTablesComponent.vue';
    export default {
        components: {
            HelpTabsTablesComponent,
        },
    }
</script>
