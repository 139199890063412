var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "d-flex flex-column" }, [
      _c("div", { staticClass: "container my-5" }, [
        _c("p", { staticClass: "seccion" }, [
          _vm._v("I. Datos identificativos"),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            '\n\t\t\tEsta página web es propiedad de la entidad "NFT MY TICKET, S.L." (en\n\t\t\tadelante, la "Empresa") cuyos datos son los siguientes:\n\t\t'
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v("Domicilio social: Plaza Isabel II, 5, 4ºC, 28013, Madrid."),
        ]),
        _vm._v(" "),
        _c("p", [_vm._v("N.I.F: B09863408")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n\t\t\tObjeto social: Intermediación y venta online de entradas de todo tipo de\n\t\t\tespectáculos (excepto taurinos).\n\t\t"
          ),
        ]),
        _vm._v(" "),
        _c("p", [_vm._v("Registro mercantil: [*]")]),
        _vm._v(" "),
        _c("p", [_vm._v("E-mail: info@nftmyticket.com")]),
        _vm._v(" "),
        _c("p", { staticClass: "seccion" }, [
          _vm._v("II. Aceptación del usuario"),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v("\n\t\t\tEl acceso y/o el uso del sitio web\n\t\t\t"),
          _c(
            "a",
            { attrs: { href: "www.xplosionmarkets.com", target: "_bank" } },
            [_vm._v("www.xplosionmarkets.com")]
          ),
          _vm._v(
            '\n\t\t\t(en adelante "página web") le atribuye la condición de usuario y, desde\n\t\t\tdicho acceso y/o uso, acepta las presentes Condiciones de uso (en\n\t\t\tadelante "Condiciones") y, en su caso, los cambios efectuados sobre las\n\t\t\tmismas, por lo que debe leerlas detenidamente antes de navegar y usar\n\t\t\teste sitio web.\n\t\t'
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n\t\t\tLas presentes Condiciones regulan los términos de acceso de la página\n\t\t\tweb, incluyendo los contenidos y los servicios puestos a disposición de\n\t\t\tlos usuarios a través de esta página web.\n\t\t"
          ),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "seccion" }, [_vm._v("III. Responsabilidad")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n\t\t\tEl usuario se obliga a utilizar esta página web conforme a lo\n\t\t\testablecido en las presentes Condiciones, en la Política de privacidad,\n\t\t\ten el Aviso legal y en la legislación vigente, respondiendo frente a la\n\t\t\tEmpresa y/o frente a terceros, de cualquier daño o perjuicio causado por\n\t\t\tincumplimiento de las mismas.\n\t\t"
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n\t\t\tEl Usuario se compromete a hacer un uso adecuado y lícito de la página\n\t\t\tweb y de los servicios y contenidos en la misma.\n\t\t"
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n\t\t\tEn tal sentido, con carácter enunciativo, pero no limitativo, los\n\t\t\tusuarios no deberán comunicar datos a través de la página web que no\n\t\t\tsean verdaderos, exactos, completos y/o actualizados, ni datos\n\t\t\tpersonales de terceros sin su consentimiento previo y expreso, ni\n\t\t\tutilizar la página web con fines fraudulentos o ilícitos.\n\t\t"
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n\t\t\tEn caso de incumplimiento de las presentes Condiciones, la Empresa se\n\t\t\treserva el derecho de denegar al usuario el acceso al servicio ofrecido.\n\t\t"
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n\t\t\tEsta página web ha sido elaborada con información procedente de fuentes\n\t\t\ttanto internas como externas, no garantizando la Empresa, por tanto, la\n\t\t\tdisponibilidad, la veracidad, la calidad, la exactitud o actualidad de\n\t\t\tla información que contiene, por lo que la Empresa declina cualquier\n\t\t\ttipo de responsabilidad por los daños y perjuicios que puedan causarse\n\t\t\tpor el uso de los servicios aquí contenidos.\n\t\t"
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n\t\t\tLa Empresa no se hace responsable de los daños y perjuicios que pudieran\n\t\t\tocasionar, a título enunciativo: causas de fuerza mayor o cualesquiera\n\t\t\totras causas ajenas a la misma; falta de disponibilidad de la página\n\t\t\tweb; errores u omisiones cometidos por el usuario en la introducción de\n\t\t\tsus datos; errores o deficiencias de seguridad producidos por parte del\n\t\t\tusuario; utilización de un navegador con una versión no actualizada o\n\t\t\tinsegura; daños y perjuicios que pudiera causar la información que se\n\t\t\ttransmite o comunique en sus sitios web o en sus comunicaciones\n\t\t\telectrónicas.\n\t\t"
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n\t\t\tQueda expresamente prohibida la creación de enlaces de hipertexto\n\t\t\t(links) a cualquier elemento integrante de esta web sin la autorización\n\t\t\tde la empresa.\n\t\t"
          ),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "seccion" }, [
          _vm._v("IV. Propiedad intelectual e industrial"),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n\t\t\tTodos los contenidos (marcas, logos, imágenes, documentación, códigos\n\t\t\tfuentes, programas informáticos, diseño gráfico o cualquier otro\n\t\t\telemento susceptible de protección por la legislación de propiedad\n\t\t\tintelectual o industrial) incorporados o accesibles desde esta web,\n\t\t\tcorresponden exclusivamente a la Empresa, la cual se reserva\n\t\t\texpresamente todos los derechos sobre los mismos.\n\t\t"
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n\t\t\tQueda prohibido la reproducción, distribución, comunicación pública,\n\t\t\ttransformación y, en general, cualquier otra forma de explotación de\n\t\t\ttodo o parte de los contenidos de este sitio web. Estos actos de\n\t\t\texplotación solo podrán ser realizados si media autorización expresa del\n\t\t\ttitular y siempre que se haga referencia explícita al titular de los\n\t\t\tderechos de propiedad intelectual e industrial.\n\t\t"
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n\t\t\tLa Empresa no concede ninguna licencia o autorización de uso sobre sus\n\t\t\tderechos de propiedad intelectual e industrial o sobre cualquier otra\n\t\t\tpropiedad o derecho relacionado con la página web, o los servicios y\n\t\t\tcontenidos de la misma.\n\t\t"
          ),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "seccion" }, [
          _vm._v(
            "\n\t\t\tV. Privacidad, protección de datos y comunicaciones comerciales\n\t\t"
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n\t\t\tLos datos del usuario que se recaben a través de la página web se usarán\n\t\t\ty se tratarán conforme a lo dispuesto en nuestra Política de privacidad.\n\t\t\tAsimismo, en dicha política se establecerá el procedimiento para darse\n\t\t\tde baja del envío de comunicaciones comerciales de nuestra entidad.\n\t\t"
          ),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "seccion" }, [_vm._v("VI. Notificaciones")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n\t\t\tCualquier comunicación, solicitud de información, petición, queja o\n\t\t\tsugerencia del usuario deberá realizarse por escrito a través de la\n\t\t\tsiguiente dirección info@xplosionmarkets.com\n\t\t"
          ),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "seccion" }, [_vm._v("VII. Modificaciones")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n\t\t\tLa Empresa se reserva la facultad de modificar en cualquier momento las\n\t\t\tpresentes Condiciones para adaptarlas a las novedades legislativas y\n\t\t\tjurisprudenciales y para mejorar el servicio de los usuarios. Por ello,\n\t\t\tel usuario se obliga a consultar las presentes Condiciones cuando acceda\n\t\t\ty/o utilice la misma.\n\t\t"
          ),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "seccion" }, [
          _vm._v("VIII. Suspensión del servicio"),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n\t\t\tLa Empresa podrá suspender temporalmente y, sin previo aviso, el acceso\n\t\t\ta la página web para realizar tareas de mantenimiento, reparación,\n\t\t\tactualización o mejora de la misma.\n\t\t"
          ),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "seccion" }, [
          _vm._v("IX. Legislación y jurisdicción competente"),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n\t\t\tLas presentes Condiciones de uso se rigen y se interpretan de acuerdo\n\t\t\tcon la ley española. Para cualquier reclamación serán competentes los\n\t\t\tjuzgados y tribunales de Madrid.\n\t\t"
          ),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }