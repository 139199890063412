var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "preregisterUser",
      staticClass: "modal fade",
      staticStyle: { "z-index": "99999" },
      attrs: {
        id: "preregisterUser",
        tabindex: "-1",
        role: "dialog",
        "aria-labelledby": "preregisterUserTitle",
        "aria-hidden": "true",
      },
    },
    [
      _c(
        "div",
        {
          class: "modal-dialog modal-dialog-scrollable modal-dialog-centered",
          attrs: { role: "" },
        },
        [
          _c("div", { staticClass: "modal-content" }, [
            _vm._m(0),
            _vm._v(" "),
            _c("div", { staticClass: "modal-body" }, [
              _c("div", [
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(1),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.user.name,
                        expression: "user.name",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      id: "name",
                      name: "name",
                      type: "text",
                      required: "",
                      autofocus: "",
                      autocomplete: "off",
                      placeholder: "Nombre",
                    },
                    domProps: { value: _vm.user.name },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.user, "name", $event.target.value)
                      },
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(2),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.user.surnames,
                        expression: "user.surnames",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      id: "surnames",
                      name: "surnames",
                      type: "text",
                      required: "",
                      autofocus: "",
                      autocomplete: "off",
                      placeholder: "Apellidos",
                    },
                    domProps: { value: _vm.user.surnames },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.user, "surnames", $event.target.value)
                      },
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(3),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _c("form-date", {
                        attrs: { id: "birthday", name: "birthday" },
                        model: {
                          value: _vm.user.birthday,
                          callback: function ($$v) {
                            _vm.$set(_vm.user, "birthday", $$v)
                          },
                          expression: "user.birthday",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "fv-plugins-message-container" }, [
                    _vm.date_message !== "" && !_vm.user_is_over_16_years
                      ? _c("div", { staticClass: "fv-help-block" }, [
                          _vm._v(
                            "\n                                " +
                              _vm._s(_vm.date_message) +
                              "\n                            "
                          ),
                        ])
                      : _vm._e(),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(4),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.user.email,
                        expression: "user.email",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      id: "email_register",
                      readonly: "",
                      disabled: "",
                      name: "email",
                      type: "email",
                      required: "",
                      autofocus: "",
                      autocomplete: "off",
                      placeholder: "Email",
                    },
                    domProps: { value: _vm.user.email },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.user, "email", $event.target.value)
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "fv-plugins-message-container" }, [
                    _vm.user.email !== "" && !_vm.user_email_is_valid
                      ? _c("div", { staticClass: "fv-help-block" }, [
                          _vm._v(
                            "La dirección\n                                introducida no es válida\n                            "
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.user.email !== "" &&
                    _vm.user_email_is_valid &&
                    _vm.user_email_exists
                      ? _c("div", { staticClass: "fv-help-block" }, [
                          _vm._v(
                            "\n                                La dirección introducida ya existe\n                            "
                          ),
                        ])
                      : _vm._e(),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(5),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.user.email_confirmation,
                        expression: "user.email_confirmation",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      readonly: "",
                      disabled: "",
                      id: "email_confirmation",
                      name: "email_confirmation",
                      type: "email",
                      required: "",
                      autofocus: "",
                      autocomplete: "off",
                      placeholder: "Confirmación de email",
                    },
                    domProps: { value: _vm.user.email_confirmation },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.user,
                          "email_confirmation",
                          $event.target.value
                        )
                      },
                    },
                  }),
                  _vm._v(" "),
                  _vm.user.email_confirmation !== "" &&
                  !_vm.user_email_confirmation_is_valid
                    ? _c(
                        "div",
                        { staticClass: "fv-plugins-message-container" },
                        [
                          _c("div", { staticClass: "fv-help-block" }, [
                            _vm._v("Las direcciones de correo no coinciden"),
                          ]),
                        ]
                      )
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(6),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.user.phone,
                        expression: "user.phone",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      id: "phone",
                      name: "phone",
                      type: "tel",
                      maxlength: "20",
                      required: "",
                      autofocus: "",
                      autocomplete: "off",
                      placeholder: "Teléfono",
                    },
                    domProps: { value: _vm.user.phone },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.user, "phone", $event.target.value)
                      },
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(7),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.user.zipcode,
                        expression: "user.zipcode",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      id: "zipcode",
                      name: "zipcode",
                      type: "text",
                      maxlength: "5",
                      required: "",
                      autofocus: "",
                      autocomplete: "off",
                      placeholder: "Código postal",
                    },
                    domProps: { value: _vm.user.zipcode },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.user, "zipcode", $event.target.value)
                      },
                    },
                  }),
                  _vm._v(" "),
                  !_vm.user_zipcode_is_valid
                    ? _c("div", { staticClass: "fv-plugins-message-container" })
                    : _vm._e(),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "modal-footer" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  attrs: { disabled: !_vm.form_is_valid, type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.registerUser()
                    },
                  },
                },
                [_vm._v("Confirmar")]
              ),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-header" }, [
      _c("h5", { staticClass: "modal-title" }, [_vm._v("Registro de Usuario")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "font-weight-bold color-darkblue" }, [
      _vm._v("Nombre"),
      _c("sup", [_vm._v("*")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "font-weight-bold color-darkblue" }, [
      _vm._v("Apellidos"),
      _c("sup", [_vm._v("*")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "font-weight-bold color-darkblue" }, [
      _vm._v("Fecha de nacimiento"),
      _c("sup", [_vm._v("*")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "font-weight-bold color-darkblue" }, [
      _vm._v("Email"),
      _c("sup", [_vm._v("*")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "font-weight-bold color-darkblue" }, [
      _vm._v("Confirmación de email"),
      _c("sup", [_vm._v("*")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "font-weight-bold color-darkblue" }, [
      _vm._v("Teléfono"),
      _c("sup", [_vm._v("*")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "font-weight-bold color-darkblue" }, [
      _vm._v("Código postal"),
      _c("sup", [_vm._v("*")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }