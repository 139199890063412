var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _vm._m(0),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "d-flex flex-wrap justify-content-start" },
      _vm._l(_vm.array_songs_collection, function (song) {
        return _c(
          "div",
          {
            key: song.id,
            staticClass: "p-0 col-6 col-md-4 col-lg-3 px-5 pb-10",
          },
          [
            _c("router-link", { attrs: { to: { path: "/song/" + song.id } } }, [
              _c("div", [
                _c("img", {
                  staticStyle: { "object-fit": "cover" },
                  attrs: { src: "/files/images/music/" + song.img_music },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "p-4 d-block bg-blue" }, [
                  _c("div", [
                    _c("span", {
                      staticClass: "color-white font-weight-bold",
                      domProps: { textContent: _vm._s(song.name) },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "my-2 text-align-end" }, [
                    _c("span", {
                      staticClass: "h2 color-green",
                      domProps: { textContent: _vm._s(song.price + "€") },
                    }),
                  ]),
                ]),
              ]),
            ]),
          ],
          1
        )
      }),
      0
    ),
    _vm._v(" "),
    _vm.hidden_button
      ? _c(
          "div",
          {
            staticClass:
              "d-flex justify-content-center align-items-center my-4",
          },
          [
            _vm._v("\n        >\n        "),
            _c(
              "button",
              {
                staticClass: "btn btn-green-secondary px-4",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.showMore()
                  },
                },
              },
              [_vm._v("\n            Ver más\n        ")]
            ),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "d-flex align-items-center my-4" }, [
      _c("span", { staticClass: "mr-6 color-white font-weight-bold" }, [
        _vm._v("Canciones"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }