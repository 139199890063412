<template>
    <div class="d-flex flex-column">
        <div class="container my-5">
            <p>
                La presente política de cookies tiene por finalidad informarle
                sobre las cookies que se utilizan en la página web
                <a href="www.nftmyticket.com" target="_blank"
                    >www.nftmyticket.com</a
                >, propiedad de NFT MY TICKET, S.L con N.I.F. B09863408 y
                domicilio en Plaza Isabel II, 5, ºC, 28013, Madrid.
            </p>

            <p>
                Esta web no instala nuestras cookies en los dispositivos de los
                usuarios hasta que estos hayan aceptado la instalación de éstas.
            </p>

            <p>
                Le informamos que en el caso de no aceptar la instalación de
                cookies o de deshabilitar alguna de estas en la configuración,
                es posible que ciertos servicios no estén disponibles sin la
                utilización de estas o que no pueda acceder a determinados
                servicios ni tampoco aprovechar por completo todo lo que este
                sitio web le ofrece.
            </p>

            <p class="seccion">¿Qué son y para qué sirven las cookies?</p>

            <p>
                Una cookie es un fichero que se descarga en su ordenador y que
                permite que el sitio web recuerde información sobre su visita,
                su idioma y otras opciones, con el fin de personalizar los
                servicios ofrecidos a los usuarios, optimizar el funcionamiento
                de nuestra página y facilitar la experiencia de navegación del
                usuario.
            </p>

            <p class="seccion">¿Qué tipos de cookies hay?</p>

            <p>Según quién sea la entidad que las gestione:</p>

            <p>
                - Cookies propias: Se envían al equipo terminal del usuario
                desde un equipo o dominio gestionado por el propio editor y
                desde el que se presta el servicio solicitado por el usuario.
            </p>

            <p>
                - Cookies de tercero: Se envían al equipo terminal del usuario
                desde un equipo o dominio que no es gestionado por el editor,
                sino por otra entidad que trata los datos obtenidos través de
                las cookies.
            </p>

            <p>
                Según la finalidad para la que se traten los datos obtenidos a
                través de las cookies:
            </p>

            <p>
                - Cookies técnicas: Permiten al usuario la navegación a través
                de una página web, aplicación o plataforma y la utilización de
                las diferentes servicios que en ella existan como, por ejemplo,
                identificar la sesión, realizar un pedido o solicitud de
                inscripción, almacenar contenidos o compartir contenidos a
                través de redes sociales.
            </p>

            <p>
                - Cookies de personalización: Permiten al usuario acceder al
                servicio con algunas características de carácter general
                predefinidas por el usuario como el idioma, el tipo de navegador
                a través del cual accede al servicio, etc.
            </p>

            <p>
                - Cookies de análisis: Permiten al responsable de las mismas
                seguir y analizar del comportamiento de los usuarios de los
                sitios web a los que están vinculadas. La información recogida
                se utiliza en la medición de la actividad de los sitios web,
                aplicación o plataforma y para la elaboración de perfiles de
                navegación de los usuarios de dichos sitios, aplicaciones y
                plataformas, con el fin de introducir mejoras en función del
                análisis de los datos de uso que hacen los usuarios del
                servicio.
            </p>

            <p>
                - Cookies publicitarias: Son aquéllas que permiten la gestión,
                de la forma más eficaz posible, de los espacios publicitarios
                que, en su caso, el editor haya incluido en una página web,
                aplicación o plataforma desde la que presta el servicio
                solicitado en base a criterios como el contenido editado o la
                frecuencia en la que se muestran los anuncios.
            </p>

            <p>
                - Cookies de publicidad comportamental: Son aquéllas que
                permiten la gestión de los espacios publicitarios que, en su
                caso, el editor haya incluido en una página web, aplicación o
                plataforma. Estas cookies almacenan información del
                comportamiento de los usuarios obtenida a través de la
                observación continuada de sus hábitos de navegación, lo que
                permite desarrollar un perfil específico para mostrar publicidad
                en función del mismo.
            </p>

            <p>Según el período de tiempo que permanecen activadas:</p>

            <p>
                - Cookies de sesión: Son un tipo de cookies diseñadas para
                recabar y almacenar datos mientras el usuario accede a una
                página web.
            </p>

            <p>
                - Cookies persistentes: Son un tipo de cookies en el que los
                datos siguen almacenados en el terminal y pueden ser accedidos y
                tratados durante un periodo definido por el responsable de la
                cookie, y que puede ir de unos minutos a varios años.
            </p>

            <p class="seccion">¿Qué cookies utilizamos en nuestra web?</p>

            <p>
                De conformidad con la Ley 34/2002, de 11 de julio, de servicios
                de la sociedad de la información y de comercio electrónico (en
                adelante, "LSSI"), le informamos que el presente Sitio Web
                utiliza cookies u otros dispositivos de almacenamiento y
                recuperación de información para realizar un seguimiento de las
                interacciones de los Usuarios con los servicios ofrecidos en el
                Sitio Web.
            </p>

            <p>
                Las cookies permiten reconocer el navegador de un Usuario, así
                como el tipo de dispositivo desde el que se accede al Sitio Web
                y se utilizan para facilitar la próxima visita del Usuario y
                hacer que el Sitio Web le resulte más útil.
            </p>

            <p>El titular de la web utiliza las cookies para:</p>

            <p>
                (i) Optimizar la navegación del Usuario mediante el seguimiento
                de la sesión.
            </p>
            <p>
                (ii) Analizar y recopilar información sobre el comportamiento de
                navegación de los Usuarios para mejorar el Sitio Web.
            </p>

            <p>El Sitio Web utiliza los siguientes tipos de cookies:</p>

            <p>
                Técnicas o de sesión: Cookies estrictamente necesarias para
                prestar un servicio o contenido solicitado por el usuario. éstas
                se encargan de aspectos técnicos como identificar registros y
                gestionar preferencias de herramientas en el uso, interacción y
                localización del acceso a los contenidos.
            </p>
            <p>
                Estadísticas/analíticas: Estas cookies recogen información
                relativa al uso de la página como la permanencia en página y los
                contenidos visitados por los lectores para medir su rendimiento
                y mejorar la navegación.
            </p>

            <p>
                Los objetivos principales que se persiguen con este tipo de
                cookies son:
            </p>

            <p>
                Permitir la identificación anónima de los Usuarios navegantes y
                por lo tanto la contabilización aproximada del número de
                visitantes.
            </p>
            <p>Identificar de forma anónima los contenidos más visitados.</p>
            <p>
                Saber si el Usuario que está accediendo es nuevo o repite
                visita.
            </p>

            <p>
                A continuación se identifican las cookies que están siendo
                utilizadas en esta página web así como su tipología y función:
            </p>

            <div class="table-container">
                <table class="table">
                    <thead>
                        <tr>
                            <th>NOMBRE</th>
                            <th>FINALIDAD</th>
                            <th>DURACIÓN</th>
                            <th>TITULAR</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td scope="row">Laravel Cross Script Reference</td>
                            <td>
                                This cookie is provided by the Laravel framework
                                in order to prevent the cross-scripting attacks.
                            </td>
                            <td>2 hours</td>
                            <td>NFT My Ticket</td>
                        </tr>
                        <tr>
                            <td scope="row">Laravel Session</td>
                            <td>
                                This cookie is provided by the Laravel framework
                                in order to handle sessions
                            </td>
                            <td>2 hours</td>
                            <td>NFT My Ticket</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <p class="seccion">
                ¿Cómo puedo modificar la configuración de las cookies?
            </p>

            <p>
                Aquellas cookies que no sean obligatorias para la navegación del
                presente Sitio Web podrán deshabilitarse en la configuración del
                Sitio Web. Estos ajustes se encuentran en el pie de página del
                Sitio Web. Además, todos los navegadores permiten hacer cambios
                para desactivar la configuración de las cookies.
            </p>

            <p>
                Este es el motivo por el que la mayoría de navegadores ofrecen
                la posibilidad de administrar las cookies, para obtener un
                control más preciso sobre la privacidad. Estos ajustes se
                encuentran ubicados en las "opciones" o "preferencias" del menú
                de su navegador.
            </p>

            <p>
                A continuación, podrá encontrar los links de cada navegador para
                deshabilitar las cookies siguiendo las instrucciones:
            </p>

            <div class="d-flex flex-column">
                <a
                    href="https://support.microsoft.com/es-es/windows/eliminar-y-administrar-cookies-168dab11-0753-043d-7c16-ede5947fc64d"
                    target="_blank"
                    >- Internet Explorer</a
                >
                <a
                    href="https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias?redirectlocale=es&redirectslug=habilitar-y-deshabilitar-cookies-que-los-sitios-we"
                    target="_blank"
                    >- Mozilla Firefox</a
                >
                <a
                    href="https://support.google.com/accounts/answer/61416?hl=es"
                    target="_blank"
                    >- Google Chrome</a
                >
                <a
                    href="https://support.apple.com/es-es/guide/safari/sfri11471/mac"
                    target="_blank"
                    >- Safari</a
                >
            </div>

            <p class="seccion">Cookies en dispositivos móviles</p>
            <p>
                El titular de la web también usa cookies u otros dispositivos de
                almacenamiento en dispositivos móviles.
            </p>
            <p>
                Aquellas cookies que no sean obligatorias para la navegación del
                presente Sitio Web podrán deshabilitarse en la configuración del
                Sitio Web.
            </p>
            <p>
                Estos ajustes se encuentran en el pie de página del Sitio Web.
                Además, al igual que sucede en los navegadores de ordenadores,
                lo navegadores de los dispositivos móviles permiten realizar
                cambios en las opciones o ajustes de privacidad para desactivar
                o eliminar las cookies.
            </p>
            <p>
                Si desea modificar las opciones de privacidad siga las
                instrucciones especificadas por el desarrollador de su navegador
                para dispositivo móvil.
            </p>
            <p>
                A continuación, podrá encontrar algunos ejemplos de los links
                que le guiarán para modificar las opciones de privacidad en su
                dispositivo móvil:
            </p>

            <div class="d-flex flex-column mb-3">
                <a
                    href="https://support.apple.com/es-es/HT201265"
                    target="_blank"
                    >- IOS</a
                >
                <a
                    href="https://support.microsoft.com/es-es/windows/finalizaci%C3%B3n-del-soporte-de-windows-phone-8-1-preguntas-frecuentes-7f1ef0aa-0aaf-0747-3724-5c44456778a3"
                    target="_blank"
                    >- Windows Phone</a
                >
                <a
                    href="https://support.microsoft.com/es-es/windows/finalizaci%C3%B3n-del-soporte-de-windows-phone-8-1-preguntas-frecuentes-7f1ef0aa-0aaf-0747-3724-5c44456778a3"
                    target="_blank"
                    >- Chrome Mobile</a
                >
                <a
                    href="https://help.opera.com/en/latest/web-preferences/"
                    target="_blank"
                    >- Opera Mobile</a
                >
            </div>
            <p>
                Si tienes cualquier duda sobre las cookies que utilizamos puede
                consultarnos a través de nuestra dirección de correo electrónico
                info@nftmyticket.com.
            </p>
        </div>
    </div>
</template>

<script>
export default {
};
</script>

<style lang="scss" scoped>
p,
h1 {
    color: white;

    &.seccion {
        margin-top: 3rem;
        font-weight: bold;
    }
}

.table-container {
    max-width: 100vw;
    overflow-x: auto;

    table {
        color: white;
        text-align: center;
    }
}
a {
    color: #009f9b;
}

#modal-aceptar-cookies {
    p {
        color: #1e1e1e;
    }
}
</style>
