var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "d-flex flex-column" }, [
      _c("div", { staticClass: "container my-5" }, [
        _c("p", [
          _vm._v(
            "\n\t\t\tNFT My Ticket Login es una aplicación de inicio de sesión de Facebook y no\n\t\t\tguardamos sus datos personales en nuestro servidor. De acuerdo con la\n\t\t\tpolítica de Facebook, debemos proporcionar la URL de devolución de\n\t\t\tllamada de eliminación de datos del usuario o la URL de instrucciones de\n\t\t\teliminación de datos.\n\t\t"
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n\t\t\tSi desea eliminar sus actividades para la aplicación de inicio de sesión\n\t\t\tNFT My Ticket, puede eliminar su información siguiendo estos pasos:\n\t\t"
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            '\n\t\t\t1. Vaya a Configuración y privacidad de su cuenta de Facebook. Haga clic\n\t\t\ten "Configuración"\n\t\t'
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            '\n\t\t\t2. Busque "Aplicaciones y sitios web" y verá todas las aplicaciones y\n\t\t\tsitios web que vinculó con su Facebook.\n\t\t'
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            '3. Busque y haga clic en "NFT My Ticket" en la barra de búsqueda.'
          ),
        ]),
        _vm._v(" "),
        _c("p", [_vm._v('4. Desplácese y haga clic en "Eliminar".')]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n\t\t\t5. Felicitaciones, ha eliminado con éxito las actividades de su\n\t\t\taplicación.\n\t\t"
          ),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }