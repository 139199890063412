<template>
    <div
        class="modal fade"
        ref="ticketRenamingBuyModal"
        id="ticketRenamingBuyModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="ticketRenamingBuyModalTitle"
        aria-hidden="true"
        style="z-index: 99999"
    >
        <div
            :class="!is_login ? 'modal-dialog modal-dialog-scrollable modal-dialog-centered modal-lg' : 'modal-dialog modal-dialog-scrollable modal-dialog-centered'"
            role="document">
            <form
                :id="'form_buy'"
                :action="redsys_url"
                method="POST"
            >
                <input
                    id="Ds_SignatureVersion"
                    type="hidden"
                    name="Ds_SignatureVersion"
                    v-model="redsys_ds_signature_version"
                />
                <input
                    id="Ds_MerchantParameters"
                    type="hidden"
                    name="Ds_MerchantParameters"
                    value=""
                />
                <input id="Ds_Signature" type="hidden" name="Ds_Signature" value=""/>
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Reasignar entradas</h5>
                    </div>


                    <div class="modal-body">

                        <div class="d-flex flex-wrap">
                            <!-- Personal data -->
                            <div class="col-12 col-md-6" v-if="!is_login">
                                <BuyUserRegistration @onUserLogin="(reload) => this.getInfoUser()"
                                                     @onUserChanged="this.setUser"/>
                            </div>
                            <!-- Payment summary -->
                            <div :class="!is_login ? 'col-12 col-md-6' : 'col-12'">
                                <TicketRenamingCartSummary v-if="Object.keys(sale_tickets).length>0"
                                                @onCartChanged="(payload) => this.cart = payload"
                                                :sale_tickets="sale_tickets"
                                                :usdt_value="parseFloat(value_usdt)"
                                                :user_credit="user_credit"/>

                                <div class="form-check">
                                    <input
                                        type="checkbox"
                                        class="form-check-input"
                                        id="terms-and-conditions"
                                        v-model="terms_and_conditions"
                                    />
                                    <label class="form-check-label" for="terms-and-conditions">
                                        Acepto los
                                        <router-link
                                            style="font-weight: bold;"
                                            :to="{ path: '/terms-and-conditions' }"
                                        >
                                            términos y condiciones
                                        </router-link>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="modal-footer justify-content-between">
                        <button
                            type="button"
                            id="cancel_modal_buy"
                            @click="closeCheckout"
                            class="btn btn-cancel btn-cancel-buy"
                            data-dismiss="modal"
                        >
                            Cancelar
                        </button>
                        <button
                            v-if="!loading"
                            type="button"
                            v-on:click="payRename()"
                            class="btn btn-blue"
                            :disabled="!is_valid_user"
                        >
                            {{ parseFloat(totalPrice).toFixed(2) }}€ - Comprar
                        </button>
                        <button
                            v-else
                            type="button"
                            class="btn btn-blue d-flex align-items-center"
                        >
                            Cargando
                            <div class="loading-transaction"></div>
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>

</template>

<script>
import BuyUserRegistration from "./Event/BuyUserRegistration";
import json_abi_polygon from "../../../../public/abi_usdt_polygon.json";
import TicketRenamingCartSummary from "./TicketRenamingCartSummary.vue";
export default {
    name: "TicketRenamingCheckout",
    components: {
        TicketRenamingCartSummary,
        BuyUserRegistration,
    },
    props: {
        sale_tickets: {
            type: Object,
            required: true
        },
        value_usdt: {
            type: Number,
            required: true
        }
    },

    computed: {
        totalPrice() {
            return this.cart.total_pending
        }
    },
    data: function () {
        return {
            redsys_url: window.REDSYS_URL,
            redsys_ds_signature_version: window.REDSYS_DS_SIGNATUREVERSION ?? 'HMAC_SHA256_V1',
            is_login: window.Laravel.isLoggedin,
            is_valid_user: window.Laravel.isLoggedin,
            user_credit: 0,
            loading: false,
            account_wallet: "",
            abi: JSON.stringify(json_abi_polygon),
            web3: "",
            terms_and_conditions: false,
            cart: {...this.sale_tickets},
            user: {}
        }
    },
    methods: {
        setUser(valid, newUser){
            this.is_valid_user = valid;
            this.user = newUser
        },
        closeCheckout() {
            $("#ticketRenamingBuyModal").modal("hide");
        },
        getInfoUser() {
            let me = this;
            axios
                .get("/get_info_user")
                .then(function (response) {
                    if (response.data.code == 1000) {
                        me.user_credit = response.data.gift;
                        me.is_login = true;
                        me.is_valid_user = true;
                        me.Laravel = {
                            isLoggedin: true,
                            user: response.data.user
                        }
                    }
                })
                .catch(function (error) {
                    console.error(error);
                });
        },
        async payRename() {
            let is_valid = true;

            if (this.cart.consumed_credit > this.user_credit) {
                swal("", "No puede usar más saldo del disponible", "warning");
                this.cart.consumed_credit = this.user_credit;
                is_valid = false;
            }

            if (this.cart.total_pending < this.cart.consumed_credit) {
                swal("", "No puede descontar más del total de los tickets", "warning");
                is_valid = false;
            }

            if (this.cart.consumed_credit < 0) {
                swal("", "El saldo regalo no es válido", "warning");
                is_valid = false;
            }

            if (!this.terms_and_conditions) {
                swal("", "Debe aceptar nuestros términos y condiciones", "warning");
                is_valid = false;
            }

            if (is_valid) {
                this.loading = true;
                //Register user if not loggedin
                try {
                    if (!this.is_login) {
                        let userRegistrationResponse = await axios.post('/preregister_user', this.user)
                        if (userRegistrationResponse && userRegistrationResponse.data.code === 1000) {
                            localStorage.setItem("send-confirmation-email-on-finish", "true");
                            this.is_login = true;
                            this.Laravel = {
                                isLoggedin: true,
                                user: userRegistrationResponse.data.user
                            }
                            this.reloadAfterLogin = true;
                        }
                    }
                    const me = this;
                    if (this.totalPrice === 0) {
                        this.payRenameFree();
                    } else {
                        localStorage.setItem("show-transaction-success-alert", "true");
                        switch (me.cart.payment_method) {
                            case 1: //Card
                                me.payRenameCard();
                                break;
                        }
                    }

                } catch (error) {
                    console.error(error);
                    if (error.response?.data?.message.length) {
                        swal("", error.response.data.message, "warning");
                    } else {
                        swal(
                            "",
                            "Parece que ha habido un error, intentalo de nuevo más tarde",
                            "warning"
                        );
                    }
                }
                this.loading = false;
            }
        },

        payRenameCard() {
            let me = this;
            const params = {...this.cart}
            axios
                .post("/pay_ticket_rename_redsys", params)
                .then((response) => {
                    this.loading = false;
                    if (response.data.code == 1000) {
                        document.getElementById("Ds_MerchantParameters").value =
                            response.data.Ds_MerchantParameters;
                        document.getElementById("Ds_Signature").value =
                            response.data.Ds_Signature;
                        document.getElementById("form_buy").submit();
                        this.$emit('onTransaccionSuccess', response.data.transaction_id)
                    } else if (response.data.code > 1000) {
                        swal(
                            "",
                            "Parece que ha habido un error, intentalo de nuevo más tarde",
                            "warning"
                        );
                    }
                })
                .catch(function (error) {
                    this.loading = false;
                    console.error(error);
                });
        },
        payRenameFree() {
            let me = this;
            const params = {...this.cart}
            axios
                .post("/pay_ticket_rename_free", params)
                .then((response) => {
                    this.loading = false;
                    swal(
                        "",
                        "Asignación realizada correctamente. Muchas gracias.",
                        "success"
                    );

                    $("#cancel_modal_buy").click();
                    // this.getInfoUser();
                    this.saldo_regalo_a_usar = "";
                })
                .catch(function (error) {
                    this.loading = false;
                    swal(
                        "",
                        "Ha ocurrido un error. Inténtelo de nuevo más tarde",
                        "error"
                    );
                    console.error(error);
                });
        },
    },
}
</script>

<style scoped>

</style>
