<template>
    <div
        class="modal fade"
        ref="ticketRenaming"
        id="ticketRenaming"
        tabindex="-1"
        role="dialog"
        data-backdrop="static"
        data-keyboard="false"
        aria-labelledby="ticketRenamingTitle"
        aria-hidden="true"
        style="z-index: 99999"
    >
        <div
            :class="'modal-dialog modal-dialog-scrollable modal-dialog-centered modal-lg'"
            role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Asignación de Tickets</h5>
                </div>

                <div class="modal-body" v-if="sale_tickets && sale_tickets.length>0">
                    <AdminEventTicketRenaming @onFormChanged="updateData" :sale_tickets="Object.values(sale_tickets)"/>
                </div>
                <div class="modal-footer">
                    <button v-if="sale_tickets && sale_tickets.length>0" type="button" @click="payDialog()"
                            :disabled="loading" class="btn btn-primary">
                        {{ loading ? 'Procesando...' : payAmount + '€ - Confirmar' }}
                    </button>
                    <button type="button" @click="closeDialog()" class="btn btn-danger">Cerrar</button>
                </div>

            </div>
        </div>
        <TicketRenamingCheckout @onTransaccionSuccess="(id_transaction) => setTransaction(id_transaction)"
                                :value_usdt="parseFloat('0.0')" :sale_tickets="data"/>

    </div>
</template>

<script>
import AdminEventTicketRenaming from "./AdminEventTicketRenaming.vue";
import BuyCheckout from "./Event/BuyCheckout.vue";
import TicketRenamingCheckout from "./TicketRenamingCheckout.vue";

export default {
    name: "TicketRenamingDialog",
    components: {
        TicketRenamingCheckout,
        BuyCheckout,
        AdminEventTicketRenaming
    },
    props: {
        sale_tickets: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            is_form_valid: false,
            loading: false,
            data: {},
            transaction_id: 0
        }
    },
    mounted() {
        document.getElementById('ticketRenaming').focus()
        $('#ticketRenaming').modal({backdrop: 'static', keyboard: false})
        $("#ticketRenaming").modal('show');
    },
    updated() {

    },
    computed: {
        payAmount() {
            let amount = Object.values(this.data)
                .filter(c => c.new_user_id || c.new_user_email)
                .reduce((p, c, i) => p + c.rename_price + c.rename_commission, 0);
            return amount;
        }
    },
    methods: {
        getErrorMsg() {
            let msg = Object.keys(this.data)
                .filter(i => !this.data[i].new_user_id)
                .map((i, x) => {
                    const d = this.data[i]
                    let msg = "";
                    if (d) {
                        if (d.new_user_email && !d.new_user_id) {
                            msg = "Debe introducir los datos del usuario con email " + d.new_user_email;
                        } else if(! d.new_user_email){
                            msg = 'Indique el email para la entrada ' + (x+1);
                        } else if(d.new_user_email.length > 0) {
                            msg = d.new_user_email + " email no válido";
                        } else {
                            msg = 'Indique el usuario para la entrada ' + (x+1);
                        }
                    }
                    return msg;
                })
            console.log(msg)
            return msg.join("\n");
        },
        payDialog() {
            if (this.is_form_valid) {
                if (this.payAmount > 0) {
                    $('#ticketRenamingBuyModal').modal('show')

                } else {
                    this.payRenameFree()
                }
            } else {
                swal(
                    "Datos incompletos",
                    this.getErrorMsg(),
                    "error"
                );
            }

        },
        payRenameFree() {
            this.loading = true
            const params = {sale_tickets: this.data, consumed_credit: 0, total_pending: 0, payment_method: 4}
            axios
                .post("/pay_ticket_rename_free", params)
                .then((response) => {
                    this.loading = false;
                    swal(
                        "",
                        "Asignación realizada correctamente. Muchas gracias.",
                        "success"
                    );

                    $("#cancel_modal_buy").click();
                    // this.getInfoUser();
                    this.saldo_regalo_a_usar = "";
                    window.location.reload()
                })
                .catch(function (error) {
                    this.loading = false;
                    swal(
                        "",
                        "Ha ocurrido un error. Inténtelo de nuevo más tarde",
                        "error"
                    );
                    console.error(error);
                });
        },
        setTransaction(transaction) {
            this.transaction_id = transaction;
            this.$emit('onTransaccionSuccess', this.transaction_id)
        },
        updateData(valid, data) {
            this.is_form_valid = valid;
            this.data = data;
        },
        closeDialog() {
            $("#ticketRenaming").modal("hide");
            this.$emit("onFinishRename", true)
        }
    }

}
</script>

<style scoped>
#ticketRenaming {
    color: black !important;
}
</style>
