<template>
	<div
		id="restaurant-front"
		v-if="restaurant != null"
		class="transform-content"
	>
		<div class="bg-header-general">
			<div class="container pt-4">
				<div class="col-md-6 d-flex align-items-end">
					<img
						:src="`/images/restaurants/${restaurant.file}`"
						style="object-fit: cover; border-radius: 0.5rem"
						width="200px"
						height="200px"
					/>
					<div class="p-4">
						<h2 class="color-white font-weight-bolder">
							{{ restaurant.name }}
						</h2>
					</div>
				</div>
			</div>
		</div>
		<div class="container">
			<div class="d-flex flex-wrap">
				<div class="col-12 col-md-6">
					<div class="p-2">
						<div class="d-flex mt-4 mb-6">
							<p
								v-html="restaurant.description"
								class="color-white description-event-file"
							></p>
						</div>

						<div class="mt-10">
							<span class="color-white font-weight-bolder">Cómo llegar</span>
							<div class="my-2">
								<div id="map"></div>
							</div>
						</div>
					</div>
				</div>

				<div id="realiza-tu-compra" class="col-12 col-md-6 position-relative">
					<div class="custom-card-absolute">
						<div class="card gutter-b">
							<div class="card-header bg-color-white border-0 pb-0">
								<div class="card-title m-0">
									<h5 class="card-label font-weight-bolder color-darkblue">
										Realiza tu reserva
									</h5>
								</div>
							</div>
							<div class="card-body pt-0">
								<!-- Date -->
								<div class="form-group">
									<label class="font-weight-bold color-darkblue"
										>Selecciona una fecha:</label
									>
									<input
										v-model="date"
										:min="minDate"
										type="date"
										class="form-control"
									/>
									<span
										v-if="showRestaurantOpensOn"
										class="text-small small text-muted"
										>{{ restaurantOpensOn }}</span
									>
								</div>

								<!-- Table -->
								<div
									class="form-group"
									:class="[
										date != null && pickTable && tables.length ? '' : 'd-none',
									]"
								>
									<label class="font-weight-bold color-darkblue"
										>Selecciona una mesa:</label
									>
									<select
										v-model="table"
										id="tables"
										class="form-control selectpicker w-100"
										data-style="select-lightgreen"
									>
										<option
											v-for="(table, index) in tables"
											:key="index"
											:value="table"
										>
											{{ tableNameWithPrice(table) }}
										</option>
									</select>
									<span
										v-if="table != null"
										class="text-small small text-muted"
										>{{ tableDiners }}</span
									>
								</div>

								<div
									:class="[
										distributionImage != null && date != null
											? 'd-flex'
											: 'd-none',
									]"
									class="justify-content-center form-group"
								>
									<img
										id="image-preview"
										class="rounded cursor-pointer"
										style="max-width: 100%; max-height: 8rem"
										:src="distributionImage"
										@click="showZoom = !showZoom"
									/>
								</div>

								<!-- Hour -->
								<div
									class="form-group"
									:class="[hours.length && date != null ? '' : 'd-none']"
								>
									<label class="font-weight-bold color-darkblue"
										>Selecciona una hora:</label
									>
									<select
										v-model="hour"
										id="hours"
										class="form-control selectpicker w-100"
										data-style="select-lightgreen"
									>
										<option
											v-for="(hour, index) in hours"
											:key="index"
											:value="hour"
										>
											{{ hour }}
										</option>
									</select>
									<span
										v-if="!pickTable && maxTableDiners != null"
										class="text-small small text-muted"
										>{{ maxTableDiners }}</span
									>
								</div>

								<!-- People -->
								<div
									class="form-group"
									:class="[
										!pickTable && date != null && !showRestaurantOpensOn
											? ''
											: 'd-none',
									]"
								>
									<label class="font-weight-bold color-darkblue"
										>Indica el número de personas:</label
									>
									<input
										id="people"
										name="people"
										type="text"
										value="1"
										class="form-control text-align-end"
									/>
								</div>

								<!-- Use gift amount -->
								<div
									v-if="
										date &&
										hour &&
										table &&
										!showRestaurantOpensOn &&
										userGift != null &&
										saveButtonText != 'Reservar'
									"
									class="form-group"
								>
									<label class="font-weight-bold color-darkblue"
										>Saldo de regalo a usar:</label
									>
									<input
										v-model="gift_amount"
										class="form-control text-align-end"
										placeholder="0€"
									/>
									<label class="w-100 text-muted"
										>Puedes usar hasta {{ userGift }}</label
									>
								</div>

								<!-- Save button -->
								<div
									v-if="date && hour && table && !showRestaurantOpensOn"
									class="d-flex justify-content-center mt-3"
								>
									<button
										@click="sendForm()"
										type="button"
										class="btn btn-blue"
									>
										{{ saveButtonText }}
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<form
			id="buy-form"
			action="https://sis-t.redsys.es:25443/sis/realizarPago"
			method="POST"
			class="d-none"
		>
			<input type="hidden" name="Ds_SignatureVersion" />
			<input type="hidden" name="Ds_MerchantParameters" />
			<input type="hidden" name="Ds_Signature" />
		</form>
		<div id="zoom-image" v-if="showZoom" @click="showZoom = !showZoom">
			<div class="image">
				<img
					class="close cursor-pointer"
					src="/svg/restaurants/x.png"
					width="20"
				/>
				<img
					class="rounded cursor-pointer"
					style="max-width: 100%; max-height: 70vh"
					:src="distributionImage"
				/>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			restaurant: null,
			date: null,
			hours: [],
			hour: null,
			tables: [],
			table: null,
			timetable: null,
			people: 1,
			reservations: [],
			restaurantOpensOn: [],
			showRestaurantOpensOn: false,
			pickTable: false,
			maxDinersPerHour: [],
			distributionImage: null,
			showZoom: false,
			gift_amount: null,
			user_has_gift: null,
			tablesHours: [],
		};
	},
	methods: {
		getRestaurant() {
			axios
				.get(`/get_restaurant/${this.$route.params.id}`)
				.then((response) => {
					this.restaurant = response.data.restaurant;

					if (this.restaurant.distribution == null) {
						this.$router.push({ path: "/" });
					}

					if (this.restaurant.distribution.file_src) {
						this.distributionImage =
							"/public/distributions/" + this.restaurant.distribution.file;
					}

					if (
						this.restaurant.distribution.tables_prices != null &&
						this.restaurant.distribution.tables_prices != "[]"
					) {
						this.pickTable = true;
					}

					this.formatRestaurantOpensOn();

					this.$nextTick(() => {
						this.initMap(
							parseFloat(this.restaurant.latitude),
							parseFloat(this.restaurant.longitude)
						);

						$("#hours").selectpicker("refresh");
						$("#tables").selectpicker("refresh");

						$("#people")
							.TouchSpin({
								buttondown_class: "btn btn-secondary",
								buttonup_class: "btn btn-secondary",
								postfix: "personas",
								min: 1,
								max: 8,
								step: 1,
								decimals: 0,
								boostat: 1,
								maxboostedstep: 1,
							})
							.on("change", (e) => {
								this.people = e.target.value;
							});
					});
				})
				.catch((error) => {
					console.error(error, error.response);

					if (
						error.response?.data?.error == "No se ha encontrado el restaurante"
					) {
						this.$router.push("/");
					}
				});
		},
		formatRestaurantOpensOn() {
			if (this.restaurant.distribution.monday_timetable != null) {
				this.restaurantOpensOn.push("Lunes");
			}

			if (this.restaurant.distribution.tuesday_timetable != null) {
				this.restaurantOpensOn.push("Martes");
			}

			if (this.restaurant.distribution.wednesday_timetable != null) {
				this.restaurantOpensOn.push("Miércoles");
			}

			if (this.restaurant.distribution.thursday_timetable != null) {
				this.restaurantOpensOn.push("Jueves");
			}

			if (this.restaurant.distribution.friday_timetable != null) {
				this.restaurantOpensOn.push("Viernes");
			}

			if (this.restaurant.distribution.saturday_timetable != null) {
				this.restaurantOpensOn.push("Sábado");
			}

			if (this.restaurant.distribution.sunday_timetable != null) {
				this.restaurantOpensOn.push("Domingo");
			}

			if (this.restaurantOpensOn.length > 1) {
				this.restaurantOpensOn.splice(
					this.restaurantOpensOn.length - 1,
					0,
					"y"
				);
			}

			this.restaurantOpensOn = this.restaurantOpensOn.join(", ");

			this.restaurantOpensOn =
				"El restaurante abre los " + this.restaurantOpensOn;

			this.restaurantOpensOn = this.restaurantOpensOn.replace(", y, ", " y ");
		},
		initMap(lat, lng) {
			const mapa = new google.maps.Map(document.getElementById("map"), {
				zoom: 10,
				center: { lat: parseFloat(lat), lng: parseFloat(lng) },
			});

			const marker = new google.maps.Marker({
				position: { lat: parseFloat(lat), lng: parseFloat(lng) },
				map: mapa,
			});

			google.maps.event.addListener(marker, "click", function () {
				window
					.open("https://maps.google.com/?q=" + lat + "," + lng, "_blank")
					.focus();
			});
		},
		showAlertLogin() {
			swal(
				"",
				"Para realizar una reserva debes iniciar sesión o registrarte",
				"warning"
			);

			$(".perfil-hover").parent().click();
		},
		formatTables() {
			this.tables = [];

			for (
				let index = 1;
				index <= this.restaurant.distribution?.total;
				index++
			) {
				this.tables.push("Mesa " + index);
			}
		},
		selectedDate(weekday) {
			let timetable = null;

			switch (weekday) {
				case 0:
					timetable = this.restaurant.distribution.sunday_timetable;
					break;

				case 1:
					timetable = this.restaurant.distribution.monday_timetable;
					break;

				case 2:
					timetable = this.restaurant.distribution.tuesday_timetable;
					break;

				case 3:
					timetable = this.restaurant.distribution.wednesday_timetable;
					break;

				case 4:
					timetable = this.restaurant.distribution.thursday_timetable;
					break;

				case 5:
					timetable = this.restaurant.distribution.friday_timetable;
					break;

				case 6:
					timetable = this.restaurant.distribution.saturday_timetable;
					break;
			}

			if (timetable != null) {
				this.showRestaurantOpensOn = false;
				this.timetable = JSON.parse(timetable);
			} else {
				this.showRestaurantOpensOn = true;
				this.hours = [];
				this.timetable = null;
			}
		},
		getReservationsSelectedDate() {
			axios
				.post("/restaurant-reservations", {
					restaurant_id: this.$route.params.id,
					date: this.date,
				})
				.then((response) => {
					if (this.pickTable) {
						this.reservations = response.data.reservations;
						this.tablesHours = response.data.tablesHours;
					} else {
						this.hours = Object.keys(response.data.maxDinersPerHour);
						this.maxDinersPerHour = response.data.maxDinersPerHour;

						this.hour = this.hours[0];

						this.$nextTick(() => {
							$("#hours").selectpicker("refresh");
						});
					}

					this.formatHours();
				})
				.catch((error) => {
					console.error(error);
				});
		},
		formatHours(fromTableChange = false) {
			this.formatTables();

			if (this.timetable != null && this.pickTable) {
				let list = [];

				this.tablesHours.forEach((tableHours) => {
					if (tableHours.table == this.table) {
						list = tableHours.hours;
					}
				});

				this.hours = list;
				this.hour = this.hours[0];

				this.tablesHours.forEach((tableHour) => {
					this.tables.forEach((table, index) => {
						if (tableHour.table == table && tableHour.hours.length == 0) {
							this.tables.splice(index, 1);
						}
					});
				});

				this.$nextTick(() => {
					$("#hours").selectpicker("refresh");
				});
			}

			if (!fromTableChange) {
				this.table = this.tables[0];
			}

			this.$nextTick(() => {
				$("#tables").selectpicker("refresh");
			});
		},
		tableNameWithPrice(table) {
			if (
				this.restaurant.distribution.tables_prices != null &&
				this.restaurant.distribution.tables_prices != "[]"
			) {
				const tablesPrices = JSON.parse(
					this.restaurant.distribution.tables_prices
				);

				return `${table} - ${tablesPrices[table.split(" ")[1] - 1]} €`;
			} else {
				return table;
			}
		},
		dateChanged() {
			const weekday = new Date(this.date).getDay();

			this.formatTables();
			this.selectedDate(weekday);
			this.getReservationsSelectedDate();
		},
		payReservation(to) {
			axios
				.post("/pay-reservation", {
					restaurant_id: this.$route.params.id,
					table: this.table,
					people: parseInt(this.people),
					date: this.date,
					from: this.hour,
					to: to,
					user_id: window.Laravel.user.id,
					gift_amount: this.gift_amount,
				})
				.then((response) => {
					$('input[name="Ds_SignatureVersion"]').val(
						response.data.Ds_SignatureVersion
					);

					$('input[name="Ds_MerchantParameters"]').val(
						response.data.Ds_MerchantParameters
					);

					$('input[name="Ds_Signature"]').val(response.data.Ds_Signature);

					localStorage.setItem("show-transaction-success-alert", true);

					document.getElementById("buy-form").submit();
				})
				.catch(function (error) {
					console.error(error);

					swal(
						"",
						error.response.data[Object.keys(error.response.data)[0]],
						"error"
					);
				});
		},
		sendForm() {
			if (!window.Laravel.isLoggedin) {
				this.showAlertLogin();
				return;
			}

			// if (!this.pickTable) {
			if (this.maxDinersPerHour[this.hour] < this.people) {
				swal(
					"",
					`No hay suficientes plazas para ${this.people} personas a las ${this.hour}`,
					"error"
				);

				return;
			}
			// }

			if (this.gift_amount < 0) {
				swal("", `El importe del saldo regalo no puede ser negativo`, "error");

				return;
			}

			if (this.gift_amount > window.Laravel.user.gift) {
				swal(
					"",
					`No tienes suficiente saldo regalo (${this.userGift})`,
					"error"
				);

				return;
			}

			const amount = this.saveButtonText.split("€")[0];

			if (amount < 0) {
				swal("", `El importe a pagar no puede ser menor que 0`, "error");

				return;
			}

			let to = new Date();

			to.setHours(this.hour.split(":")[0]);
			to.setMinutes(this.hour.split(":")[1]);

			to.setMinutes(to.getMinutes() + this.restaurant.distribution?.duration);

			to = to.getHours() + ":" + to.getMinutes();

			if (to.split(":")[0] < 10) {
				to = "0" + to;
			}

			if (to.split(":")[1] < 10) {
				to = to.split(":")[0] + ":0" + to.split(":")[1];
			}

			if (amount > 0) {
				this.payReservation(to);
				return;
			}

			axios({
				method: "post",
				url: "/reservations",
				data: {
					restaurant_id: this.$route.params.id,
					table: this.table,
					people: this.people,
					date: this.date,
					from: this.hour,
					to: to,
					user_id: window.Laravel.user.id,
					gift_amount: this.gift_amount,
				},
			})
				.then(() => {
					this.dateChanged();
					swal("", "Se ha solicitado la reserva ", "success");
					this.getUser();
				})
				.catch((error) => {
					swal(
						"",
						error.response.data[Object.keys(error.response.data)[0]],
						"error"
					);
					console.error(error.response);
				});
		},
		getValueQuery() {
			if (
				localStorage.getItem("show-transaction-success-alert") != "false" &&
				localStorage.getItem("show-transaction-success-alert") != null &&
				this.$route.query.type != undefined &&
				this.$route.query.type == "success"
			) {
				swal(
					"Reserva realizada correctamente",
					"Hemos enviado a tu correo los detalles de la reserva",
					"success"
				);

				localStorage.setItem("show-transaction-success-alert", false);

				this.getUser();
			}
		},
		getUser() {
			axios
				.get("/get_user/" + window.Laravel.user.id)
				.then((response) => {
					this.user_has_gift = response.data.user.gift;
				})
				.catch(function (error) {
					console.error(
						"🚀 ~ file: RestaurantFront.vue:723 ~ getUser ~ error",
						error
					);
					this.error = response.data.message;
				});
		},
	},
	computed: {
		saveButtonText() {
			if (
				(this.restaurant.distribution.price == null ||
					this.restaurant.distribution.price == 0) &&
				(this.restaurant.distribution.tables_prices == null ||
					this.restaurant.distribution.tables_prices == "[]")
			) {
				return "Reservar";
			} else if (
				this.restaurant.distribution.tables_prices != null &&
				this.restaurant.distribution.tables_prices != "[]"
			) {
				if (this.table != null) {
					const tableIndex = this.table.split(" ")[1] - 1;
					const tablesPrices = JSON.parse(
						this.restaurant.distribution.tables_prices
					);

					return (
						parseFloat(
							tablesPrices[tableIndex] -
								(this.gift_amount != null && !isNaN(this.gift_amount)
									? this.gift_amount
									: 0)
						).toFixed(2) + "€ - Reservar"
					);
				} else {
					return "Reservar";
				}
			} else {
				return (
					parseFloat(
						this.restaurant.distribution.price.toFixed(2) -
							(this.gift_amount != null && !isNaN(this.gift_amount)
								? this.gift_amount
								: 0)
					).toFixed(2) + "€ - Reservar"
				);
			}
		},
		tableDiners() {
			if (Array.isArray(this.restaurant.tablesDiners)) {
				const table = this.table.split(" ")[1] - 1;

				return `Puedes reservar hasta para ${this.restaurant.tablesDiners[table]} comensales`;
			} else {
				return `Cada mesa tiena una capacidad de ${this.restaurant.tablesDiners} comensales`;
			}
		},
		maxTableDiners() {
			const maxDiners = this.maxDinersPerHour[this.hour];

			return `Puedes reservar hasta para ${
				maxDiners > 8 ? 8 : maxDiners
			} comensales`;
		},
		minDate() {
			const today = new Date();
			let dd = today.getDate();
			let mm = today.getMonth() + 1;
			const yyyy = today.getFullYear();

			if (dd < 10) {
				dd = "0" + dd;
			}

			if (mm < 10) {
				mm = "0" + mm;
			}

			return yyyy + "-" + mm + "-" + dd;
		},
		userGift() {
			if (this.user_has_gift != null) {
				return parseFloat(this.user_has_gift).toFixed(2) + "€";
			} else {
				return null;
			}
		},
	},
	mounted() {
		this.getRestaurant();
		this.getValueQuery();

		if (window.Laravel.isLoggedin) {
			this.user_has_gift = window.Laravel.user.gift;
		}
	},
	watch: {
		date: function () {
			if (this.date != null && this.date != "") {
				this.dateChanged();
			}
		},
		table: function () {
			this.formatHours(true);
		},
		gift_amount() {
			// Remove all non numeric characters, except the dot
			this.gift_amount = this.gift_amount.replace(/[^0-9.]/g, "");
		},
	},
};
</script>

<style lang="scss" scoped>
.loading-transaction {
	border: 3px solid white;
	border-top: 3px solid #bbbbbb;
	border-radius: 50%;
	width: 20px;
	height: 20px;
	margin-left: 0.5rem;
	animation: spin 2s ease infinite;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.selected-tickets {
	display: flex;
	flex-wrap: wrap;
	gap: 1rem;
	max-height: 5rem;
	overflow: auto;
}

.custom-image {
	border-radius: 0.5rem;
	object-fit: cover;
	width: 200px;
	height: 200px;
	margin: auto;
	cursor: pointer;
}
</style>

<style lang="scss">
.input-group-addon.bootstrap-touchspin-postfix.input-group-append {
	flex: 1 1 auto;
}

#restaurant-front {
	.bootstrap-select .dropdown-menu.show {
		max-height: 20rem !important;
	}

	.bootstrap-touchspin-postfix {
		span {
			padding: 0 !important;
		}
	}
}

#zoom-image {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.8);
	z-index: 9999999999999999999;
	display: flex;
	align-items: center;
	justify-content: center;

	.image {
		position: relative;

		.close {
			position: absolute;
			top: -2rem;
			right: -2rem;
		}
	}
}
</style>
