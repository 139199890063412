var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "p-4" }, [
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "justify-content-center" }, [
        _c("div", { staticClass: "wizard wizard-4" }, [
          _c("div", { staticClass: "wizard-nav" }, [
            _c(
              "div",
              {
                staticClass: "wizard-steps",
                attrs: { "data-total-steps": "3" },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "wizard-step cursor-pointer mycollection-tab",
                    attrs: {
                      "data-wizard-type": "step",
                      id: "tag_step1",
                      "data-wizard-state": "current",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.selectTab($event)
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "wizard-wrapper justify-content-center" },
                      [
                        _c("span", { staticClass: "font-weight-bold" }, [
                          _c("img", {
                            staticClass: "mx-3",
                            attrs: {
                              width: "35",
                              height: "auto",
                              src:
                                _vm.publicPath + "/svg/entradas-seleccion.svg",
                            },
                          }),
                          _vm._v("Entrada"),
                        ]),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "wizard-step cursor-pointer mycollection-tab",
                    attrs: {
                      "data-wizard-type": "step",
                      id: "tag_step2",
                      "data-wizard-state": "pending",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.selectTab($event)
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "wizard-wrapper justify-content-center" },
                      [
                        _c("span", { staticClass: "font-weight-bold" }, [
                          _c("img", {
                            staticClass: "mx-3",
                            attrs: {
                              width: "35",
                              height: "auto",
                              src: _vm.publicPath + "/svg/musica.svg",
                            },
                          }),
                          _vm._v("Música"),
                        ]),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "wizard-step cursor-pointer mycollection-tab",
                    attrs: {
                      "data-wizard-type": "step",
                      id: "tag_step3",
                      "data-wizard-state": "pending",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.selectTab($event)
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "wizard-wrapper justify-content-center" },
                      [
                        _c("span", { staticClass: "font-weight-bold" }, [
                          _c("img", {
                            staticClass: "mx-3",
                            attrs: {
                              width: "35",
                              height: "auto",
                              src: _vm.publicPath + "/svg/nft.svg",
                            },
                          }),
                          _vm._v("NFT"),
                        ]),
                      ]
                    ),
                  ]
                ),
              ]
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "card card-custom card-shadowless rounded-top-0 div-content-tabs",
            },
            [
              _c("div", { staticClass: "card-body" }, [
                _c(
                  "div",
                  {
                    staticClass: "step",
                    attrs: {
                      id: "step_events",
                      "data-wizard-type": "step-content",
                      "data-wizard-state": "current",
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "card card-custom shadow-none border-0" },
                      [
                        _c(
                          "div",
                          { staticClass: "card-body body-tab-step p-3" },
                          [_c("TableMyEventsComponent")],
                          1
                        ),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "step",
                    attrs: {
                      id: "step_songs",
                      "data-wizard-type": "step-content",
                      "data-wizard-state": "pending",
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "card card-custom shadow-none border-0" },
                      [
                        _c(
                          "div",
                          { staticClass: "card-body body-tab-step p-3" },
                          [_c("TableMySongsComponent", { ref: "mySongs" })],
                          1
                        ),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "step",
                    attrs: {
                      id: "step_nfts",
                      "data-wizard-type": "step-content",
                      "data-wizard-state": "pending",
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "card card-custom shadow-none border-0" },
                      [
                        _c(
                          "div",
                          { staticClass: "card-body body-tab-step p-3" },
                          [_c("GridMyNFTsComponent", { ref: "grid_my_nfts" })],
                          1
                        ),
                      ]
                    ),
                  ]
                ),
              ]),
            ]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }