<template>
    <div>
        <div
            id="accordionExample"
            class="row accordion accordion-solid accordion-toggle-plus"
        >
            <div
                class="col-12 col-md-6 card box-general-acordation"
                v-for="(help, index) in faqs"
                :key="index"
            >
                <div class="card-header" :id="'heading' + help.id">
                    <div
                        class="card-title justify-content-between box-question collapsed"
                        data-toggle="collapse"
                        :data-target="'#a-element-' + help.id"
                    >
                        <div class="d-flex align-items-center">
                            <span
                                class="color-muted font-weight-bold font-size-sm"
                                v-text="help.question"
                            ></span>
                        </div>
                    </div>
                </div>
                <div
                    :id="'a-element-' + help.id"
                    class="collapse"
                    data-parent="#accordionExample"
                >
                    <div
                        class="card-body box-answer"
                        v-text="help.answer"
                    ></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "FAQsComponent",
    data() {
        return {
            faqs: [],
        };
    },
    methods: {
        getFaqs() {
            let url = "/faqs";

            axios
                .post(url)
                .then((response) => {
                    this.faqs = response.data.helps;
                })
                .catch(function (error) {
                    this.error = response.data.message;
                });
        },
    },
    mounted() {
        this.getFaqs();
    },
};
</script>

<style lang="scss" scoped>
.accordion.accordion-solid .card {
    background-color: transparent;
    border: none;

    .card-header .card-title {
        background-color: #8cc5ba !important;
        border-radius: 0.42rem;

        .btn {
            color: #52a898;
        }
    }

    .card-body {
        margin-top: 0.5rem;
        border-radius: 0.42rem;
    }
}
</style>
