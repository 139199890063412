<template>
    <div
        class="FormDate"
        @keyup.capture="updateValue"
    >
        <input
            v-if="showDay"
            ref="day"
            v-model="day"
            class="FormDate__input FormDate__input--day"
            type="number"
            placeholder="dd"
            @input="updateDay"
            required
            @blur="blurDay"
        >
        <span
            v-if="showDay && showMonth"
            class="FormDate__divider"
        >/</span>
        <input
            v-if="showMonth"
            ref="month"
            v-model="month"
            class="FormDate__input FormDate__input--month"
            type="number"
            placeholder="mm"
            @input="updateMonth"
            required
            @blur="blurMonth">
        <span
            v-if="showYear && (showDay || showMonth)"
            class="FormDate__divider"
        >/</span>
        <input
            v-if="showYear"
            ref="year"
            v-model="year"
            class="FormDate__input FormDate__input--year"
            type="number"
            placeholder="yyyy"
            required
            @blur="year = year.padStart(4, 0)">
    </div>
</template>

<script>
import {validateDate} from "./dateUtils";
export default {
    name: `FormDate`,
    props: {
        value: {
            type: [Number, String],
            required: true,
        },
        showDay: {
            type: Boolean,
            default: true,
        },
        showMonth: {
            type: Boolean,
            default: true,
        },
        showYear: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            day: `${this.value ? new Date(this.value).getDate() : ``}`,
            month: `${this.value ? new Date(this.value).getMonth() + 1 : ``}`,
            year: `${this.value ? new Date(this.value).getFullYear(): ``}`,
        };
    },
    watch: {
        year(current, prev) {
            if (current > 9999) this.year = prev;
        },
    },
    methods: {
        updateDay() {
            if (!this.day.length || parseInt(this.day, 10) < 4) return;
            if (this.showMonth) this.$refs.month.select();
            else if (this.showYear) this.$refs.year.select();
        },
        updateMonth() {
            if (!this.month.length || parseInt(this.month, 10) < 2) return;
            if (this.showYear) this.$refs.year.select();
        },
        updateValue() {
            const timestamp = Date.parse(`${this.year.padStart(4, 0)}-${this.month}-${this.day}`);
            if (this.year === '' || this.month === '' || this.day === '' || Number.isNaN(timestamp)){
                this.$emit(`input`, '');
            }
            else if(validateDate(parseInt(this.day, 10), parseInt(this.month, 10), parseInt(this.year, 10))){
                this.$emit(`input`, timestamp);
            }
            else{
                this.$emit(`input`, -1);
            }
        },
        blurDay() {
            if(!this.day.length || parseInt(this.day, 10) === 0 ){
                this.day = "";
            }
            else if(this.day.length){
                if(this.day.length > 2){
                    this.day = this.day.substring(0,2);
                }

                let dayValue = parseInt(this.day, 10);
                if(dayValue > 31 || dayValue < 0){
                    this.day = ""
                }

                if(this.day.length === 1){
                    this.day = this.day.padStart(2, 0);
                }
            }
        },
        blurMonth(){
            if(!this.month.length || parseInt(this.month, 10) === 0 ){
                this.month = "";
            }
            else if(this.month.length){
                if(this.month.length > 2){
                    this.month = this.month.substring(0,2);
                }

                let monthValue = parseInt(this.month, 10);
                if(monthValue > 12 || monthValue < 0){
                    this.month = ""
                }

                if(this.month.length === 1){
                    this.month = this.month.padStart(2, 0);
                }
            }
        },
        getValue(){
            return Date.parse(`${this.year.padStart(4, 0)}-${this.month}-${this.day}`);
        }
    },
};
</script>

<style lang="scss">
.FormDate {
    $spacing: 0.75em;

    display: inline-flex;
    position: relative;
    overflow: hidden;
    border: 1px solid #d7f4ee;
    border-radius: 0.42em;
    // 1. Hide the spinner button in Chrome, Safari and Firefox.
    &__input {
        padding: $spacing;
        padding-right: calc($spacing / 2);
        padding-left: calc($spacing / 2);
        border: none !important;
        text-align: center;
        /* stylelint-disable-next-line property-no-vendor-prefix */
        -moz-appearance: textfield; // 1

        &::-webkit-inner-spin-button {
            display: none; // 1
        }

        &:first-child {
            padding-left: $spacing;
        }

        &:last-child {
            padding-right: $spacing;
        }

        &:focus {
            outline: none;
        }

        &--day,
        &--month {
            width: 3em;
        }

        &--year {
            width: 4em;
        }
    }

    &__divider {
        padding-top: $spacing;
        padding-bottom: $spacing;
        pointer-events: none;
    }
}
</style>
