var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "description col-12 col-md-6 order-1 order-sm-1 order-md-0",
    },
    [
      _c("div", { staticClass: "p-2" }, [
        _c("div", { staticClass: "d-flex mt-4 mb-6" }, [
          _c("p", {
            staticClass: "color-white description-event-file",
            domProps: { innerHTML: _vm._s(_vm.event.description) },
          }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "mt-4" }, [
          _vm._m(0),
          _vm._v(" "),
          _vm._m(1),
          _vm._v(" "),
          _c(
            "ul",
            { staticClass: "color-white" },
            _vm._l(_vm.getDisplayTickets(), function (ticket) {
              return _c("li", { key: ticket.id, staticClass: "mb-3" }, [
                _vm._v(
                  "\n                    " +
                    _vm._s(ticket.name_ticket) +
                    " (" +
                    _vm._s(ticket.description_ticket) +
                    ")\n                "
                ),
              ])
            }),
            0
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "direccion mt-4" }, [
          _c("div", [
            _c("span", { staticClass: "color-white font-weight-bolder" }, [
              _vm._v("Dirección"),
            ]),
            _c("br"),
            _vm._v(" "),
            _c("span", { staticClass: "color-white" }, [
              _vm._v(_vm._s(_vm.event.address)),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _vm._m(2),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("span", { staticClass: "color-white font-weight-bolder" }, [
        _vm._v("Entradas"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "mb-3" }, [
      _c("span", { staticClass: "color-white mb-3" }, [
        _vm._v("Tipos de entradas:"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "mt-10" }, [
      _c("span", { staticClass: "color-white font-weight-bolder" }, [
        _vm._v("Cómo llegar"),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "my-2" }, [_c("div", { attrs: { id: "map" } })]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }