<template>
	<div class="modal fade" id="modal_register" tabindex="-1" role="dialog" aria-labelledby="registerModalTitle" aria-hidden="true">
	  <div class="modal-dialog modal-dialog-centered" role="document">
	    <form id="form_register">
			<div class="modal-content">
				<div class="modal-body">
					<div class="alert alert-danger" role="alert" v-if="error !== null">
						{{ error }}
					</div>
					<div>
						<h5 class="font-weight-bolder color-darkblue">Crear nueva cuenta</h5>
						<p class="text-muted">Introduce tus datos para crear tu cuenta</p>
					</div>
					<div class="form-group">
						<label class="font-weight-bold color-darkblue">Nombre</label>
						<input id="name" name="name" type="text" class="form-control" v-model="name" required autofocus autocomplete="off" placeholder="Nombre">
					</div>
					<div class="form-group">
						<label class="font-weight-bold color-darkblue">Apellidos</label>
						<input id="surnames" name="surnames" type="text" class="form-control" v-model="surnames" required autofocus autocomplete="off" placeholder="Apellidos">
					</div>
					<div class="form-group">
						<label class="font-weight-bold color-darkblue">Email</label>
						<input id="email_register" name="email_register" type="email" class="form-control" v-model="email_register" required autofocus autocomplete="off" placeholder="Email">
					</div>
                    <div class="form-group">
                        <label class="font-weight-bold color-darkblue">Fecha de nacimiento</label>
                        <div>
                            <form-date id="birthday" name="birthday" v-model="birthday"/>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="font-weight-bold color-darkblue">Teléfono</label>
                        <input id="phone" name="phone" type="tel" class="form-control" maxlength="20"
                               v-model="phone" required autofocus autocomplete="off"
                               placeholder="Teléfono">
                    </div>
                    <div class="form-group">
                        <label class="font-weight-bold color-darkblue">Código postal</label>
                        <input id="zipcode" name="zipcode" type="text" class="form-control" maxlength="5"
                               v-model="zipcode" required autofocus autocomplete="off"
                               placeholder="Código postal">
                    </div>
					<div class="form-group">
						<label class="font-weight-bold color-darkblue">Contraseña</label>
						<input id="password" name="password" type="password" class="form-control" v-model="password" required autocomplete="off" placeholder="Contraseña">
					</div>
					<div class="form-group">
						<label class="font-weight-bold color-darkblue">Repetir Contraseña</label>
						<input id="password2" name="password2" type="password" class="form-control" required autocomplete="off" placeholder="Repetir contraseña">
					</div>

					<div class="form-group d-flex align-items-center">
						<input type="checkbox" name="agree" class="mr-2">&nbsp;Acepto&nbsp;
						<router-link class="color-darkblue create-account" @click.native="goToPrivacyPolicy()" :to="{ path: '/privacy-policy' }">política de privacidad</router-link>
					</div>
				</div>

				<div class="modal-footer justify-content-between">
					<button type="button" id="back_btn_register" class="btn btn-cancel btn-cancel-register" data-dismiss="modal">Volver</button>
					<button type="submit" class="btn btn-submit-register px-4" @click="handleSubmit">Crear nueva cuenta</button>
				</div>
			</div>
		</form>
	  </div>
	</div>
</template>

<script>
    import FormDate from "./util/FormDate.vue";
    import validateBirtday from "./util/dateUtils";

    export default {
        components: {
            FormDate,
        },
        data(){
	        return {
	            name: "",
				surnames: "",
	            email_register: "",
                phone: "",
                zipcode: "",
	            password: "",
                birthday: '',
	            error: null,
				terms_and_conditions: null
	        }
        },
        methods:{
			validateFormRegister(){
                //Validar formulario de cambio de contraseña
                // Base elements
                var form = KTUtil.getById('form_register');

                if (!form) {
                    return;
                }

                // Init form validation rules. For more info check the FormValidation plugin's official documentation:https://formvalidation.io/
                this.validation = FormValidation.formValidation(
                form,
                    {
                        fields: {
                                name: {
                                    validators: {
                                        notEmpty: {
                                            message: 'Este campo es obligatorio'
                                        }
                                    }
                                },
                                surnames: {
                                    validators: {
                                        notEmpty: {
                                            message: 'Este campo es obligatorio'
                                        },
                                    }
                                },
                                email: {
                                    validators: {
                                        notEmpty: {
                                            message: 'Este campo es obligatorio'
                                        },
                                    }
                                },
                                phone: {
                                    validators: {
                                        notEmpty: {
                                            message: 'Este campo es obligatorio'
                                        },
                                    }
                                },
                                zipcode: {
                                    validators: {
                                        notEmpty: {
                                            message: 'Este campo es obligatorio'
                                        },
                                    }
                                },
								password: {
									validators: {
										notEmpty: {
											message: 'La contraseña es obligatoria'
										}
									}
								},
								password2: {
									validators: {
										notEmpty: {
											message: 'Debes confirmar la contraseña'
										},
										identical: {
											compare: function() {
												return form.querySelector('[name="password_r"]').value;
											},
											message: 'Las contraseñas no coinciden'
										}
									}
								},
								agree: {
									validators: {
										notEmpty: {
											message: 'Debes aceptar la política de privacidad'
										}
									}
								},
                            },
                        plugins: {
                            trigger: new FormValidation.plugins.Trigger(),
                            bootstrap: new FormValidation.plugins.Bootstrap()
                        }
                    }
                );
            },
            handleSubmit(e) {
				let me = this;
	            e.preventDefault()

                let dateValid = validateBirtday(me.birthday);
                if(dateValid.message !== ''){
                    swal('', dateValid.message, 'error');
                    return;
                }

				this.validation.validate().then(function(status) {
					if (status == 'Valid') {
						axios.get('/sanctum/csrf-cookie').then(response => {
							axios.post('/api/register', {
								name: me.name,
								surnames: me.surnames,
								email: me.email_register,
								password: me.password,
                                phone: me.phone,
                                zipcode: me.zipcode,
                                birthdate: me.birthday / 1000
							})
							.then(response => {
								if (response.data.success) {
									document.getElementById('back_btn_register').click();
									swal('','Se ha enviado un correo a la cuenta indicada para verificar su usuario','success');
									//$('#modal_register').modal('hide');
								} else if(response.data.message == "User exist") {
									swal('','Este usuario ya está registrado','warning');

								}else{
									swal('','Parece que ha habido un error, intentalo de nuevo más tarde','error');
									this.error = response.data.message
								}
							})
							.catch(function (error) {
								console.log(error);
							});
						});
					} else {
						swal.fire({
							text: "Lo siento, debes completar todos los campos.",
							icon: "error",
							buttonsStyling: false,
							confirmButtonText: "Aceptar",
							customClass: {
								confirmButton: "btn font-weight-bold btn-light-primary"
							}
						}).then(function() {
							KTUtil.scrollTop();
						});
					}
				});

	        },
			goToPrivacyPolicy() {
				$('#modal_register').removeClass('show').css('display', 'none')
				$('#offcanvasLogin a[data-bs-dismiss="offcanvas"] img').trigger('click')
				$(".modal-backdrop").remove();
			},
        },
	    beforeRouteEnter(to, from, next) {
	        next();
	    },
		mounted(){
			this.validateFormRegister();
		}
    }
</script>
