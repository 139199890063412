<template>
    <div>
        <div class="row mb-4">
            <div class="col-12 col-md-6 col-lg-3">
                <div class="input-group search-input">
                    <div class="input-group-prepend">
                        <span
                            class="input-group-text bg-white border-radius-left border-0 h-100"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="#ffffff"
                                class="bi bi-search"
                                viewBox="0 0 16 16"
                            >
                                <path
                                    d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"
                                />
                            </svg>
                        </span>
                    </div>
                    <input
                        v-model="search_documents"
                        id="search_documents"
                        type="text"
                        class="form-control bg-white color-green border-0 h-100"
                        placeholder="Buscar..."
                        aria-describedby="search-addon"
                    />
                </div>
            </div>
        </div>
        <div
            class="datatable datatable-bordered datatable-head-custom"
            id="list_documents"
            style="width: 100%"
        ></div>
        <div class="row justify-content-center">
            <button @click="verMas" class="btn ver-mas">Ver más</button>
        </div>
    </div>
</template>

<script>
var url_img = window.location.origin + "/svg";
var url_files = window.location.origin + "/files/files/";

export default {
    name: "TableMyDocumentsComponent",
    components: {},
    data() {
        return {
            publicPath: window.location.origin,
            array_helps: [],
            search: "",
            paginate: 4,
            search_documents: "",
            basketAddSuccess: true,
        };
    },
    methods: {
        searchDocuments() {
            // Get the input box
            let input = document.getElementById("search_documents");

            // Init a timeout variable to be used below
            let timeout = null;
            let self = this;

            // Listen for keystroke events
            input.addEventListener("keyup", function (e) {
                clearTimeout(timeout);

                timeout = setTimeout(function () {
                    self.listDocuments();
                }, 500);
            });
        },
        listDocuments() {
            $("#list_documents").KTDatatable("destroy");
            $("#list_documents").KTDatatable("init");
            
            $("#list_documents").KTDatatable({
                data: {
                    type: "remote",
                    source: {
                        read: {
                            url: this.publicPath + "/documents",
                            headers: {
                                "X-CSRF-TOKEN": $(
                                    'meta[name="csrf-token"]'
                                ).attr("content"),
                            },
                            data: {
                                pagination: {
                                    page: 1,
                                    pages: 18,
                                    perpage: this.paginate,
                                },
                                query: {
                                    search_documents: $("#search_documents").val(),
                                },
                                sort: {
                                    sort: "asc",
                                },
                            },
                        },
                    },
                    pageSize: 10,
                    serverPaging: !0,
                    serverFiltering: !0,
                    serverSorting: !0,
                    serverSorting: !0,
                },
                layout: {
                    scroll: true,
                    customScrollbar: true,
                    scrollX: true,
                    footer: !1,
                    spinner: {
                        color: "#FFF",
                    },
                },
                sortable: 1,
                pagination: 0,
                translate: {
                    records: {
                        processing: "Cargando...",
                        noRecords: "Sin resultados",
                    },
                    toolbar: {
                        pagination: {
                            items: {
                                info: "Mostrando {{start}} - {{end}} de {{total}} resultados",
                            },
                        },
                    },
                },
                rows: {
                    autoHide: false,
                },
                columns: [
                    {
                        field: "#type_docs_imgs",
                        title: "",
                        width: 80,
                        sortable: !1,
                        textAlign: "center",
                        template: function (row, data, index) {
                            return (
                                '<div><img src="' +
                                url_img +
                                "/type_documents/" +
                                row.type_document_img +
                                '" width="30" height="auto"></div>'
                            );
                        },
                    },
                    {
                        field: "name",
                        title: "nombre",
                        sortable: !1,
                        textAlign: "center",
                    },
                    {
                        field: "extension",
                        title: "documento",
                        sortable: !1,
                        textAlign: "center",
                    },
                    {
                        field: "format_date",
                        title: "fecha",
                        sortable: !1,
                        textAlign: "center",
                    },
                    {
                        field: "#",
                        title: "acciones",
                        sortable: !1,
                        textAlign: "center",
                        template: function (row, data, index) {
                            return (
                                '<div><a href="' +
                                url_files +
                                row.file +
                                '" download><img class="download-hover" src="' +
                                url_img +
                                '/accion-descargar.svg" height="30px" width="auto"></a>'
                            );
                        },
                    },
                ],
            });
        },
        verMas() {
            this.paginate += 4;
            this.listDocuments();
        },
    },
};
</script>

<style lang="scss">
$primary: #45c4b0;

.input-group.search-input {
    background-color: transparent;
    border: 1px solid white;
    border-radius: 5px;

    .input-group-text {
        background-color: transparent !important;
    }

    #search_documents {
        background-color: transparent !important;

        &::placeholder {
            color: white;
        }
    }
}

.datatable-head-custom table {
    background-color: transparent !important;

    tr th {
        span {
            color: white !important;
        }
    }

    tr td {
        span {
            color: $primary !important;
        }
    }
}

.ver-mas {
    background-color: $primary;
    color: darken($primary, 30%);
    font-weight: bold;
    padding: 0.5rem 1rem;
}
</style>
