var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "ticketRenaming",
      staticClass: "modal fade",
      staticStyle: { "z-index": "99999" },
      attrs: {
        id: "ticketRenaming",
        tabindex: "-1",
        role: "dialog",
        "data-backdrop": "static",
        "data-keyboard": "false",
        "aria-labelledby": "ticketRenamingTitle",
        "aria-hidden": "true",
      },
    },
    [
      _c(
        "div",
        {
          class:
            "modal-dialog modal-dialog-scrollable modal-dialog-centered modal-lg",
          attrs: { role: "document" },
        },
        [
          _c("div", { staticClass: "modal-content" }, [
            _vm._m(0),
            _vm._v(" "),
            _vm.sale_tickets && _vm.sale_tickets.length > 0
              ? _c(
                  "div",
                  { staticClass: "modal-body" },
                  [
                    _c("AdminEventTicketRenaming", {
                      attrs: { sale_tickets: _vm.sale_tickets },
                      on: { onFormChanged: _vm.updateData },
                    }),
                  ],
                  1
                )
              : !_vm.error
              ? _c("div", { staticClass: "modal-body" }, [
                  _c("h4", [
                    _vm._v(
                      "Validando transacción, por favor espere..." +
                        _vm._s(_vm.retry > 0 ? _vm.retry : "")
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.error
              ? _c("div", { staticClass: "modal-body" }, [
                  _c("h4", [_vm._v(_vm._s(_vm.error))]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "modal-footer" }, [
              _vm.sale_tickets && _vm.sale_tickets.length > 0
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: { type: "button", disabled: _vm.loading },
                      on: {
                        click: function ($event) {
                          return _vm.saveTicketRenaming()
                        },
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.loading ? "Procesando..." : "Confirmar")
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.error
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-danger",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.closeDialog()
                        },
                      },
                    },
                    [_vm._v("Cerrar")]
                  )
                : _vm._e(),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-header" }, [
      _c("h5", { staticClass: "modal-title" }, [
        _vm._v("Asignación de Tickets"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }