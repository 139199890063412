<template>
	<div>
		<div class="bg-header-general d-flex align-items-end">
			<div class="container pt-4">
				<div class="col-md-6 d-flex align-items-end">
					<img
						:src="publicPath + '/svg/usuario.svg'"
						width="auto"
						height="180px"
						style="object-fit: cover"
					/>
					<div class="p-4" style="height: 180px">
						<div style="position: absolute; top: 10px">
							<button
								v-on:click="connectMetamask()"
								v-if="address_wallet == ''"
								class="btn btn-green mr-auto mb-auto"
							>
								Conectar wallet
							</button>
							<h5 v-else class="color-white mb-auto address-wallet-container">
								<div>Dirección wallet:</div>
								<div class="address-wallet">
									{{ address_wallet }}
								</div>
							</h5>
						</div>
						<div style="position: absolute; bottom: 10px">
							<h3 class="color-white">Mis colecciones</h3>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "HeaderMyCollectionComponent",
	data() {
		return {
			publicPath: window.location.origin,
			address_wallet: "",
		};
	},
	props: ["me_collection"],
	methods: {
		async connectMetamask() {
			// our decimal number
			if (typeof window.ethereum !== "undefined") {
				//Conectar MetaMask con la web y sacar su dirección
				if (window.ethereum.chainId == "0x89") {
					var accounts = await ethereum.request({
						method: "eth_requestAccounts",
					});
					this.saveAddressWallet(accounts[0]);
				} else {
					swal("", "Conectate a la red de Polygon", "warning");
				}
			} else {
				swal({
					title: "Instala la extensión de MetaMask",
					type: "warning",
					text: 'Si tiene alguna duda con este proceso puede revisar nuestros documentos explicativos en <a href="/help" target="_blank">AYUDA</a>',
					html: true,
				});
			}
		},
		saveAddressWallet(address) {
			let me = this;

			let params = {
				address_wallet: address,
			};

			axios
				.post("/save_address_wallet", params)
				.then((response) => {
					if (response.data.code == 1000) {
						me.address_wallet = address;
						me.me_collection.updateAddress(me.address_wallet);
						swal(
							"¡Wallet conectada! Ya puedes reclamar tus NFTs",
							"",
							"success"
						);
					} else {
						swal(
							"",
							"Parece que ha habido un error, inténtalo de nuevo más tarde",
							"warning"
						);
					}
				})
				.catch(function (error) {
					swal(
						"",
						"Parece que ha habido un error, inténtalo de nuevo más tarde",
						"error"
					);
				});
		},
		getAddressUser() {
			let me = this;
			axios
				.get("/get_address_wallet")
				.then(function (response) {
					if (response.data.code == 1000) {
						me.address_wallet = response.data.address;
						me.me_collection.updateAddress(me.address_wallet);
					}
				})
				.catch(function (error) {
					// handle error
					console.log(error);
				});
		},
	},
	mounted() {
		this.getAddressUser();
	},
};
</script>

<style lang="scss" scoped>
// Mobiles
@media only screen and (min-width: 280px) and (max-width: 500px) {
	.address-wallet-container {
		max-width: 150px;

		.address-wallet {
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
}
</style>
