<template>
    <div>
        <SongsRecentGeneralComponent :ref="'songs_recent'" :array_songs_recent="array_songs_recent"></SongsRecentGeneralComponent>
        <SongsCollectionsGeneralComponent :ref="'collections'" :array_collections="array_collections"></SongsCollectionsGeneralComponent>

        <div class="d-flex justify-content-center align-items-center my-4">
            <span class="color-white"></span>
        </div>
    </div>
</template>

<script>
    import SongsRecentGeneralComponent from './SongsRecentGeneralComponent.vue';
    import SongsCollectionsGeneralComponent from './SongsCollectionsGeneralComponent.vue';
    export default {
        components: {
            SongsRecentGeneralComponent,
            SongsCollectionsGeneralComponent,
        },
        data(){
            return{
                publicPath: window.location.origin,
                array_songs_recent: [],
                array_collections: [],
            }
        },
        methods:{   
            listSongs(){
                let me = this;
                axios.get("/list_songs_front").then((response) => {
                    if (response.data.code == 1000) {
                        me.array_songs_recent = response.data.array_songs_recent;
                        if(response.data.array_songs_recent.length < 8){
                            me.$refs.songs_recent.show_button_carrusel = false;
                        }

                        me.array_collections = response.data.array_collections;
                        if(response.data.array_collections.length < 8){
                            me.$refs.collections.show_button_carrusel = false;
                        }

                    } else {
                        swal("", "Parece que ha habido un error, inténtalo de nuevo más tarde", "error");
                    }
                }).catch(function (error) {
                    swal("", "Parece que ha habido un error, inténtalo de nuevo más tarde", "error");
                });
            },
            listCategories(){
                let me =this;
                let url = '/list_categories';
                axios.get(url).then(function (response) {
                    me.array_categories = response.data.array_categories;
                })
                .catch(function (error) {
                    // handle error
                    console.log(error);
                });
            },
        },
        mounted() {
            this.listSongs();
        },
    }
</script>
