<template>
	<div class="modal fade" id="changePaswordModal" tabindex="-1" role="dialog" aria-labelledby="changePaswordModalTitle" aria-hidden="true" v-if="modalShow">
	  <div class="modal-dialog modal-dialog-centered" role="document">
	    <form id="form_change_password">
			<div class="modal-content">
				<div class="modal-body">
					<div class="alert alert-danger" role="alert" v-if="error !== null">
						{{ error }}
					</div>
					<div>
						<h5 class="font-weight-bolder color-darkblue">Modificar contraseña</h5>
					</div>
					<div class="form-group">
						<label class="font-weight-bold color-darkblue">Contraseña actual</label>
						<input id="password_cp" name="password_cp" type="password" class="form-control" v-model="password_cp" required autocomplete="off" placeholder="Contraseña">
					</div>
					<div class="form-group">
						<label class="font-weight-bold color-darkblue">Nueva contraseña</label>
						<input id="new_password_cp" name="new_password_cp" type="password" class="form-control" v-model="new_password_cp" required autocomplete="off" placeholder="Nueva contraseña">
					</div>
					<div class="form-group">
						<label class="font-weight-bold color-darkblue">Repetir nueva contraseña</label>
						<input id="repeat_new_password_cp" name="repeat_new_password_cp" v-model="repeat_new_password_cp" type="password" class="form-control" required autocomplete="off" placeholder="Repetir nueva contraseña">
					</div>
				</div>

				<div class="modal-footer justify-content-between">
					<button type="button" class="btn btn-cancel btn-cancel-changePasword" @click="close()" data-dismiss="modal">Volver</button>
					<button type="submit" class="btn btn-submit-register px-4" @click="handleSubmit">Cambiar contraseña</button>
				</div>
			</div>
		</form>
	  </div>
	</div>
</template>

<script>
    export default {
        data(){
	        return {
	            password_cp: "",
	            new_password_cp: "",
				repeat_new_password_cp: "",
				modalShow: true,
	            error: null
	        }
        },
        methods:{
            close(){
                $("#changePaswordModal").modal('hide');
            },
            validateFormChangePassword(){
                //Validar formulario de cambio de contraseña
                // Base elements
                var form = KTUtil.getById('form_change_password');

                if (!form) {
                    return;
                }

                // Init form validation rules. For more info check the FormValidation plugin's official documentation:https://formvalidation.io/
                this.validation = FormValidation.formValidation(
                form,
                    {
                        fields: {
                                password_cp: {
                                    validators: {
                                        notEmpty: {
                                            message: 'La contraseña es obligatoria'
                                        }
                                    }
                                },
								new_password_cp: {
									validators: {
										notEmpty: {
											message: 'La contraseña es obligatoria'
										}
									}
								},
								repeat_new_password_cp: {
									validators: {
										notEmpty: {
											message: 'Debes confirmar la contraseña'
										},
										identical: {
											compare: function() {
												return form.querySelector('[name="new_password_cp"]').value;
											},
											message: 'Las contraseñas no coinciden'
										}
									}
								},
								agree: {
									validators: {
										notEmpty: {
											message: ''
										}
									}
								},
                            },
                        plugins: {
                            trigger: new FormValidation.plugins.Trigger(),
                            bootstrap: new FormValidation.plugins.Bootstrap()
                        }
                    }
                );
            },
			handleSubmit(e) {
				let me = this;
	            e.preventDefault()
				this.validation.validate().then(function(status) {
					if (status == 'Valid') {
						axios.get('/sanctum/csrf-cookie').then(response => {
							axios.post('/change_password', {
								password: me.password_cp,
								new_password: me.new_password_cp,
								repeat_new_password: me.repeat_new_password_cp
							})
							.then(response => {
								if(response.data.code == 1000){
									swal('','Contraseña modificada correctamente','success');
									me.modalShow = false;

								}else if(response.data.code == 1001){
									swal('','Contraseña incorrecta','warning');

								}else if(response.data.code == 1002){
									swal('','La antigua contraseña y la nueva no pueden ser iguales','warning');

								}else if(response.data.code == 1003){
									swal('','Las contraseñas no coinciden','warning');

								}else if(response.data.code == 1004){
									swal('','La contraseña debe de tener más de 6 caracteres','warning');

								}else{
									swal('','Parece que ha habido un error, intentalo de nuevo más tarde','error');
									this.error = response.data.message;
								}
							})
							.catch(function (error) {
								console.log(error);
							});
						});
					} else {
						swal.fire({
							text: "Lo siento, debes completar todos los campos.",
							icon: "error",
							buttonsStyling: false,
							confirmButtonText: "Aceptar",
							customClass: {
								confirmButton: "btn font-weight-bold btn-light-primary"
							}
						}).then(function() {
							KTUtil.scrollTop();
						});
					}
				});

	        }
        },
	    beforeRouteEnter(to, from, next) {
	        next();
	    },
		mounted(){
			this.validateFormChangePassword();
		}
    }
</script>
