<template>
	<div class="container px-0">
		<div class="d-flex justify-content-end my-4">
			<div class="col-md-4">
				<select
					class="form-control selectpicker select-green w-100"
					@change="changeCategory()"
					name="category"
					id="select_categories_events_recent"
					v-model="id_category_query"
					data-style="select-green"
					data-live-search="true"
				>
					<option :value="'0'" selected>Selecciona una categoria</option>
					<option
						v-for="category in array_categories"
						:value="category.id"
						:key="category.id"
						v-text="category.name"
					></option>
				</select>
			</div>
		</div>
		<div class="d-flex align-items-center my-4">
			<span class="col-12 mr-6 color-white h4"
				>Planes añadidos recientemente</span
			>
		</div>
		<div
			class="d-flex flex-wrap justify-content-start"
			v-if="array_events.length"
		>
			<div
				class="col-8 col-md-4 col-lg-3 mb-5"
				v-for="event in array_events"
				:key="event.id"
			>
				<div class="evecan custom">
					<router-link
						v-if="!event.isRestaurant"
						:to="{ path: '/event/' + event.id }"
					>
						<div class="h-100">
							<img
								v-bind:src="'/files/images/events/' + event.img_event"
								style="object-fit: cover"
							/>
							<div
								class="
									description
									p-4
									d-flex
									flex-wrap
									justify-content-between
									align-content-between
									bg-blue
								"
							>
								<div class="w-100">
									<span
										class="event-name color-white"
										v-text="event.name"
									></span>
								</div>
								<div
									class="
										position-to-bottom
										w-100
										d-flex
										justify-content-between
									"
								>
									<div>
										<span class="h2 text-white" v-if="event.sold_out"
											>Sold out</span
										>
									</div>
									<div>
										<span class="color-white">Desde</span><br />
										<span
											class="h2 color-green"
											v-text="event.price + '€'"
										></span>
									</div>
								</div>
							</div>
						</div>
					</router-link>
					<router-link v-else :to="{ path: '/restaurant/' + event.id }">
						<div class="h-100">
							<img
								:src="`/images/restaurants/${event.file}`"
								style="object-fit: cover"
							/>
							<div
								class="
									description
									p-4
									d-flex
									flex-wrap
									justify-content-between
									align-content-between
								"
							>
								<div class="w-100">
									<span
										class="event-name color-white"
										v-text="event.name"
									></span>
								</div>
								<div class="w-100 d-flex justify-content-end">
									<span class="h2 color-green">Reservar</span>
								</div>
							</div>
						</div>
					</router-link>
				</div>
			</div>
		</div>
		<div class="text-white h2 mt-3" v-else>No hay planes disponibles</div>
		<div
			class="d-flex justify-content-center align-items-center my-4"
			v-if="hidden_button"
		>
			<button
				v-on:click="showMore()"
				type="button"
				class="btn btn-green-secondary px-4"
			>
				Ver más
			</button>
		</div>
	</div>
</template>

<script>
export default {
	name: "EventsRecentComponent",
	data() {
		return {
			category: "",
			array_events: [],
			array_categories: [],
			start: 0,
			id_category_query: "",
			value_min_query: "",
			value_max_query: "",
			date_query: "",
			hidden_button: false,
		};
	},
	methods: {
		listCategories() {
			let me = this;
			let url = "/list_categories";
			axios
				.get(url)
				.then(function (response) {
					me.array_categories = response.data.array_categories;
				})
				.catch(function (error) {
					// handle error
					console.log(error);
				});
		},
		changeCategory() {
			let me = this;
			me.applyFilters();
			me.listEvents(2);
		},
		applyFilters() {
			var params = "";
			if (this.id_category_query != 0) {
				params += "id_category=" + this.id_category_query;
			}

			if (this.value_min_query != "" && this.value_max_query != "") {
				if (params != "") {
					params += "&";
				}
				params +=
					"value_min=" +
					this.value_min_query +
					"&value_max=" +
					this.value_max_query;
			}

			if (this.date_query != "") {
				if (params != "") {
					params += "&";
				}
				params += "date=" + this.date_query;
			}

			if (params != "") {
				window.history.replaceState(
					null,
					null,
					window.location.pathname + "?" + params
				);
			}
		},
		listEvents(type) {
			let params = {
				start: this.start,
				id_category: this.id_category_query,
				value_min: this.value_min_query,
				value_max: this.value_max_query,
				date: this.date_query,
			};

			axios
				.post("/list_recent_events", params)
				.then((response) => {
					this.array_events = [];

					if (response.data.code == 1000) {
						response.data.array_events_recent.forEach((value, index, array) => {
							if (!value.isRestaurant) {
								let sold_out = true;

								value.tickets.forEach((ticket) => {
									if (parseInt(ticket.num_ticket) != ticket.sales.length) {
										sold_out = false;
									}
								});

								value.sold_out = sold_out;
							}

							this.array_events.push(value);
						});

						this.hidden_button = response.data.hidden_button;
					} else {
						swal(
							"",
							"Parece que ha habido un error, inténtalo de nuevo más tarde",
							"error"
						);
					}
				})
				.catch((error) => {
					console.log(
						"🚀 ~ file: EventsRecentComponent.vue:233 ~ listEvents ~ error",
						error
					);
					swal(
						"",
						"Parece que ha habido un error, inténtalo de nuevo más tarde",
						"error"
					);
				});
		},
		showMore() {
			let me = this;
			me.start += 12;
			me.listEvents(1);
		},
		getValueQuery() {
			let me = this;
			if (this.$route.query.id_category != undefined) {
				me.id_category_query = this.$route.query.id_category;
			}
			if (this.$route.query.value_min != undefined) {
				me.value_min_query = this.$route.query.value_min;
			}
			if (this.$route.query.value_max != undefined) {
				me.value_max_query = this.$route.query.value_max;
			}
			if (this.$route.query.date != undefined) {
				me.date_query = this.$route.query.date;
			}
		},
	},
	mounted() {
		this.listCategories();
		this.getValueQuery();
		this.listEvents(1);
	},
	updated() {
		if (this.id_category_query == 0) {
			$("#select_categories_events_recent").val(0);
		}
		$("#select_categories_events_recent").selectpicker("refresh");
	},
};
</script>
