<template>
    <div class="p-4">
        <div class="container">
            <div class="justify-content-center">
                <div class="wizard wizard-4">
                    <div class="wizard-nav">
                        <div class="wizard-steps" data-total-steps="3">
                            <div
                                class="wizard-step cursor-pointer mycollection-tab"
                                data-wizard-type="step"
                                id="tag_step1"
                                data-wizard-state="current"
                                @click="selectTab($event)"
                            >
                                <div
                                    class="wizard-wrapper justify-content-center"
                                >
                                    <span class="font-weight-bold"
                                        ><img
                                            width="35"
                                            height="auto"
                                            :src="
                                                publicPath +
                                                '/svg/entradas-seleccion.svg'
                                            "
                                            class="mx-3"
                                        />Entrada</span
                                    >
                                </div>
                            </div>
                            <div
                                class="wizard-step cursor-pointer mycollection-tab"
                                data-wizard-type="step"
                                id="tag_step2"
                                data-wizard-state="pending"
                                @click="selectTab($event)"
                            >
                                <div
                                    class="wizard-wrapper justify-content-center"
                                >
                                    <span class="font-weight-bold"
                                        ><img
                                            width="35"
                                            height="auto"
                                            :src="
                                                publicPath + '/svg/musica.svg'
                                            "
                                            class="mx-3"
                                        />Música</span
                                    >
                                </div>
                            </div>
                            <div
                                class="wizard-step cursor-pointer mycollection-tab"
                                data-wizard-type="step"
                                id="tag_step3"
                                data-wizard-state="pending"
                                @click="selectTab($event)"
                            >
                                <div
                                    class="wizard-wrapper justify-content-center"
                                >
                                    <span class="font-weight-bold"
                                        ><img
                                            width="35"
                                            height="auto"
                                            :src="publicPath + '/svg/nft.svg'"
                                            class="mx-3"
                                        />NFT</span
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        class="card card-custom card-shadowless rounded-top-0 div-content-tabs"
                    >
                        <div class="card-body">
                            <div
                                class="step"
                                id="step_events"
                                data-wizard-type="step-content"
                                data-wizard-state="current"
                            >
                                <div
                                    class="card card-custom shadow-none border-0"
                                >
                                    <div class="card-body body-tab-step p-3">
                                        <TableMyEventsComponent />
                                    </div>
                                </div>
                            </div>
                            <div
                                class="step"
                                id="step_songs"
                                data-wizard-type="step-content"
                                data-wizard-state="pending"
                            >
                                <div
                                    class="card card-custom shadow-none border-0"
                                >
                                    <div class="card-body body-tab-step p-3">
                                        <TableMySongsComponent ref="mySongs" />
                                    </div>
                                </div>
                            </div>
                            <div
                                class="step"
                                id="step_nfts"
                                data-wizard-type="step-content"
                                data-wizard-state="pending"
                            >
                                <div
                                    class="card card-custom shadow-none border-0"
                                >
                                    <div class="card-body body-tab-step p-3">
                                        <GridMyNFTsComponent
                                            ref="grid_my_nfts"
                                        ></GridMyNFTsComponent>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import TableMyEventsComponent from "./TableMyEventsComponent.vue";
import TableMySongsComponent from "./TableMySongsComponent.vue";
import GridMyNFTsComponent from "./GridMyNFTsComponent.vue";
export default {
    name: "MyCollectionTabsTableComponent",
    components: {
        TableMyEventsComponent,
        TableMySongsComponent,
        GridMyNFTsComponent,
    },
    data() {
        return {
            publicPath: window.location.origin,
            id_tab: "tag_step1",
            address: "",
        };
    },

    methods: {
        selectTab(event) {
            $("#tag_step1").attr("data-wizard-state", "pending");
            $("#tag_step2").attr("data-wizard-state", "pending");
            $("#tag_step3").attr("data-wizard-state", "pending");
            $(event.currentTarget).attr("data-wizard-state", "current");

            this.id_tab = $(event.currentTarget).attr("id");
            this.navegationTabs(this.id_tab);
        },
        navegationTabs(id_tab) {
            if (id_tab == "tag_step1") {
                $("#step_events").attr("data-wizard-state", "current");
                $("#step_songs").attr("data-wizard-state", "pending");
                $("#step_nfts").attr("data-wizard-state", "pending");

                $("#tag_step1")
                    .children()
                    .children()
                    .children()
                    .attr(
                        "src",
                        this.publicPath + "/svg/entradas-seleccion.svg"
                    );
                $("#tag_step2")
                    .children()
                    .children()
                    .children()
                    .attr("src", this.publicPath + "/svg/musica.svg");
                $("#tag_step3")
                    .children()
                    .children()
                    .children()
                    .attr("src", this.publicPath + "/svg/nft.svg");
            } else if (id_tab == "tag_step2") {
                $("#step_events").attr("data-wizard-state", "pending");
                $("#step_songs").attr("data-wizard-state", "current");
                $("#step_nfts").attr("data-wizard-state", "pending");

                $("#tag_step1")
                    .children()
                    .children()
                    .children()
                    .attr("src", this.publicPath + "/svg/entradas.svg");
                $("#tag_step2")
                    .children()
                    .children()
                    .children()
                    .attr("src", this.publicPath + "/svg/musica-seleccion.svg");
                $("#tag_step3")
                    .children()
                    .children()
                    .children()
                    .attr("src", this.publicPath + "/svg/nft.svg");
                this.$refs.mySongs.listSongs();
                this.$refs.mySongs.searchsongs();
            } else if (id_tab == "tag_step3") {
                $("#step_events").attr("data-wizard-state", "pending");
                $("#step_songs").attr("data-wizard-state", "pending");
                $("#step_nfts").attr("data-wizard-state", "current");

                $("#tag_step1")
                    .children()
                    .children()
                    .children()
                    .attr("src", this.publicPath + "/svg/entradas.svg");
                $("#tag_step2")
                    .children()
                    .children()
                    .children()
                    .attr("src", this.publicPath + "/svg/musica.svg");
                $("#tag_step3")
                    .children()
                    .children()
                    .children()
                    .attr("src", this.publicPath + "/svg/nft-seleccion.svg");
            }
        },
        updateAddress(address) {
            let me = this;
            me.address = address;
            me.$refs.grid_my_nfts.updateAddress(me.address);
        },
    },
    mounted() {},
};
</script>
