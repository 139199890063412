<template>
	<div class="d-flex flex-column">
		<div class="container my-5">
			<p class="seccion">INTRODUCCIÓN</p>
			<p>
				El presente documento (en adelante, las "Condiciones"), tiene por objeto
				establecer los Términos y Condiciones Generales de Venta de productos
				ofrecidos por la sociedad NFT MY TICKET, S.L. con domicilio en Plaza
				Isabel II, 5, 4ºC, 28013, Madrid y NIF B09863408 (en adelante, el
				"Titular" o "NFT MY TICKET"), a través de su página web
				www.nftmyticket.com (en adelante, el "Sitio Web"), así como cumplir con
				la obligación de información de la Ley 34/2002, de 11 de julio, de
				servicios de la sociedad de la información y de comercio electrónico.
			</p>
			<p class="seccion">DATOS DE USUARIOS Y CONDUCTA DEL SITIO WEB</p>
			<p>
				El Usuario será responsable de mantener la confidencialidad de los datos
				e información aportados y de restringir el acceso a su ordenador y a sus
				claves de acceso para evitar posibles usos no autorizados.
			</p>
			<p>
				En caso de que el Usuario tenga motivos para creer que sus datos han
				sido puestos en conocimiento de un tercero, o si estos han sido
				utilizados de manera no autorizada, o son susceptibles de serlo, deberá
				comunicar esta situación al Titular de forma inmediata.
			</p>
			<p>
				Los Usuarios se comprometen a hacer un uso lícito, diligente, honesto y
				correcto de cuanta información o contenidos tengan acceso a través del
				Sitio Web, y todo ello bajo los principios de la buena fe y respetando
				en todo momento a la legalidad vigente y los presentes Términos y
				Condiciones.
			</p>
			<p>
				El Titular se reserva el derecho a denegar el acceso al Sitio Web a
				Usuarios que hagan un mal uso de los contenidos y/o incumplan cualquiera
				de las condiciones que aparecen en el presente documento.
			</p>
			<p class="seccion">FUNCIONAMIENTO DE LA WEB</p>
			<p>
				El presente Sitio Web ha sido desarrollado por el Titular con la
				finalidad de ofrecer a los Usuarios la venta de entradas de forma
				digital para espectáculos artísticos, musicales, culturales, deportivos,
				etc., en adelante los "Eventos".
			</p>
			<p>
				NFT MY TICKET actúa como mandataria, esto es, por cuenta y orden del
				organizador, productor o gestor del evento o espectáculo, en adelante
				también el "Organizador", prestando a este último los servicios de venta
				y distribución de entradas.
			</p>
			<p>
				El Organizador es el único y exclusivo responsable de la producción y
				organización del Evento, sujeto a las condiciones de venta que el mismo
				establezca. El Organizador es el responsable de la definición de
				precios, características del evento y la correcta ejecución del mismo,
				por lo que el Titular no asume responsabilidad a este respecto.
			</p>
			<p class="seccion">PROCESO DE COMPRA</p>
			<p>
				Al realizar compras a través del Sitio Web, el Usuario se compromete a
				(i) no realizar pedidos falsos; y, (ii) facilitarnos sus datos
				personales de forma veraz y exacta. El Usuario declara ser consciente de
				que el Titular hará uso de dicha información para ponerse en contacto
				con él y tramitar su pedido.
			</p>
			<p>
				Las entradas adquiridas a través de la web son para uso personal e
				intrasferible, no pudiendo revenderlas. En caso de incumplimiento de
				dicha obligación, NFT MY TICKET o el Organizador tendrán derecho a
				cancelar las entradas sin necesidad de notificación previa, reembolso o
				compensación alguna.
			</p>
			<p>
				La compra de las entradas, llevará acarreado un gasto de gestión o
				distribución, tasa de transacción o cualquier suplemente que resulte de
				aplicación según el caso concreto. Dichas cantidades serán indicadas
				antes de proceder a la compra de la entrada.
			</p>
			<p>
				Las reservas de entradas no se considerarán completadas hasta que no
				sean aceptadas por NFT MY TICKET a través de un correo electrónico que
				el Usuario recibirá una vez completada la compra.
			</p>
			<p>
				En caso de que existiera algún error en el precio de las entradas, NFT
				MY TICKET le contactará lo antes posible para cancelar la reserva, con
				la devolución de todas las cantidades abonadas, y ofrecerle la opción de
				realizar la compra de nuevo con el precio actualizado.
			</p>
			<p>
				Para iniciar el proceso de compra de los productos ofertados en el Sitio
				Web, el cliente debe necesariamente registrarse como Usuario y crear una
				cuenta. Para ello, es necesario elegir una contraseña de acceso,
				personal e intransferible.
			</p>
			<p>
				En caso de que el Usuario olvide su contraseña podrá recuperarla en
				cualquier momento siguiendo el procedimiento que se indica en el Sitio
				Web. El Usuario se obliga a notificar al Titular de forma inmediata
				cualquier hecho que permita el uso indebido de su contraseña. Mientras
				no se comuniquen tales hechos, el Titular quedará eximida de cualquier
				responsabilidad que pudiera derivarse del uso indebido de la contraseña
				por terceros no autorizados.
			</p>
			<p>
				Para proceder con la compra, el Usuario deberá acceder a su cuenta,
				seleccionar los productos que desea adquirir, hacer click en "Comprar" e
				introducir los datos de facturación y los datos de la tarjeta u otro
				medio a través del cual desea hacer el pago.
			</p>
			<p>
				Al precio final de las entradas se sumará el precio de los gastos de
				gestión que llevan acarreadas las entradas adquiridas.
			</p>
			<p>
				Tras insertar los datos de facturación, el Usuario debe marcar la
				casilla de aceptación de los presentes Términos y Condiciones para
				finalizar el proceso de compra.
			</p>
			<p>
				Al adquirir sus entradas a través de nuestra página Web, las entradas
				estarán disponibles en la cuenta que ha sido creada por del Usuario, en
				el apartado "Entradas" de dicho perfil. Las entradas, serán NFTs que
				pertenecerán al usuario que los haya adquirido e incluirán el QR que
				permitirá el acceso al evento. Para garantizar la seguridad de las
				entradas adquiridas, el QR que contiene la entrada se modificará
				automáticamente cada 5 segundos.
			</p>
			<p class="seccion">PRECIOS Y FORMA DE PAGO</p>
			<p>
				Todos los productos incluidos en el Sitio Web llevan asociado un precio
				determinado.
			</p>
			<p>
				El IVA, así como cualquier otro impuesto que fuera de aplicación está
				incluido en estos precios. No están incluidos en el precio de las
				entradas los gastos de gestión del servicio y se añadirán al total del
				importe de las entradas seleccionadas.
			</p>
			<p>
				El Usuario podrá pagar el importe de las entradas u otros productos que
				adquiera a través de la página web, mediante los siguientes medios:
			</p>
			<p>Tarjeta de crédito y/o débito: Visa, Mastercard, American Express.</p>
			<p>Coinbase commerce</p>
			<p>Usdt</p>

			<p class="seccion">POLíTICA DE DEVOLUCIONES</p>
			<p>
				NFY MY TICKET declara expresamente que no se realizaran cambios ni
				devoluciones una vez finalizado el proceso de compra salvo por causas
				previstas en la legislación vigente.
			</p>
			<p>
				Será de su exclusiva responsabilidad la revisión de todos los
				antecedentes proporcionados en el proceso de compra y de las
				características de los eventos de los cuales se ha realizado la compra
				de la entrada. No se procederá a reembolsar por errores en la compra de
				dichas entradas.
			</p>
			<p>
				Las decisiones relacionadas con la cancelación, aplazamiento o
				cancelación de los eventos son, en todo caso, responsabilidad del
				Organizados del evento y será este quien responda por ellas.
			</p>
			<p>
				En ese caso, será el Organización quien, de la opción a los compradores
				de las entradas de mantener, intercambiar la entrada o solicitar un
				reembolso en caso de cancelación o aplazamiento. En caso de que proceda
				el reembolso de las entradas, NFT MY TICKET lo pondrá en conocimiento
				del Usuario tan pronto como tenga conocimiento, indicándole el contacto
				del Organizador para la solicitud del reembolso o intercambio de la
				entrada del evento cancelado o aplazado. No serán reembolsados los
				gastos de gestión o distribución o cualquier tasa de transacción o
				comisión.
			</p>
			<p>
				Dada la relación contractual entre el Organizador y NFT MY TICKET, la
				responsabilidad de proceder con la devolución de importes será del
				Organizador. En ningún caso NFT MY TICKET, realizará estas devoluciones.
			</p>
			<p>Cualquier reembolso será abonado directamente por el Organizador.</p>
			<p class="seccion">CARACTERíSTICAS DEL EVENTO</p>
			<p>
				NFT MY TICKET no es el encargado de organizar los eventos que se
				comercializan en su web por lo que, no es responsable por cualquier daño
				o pérdida que se realice en el evento o esté relacionado con el mismo.
			</p>
			<p>
				Asimismo, la admisión del usuario al evento dependerá de las condiciones
				establecidas por el Organizador, siendo este el único responsable de
				denegar la admisión o expulsarle del recinto.
			</p>
			<p>
				NFT MY TICKET informará de las condiciones específicas o inusuales de
				cada evento como puede ser la restricción de la edad, según las
				indicaciones proporcionadas por el Organizador.
			</p>
			<p class="seccion">SERVICIO AL CLIENTE</p>
			<p>
				Con el fin de contestar todas las dudas sobre los términos y condiciones
				y solucionar cualquier problema con los servicios prestados por NFT MY
				TICKET, los consumidores pueden ponerse en contacto a través del email
				info@nftmyticket.com.
			</p>
			<p>
				Sin perjuicio de ello, recalcamos que NFT MY TICKET actúa como
				mandatario, esto es, por cuenta y riesgo del Organizador. Por lo tanto,
				que el evento se realice en las condiciones descritas y en conformidad
				con la ley es de responsabilidad exclusiva del Organizador. NFT MY
				TICKET sin perjuicio de ello, siempre responderá dudas y tratará de
				prestar un servicio de calidad a los usuarios, para que la experiencia
				de compra y de servicio sea la mejor.
			</p>
			<p class="seccion">DERECHO DE DESISTIMIENTO</p>
			<p>
				El Usuario no podrá ejercitar el derecho de desistimiento o resolución
				previsto en la normativa de consumidores y usuarios al estar dicho
				derecho excluido para los servicios relacionados con actividades de ocio
				o esparcimiento, de acuerdo con lo establecido en el artículo 103 del
				texto refundido de la Ley General para la Defensa de los Consumidores y
				Usuarios y otras leyes complementarias, aprobado por el Real Decreto
				Legislativo 1/2007, de 16 de noviembre.
			</p>
			<p class="seccion">RESPONSABILIDAD</p>
			<p>Exoneración de responsabilidad</p>
			<p>
				El usuario garantiza que los datos incluidos en los formularios que
				puedan estar disponibles en el Sitio Web, son auténticos y veraces y
				corresponden al usuario que los proporciona.
			</p>
			<p>
				El Titular no otorga ninguna garantía ni se hace responsable, en ningún
				caso, de los daños y perjuicios de cualquier naturaleza que pudieran
				derivarse del acceso o uso de los contenidos o del Sitio Web. Entre
				otras, y a título enunciativo y no limitativo, de las siguientes
				circunstancias:
			</p>
			<p>
				- De la falta de disponibilidad, mantenimiento y funcionamiento del
				Sitio Web y/o de sus servicios o contenidos en la máxima medida
				permitida por la legislación vigente, así como cualquier responsabilidad
				por los daños y perjuicios que puedan deberse a la falta de
				disponibilidad o de continuidad del Sitio Web, como por ejemplo, errores
				o retrasos en el acceso al Sitio Web por parte del usuario a la hora de
				introducir sus datos en el formulario o cualquier anomalía que pueda
				surgir y que sea ajena a la buena fe del Titular.
			</p>
			<p>
				- De la falta de utilidad del Sitio Web o los contenidos para cualquier
				servicio.
			</p>
			<p>
				- Del contenido de otras páginas de Internet a las que puedan dirigirse
				links o enlaces colocados en el Sitio Web.
			</p>
			<p>
				- De la existencia de virus, programas maliciosos o lesivos en el Sitio
				Web.
			</p>
			<p>
				- Del uso ilícito, negligente, fraudulento, contrario a los presentes
				Términos y Condiciones o a la buena fe, del Sitio Web o sus contenidos,
				por parte de los usuarios, incluido cualquier infracción de los derechos
				de propiedad intelectual y/o industrial del Titular o de terceros.
			</p>
			<p>
				En todo caso, el Titular se compromete a solucionar los problemas que
				puedan surgir y a ofrecer todo el apoyo necesario al Usuario para llegar
				a una solución rápida y satisfactoria de cualquier incidencia que se
				produzca.
			</p>
			<p>Suspensión y cancelación de los servicios</p>
			<p>
				El Titular podrá suspender temporalmente, la accesibilidad al Sitio Web
				con motivo de operaciones de mantenimiento, reparación, actualización o
				mejora. No obstante, siempre que las circunstancias lo permitan, el
				Titular informará al Usuario, con una antelación suficiente, la fecha
				prevista para la suspensión de los servicios.
			</p>
			<p>
				El Titular se reserva el derecho a eliminar, limitar o impedir el acceso
				al Sitio Web cuando surjan dificultades técnicas por hechos o
				circunstancias ajenas al Titular que, a su criterio, disminuyan o anulen
				los niveles de seguridad adoptados para el funcionamiento del Sitio Web.
			</p>
			<p>
				NFT MY TICKET tampoco asume responsabilidad por la cancelación o
				suspensión de servicios en el Sitio Web por causas que no le sean
				imputables.
			</p>
			<p>
				En todo caso, El Titular se compromete a solucionar los problemas que
				puedan surgir y a ofrecer todo el apoyo necesario al Usuario para llegar
				a una solución rápida y satisfactoria de la incidencia.
			</p>
			<p>Hipervínculos</p>
			<p>
				El Sitio Web puede contener hipervínculos que permitan al usuario
				acceder a plataformas o sitios web de terceros.
			</p>
			<p>
				NFT MY TICKET no asume ninguna responsabilidad por el contenido,
				informaciones o servicios que pudieran aparecer en dichas plataformas o
				sitios web, que se entenderán ofrecidos exclusivamente con carácter
				informativo por parte del Titular, y que en ningún caso implican
				relación, aceptación o respaldo alguno entre el Titular y las personas o
				entidades titulares de tales contenidos o titulares de los sitios donde
				se encuentren.
			</p>
			<p class="seccion">PROPIEDAD INTELECTUAL</p>
			<p>
				Todos los derechos sobre el contenido o diseño del Sitio Web y, en
				especial, con carácter enunciativo pero no limitativo, todos los
				derechos sobre las fotografías, imágenes, textos, logotipos, diseños,
				marcas, nombres comerciales, datos que se incluyen en la web y
				cualesquiera otros derechos de propiedad intelectual e industrial son
				titularidad de NFT MY TICKET.
			</p>
			<p>
				Por ello y en virtud de lo dispuesto en Real Decreto Legislativo 1/1996,
				de 12 de abril, por el que se aprueba el texto refundido de la Ley de
				Propiedad Intelectual, así como en la Ley 17/2001, de 7 de diciembre de
				Marcas y la legislación complementaria en materia de propiedad
				intelectual e industrial, queda expresamente prohibida la reproducción,
				transmisión, adaptación, traducción, distribución, comunicación pública,
				incluida su modalidad de puesta a disposición, de la totalidad o parte
				de los contenidos de la web, en cualquier soporte y por cualquier medio
				técnico, salvo autorización expresa otorgada por escrito por parte del
				Titular.
			</p>
			<p>
				El Titular no concede licencia o autorización de uso de ninguna clase
				sobre sus derechos de propiedad intelectual e industrial o sobre
				cualquier otra propiedad o derecho relacionado con la web, y que en
				ningún caso se entenderá que el acceso de navegación de los usuarios
				implica una renuncia, transmisión, licencia o cesión de dichos derechos
				por parte de NFT MY TICKET.
			</p>
			<p>
				Cualquier uso de esos contenidos no autorizado previamente por parte del
				Titular será considerado un incumplimiento grave de los derechos de
				propiedad intelectual o industrial y dará lugar a las responsabilidades
				legalmente establecidas.
			</p>
			<p class="seccion">RESOLUCIóN DE CONFLICTOS</p>
			<p>
				El Usuario, residente en la Unión Europea, puede reclamar a través de la
				Plataforma ODR (Online Dispute Resolution &ndash; Resolución de Disputas
				en Línea), que facilita la Comisión Europea a través del siguiente
				enlace: http://ec.europa.eu/consumers/odr/.
			</p>
			<p>
				La Plataforma ODR da la posibilidad a consumidores y comerciantes de
				presentar reclamaciones mediante un formulario electrónico disponible en
				todas las lenguas de la Unión Europea, para todos los asuntos
				relacionados con comercio electrónico o prestación de servicios en la
				red, conforme a lo previsto en el Reglamento 524/2013 del Parlamento
				Europeo y del Consejo de 21 de mayo de 2013 y la Directiva 2013/11/UE
				del Parlamento Europeo y del Consejo sobre resolución alternativa de
				litigios en materia de consumo.
			</p>
			<p class="seccion">LEGISLACIóN APLICABLE Y JURISDICCIóN</p>
			<p>
				Los presentes Términos y Condiciones están sometidos a la legislación
				española. Las partes, de conformidad a la Ley General para la Defensa de
				los Consumidores y Usuarios, se someten, a su elección, para la
				resolución de los conflictos y con renuncia a cualquier otro fuero, a
				los Juzgados y Tribunales del domicilio del Usuario.
			</p>
		</div>
	</div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
p,
h1 {
	color: white;

	&.seccion {
		margin-top: 3rem;
		font-weight: bold;
	}
}

a {
	color: #009f9b;
}
</style>
