var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "d-flex flex-column" }, [
      _c("div", { staticClass: "container my-5" }, [
        _c("p", [
          _vm._v(
            "\n            Todo aquel que navegue en el sitio web\n            "
          ),
          _c(
            "a",
            { attrs: { href: "www.nftmyticket.com", target: "_blank" } },
            [_vm._v("www.nftmyticket.com")]
          ),
          _vm._v(
            '\n            (en lo sucesivo, la "web") será considerado usuario.\n        '
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n            La entidad NFT MY TICKET, S.L. se reserva el derecho de\n            modificar la presente Política de Privacidad, así como las\n            Condiciones de Uso de la web, y la Política de Cookies en\n            cualquier momento y cuando lo considere oportuno y/o para\n            adaptarlas a la normativa vigente. No obstante, se recomienda al\n            usuario o a cualquier interesado que revise periódicamente estos\n            textos legales para estar informado sobre el tratamiento de sus\n            datos por la Empresa y sobre las normas de uso de la web.\n        "
          ),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "seccion" }, [
          _vm._v(
            "\n            ¿Quién es el responsable del tratamiento de sus datos\n            personales?\n        "
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v("Domicilio social: Plaza Isabel II, 5, 4ºC, 28013, Madrid."),
        ]),
        _vm._v(" "),
        _c("p", [_vm._v("N.I.F: B09863408")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n            Objeto social: Intermediación y venta online de entradas de todo\n            tipo de espectáculos (excepto taurinos)\n        "
          ),
        ]),
        _vm._v(" "),
        _c("p", [_vm._v("E-mail: info@nftmyticket.com")]),
        _vm._v(" "),
        _c("p", { staticClass: "seccion" }, [
          _vm._v(
            "\n            ¿Qué tipo de datos personales tratamos y de dónde proceden?\n        "
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n            Para navegar en la web no es necesario que el usuario revele sus\n            datos personales.\n        "
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n            No obstante, para contratar nuestros servicios, será necesario\n            introducir los datos identificativos solicitados. También será\n            necesaria la aceptación de la Política de Privacidad y de las\n            Condiciones de Uso de la web. Por ello, se recomienda al usuario\n            que, con carácter previo, lea detenidamente los referidos textos\n            legales.\n        "
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n            De no proporcionarse los datos personales solicitados y de no\n            aceptar los referidos textos legales, no se podrá ejecutar la\n            petición del usuario.\n        "
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n            Recabamos datos a través de las cookies o dispositivos de\n            almacenamiento y recuperación de datos sobre navegación y uso\n            que haces de nuestra web.\n        "
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n            En tal sentido, hemos adoptado las medidas técnicas y\n            organizativas para mantener la seguridad de sus datos, al objeto\n            de evitar, en la medida de lo posible y según el estado de la\n            técnica, su alteración, pérdida, tratamiento o acceso no\n            autorizado.\n        "
          ),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "seccion" }, [
          _vm._v("¿Con qué finalidad tratamos sus datos?"),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n            Los datos recabados a través del sitio web serán tratados por la\n            Empresa para la prestación del servicio contratado por el\n            usuario. Esta finalidad incluye: (i) gestión de pagos, (ii)\n            informar sobre posibles modificaciones de los eventos ofertados\n            en la web, (iii) fines de facturación y para poner a disposición\n            de los usuarios los justificantes de compra, (iv) el tratamiento\n            de los datos para contactar con el usuario en relación con las\n            funcionalidades, productos o servicios contratados, incluyendo\n            el envío de publicidad, promociones y encuestas sobre los\n            productos o servicios prestados.\n        "
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n            Asimismo, los datos personales podrán tratarse con la finalidad\n            de atender a las solicitudes, dudas o peticiones que realicen\n            los usuarios a través de los canales indicados en la web.\n        "
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n            La Empresa no llevará a cabo decisiones automatizadas sobre los\n            datos personales ni elaboración de perfiles.\n        "
          ),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "seccion" }, [
          _vm._v(
            "\n            ¿Cuál es la legitimación para el tratamiento de sus datos?\n        "
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n            La Empresa, para la gestión de la contratación de servicios,\n            pago, facturación y envíos correspondientes está legitimada por\n            la propia ejecución del contrato.\n        "
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n            En caso del tratamiento de los datos recogidos a través del\n            formulario de contacto, la empresa se encuentra legitimada por\n            el consentimiento previo del usuario.\n        "
          ),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "seccion" }, [
          _vm._v("¿Por cuánto tiempo conservaremos sus datos?"),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n            Los datos personales proporcionados se conservarán durante el\n            tiempo estrictamente necesario para las finalidades del\n            tratamiento para cuyo uso hayan sido proporcionados y como\n            máximo el tiempo que la normativa aplicable prevé a los efectos,\n            siempre que el usuario o interesado no haya revocado su\n            consentimiento.\n        "
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n            En cualquier caso, cuando el uso de los datos proporcionados no\n            sea necesario o se haya revocado el consentimiento, los datos\n            permanecerán bloqueados, quedando a disposición de las\n            autoridades competentes durante a los efectos de atender\n            posibles responsabilidades legales. Transcurrido ese plazo, se\n            procederá a la eliminación de los datos.\n        "
          ),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "seccion" }, [
          _vm._v(
            "\n            ¿A qué destinatarios se comunicarán sus datos?\n        "
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n            La Empresa no realizará cesiones de datos salvo aquellas\n            comunicaciones de datos que sean necesarias por obligación legal\n            (por ejemplo: administraciones públicas) o para la prestación\n            del servicio o petición solicitada.\n        "
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n            Sin perjuicio de lo anterior, la Empresa utiliza el servicio de\n            terceros que podrían tener acceso a sus datos solo en su\n            condición de Encargado de Tratamiento, y a los únicos fines de\n            prestar el servicio solicitado por la Empresa. En este sentido,\n            solo accederán a sus datos bajo las instrucciones de la Empresa\n            y de acuerdo a las condiciones contractuales impuestas por ella\n            siempre dirigidas a proteger sus datos personales.\n        "
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n            No se realizarán transferencias internacionales de los datos\n            personales.\n        "
          ),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "seccion" }, [
          _vm._v(
            "\n            ¿Cuáles son sus derechos cuando nos facilita sus datos?\n        "
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n            Cualquier interesado puede ejercer sus derechos de acceso, con\n            el fin de que la entidad la Empresa le confirme si está tratando\n            sus datos personales; de rectificación de los datos inexactos\n            que le conciernan; de supresión, cuando ya no sean necesarios\n            para los fines para los que fueron recogidos o de retirada del\n            consentimiento, entre otros supuestos. En tal caso, dejaremos de\n            tratar los datos, salvo por motivos de interés público, para el\n            cumplimiento de una obligación legal o para la formulación,\n            ejercicio y defensa de reclamaciones.\n        "
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n            Asimismo, el interesado tiene derecho a la limitación del\n            tratamiento en ciertos casos como, por ejemplo, cuando el\n            interesado impugne la exactitud de sus datos; a la portabilidad\n            de los datos personales cuando el tratamiento esté basado en el\n            consentimiento, en un contrato o se efectúe por medios\n            automatizados; de oposición y a no ser objeto de decisiones\n            basadas únicamente en el tratamiento automatizado de datos.\n        "
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n            El interesado también podrá revocar su consentimiento prestado\n            para el tratamiento de sus datos con las finalidades indicadas.\n        "
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n            El ejercicio de estos derechos es personalísimo. Por ello, el\n            interesado podrá ejercer sus derechos ante la Empresa, a través\n            del correo electrónico info@xplosionmarkets.com, mediante\n            comunicación firmada por el mismo y adjuntando copia de su\n            documento de identidad.\n        "
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n            El interesado podrá presentar una reclamación ante la Agencia\n            Española de Protección de Datos, u autoridad competente, si\n            entiende que la Empresa no ha atendido debidamente sus derechos\n            o está vulnerando sus derechos de protección de datos.\n        "
          ),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "seccion" }, [
          _vm._v(
            "\n            ¿Cómo puedes darte de baja en nuestras comunicaciones\n            comerciales?\n        "
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            '\n            El usuario o interesado podrá revocar el consentimiento prestado\n            y darse de baja, en cualquier momento, enviando un correo\n            electrónico con el texto "Baja" a través del correo electrónico\n            info@nftmyticket.com o siguiendo las instrucciones que aparecen\n            en el pie del correo electrónico recibido con nuestras\n            comunicaciones comerciales.\n        '
          ),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "seccion" }, [
          _vm._v("Aceptación y Consentimiento"),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n            El usuario declara haber sido informado de las condiciones sobre\n            protección de datos personales, aceptando y consintiendo el\n            tratamiento de los mismos por parte de la Empresa, en la forma y\n            para las finalidades indicadas en la presente Política de\n            Privacidad.\n        "
          ),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "seccion" }, [
          _vm._v(
            "\n            Cómo eliminar tu Cuenta y qué sucede al hacerlo:\n        "
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n            Tienes total libertad para borrar tu Cuenta de NFTMyTicket, sea\n            cual sea el motivo. La mayoría de la información se eliminará de\n            la Cuenta de forma inmediata, aunque puede llevar un tiempo\n            borrar todo de nuestros archivos y copias de seguridad.\n        "
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n            Si quieres eliminar tu Cuenta, puedes hacerlo enviando un mail\n            con tus datos personales al correo electrónico\n            support@nftmyticket.com. Recuerda que, al hacerlo, es posible\n            que no borres completamente el contenido de nuestro sistema, ya\n            que este puede seguir almacenado en la memoria caché, en copias\n            de seguridad o en otro tipo de copias o menciones durante un\n            tiempo.\n        "
          ),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }