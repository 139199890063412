<template>
    <div
        class="modal fade"
        ref="preregisterUser"
        id="preregisterUser"
        tabindex="-1"
        role="dialog"
        aria-labelledby="preregisterUserTitle"
        aria-hidden="true"
        style="z-index: 99999"
    >
        <div
            :class="'modal-dialog modal-dialog-scrollable modal-dialog-centered'"
            role="">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Registro de Usuario</h5>
                </div>

                <div class="modal-body">
                    <div>
                        <div class="form-group">
                            <label class="font-weight-bold color-darkblue">Nombre<sup>*</sup></label>
                            <input id="name" name="name" type="text" class="form-control" v-model="user.name"
                                   required autofocus autocomplete="off" placeholder="Nombre">
                        </div>
                        <div class="form-group">
                            <label class="font-weight-bold color-darkblue">Apellidos<sup>*</sup></label>
                            <input id="surnames" name="surnames" type="text" class="form-control"
                                   v-model="user.surnames" required autofocus autocomplete="off"
                                   placeholder="Apellidos">
                        </div>
                        <div class="form-group">
                            <label class="font-weight-bold color-darkblue">Fecha de nacimiento<sup>*</sup></label>
                            <div>
                                <form-date id="birthday" name="birthday" v-model="user.birthday"/>
                            </div>

                            <div class="fv-plugins-message-container">
                                <div class="fv-help-block" v-if="date_message !== '' && !user_is_over_16_years">
                                    {{ date_message }}
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="font-weight-bold color-darkblue">Email<sup>*</sup></label>
                            <input id="email_register" readonly disabled name="email" type="email" class="form-control"
                                   v-model="user.email" required autofocus autocomplete="off" placeholder="Email">
                            <div class="fv-plugins-message-container">
                                <div class="fv-help-block" v-if="user.email !== '' && !user_email_is_valid">La dirección
                                    introducida no es válida
                                </div>
                                <div class="fv-help-block"
                                     v-if="user.email !== '' && user_email_is_valid && user_email_exists">
                                    La dirección introducida ya existe
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="font-weight-bold color-darkblue">Confirmación de email<sup>*</sup></label>
                            <input readonly disabled  id="email_confirmation" name="email_confirmation" type="email" class="form-control"
                                   v-model="user.email_confirmation" required autofocus autocomplete="off"
                                   placeholder="Confirmación de email">
                            <div class="fv-plugins-message-container"
                                 v-if="user.email_confirmation !== '' && !user_email_confirmation_is_valid">
                                <div class="fv-help-block">Las direcciones de correo no coinciden</div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="font-weight-bold color-darkblue">Teléfono<sup>*</sup></label>
                            <input id="phone" name="phone" type="tel" class="form-control" maxlength="20"
                                   v-model="user.phone" required autofocus autocomplete="off"
                                   placeholder="Teléfono">
                        </div>
                        <div class="form-group">
                            <label class="font-weight-bold color-darkblue">Código postal<sup>*</sup></label>
                            <input id="zipcode" name="zipcode" type="text" class="form-control" maxlength="5"
                                   v-model="user.zipcode" required autofocus autocomplete="off"
                                   placeholder="Código postal">
                            <div class="fv-plugins-message-container"
                                 v-if="!user_zipcode_is_valid">
                            </div>
                        </div>
                    </div>
                </div>

                <div class="modal-footer">
                    <button :disabled="!form_is_valid" type="button" @click="registerUser()" class="btn btn-primary">Confirmar</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import FormDate from "../util/FormDate.vue";
import validateBirtday from "../util/dateUtils";

export default {
    name: "AdminEventTicketRenamingRegisterUserDialog",
    components: {
        FormDate
    },
    props: {
        userEmail: {
            type: String,
            required: false
        }
    },
    mounted() {
        this.openModal()
    },
    data: function () {
        return {
            error: false,
            date_message: '',
            user: {
                name: '',
                surnames: '',
                email: this.userEmail,
                email_confirmation: this.userEmail,
                birthday: '',
                phone: '',
                zipcode: ''
            },
            email: this.userEmail,
            password: "",
            user_email_exists: false,
        }
    },
    watch: {
        async user_email() {
            if (this.user_email_is_valid) {
                this.user_email_exists = await this.checkEmailExists(this.user.email)
            }
        },
        user: {
            handler(newValue, oldValue){
                this.$emit('onUserChanged', this.form_is_valid, newValue)
            },
            deep: true
        }
    },
    computed: {
        user_name_is_valid() {
            return this.user.name !== ''
        },
        user_surnames_is_valid() {
            return this.user.surnames !== ''
        },
        user_phone_is_valid() {
            return this.user.phone !== '' && this.user.phone.length <= 20
        },
        user_zipcode_is_valid() {
            return this.user.zipcode !== '' && this.user.zipcode.length === 5
        },
        user_is_over_16_years() {
            const dateValid = validateBirtday(this.user.birthday);
            this.date_message = dateValid.message;
            return dateValid.message === '';
        },
        user_email_confirmation_is_valid() {
            let is_empty = this.user.email_confirmation === '';
            let is_valid = !is_empty && this.emailValid(this.user.email_confirmation)
            return is_valid && this.user.email === this.user.email_confirmation
        },
        user_email() {
            return this.user.email
        },
        user_email_is_valid() {
            let is_empty = this.user.email === '';
            return !is_empty && this.emailValid(this.user.email)
        },
        form_is_valid() {
            return  this.user_name_is_valid
                && this.user_surnames_is_valid
                && this.user_phone_is_valid
                && this.user_zipcode_is_valid
                && this.user_is_over_16_years
                && this.user_email_is_valid
                && !this.user_email_exists
                && this.user_email_confirmation_is_valid
        },
    },
    methods: {
        emptyUser() {
            return {
                name: '',
                surnames: '',
                email: '',
                email_confirmation:'',
                birthday: '',
                phone: '',
                zipcode: ''
            }
        },
        openModal() {
            const me = this
            document.getElementById('preregisterUser').focus()
            console.log("openning dialog")
            $("#preregisterUser").modal("show");
            $("#preregisterUser").on('hidden.bs.modal', function () {
                console.log("closing dialog")
                me.$emit(`closePreregistrationDialog`, true);
            })
        },
        getYears(birthday) {
            let today = new Date();
            let birthdate = new Date(birthday);
            let years = today.getFullYear() - birthdate.getFullYear();
            let month = today.getMonth() - birthdate.getMonth();
            if (month < 0 || (month === 0 && today.getDate() < birthdate.getDate())) {
                years--;
            }
            return years
        },
        emailValid($email = '') {
            return /^\w+([\.+-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test($email)
        },
        async checkEmailExists($email = '') {
            try {
                let response = await axios
                    .post("/check_email_exists", {email: $email})
                return response && response.data.code === 1000 && response.data.exists
            } catch (e) {
                return false
            }
        },
        async registerUser() {
            const params = {...this.user, birthdate: this.user.birthday / 1000};
            let userRegistrationResponse = await axios.post('/preregister_user_without_login', params)
            if (userRegistrationResponse && userRegistrationResponse.data.code === 1000) {
                this.user = this.emptyUser()
                $("#preregisterUser").modal("hide");
                this.$emit("onUserPreregister", this.user.email)
            }
        }
    }

}
</script>

<style scoped>

</style>
