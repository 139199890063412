<template>
	<div class="container-fluid mt-4 mb-12">
		<div class="d-flex align-items-center mt-4">
			<router-link :to="{ path: '/events-nearby' }">
				<span class="color-white h4" style="margin-right: 20px"
					>Planes más cercanos</span
				>
				<img src="svg/flecha-pequeña.svg" width="5px" height="auto" />
			</router-link>
		</div>

		<div
			class="evecans-carrousel content p-0"
			v-if="array_events_nearby.length"
		>
			<div class="content-principal">
				<button
					role="button"
					id="arrow-left-recent"
					class="arrow-left"
					@click="carrouselLeft()"
				>
					<img src="svg/flecha-detras.svg" width="15px" height="auto" />
				</button>
				<div class="content-carousel" id="content-carousel-nearby">
					<div class="carousel">
						<div
							class="evecan custom col-8 col-md-4 col-lg-2 px-0"
							v-for="event in array_events_nearby"
							:key="event.id"
						>
							<router-link
								v-if="!event.isRestaurant"
								:to="{ path: '/event/' + event.id }"
							>
								<div class="h-100">
									<img
										v-bind:src="'/files/images/events/' + event.img_event"
										style="object-fit: cover"
									/>
									<div
										class="
											description
											p-4
											d-flex
											flex-wrap
											justify-content-between
											align-content-between
										"
									>
										<div class="w-100">
											<span
												class="event-name color-white"
												v-text="event.name"
											></span>
										</div>
										<div
											class="
												position-to-bottom
												w-100
												d-flex
												justify-content-between
											"
										>
											<div>
												<span class="h2 text-white" v-if="event.sold_out"
													>Sold out</span
												>
											</div>
											<div>
												<span class="color-white">Desde</span><br />
												<span
													class="h2 color-green"
													v-text="event.price + '€'"
												></span>
											</div>
										</div>
									</div>
								</div>
							</router-link>
							<router-link v-else :to="{ path: '/restaurant/' + event.id }">
								<div class="h-100">
									<img
										:src="`/images/restaurants/${event.file}`"
										style="object-fit: cover"
									/>
									<div
										class="
											description
											p-4
											d-flex
											flex-wrap
											justify-content-between
											align-content-between
										"
									>
										<div class="w-100">
											<span
												class="event-name color-white"
												v-text="event.name"
											></span>
										</div>
										<div class="w-100 d-flex justify-content-end">
											<span class="h2 color-green">Reservar</span>
										</div>
									</div>
								</div>
							</router-link>
						</div>
					</div>
				</div>
				<button
					role="button"
					id="arrow-right-recent"
					class="arrow-right"
					@click="carrouselRight()"
				>
					<img src="svg/flecha-delante.svg" width="15px" height="auto" />
				</button>
			</div>
		</div>
		<div class="text-white h2 mt-3" v-else>No hay planes disponibles</div>
	</div>
</template>

<script>
export default {
	name: "EventsNearbyGeneralComponent",
	data() {
		return {
			arrayCategories: [],
			show_button_carrusel_event_nearby: true,
		};
	},
	props: ["array_events_nearby"],
	methods: {
		getCategories() {
			let me = this;
			let url = "/categoriesAll";
			axios
				.get(url)
				.then(function (response) {
					me.arrayCategories = response.data;
				})
				.catch(function (error) {
					// handle error
					console.log(error);
				});
		},
		carrouselRight() {
			const fila = document.querySelector("#content-carousel-nearby");
			fila.scrollLeft += fila.offsetWidth + 20;
		},

		carrouselLeft() {
			const fila = document.querySelector("#content-carousel-nearby");
			fila.scrollLeft -= fila.offsetWidth + 20;
		},
	},
	mounted() {
		this.getCategories();
	},
};
</script>
