<template>
    <div class="transform-content">
        <div class="bg-header-general d-flex align-items-end">
            <div class="container pt-4">
                <div class="col-md-6 d-flex align-items-end">
                    <div class="p-4" style="height: 60px">
                        <div style="position: absolute; bottom: 10px">
                            <h3 class="color-white">Venta de entradas</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="container px-2">
            <form>
                <div class="d-flex flex-wrap">
                    <div class="events col-12 col-md-6">
                        <div class="card card-custom gutter-b">
                            <div class="card-header bg-color-white border-0 px-4 px-sm-4 px-md-8">
                                <div class="card-title">
                                    <h5 class="card-label font-weight-bolder color-darkblue">
                                        Eventos
                                    </h5>
                                </div>
                            </div>
                            <div class="card-body px-4 px-sm-4 px-md-8 py-4 py-sm-4 py-md-4">
                                <!-- Events -->
                                <div class="form-group d-flex flex-wrap">
                                    <label class="font-weight-bold color-darkblue">Evento:</label>
                                    <select
                                        class="form-control selectpicker w-100"
                                        id="eventsPicker"
                                        v-model="event_id"
                                        data-style="select-lightgreen"
                                    >
                                        <option
                                            :value="'0'"
                                            selected
                                        >
                                            Selecciona un evento
                                        </option>
                                        <option
                                            v-for="publicRelationsEvent in publicRelationsEvents"
                                            :key="publicRelationsEvent.event.id"
                                            :value="publicRelationsEvent.event.id"
                                        >
                                            {{ publicRelationsEvent.event.name }} ({{ publicRelationsEvent.event.city }})
                                        </option>
                                    </select>
                                </div>

                                <!-- Dates -->
                                <div class="form-group d-flex flex-wrap" v-if="selectedEvent && eventDates.length > 1">
                                    <label class="font-weight-bold color-darkblue">Fechas:</label>
                                    <select
                                        class="form-control selectpicker w-100"
                                        id="datesPicker"
                                        v-model="date_id"
                                        data-style="select-lightgreen"
                                    >
                                        <option
                                            :value="'0'"
                                            selected
                                        >
                                            Selecciona una fecha
                                        </option>
                                        <option
                                            v-for="date in eventDates"
                                            :key="date.id"
                                            :value="date.id"
                                        >
                                            {{ date.date_event }}
                                        </option>
                                    </select>
                                </div>

                                <!-- Ticket types -->
                                <div class="form-group d-flex flex-wrap" v-if="selectedDate">
                                    <label class="font-weight-bold color-darkblue">Tipos de entradas:</label>
                                    <div class="table-responsive border rounded">
                                        <table class="table table-striped table-hover mb-0">
                                            <thead>
                                            <tr>
                                                <th scope="col" colspan="2">Tipos</th>
                                                <th scope="col" style="text-align: right">Precio</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr v-if="ticketTypes.length === 0">
                                                <td colspan="3">Seleccione un evento y una fecha</td>
                                            </tr>
                                            <tr
                                                v-for="ticket in ticketTypes"
                                                :key="ticket.id"
                                                :style="ticket.tickets_available <= 0 ? 'cursor: not-allowed' : 'cursor: pointer;'"
                                                :class="ticket.tickets_available <= 0 ? 'table-danger text-danger disabled ' : (ticket_type_id === ticket.id ? 'table-success' : '')"
                                                @click="selectTicketType(ticket)"
                                            >
                                                <td class="pr-0" style="width:28px"><input type="radio" :checked="ticket_type_id === ticket.id" :disabled="ticket.tickets_available <= 0" readonly></td>
                                                <td class="px-0 py-2">
                                                    <span class="badge bg-danger text-white" v-if="ticket.tickets_available <= 0">Agotadas</span>
                                                    {{ ticket.name_ticket }}
                                                    <template v-if="ticket.name_ticket !== ticket.description_ticket"><br><small>{{ ticket.description_ticket }}</small></template>
                                                </td>
                                                <td style="text-align: right; width: 80px;"><strong>{{ ticket.num_tickets_pack ? (ticket.num_tickets_pack * ticket.price_ticket).toFixed(2) : ticket.price_ticket }}€</strong></td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <!-- Number of tickets -->
                                <div class="form-group" v-if="selectedTicketType && !isNumberedTicketType">
                                    <label class="font-weight-bold color-darkblue">Número de entradas:</label>
                                    <div class="d-flex justify-content-between align-items-center">
                                        <button type="button" class="btn btn-primary" @click="subtractTickets()" :disabled="tickets === 0">-</button>
                                        <div class="text-center"><strong>{{ tickets }} entradas</strong></div>
                                        <button type="button" class="btn btn-primary" @click="addTickets()" :disabled="tickets === max_num_tickets">+</button>
                                    </div>
                                </div>

                                <!-- Numbered tickets -->
                                <div class="form-group d-flex flex-wrap" v-if="selectedTicketType && isNumberedTicketType">
                                    <div class="d-flex flex-column justify-content-center w-100">
                                        <img
                                            @click="zoomImage()"
                                            v-if="ticket_image != null"
                                            :src="'/files/images/seatings/' + ticket_image"
                                            alt="Imagen Ticket"
                                            class="custom-image mx-auto mt-3"
                                            style="width: 100%; height: auto;"
                                        />

                                        <small class="mx-auto" v-if="ticket_image != null">Pulse en la imagen para ampliar</small>

                                        <label>Selecciona el número de asiento</label>
                                        <select
                                            v-if="!isTicketOne"
                                            class="form-control w-100 selectpicker"
                                            name="ticket_number_select"
                                            id="tickets_number_select_one"
                                            v-model="ticket_number_select"
                                            data-style="select-lightgreen"
                                        >
                                            <option disabled :value="0">Seleccione el número</option>
                                            <template v-for="(ticket, index) in array_numbered_tickets">
                                                <option
                                                    :key="index"
                                                    :value="ticket"
                                                    v-if="!selected_numbered_tickets.includes(ticket)"
                                                >
                                                    {{ ticket }}
                                                </option>
                                            </template>
                                        </select>

                                        <select
                                            v-else
                                            class="form-control w-100 selectpicker"
                                            name="ticket_number_select"
                                            id="tickets_number_select"
                                            v-model="ticket_number_select"
                                            :disabled="selected_numbered_tickets.length > 0"
                                            data-style="select-lightgreen"
                                        >
                                            <option disabled :value="0">Seleccione el número</option>
                                            <template v-for="(ticket, index) in array_numbered_tickets">
                                                <option
                                                    :key="index"
                                                    :value="ticket"
                                                    v-if="!selected_numbered_tickets.includes(ticket)"
                                                >
                                                    {{ ticket }}
                                                </option>
                                            </template>
                                        </select>
                                    </div>

                                    <div class="selected-tickets mt-3 d-flex g-2 flex-wrap">
                                        <div
                                            class="cursor-pointer badge bg-primary text-white d-flexalign-items-center"
                                            v-for="(selected_ticket, index) in selected_numbered_tickets"
                                            :key="index"
                                            @click="removeSelectedNumberedTicket(selected_ticket)"
                                        >
                                            Asiento: {{ selected_ticket }}
                                            <img
                                                src="/svg/accion-eliminar-hover.svg"
                                                height="20px"
                                                class="ml-2"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="card card-custom gutter-b">
                            <div class="card-header bg-color-white border-0 px-4 px-sm-4 px-md-8">
                                <div class="card-title">
                                    <h5 class="font-weight-bolder color-darkblue">Resumen de pago</h5>
                                </div>
                            </div>

                            <!-- Summary -->
                            <div class="card-body px-4 px-sm-4 px-md-8 py-4 py-sm-4 py-md-4">
                                <div v-if="ticket_type_id !== 0 && (selected_numbered_tickets.length > 0 || tickets > 0)">
                                    <h6 class="d-flex justify-content-between"><strong>Fecha:</strong><span>{{ selectedDate.date_event }}</span></h6>
                                    <h6 class="d-flex justify-content-between"><strong>Horario de apertura:</strong><span>{{ selectedDate.time_event_since }}</span></h6>
                                    <h6 class="d-flex justify-content-between"><strong>Ticket individual:</strong><span>{{ price_ticket.toFixed(2) }}€</span></h6>
                                    <h6 class="d-flex justify-content-between"><strong>Nº de tickets:</strong><span>{{ isNumberedTicketType ? selected_numbered_tickets.length : tickets }} ticket(s)</span></h6>
                                    <h6 class="d-flex justify-content-between"><strong>Gastos de gestión:</strong><span>{{ purchase_price.commission.toFixed(2) }}€</span></h6>
                                    <h4 class="d-flex justify-content-between text-primary"><strong>Total:</strong><span>{{ (purchase_price.price + purchase_price.commission).toFixed(2) }}€</span></h4>
                                </div>

                                <div class="d-flex flex-column justify-content-center" v-else>
                                    <img
                                        src="/svg/entradas.svg"
                                        height="48px"
                                        alt="ticket"
                                    />
                                    <p class="h4 text-primary mt-4">
                                        Selecciona un evento, un tipo de entrada y marca cuantas entradas quieres.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="user-data col-12 col-md-6">
                        <div class="card card-custom gutter-b" v-if="ticket_type_id !== 0 && (selected_numbered_tickets.length > 0 || tickets > 0)">
                            <div class="card-header bg-color-white border-0 px-4 px-sm-4 px-md-8">
                                <div class="card-title">
                                    <h5 class="font-weight-bolder color-darkblue">Datos del comprador</h5>
                                </div>
                            </div>
                            <!-- User -->
                            <div class="card-body px-4 px-sm-4 px-md-8 py-4 py-sm-4 py-md-4 text-primary">
                                <div class="form-group">
                                    <label class="font-weight-bold color-darkblue">Email<sup>*</sup></label>
                                    <div class="d-flex">
                                        <input id="email_register" name="email" type="email" class="form-control flex-fill"
                                               v-model="user.email" required autofocus autocomplete="off" placeholder="Email*">
                                        <button class="btn btn-secondary" type="button" @click="checkEmailExists" :disabled="!user_email_is_valid">Buscar</button>
                                    </div>
                                    <div class="fv-plugins-message-container">
                                        <div class="fv-help-block" v-if="user.email !== '' && !user_email_is_valid">La dirección introducida no es válida</div>
                                    </div>
                                </div>
                                <div class="form-group" v-if="user_email_is_valid && !user_email_exists">
                                    <label class="font-weight-bold color-darkblue">Confirmación de email<sup>*</sup></label>
                                    <input id="email_register" name="email_confirmation" type="email" class="form-control"
                                           v-model="user.email_confirmation" required autofocus autocomplete="off"
                                           placeholder="Confirmación de email">
                                    <div class="fv-plugins-message-container"
                                         v-if="user.email_confirmation !== '' && !user_email_confirmation_is_valid">
                                        <div class="fv-help-block">Las direcciones de correo no coinciden</div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="font-weight-bold color-darkblue">Nombre<sup>*</sup></label>
                                    <input id="name" name="name" type="text" class="form-control" v-model="user.name"
                                           required autofocus autocomplete="off" placeholder="Nombre">
                                </div>
                                <div class="form-group">
                                    <label class="font-weight-bold color-darkblue">Apellidos<sup>*</sup></label>
                                    <input id="surnames" name="surnames" type="text" class="form-control"
                                           v-model="user.surnames" required autofocus autocomplete="off"
                                           placeholder="Apellidos">
                                </div>
                                <div class="form-group">
                                    <label class="font-weight-bold color-darkblue">Fecha de nacimiento<sup>*</sup></label>
                                    <div>
                                        <form-date ref="birthdate" id="birthdate" name="birthdate" v-model="user.birthdate"/>
                                    </div>

                                    <div class="fv-plugins-message-container">
                                        <div class="fv-help-block" v-if="date_message !== '' && !user_is_over_16_years">
                                            {{ date_message }}
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="font-weight-bold color-darkblue">Teléfono<sup>*</sup></label>
                                    <input id="phone" name="phone" type="tel" class="form-control" maxlength="20"
                                           v-model="user.phone" required autofocus autocomplete="off"
                                           placeholder="Teléfono">
                                </div>
                                <div class="form-group">
                                    <label class="font-weight-bold color-darkblue">Código postal<sup>*</sup></label>
                                    <input id="zipcode" name="zipcode" type="text" class="form-control" maxlength="5"
                                           v-model="user.zipcode" required autofocus autocomplete="off"
                                           placeholder="Código postal">
                                    <div class="fv-plugins-message-container"
                                         v-if="!user_zipcode_is_valid">
                                    </div>
                                </div>

                                <!-- Terms and conditions -->
                                <div class="form-check d-flex align-items-center my-4">
                                    <input class="form-check-input" type="checkbox" id="terms-and-conditions" v-model="terms_and_conditions"/>
                                    <label class="form-check-label ml-2" for="terms-and-conditions">
                                        Acepto los
                                        <router-link
                                            style="font-weight: bold;"
                                            :to="{ path: '/terms-and-conditions' }"
                                            target="_blank"
                                        >
                                            términos y condiciones
                                        </router-link>
                                    </label>
                                </div>

                                <!-- Purchase -->
                                <div class="form-group text-center mt-8" v-if="selected_numbered_tickets.length > 0 || tickets > 0">
                                    <button
                                        type="button"
                                        class="btn btn-block btn-blue cursor-no-pointer"
                                        data-toggle="modal"
                                        v-if="(ticket_type_id === 0 && selected_numbered_tickets.length > 1) || max_num_tickets === 0"
                                        disabled
                                    >
                                        Entradas agotadas
                                    </button>

                                    <button
                                        type="button"
                                        class="btn btn-block btn-blue"
                                        v-else
                                        :disabled="!form_is_valid"
                                        @click="purchase()"
                                    >
                                        {{loading ? 'Realizando compra, espere...' :  (purchase_price.price + purchase_price.commission).toFixed(2) + '€ - Comprar' }}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>

        <ZoomImage
            ref="zoomImage"
            v-if="ticket_image != null"
            :src="'/files/images/seatings/' + ticket_image"
            alt="Imagen Ticket"
        />
    </div>
</template>

<script>
import ZoomImage from "./ZoomImageComponent.vue";
import FormDate from "./util/FormDate.vue";
import validateBirtday from "./util/dateUtils";

export default {
    components: {
        FormDate,
        ZoomImage,
    },
    data() {
        return {
            loading: false,
            publicRelationsId: Laravel.user.id,
            publicRelationsEvents: [],
            date_message: '',
            event_id: 0,
            date_id: 0,
            ticket_type_id: 0,
            error: null,
            tickets: 0,
            increment_tickets: 1,
            max_num_tickets: 1,
            ticket_image: null,
            price_ticket: 0,
            array_numbered_tickets: [],
            selected_numbered_tickets: [],
            ticket_number_select: 0,
            terms_and_conditions: false,
            user_email_exists: false,
            user: {
                id: 0,
                name: '',
                surnames: '',
                email: '',
                email_confirmation: '',
                phone: '',
                zipcode: '',
                birthdate: '',
            },
        }
    },
    computed : {
        selectedEvent() {
            let event = this.publicRelationsEvents.find(events => events.id_event === this.event_id);
            return event ? event.event : null
        },
        eventDates() {
            return this.selectedEvent ? this.selectedEvent.date : []
        },
        selectedDate() {
            return this.eventDates.find(date => date.id === this.date_id)
        },
        ticketTypes() {
            return this.selectedDate ? this.selectedDate.tickets.map(ticket => {
                ticket['tickets_available'] = parseInt(ticket.num_ticket) - ticket.sales.length
                return ticket
            }) : []
        },
        selectedTicketType() {
            return this.ticketTypes.find(type => type.id === this.ticket_type_id)
        },
        isNumberedTicketType() {
            return this.selectedTicketType && this.selectedTicketType.numbered_ticket === "1"
        },
        isTicketOne() {
            return this.selectedTicketType && this.selectedTicketType.ticket_one === "1"
        },
        isPackTicketType() {
            return this.selectedTicketType && this.selectedTicketType.num_tickets_pack > 1;
        },
        numberedTicketsSold() {
            return this.selectedTicketType.sales.map(sale => parseInt(sale.num_ticket))
        },
        purchase_price() {
            let price = 0.0
            let commission = 0.0
            if (this.ticket_type_id > 0) {
                let price_commission = parseFloat(this.selectedTicketType.commission_ticket)
                if (this.isNumberedTicketType) {
                    price = this.selected_numbered_tickets.length * this.price_ticket
                    commission = this.selected_numbered_tickets.length * price_commission
                } else {
                    if (this.isPackTicketType) {
                        price = this.tickets * this.price_ticket
                        commission = this.tickets * price_commission
                    } else {
                        price = this.tickets * this.price_ticket
                        commission = this.tickets * price_commission
                    }
                }
            }
            return {
                price,
                commission
            }
        },
        user_name_is_valid() {
            return this.user.name !== ''
        },
        user_surnames_is_valid() {
            return this.user.surnames !== ''
        },
        user_phone_is_valid() {
            return this.user.phone !== null && this.user.phone !== '' && this.user.phone.length <= 20
        },
        user_zipcode_is_valid() {
            return this.user.zipcode !== null && this.user.zipcode !== '' && this.user.zipcode.length === 5
        },
        user_is_over_16_years() {
            const dateValid = validateBirtday(this.user.birthdate);
            this.date_message = dateValid.message;
            return dateValid.message === '';
        },
        user_email_confirmation_is_valid() {
            let is_empty = this.user.email_confirmation === '';
            let is_valid = !is_empty && this.emailValid(this.user.email_confirmation)
            return this.user_email_exists || (is_valid && this.user.email === this.user.email_confirmation)
        },
        user_email() {
            return this.user.email
        },
        user_email_is_valid() {
            let is_empty = this.user.email === '';
            return !is_empty && this.emailValid(this.user.email)
        },
        form_is_valid() {
             return this.user_name_is_valid
                && this.user_surnames_is_valid
                && this.user_phone_is_valid
                && this.user_zipcode_is_valid
                 && this.user_is_over_16_years
                 && this.user_email_is_valid
                && this.user_email_confirmation_is_valid
                && this.terms_and_conditions

        },
    },
    watch: {
        event_id() {
            this.date_id = 0;
            this.date_id = (this.eventDates.length > 0) ? this.eventDates[0].id : 0
            this.$nextTick(() => {
                $("#datesPicker").selectpicker("refresh");
            })
        },
        ticket_number_select() {
            this.addSelectedNumberedTicket();
        },
        // async user_email() {
        //     if (this.user_email_is_valid) {
        //         this.user_email_exists = await this.checkEmailExists(this.user.email)
        //     }
        // },
    },
    methods: {
        async getMyEvents() {
            try {
                let response = await axios.get('/public_relations_events')
                if (response.data.code === 1000) {
                    this.publicRelationsEvents = response.data.results;
                    this.terms_and_conditions = 0;
                    this.$nextTick(() => {
                        $('#eventsPicker').selectpicker("refresh");
                    })
                } else {
                    this.error = response.data.message;
                }
            } catch (error) {
                console.log(error)
                this.error = error.data.message;
            }
        },
        zoomImage() {
            this.$refs.zoomImage.show();
        },
        selectTicketType(ticket) {
            this.$nextTick(() => {
                if (ticket.tickets_available > 0) {
                    this.ticket_type_id = ticket.id;
                    this.increment_tickets = this.selectedTicketType.num_tickets_pack ?? 1;
                    this.max_num_tickets = parseInt(this.selectedTicketType.num_ticket) - this.selectedTicketType.sales.length;
                    this.tickets = 0;
                    this.price_ticket = parseFloat(this.selectedTicketType.price_ticket)
                    this.ticket_image = this.selectedTicketType.image_seating ?? null;
                    this.array_numbered_tickets = [];
                    this.selected_numbered_tickets = [];
                    this.ticket_number_select = 0;
                    if (this.isNumberedTicketType) {
                        for (
                            let index = parseInt(this.selectedTicketType.ticket_range_since);
                            index < parseInt(this.selectedTicketType.ticket_range_until) + 1;
                            index++
                        ) {
                            if (!this.numberedTicketsSold.includes(index)) {
                                this.array_numbered_tickets.push(index);
                            }
                        }
                        this.$nextTick(() => {
                            $('#tickets_number_select_one').selectpicker("refresh");
                            $('#tickets_number_select').selectpicker("refresh");
                        })
                    }
                }
            })
        },
        removeSelectedNumberedTicket(selected_ticket) {
            // this.array_numbered_tickets.push(selected_ticket);
            // this.array_numbered_tickets.sort((a, b)  => a - b);
            const index = this.selected_tickets.indexOf(selected_ticket);
            this.selected_numbered_tickets.splice(index, 1);
            this.$nextTick(() => {
                $('#tickets_number_select_one').selectpicker("refresh");
                $('#tickets_number_select').selectpicker("refresh");
            })
            this.tickets--;

        },
        addSelectedNumberedTicket() {
            if (this.ticket_number_select !== 0) {
                this.selected_numbered_tickets.push(this.ticket_number_select);
                this.selected_numbered_tickets.sort((a, b) => a - b);
                // const index = this.array_numbered_tickets.indexOf(this.ticket_number_select);
                // this.array_numbered_tickets.splice(index, 1);
                this.ticket_number_select = 0;
                this.$nextTick(() => {
                    $('#tickets_number_select_one').selectpicker("refresh");
                    $('#tickets_number_select').selectpicker("refresh");
                })
            }
            this.tickets++;
        },
        subtractTickets() {
            if (this.tickets > 0) {
                this.tickets -= this.increment_tickets
                this.tickets = Math.max(this.tickets, 0)
            }
        },
        addTickets() {
            if (this.tickets < this.max_num_tickets) {
                this.tickets += this.increment_tickets
                this.tickets = Math.min(this.tickets, this.max_num_tickets)
            }

        },
        emailValid($email = '') {
            return /^\w+([\.+-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test($email)
        },
        async checkEmailExists() {
            try {
                if (this.user_email_is_valid) {
                    let response = await axios
                        .post("/check_email_exists", {email: this.user.email})
                    if (response && response.data.code === 1000 && response.data.exists) {
                        let date = new Date(response.data.user.birthdate);
                        this.user = {...response.data.user, birthdate: date.getTime()}
                        this.$refs.birthdate.day = date.getDate();
                        this.$refs.birthdate.month = date.getMonth() + 1;
                        this.$refs.birthdate.year = date.getFullYear();
                    } else {
                        swal(
                            "",
                            "No se ha encontrado ningún usuario con esa dirección email.",
                            "warning"
                        );
                    }
                    this.user_email_exists = (response && response.data.code === 1000 && response.data.exists)
                }
            } catch (e) {
                this.user_email_exists = false
            }
        },
        async purchase() {
            this.loading = true
            let purchase = {
                event_id: this.event_id,
                date_id: this.date_id,
                ticket_type_id: this.ticket_type_id,
                tickets: this.tickets,
                selected_numbered_tickets: this.selected_numbered_tickets,
                price: this.purchase_price,
                user: this.user
            }
            try {
                let response = await axios.post('/public_relations_purchase', purchase);
                // window.console.log('Purchase response', response)
                if (response.data.code === 1000) {
                    swal(
                        "",
                        "La compra se ha realizado correctamente. El usuario recibirá un mail con sus entradas y un acceso a su panel de nft myticket.",
                        "success"
                    );
                    this.ticket_type_id = 0;
                    this.user = {
                        id: 0,
                        name: '',
                        surnames: '',
                        email: '',
                        email_confirmation: '',
                        phone: '',
                        birthdate: '',
                        zipcode: ''
                    }
                    this.loading = false
                } else {
                    this.loading = false
                    swal(
                        "",
                        "Se produjo un error al realizar la compra de las entradas:<br>" + response.data?.message,
                        "error"
                    );
                }
            } catch (error) {
                this.loading = false
                swal(
                    "",
                    "Se produjo un error al realizar la compra de las entradas:<br>" + error.data?.message,
                    "error"
                );
            }
        }
    },
    mounted() {
        this.getMyEvents()
    }
}
</script>
