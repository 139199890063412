<template>
    <div class="container px-0">
        <div class="d-flex justify-content-end my-4">
            <div class="col-md-4">
                <select
                    class="form-control selectpicker select-green w-100"
                    name="category"
                    @change="listSongsRecent(2)"
                    v-model="id_collection"
                    id="select_collections_songs_recent"
                    data-style="select-green"
                >
                    <option :value="'0'" selected>
                        Selecciona una colección
                    </option>
                    <option
                        v-for="collection in array_collections"
                        :key="collection.id"
                        :value="collection.id"
                        v-text="collection.name_collection"
                    ></option>
                </select>
            </div>
        </div>
        <div class="d-flex align-items-center my-4">
            <span class="col-12 mr-6 color-white font-weight-bolder h4"
                >Canciones añadidas recientemente</span
            >
        </div>
        <div
            class="d-flex flex-wrap justify-content-start"
            v-if="array_songs_recent.length"
        >
            <div
                class="col-8 col-md-4 col-lg-3 mb-5"
                v-for="song in array_songs_recent"
                :key="song.id"
            >
                <div class="evecan custom">
                    <router-link :to="{ path: '/song/' + song.id }">
                        <div class="h-100">
                            <img
                                v-bind:src="
                                    '/files/images/music/' + song.img_music
                                "
                                style="object-fit: cover"
                            />
                            <div
                                class="description p-4 d-flex flex-wrap justify-content-between align-content-between bg-blue"
                            >
                                <div class="w-100">
                                    <span
                                        class="event-name color-white font-weight-bold"
                                        v-text="song.name"
                                    ></span>
                                </div>
                                <div class="w-100 text-align-end">
                                    <span
                                        class="h2 color-green"
                                        v-text="song.price + '€'"
                                    ></span>
                                </div>
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
        <div class="text-white h2 mt-3" v-else>
            No hay canciones disponibles
        </div>
        <div
            class="d-flex justify-content-center align-items-center my-4"
            v-if="hidden_button"
        >
            <button
                v-on:click="showMore()"
                type="button"
                class="btn btn-green-secondary px-4"
            >
                Ver más
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: "SongsRecentComponent",
    data() {
        return {
            category: "",
            array_collections: [],
            array_songs_recent: [],
            start: 0,
            hidden_button: false,
            id_collection: 0,
        };
    },
    methods: {
        listCollections() {
            let me = this;
            let url = "/list_collections_select";
            axios
                .get(url)
                .then(function (response) {
                    me.array_collections = response.data.array_collections;
                })
                .catch(function (error) {
                    // handle error
                    console.log(error);
                });
        },
        listSongsRecent(type) {
            let me = this;

            let params = {
                start: me.start,
                id_collection: me.id_collection,
            };

            axios
                .post("/list_songs_recent", params)
                .then((response) => {
                    if (response.data.code == 1000) {
                        if (type == 2) {
                            me.array_songs_recent = [];
                        }
                        response.data.array_songs_recent.forEach(function (
                            value,
                            index,
                            array
                        ) {
                            me.array_songs_recent.push(value);
                        });
                        me.hidden_button = response.data.hidden_button;
                    } else {
                        swal(
                            "",
                            "Parece que ha habido un error, inténtalo de nuevo más tarde",
                            "error"
                        );
                    }
                })
                .catch(function (error) {
                    swal(
                        "",
                        "Parece que ha habido un error, inténtalo de nuevo más tarde",
                        "error"
                    );
                });
        },
        showMore() {
            let me = this;
            me.start += 12;
            me.listSongsRecent(1);
        },
    },
    mounted() {
        this.listCollections();
        this.listSongsRecent(1);
    },
    updated() {
        if (this.id_collection == 0) {
            $("#select_collections_songs_recent").val(0);
        }
        $("#select_collections_songs_recent").selectpicker("refresh");
    },
};
</script>
