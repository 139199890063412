var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "d-flex flex-column" }, [
      _c("div", { staticClass: "container my-5" }, [
        _c("p", { staticClass: "seccion" }, [_vm._v("INTRODUCCIÓN")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            '\n\t\t\tEl presente documento (en adelante, las "Condiciones"), tiene por objeto\n\t\t\testablecer los Términos y Condiciones Generales de Venta de productos\n\t\t\tofrecidos por la sociedad NFT MY TICKET, S.L. con domicilio en Plaza\n\t\t\tIsabel II, 5, 4ºC, 28013, Madrid y NIF B09863408 (en adelante, el\n\t\t\t"Titular" o "NFT MY TICKET"), a través de su página web\n\t\t\twww.nftmyticket.com (en adelante, el "Sitio Web"), así como cumplir con\n\t\t\tla obligación de información de la Ley 34/2002, de 11 de julio, de\n\t\t\tservicios de la sociedad de la información y de comercio electrónico.\n\t\t'
          ),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "seccion" }, [
          _vm._v("DATOS DE USUARIOS Y CONDUCTA DEL SITIO WEB"),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n\t\t\tEl Usuario será responsable de mantener la confidencialidad de los datos\n\t\t\te información aportados y de restringir el acceso a su ordenador y a sus\n\t\t\tclaves de acceso para evitar posibles usos no autorizados.\n\t\t"
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n\t\t\tEn caso de que el Usuario tenga motivos para creer que sus datos han\n\t\t\tsido puestos en conocimiento de un tercero, o si estos han sido\n\t\t\tutilizados de manera no autorizada, o son susceptibles de serlo, deberá\n\t\t\tcomunicar esta situación al Titular de forma inmediata.\n\t\t"
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n\t\t\tLos Usuarios se comprometen a hacer un uso lícito, diligente, honesto y\n\t\t\tcorrecto de cuanta información o contenidos tengan acceso a través del\n\t\t\tSitio Web, y todo ello bajo los principios de la buena fe y respetando\n\t\t\ten todo momento a la legalidad vigente y los presentes Términos y\n\t\t\tCondiciones.\n\t\t"
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n\t\t\tEl Titular se reserva el derecho a denegar el acceso al Sitio Web a\n\t\t\tUsuarios que hagan un mal uso de los contenidos y/o incumplan cualquiera\n\t\t\tde las condiciones que aparecen en el presente documento.\n\t\t"
          ),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "seccion" }, [
          _vm._v("FUNCIONAMIENTO DE LA WEB"),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            '\n\t\t\tEl presente Sitio Web ha sido desarrollado por el Titular con la\n\t\t\tfinalidad de ofrecer a los Usuarios la venta de entradas de forma\n\t\t\tdigital para espectáculos artísticos, musicales, culturales, deportivos,\n\t\t\tetc., en adelante los "Eventos".\n\t\t'
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            '\n\t\t\tNFT MY TICKET actúa como mandataria, esto es, por cuenta y orden del\n\t\t\torganizador, productor o gestor del evento o espectáculo, en adelante\n\t\t\ttambién el "Organizador", prestando a este último los servicios de venta\n\t\t\ty distribución de entradas.\n\t\t'
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n\t\t\tEl Organizador es el único y exclusivo responsable de la producción y\n\t\t\torganización del Evento, sujeto a las condiciones de venta que el mismo\n\t\t\testablezca. El Organizador es el responsable de la definición de\n\t\t\tprecios, características del evento y la correcta ejecución del mismo,\n\t\t\tpor lo que el Titular no asume responsabilidad a este respecto.\n\t\t"
          ),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "seccion" }, [_vm._v("PROCESO DE COMPRA")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n\t\t\tAl realizar compras a través del Sitio Web, el Usuario se compromete a\n\t\t\t(i) no realizar pedidos falsos; y, (ii) facilitarnos sus datos\n\t\t\tpersonales de forma veraz y exacta. El Usuario declara ser consciente de\n\t\t\tque el Titular hará uso de dicha información para ponerse en contacto\n\t\t\tcon él y tramitar su pedido.\n\t\t"
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n\t\t\tLas entradas adquiridas a través de la web son para uso personal e\n\t\t\tintrasferible, no pudiendo revenderlas. En caso de incumplimiento de\n\t\t\tdicha obligación, NFT MY TICKET o el Organizador tendrán derecho a\n\t\t\tcancelar las entradas sin necesidad de notificación previa, reembolso o\n\t\t\tcompensación alguna.\n\t\t"
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n\t\t\tLa compra de las entradas, llevará acarreado un gasto de gestión o\n\t\t\tdistribución, tasa de transacción o cualquier suplemente que resulte de\n\t\t\taplicación según el caso concreto. Dichas cantidades serán indicadas\n\t\t\tantes de proceder a la compra de la entrada.\n\t\t"
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n\t\t\tLas reservas de entradas no se considerarán completadas hasta que no\n\t\t\tsean aceptadas por NFT MY TICKET a través de un correo electrónico que\n\t\t\tel Usuario recibirá una vez completada la compra.\n\t\t"
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n\t\t\tEn caso de que existiera algún error en el precio de las entradas, NFT\n\t\t\tMY TICKET le contactará lo antes posible para cancelar la reserva, con\n\t\t\tla devolución de todas las cantidades abonadas, y ofrecerle la opción de\n\t\t\trealizar la compra de nuevo con el precio actualizado.\n\t\t"
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n\t\t\tPara iniciar el proceso de compra de los productos ofertados en el Sitio\n\t\t\tWeb, el cliente debe necesariamente registrarse como Usuario y crear una\n\t\t\tcuenta. Para ello, es necesario elegir una contraseña de acceso,\n\t\t\tpersonal e intransferible.\n\t\t"
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n\t\t\tEn caso de que el Usuario olvide su contraseña podrá recuperarla en\n\t\t\tcualquier momento siguiendo el procedimiento que se indica en el Sitio\n\t\t\tWeb. El Usuario se obliga a notificar al Titular de forma inmediata\n\t\t\tcualquier hecho que permita el uso indebido de su contraseña. Mientras\n\t\t\tno se comuniquen tales hechos, el Titular quedará eximida de cualquier\n\t\t\tresponsabilidad que pudiera derivarse del uso indebido de la contraseña\n\t\t\tpor terceros no autorizados.\n\t\t"
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            '\n\t\t\tPara proceder con la compra, el Usuario deberá acceder a su cuenta,\n\t\t\tseleccionar los productos que desea adquirir, hacer click en "Comprar" e\n\t\t\tintroducir los datos de facturación y los datos de la tarjeta u otro\n\t\t\tmedio a través del cual desea hacer el pago.\n\t\t'
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n\t\t\tAl precio final de las entradas se sumará el precio de los gastos de\n\t\t\tgestión que llevan acarreadas las entradas adquiridas.\n\t\t"
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n\t\t\tTras insertar los datos de facturación, el Usuario debe marcar la\n\t\t\tcasilla de aceptación de los presentes Términos y Condiciones para\n\t\t\tfinalizar el proceso de compra.\n\t\t"
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            '\n\t\t\tAl adquirir sus entradas a través de nuestra página Web, las entradas\n\t\t\testarán disponibles en la cuenta que ha sido creada por del Usuario, en\n\t\t\tel apartado "Entradas" de dicho perfil. Las entradas, serán NFTs que\n\t\t\tpertenecerán al usuario que los haya adquirido e incluirán el QR que\n\t\t\tpermitirá el acceso al evento. Para garantizar la seguridad de las\n\t\t\tentradas adquiridas, el QR que contiene la entrada se modificará\n\t\t\tautomáticamente cada 5 segundos.\n\t\t'
          ),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "seccion" }, [
          _vm._v("PRECIOS Y FORMA DE PAGO"),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n\t\t\tTodos los productos incluidos en el Sitio Web llevan asociado un precio\n\t\t\tdeterminado.\n\t\t"
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n\t\t\tEl IVA, así como cualquier otro impuesto que fuera de aplicación está\n\t\t\tincluido en estos precios. No están incluidos en el precio de las\n\t\t\tentradas los gastos de gestión del servicio y se añadirán al total del\n\t\t\timporte de las entradas seleccionadas.\n\t\t"
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n\t\t\tEl Usuario podrá pagar el importe de las entradas u otros productos que\n\t\t\tadquiera a través de la página web, mediante los siguientes medios:\n\t\t"
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "Tarjeta de crédito y/o débito: Visa, Mastercard, American Express."
          ),
        ]),
        _vm._v(" "),
        _c("p", [_vm._v("Coinbase commerce")]),
        _vm._v(" "),
        _c("p", [_vm._v("Usdt")]),
        _vm._v(" "),
        _c("p", { staticClass: "seccion" }, [
          _vm._v("POLíTICA DE DEVOLUCIONES"),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n\t\t\tNFY MY TICKET declara expresamente que no se realizaran cambios ni\n\t\t\tdevoluciones una vez finalizado el proceso de compra salvo por causas\n\t\t\tprevistas en la legislación vigente.\n\t\t"
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n\t\t\tSerá de su exclusiva responsabilidad la revisión de todos los\n\t\t\tantecedentes proporcionados en el proceso de compra y de las\n\t\t\tcaracterísticas de los eventos de los cuales se ha realizado la compra\n\t\t\tde la entrada. No se procederá a reembolsar por errores en la compra de\n\t\t\tdichas entradas.\n\t\t"
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n\t\t\tLas decisiones relacionadas con la cancelación, aplazamiento o\n\t\t\tcancelación de los eventos son, en todo caso, responsabilidad del\n\t\t\tOrganizados del evento y será este quien responda por ellas.\n\t\t"
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n\t\t\tEn ese caso, será el Organización quien, de la opción a los compradores\n\t\t\tde las entradas de mantener, intercambiar la entrada o solicitar un\n\t\t\treembolso en caso de cancelación o aplazamiento. En caso de que proceda\n\t\t\tel reembolso de las entradas, NFT MY TICKET lo pondrá en conocimiento\n\t\t\tdel Usuario tan pronto como tenga conocimiento, indicándole el contacto\n\t\t\tdel Organizador para la solicitud del reembolso o intercambio de la\n\t\t\tentrada del evento cancelado o aplazado. No serán reembolsados los\n\t\t\tgastos de gestión o distribución o cualquier tasa de transacción o\n\t\t\tcomisión.\n\t\t"
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n\t\t\tDada la relación contractual entre el Organizador y NFT MY TICKET, la\n\t\t\tresponsabilidad de proceder con la devolución de importes será del\n\t\t\tOrganizador. En ningún caso NFT MY TICKET, realizará estas devoluciones.\n\t\t"
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "Cualquier reembolso será abonado directamente por el Organizador."
          ),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "seccion" }, [
          _vm._v("CARACTERíSTICAS DEL EVENTO"),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n\t\t\tNFT MY TICKET no es el encargado de organizar los eventos que se\n\t\t\tcomercializan en su web por lo que, no es responsable por cualquier daño\n\t\t\to pérdida que se realice en el evento o esté relacionado con el mismo.\n\t\t"
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n\t\t\tAsimismo, la admisión del usuario al evento dependerá de las condiciones\n\t\t\testablecidas por el Organizador, siendo este el único responsable de\n\t\t\tdenegar la admisión o expulsarle del recinto.\n\t\t"
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n\t\t\tNFT MY TICKET informará de las condiciones específicas o inusuales de\n\t\t\tcada evento como puede ser la restricción de la edad, según las\n\t\t\tindicaciones proporcionadas por el Organizador.\n\t\t"
          ),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "seccion" }, [_vm._v("SERVICIO AL CLIENTE")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n\t\t\tCon el fin de contestar todas las dudas sobre los términos y condiciones\n\t\t\ty solucionar cualquier problema con los servicios prestados por NFT MY\n\t\t\tTICKET, los consumidores pueden ponerse en contacto a través del email\n\t\t\tinfo@nftmyticket.com.\n\t\t"
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n\t\t\tSin perjuicio de ello, recalcamos que NFT MY TICKET actúa como\n\t\t\tmandatario, esto es, por cuenta y riesgo del Organizador. Por lo tanto,\n\t\t\tque el evento se realice en las condiciones descritas y en conformidad\n\t\t\tcon la ley es de responsabilidad exclusiva del Organizador. NFT MY\n\t\t\tTICKET sin perjuicio de ello, siempre responderá dudas y tratará de\n\t\t\tprestar un servicio de calidad a los usuarios, para que la experiencia\n\t\t\tde compra y de servicio sea la mejor.\n\t\t"
          ),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "seccion" }, [
          _vm._v("DERECHO DE DESISTIMIENTO"),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n\t\t\tEl Usuario no podrá ejercitar el derecho de desistimiento o resolución\n\t\t\tprevisto en la normativa de consumidores y usuarios al estar dicho\n\t\t\tderecho excluido para los servicios relacionados con actividades de ocio\n\t\t\to esparcimiento, de acuerdo con lo establecido en el artículo 103 del\n\t\t\ttexto refundido de la Ley General para la Defensa de los Consumidores y\n\t\t\tUsuarios y otras leyes complementarias, aprobado por el Real Decreto\n\t\t\tLegislativo 1/2007, de 16 de noviembre.\n\t\t"
          ),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "seccion" }, [_vm._v("RESPONSABILIDAD")]),
        _vm._v(" "),
        _c("p", [_vm._v("Exoneración de responsabilidad")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n\t\t\tEl usuario garantiza que los datos incluidos en los formularios que\n\t\t\tpuedan estar disponibles en el Sitio Web, son auténticos y veraces y\n\t\t\tcorresponden al usuario que los proporciona.\n\t\t"
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n\t\t\tEl Titular no otorga ninguna garantía ni se hace responsable, en ningún\n\t\t\tcaso, de los daños y perjuicios de cualquier naturaleza que pudieran\n\t\t\tderivarse del acceso o uso de los contenidos o del Sitio Web. Entre\n\t\t\totras, y a título enunciativo y no limitativo, de las siguientes\n\t\t\tcircunstancias:\n\t\t"
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n\t\t\t- De la falta de disponibilidad, mantenimiento y funcionamiento del\n\t\t\tSitio Web y/o de sus servicios o contenidos en la máxima medida\n\t\t\tpermitida por la legislación vigente, así como cualquier responsabilidad\n\t\t\tpor los daños y perjuicios que puedan deberse a la falta de\n\t\t\tdisponibilidad o de continuidad del Sitio Web, como por ejemplo, errores\n\t\t\to retrasos en el acceso al Sitio Web por parte del usuario a la hora de\n\t\t\tintroducir sus datos en el formulario o cualquier anomalía que pueda\n\t\t\tsurgir y que sea ajena a la buena fe del Titular.\n\t\t"
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n\t\t\t- De la falta de utilidad del Sitio Web o los contenidos para cualquier\n\t\t\tservicio.\n\t\t"
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n\t\t\t- Del contenido de otras páginas de Internet a las que puedan dirigirse\n\t\t\tlinks o enlaces colocados en el Sitio Web.\n\t\t"
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n\t\t\t- De la existencia de virus, programas maliciosos o lesivos en el Sitio\n\t\t\tWeb.\n\t\t"
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n\t\t\t- Del uso ilícito, negligente, fraudulento, contrario a los presentes\n\t\t\tTérminos y Condiciones o a la buena fe, del Sitio Web o sus contenidos,\n\t\t\tpor parte de los usuarios, incluido cualquier infracción de los derechos\n\t\t\tde propiedad intelectual y/o industrial del Titular o de terceros.\n\t\t"
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n\t\t\tEn todo caso, el Titular se compromete a solucionar los problemas que\n\t\t\tpuedan surgir y a ofrecer todo el apoyo necesario al Usuario para llegar\n\t\t\ta una solución rápida y satisfactoria de cualquier incidencia que se\n\t\t\tproduzca.\n\t\t"
          ),
        ]),
        _vm._v(" "),
        _c("p", [_vm._v("Suspensión y cancelación de los servicios")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n\t\t\tEl Titular podrá suspender temporalmente, la accesibilidad al Sitio Web\n\t\t\tcon motivo de operaciones de mantenimiento, reparación, actualización o\n\t\t\tmejora. No obstante, siempre que las circunstancias lo permitan, el\n\t\t\tTitular informará al Usuario, con una antelación suficiente, la fecha\n\t\t\tprevista para la suspensión de los servicios.\n\t\t"
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n\t\t\tEl Titular se reserva el derecho a eliminar, limitar o impedir el acceso\n\t\t\tal Sitio Web cuando surjan dificultades técnicas por hechos o\n\t\t\tcircunstancias ajenas al Titular que, a su criterio, disminuyan o anulen\n\t\t\tlos niveles de seguridad adoptados para el funcionamiento del Sitio Web.\n\t\t"
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n\t\t\tNFT MY TICKET tampoco asume responsabilidad por la cancelación o\n\t\t\tsuspensión de servicios en el Sitio Web por causas que no le sean\n\t\t\timputables.\n\t\t"
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n\t\t\tEn todo caso, El Titular se compromete a solucionar los problemas que\n\t\t\tpuedan surgir y a ofrecer todo el apoyo necesario al Usuario para llegar\n\t\t\ta una solución rápida y satisfactoria de la incidencia.\n\t\t"
          ),
        ]),
        _vm._v(" "),
        _c("p", [_vm._v("Hipervínculos")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n\t\t\tEl Sitio Web puede contener hipervínculos que permitan al usuario\n\t\t\tacceder a plataformas o sitios web de terceros.\n\t\t"
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n\t\t\tNFT MY TICKET no asume ninguna responsabilidad por el contenido,\n\t\t\tinformaciones o servicios que pudieran aparecer en dichas plataformas o\n\t\t\tsitios web, que se entenderán ofrecidos exclusivamente con carácter\n\t\t\tinformativo por parte del Titular, y que en ningún caso implican\n\t\t\trelación, aceptación o respaldo alguno entre el Titular y las personas o\n\t\t\tentidades titulares de tales contenidos o titulares de los sitios donde\n\t\t\tse encuentren.\n\t\t"
          ),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "seccion" }, [_vm._v("PROPIEDAD INTELECTUAL")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n\t\t\tTodos los derechos sobre el contenido o diseño del Sitio Web y, en\n\t\t\tespecial, con carácter enunciativo pero no limitativo, todos los\n\t\t\tderechos sobre las fotografías, imágenes, textos, logotipos, diseños,\n\t\t\tmarcas, nombres comerciales, datos que se incluyen en la web y\n\t\t\tcualesquiera otros derechos de propiedad intelectual e industrial son\n\t\t\ttitularidad de NFT MY TICKET.\n\t\t"
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n\t\t\tPor ello y en virtud de lo dispuesto en Real Decreto Legislativo 1/1996,\n\t\t\tde 12 de abril, por el que se aprueba el texto refundido de la Ley de\n\t\t\tPropiedad Intelectual, así como en la Ley 17/2001, de 7 de diciembre de\n\t\t\tMarcas y la legislación complementaria en materia de propiedad\n\t\t\tintelectual e industrial, queda expresamente prohibida la reproducción,\n\t\t\ttransmisión, adaptación, traducción, distribución, comunicación pública,\n\t\t\tincluida su modalidad de puesta a disposición, de la totalidad o parte\n\t\t\tde los contenidos de la web, en cualquier soporte y por cualquier medio\n\t\t\ttécnico, salvo autorización expresa otorgada por escrito por parte del\n\t\t\tTitular.\n\t\t"
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n\t\t\tEl Titular no concede licencia o autorización de uso de ninguna clase\n\t\t\tsobre sus derechos de propiedad intelectual e industrial o sobre\n\t\t\tcualquier otra propiedad o derecho relacionado con la web, y que en\n\t\t\tningún caso se entenderá que el acceso de navegación de los usuarios\n\t\t\timplica una renuncia, transmisión, licencia o cesión de dichos derechos\n\t\t\tpor parte de NFT MY TICKET.\n\t\t"
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n\t\t\tCualquier uso de esos contenidos no autorizado previamente por parte del\n\t\t\tTitular será considerado un incumplimiento grave de los derechos de\n\t\t\tpropiedad intelectual o industrial y dará lugar a las responsabilidades\n\t\t\tlegalmente establecidas.\n\t\t"
          ),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "seccion" }, [
          _vm._v("RESOLUCIóN DE CONFLICTOS"),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n\t\t\tEl Usuario, residente en la Unión Europea, puede reclamar a través de la\n\t\t\tPlataforma ODR (Online Dispute Resolution – Resolución de Disputas\n\t\t\ten Línea), que facilita la Comisión Europea a través del siguiente\n\t\t\tenlace: http://ec.europa.eu/consumers/odr/.\n\t\t"
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n\t\t\tLa Plataforma ODR da la posibilidad a consumidores y comerciantes de\n\t\t\tpresentar reclamaciones mediante un formulario electrónico disponible en\n\t\t\ttodas las lenguas de la Unión Europea, para todos los asuntos\n\t\t\trelacionados con comercio electrónico o prestación de servicios en la\n\t\t\tred, conforme a lo previsto en el Reglamento 524/2013 del Parlamento\n\t\t\tEuropeo y del Consejo de 21 de mayo de 2013 y la Directiva 2013/11/UE\n\t\t\tdel Parlamento Europeo y del Consejo sobre resolución alternativa de\n\t\t\tlitigios en materia de consumo.\n\t\t"
          ),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "seccion" }, [
          _vm._v("LEGISLACIóN APLICABLE Y JURISDICCIóN"),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n\t\t\tLos presentes Términos y Condiciones están sometidos a la legislación\n\t\t\tespañola. Las partes, de conformidad a la Ley General para la Defensa de\n\t\t\tlos Consumidores y Usuarios, se someten, a su elección, para la\n\t\t\tresolución de los conflictos y con renuncia a cualquier otro fuero, a\n\t\t\tlos Juzgados y Tribunales del domicilio del Usuario.\n\t\t"
          ),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }