<template>
    <div v-if="event.active == 1" class="transform-content">
        <div class="bg-header-general">
            <div class="container pt-4 mt-4 px-2">
                <div class="col-md-6 d-flex align-items-end" v-if="event != ''">
                    <img
                        v-bind:src="'/files/images/events/' + event.img_event"
                        style="object-fit: cover; border-radius: 0.5rem; width: 200px; height: auto; max-width: 30vw; aspect-ratio: 1 / 1;"
                    />
                    <div class="px-4 pt-8">
                        <h2 class="color-white font-weight-bolder">
                            {{ event.name }}
                        </h2>
                        <div class="d-flex align-items-center">
                            <img
                                :src="publicPath + '/svg/calendario-blanco.svg'"
                                width="15px"
                                height="auto"
                            />

                            <span class="color-white mx-1">{{ custom_date }}</span>

                        </div>
                        <div v-if="isFinished" class="d-flex align-items-center">
                            <span  class="color-white mx-1">{{ "FINALIZADO" }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container px-2">
            <div class="d-flex flex-wrap">
                <EventSummary :ticket_types="array_tickets" :event="event"/>
                <div id="realiza-tu-compra"
                     class="buy-tickets col-12 col-md-6 position-relative order-0 order-sm-0 order-md-1">
                    <BuyTicketSelection :is-finished="isFinished" @ticketCartFinish="processCart" :ticket_types="array_dates[0].array_tickets"/>
                </div>
            </div>
        </div>

        <BuyCheckout @onTransaccionSuccess="(id_transaction) => setTransaction(id_transaction)" :ticket_cart="ticket_cart" :event="event" :value_usdt="parseFloat(value_usdt)"/>
        <AdminEventTicketRenamingDialog @onFinishRename="(success) => setTransaction(0)" v-if="transaction_id>0" :transaction_id="transaction_id"/>

        <ZoomImage
            ref="zoomImage"
            v-if="ticket_image != null"
            :src="'/files/images/seatings/' + ticket_image"
            alt="Imagen Ticket"
        />
        <div v-html="event.meta_pixel_code"/>

        </div>
</template>

<script>
import json_abi_polygon from "../../../../../public/abi_usdt_polygon.json";
import ZoomImage from "../../ZoomImageComponent.vue";
import c from '../../../const.js';
import FormDate from "../../util/FormDate.vue";
import BuyTicketSelection from "./BuyTicketSelection";
import BuyCartSummary from "./BuyCartSummary";
import BuyUserRegistration from "./BuyUserRegistration";
import EventSummary from "./EventSummary";
import BuyCheckout from "./BuyCheckout";
import AdminEventTicketRenamingDialog from "../AdminEventTicketRenamingDialog.vue";


export default {
    components: {
        AdminEventTicketRenamingDialog,
        BuyCheckout,
        EventSummary,
        BuyUserRegistration,
        BuyCartSummary,
        BuyTicketSelection,
        ZoomImage,
        FormDate
    },
    data() {
        return {
            redsys_url: window.REDSYS_URL,
            redsys_ds_signature_version: window.REDSYS_DS_SIGNATUREVERSION ?? 'HMAC_SHA256_V1',
            publicPath: window.location.origin,
            pay_selected: 1,
            id_event: "",
            event: "",
            array_tickets: [],
            length_array_tickets: 0,
            array_dates: [],
            custom_date: "",
            select_type_ticket: 0,
            price_ticket: 0,
            price_ticket_individual: 0,
            num_tickets: 1,
            id_type_ticket: 0,
            select_date_event: 0,
            commission_ticket: 0,
            price_commission: 0,
            account_wallet: "",
            abi: JSON.stringify(json_abi_polygon),
            web3: "",
            value_usdt: 0,
            price_usdt: 0,
            is_login: window.Laravel.isLoggedin,
            map: "",
            max_num_tickets: 0,
            min_num_tickets: 1,
            innerWidth: window.innerWidth,
            array_tickets_select: [],
            saldo_regalo_a_usar: "",
            total_gifts: 1000,
            terms_and_conditions: false,
            loading: false,
            eventHasNFT: false,
            ticket_image: null,
            all_tickets_sold_out: false,
            anticipado: 0,
            code_check: "",

            isFinished: false,
            new_ticket_price: 0,
            numbered_ticket: false,
            ticket_one: false,
            ticket_number_select: null,
            max_numbered_ticket: 0,
            array_numbered_tickets: [],
            selected_tickets: [],

            // Preregistration user
            error: false,
            user: {
                name: '',
                surnames: '',
                email: '',
                email_confirmation: '',
                birthday: '',
                phone: '',
                zipcode: ''
            },
            date_message: '',
            user_email_exists: false,
            email: "",
            password: "",
            reloadAfterLogin: false,

            // Ticket cart
            ticket_cart: {},

            // User
            is_valid_user: false,

            openRenaming: false,
            transaction_id: 0,
        }
    },
    computed: {
        ticket_price() {
            return this.new_ticket_price * this.selected_tickets.length;
        },
    },
    watch: {
        select_type_ticket() {
            this.selected_tickets = [];
            this.array_tickets_select.forEach((ticket, index) => {
                if (ticket.id == this.select_type_ticket) {
                    this.new_ticket_price = parseFloat(ticket.price_ticket);
                    this.array_numbered_tickets = [];
                    this.numbered_ticket = ticket.numbered_ticket == "1";
                    this.ticket_one = ticket.ticket_one == "1";
                    for (
                        let index = parseInt(ticket.ticket_range_since);
                        index < parseInt(ticket.ticket_range_until) + 1;
                        index++
                    ) {
                        this.array_numbered_tickets.push(index);
                    }

                    ticket.sales.forEach((sale) => {
                        if (
                            this.array_numbered_tickets.includes(parseInt(sale.num_ticket))
                        ) {
                            const id = this.array_numbered_tickets.indexOf(
                                parseInt(sale.num_ticket)
                            );
                            this.array_numbered_tickets.splice(id, 1);
                        }
                    });

                    this.ticket_image = ticket.image_seating;
                }
            });
        },
        all_tickets_sold_out() {
            // alert("Todas las entradas vendidas");
        },
        ticket_number_select() {
            if (this.ticket_number_select != null) {
                this.selected_tickets.push(this.ticket_number_select);
                this.selected_tickets.sort(function (a, b) {
                    return a - b;
                });

                const index = this.array_numbered_tickets.indexOf(
                    this.ticket_number_select
                );
                this.array_numbered_tickets.splice(index, 1);

                this.ticket_number_select = null;
            }
        },
    },
    methods: {
        setTransaction(id){
            this.transaction_id = id
        },
        log(item) {
            console.log(item)
        },
        zoomImage() {
            this.$refs.zoomImage.show();
        },
        processCart(ticket_cart) {
            this.ticket_cart = ticket_cart
            $("#buyModal").modal("show");
        },

        selectTicketType(ticket) {
            if (ticket.tickets_available > 0) {
                this.select_type_ticket = ticket.id
            }
        },
        showBidModal() {
            $(".value-bid").val(this.nft.price);
            $("#bidModal").modal("toggle");
        },
        getInfoEvent() {
            let me = this;
            let url = "/get_info_event/" + me.id_event;
            axios
                .get(url)
                .then(function (response) {
                    var html_description = "";
                    var description = response.data.event.description;
                    html_description += description;
                    me.event = response.data.event;
                    if(me.event && me.event.meta_pixel_code){
                        $("head").append(me.event.meta_pixel_code);
                    }

                    me.event.description = html_description;

                    //response.data.array_dates[0].array_tickets[0].tickets_available = 0

                    me.array_tickets = response.data.array_tickets;
                    me.array_tickets_select = response.data.array_dates[0].array_tickets
                        .filter(ticketEvent => !ticketEvent.hidden);

                    me.length_array_tickets = me.array_tickets_select.length;
                    me.array_dates = response.data.array_dates;
                    me.custom_date = me.array_dates[0].custom_date;

                    let endDate = Date.parse(me.array_dates[0].date_event_end_sell + " " + me.array_dates[0].time_event_end_sell)
                    me.isFinished = endDate < new Date().getTime();

                        //    if (new Date(me.array_dates[0].date_event + " " + me.array_dates[0].time_event_end_sell) < new Date()) {
                //        me.$router.push("/");
                //    }

                    me.select_date_event = me.array_dates[0].id;
                    me.array_dates.forEach(function (value, index, array) {
                        if (me.select_date_event == value.id) {
                            me.anticipado = value.anticipado;
                        }
                    });

                    me.value_usdt = response.data.value_usdt;

                    let contador_tickets_restantes = 0;
                    me.array_dates[0].array_tickets.forEach((ticket) => {
                        contador_tickets_restantes += ticket.tickets_available;
                    });

                    me.all_tickets_sold_out = contador_tickets_restantes == 0;

                    if (me.length_array_tickets == 1) {
                        me.price_ticket = parseFloat(response.data.array_tickets[0].price_ticket).toFixed(2);
                        me.select_type_ticket = response.data.array_tickets[0].id;
                        me.max_num_tickets = me.array_dates[0].array_tickets[0].tickets_available;

                        $("#touchspin_num_tickets").trigger("touchspin.updatesettings", {
                            max: Math.min.apply(Math, [me.max_num_tickets, c.maxTicketsPerBuy]),
                        });

                        if (me.max_num_tickets == 0) {
                            me.min_num_tickets = 0;
                            $("#touchspin_num_tickets").trigger("touchspin.updatesettings", {
                                min: me.min_num_tickets,
                            });
                        }

                        me.new_ticket_price = response.data.array_tickets[0].price_ticket;
                        me.numbered_ticket = me.array_tickets[0].numbered_ticket == "1";
                        me.ticket_one = me.array_tickets[0].ticket_one == "1";

                        for (
                            let index = parseInt(me.array_tickets[0].ticket_range_since);
                            index < parseInt(me.array_tickets[0].ticket_range_until) + 1;
                            index++
                        ) {
                            me.array_numbered_tickets.push(index);
                        }

                        me.array_tickets[0].sales.forEach((sale) => {
                            if (
                                me.array_numbered_tickets.includes(parseInt(sale.num_ticket))
                            ) {
                                const id = me.array_numbered_tickets.indexOf(
                                    parseInt(sale.num_ticket)
                                );
                                me.array_numbered_tickets.splice(id, 1);
                            }
                        });
                    }

                    //me.initMap(parseFloat(response.data.event.latitude), parseFloat(response.data.event.longitude));

                    //console.log("Finalizando seteo")

                })
                .catch(function (error) {
                    // handle error
                    console.error(error);
                });
        },

        getValueQuery() {
            if (localStorage.getItem("show-transaction-success-alert") !== "false" && localStorage.getItem("show-transaction-success-alert") !== null) {
                if (this.$route.query.type !== undefined && this.$route.query.type === "success") {
                    if (localStorage.getItem('send-confirmation-email-on-finish') === 'true') {
                        localStorage.setItem('send-confirmation-email-on-finish', 'false');
                        // this.resetPassword();
                        swal(
                            "Compra realizada correctamente",
                            "Hemos enviado un correo con la factura de compra. Puedes encontrar tu entrada en MI COLECCIÓN",
                            "success"
                        );
                        if(this.$route.query.transaction_id){
                            this.transaction_id = this.$route.query.transaction_id
                        }
                    } else {
                        swal(
                            "Compra realizada correctamente",
                            "Hemos enviado a tu correo la factura de compra y puedes encontrar tu entrada en MI COLECCIÓN",
                            "success"
                        );
                        if(this.$route.query.transaction_id){
                            this.transaction_id = this.$route.query.transaction_id
                        }
                    }
                    //this.$router.push({ path: '/mycollection', query: null }).then()
                    this.$router.push({path: this.$route.path, query: {'': ''}}).then()
                }
                if (this.$route.query.type !== undefined && this.$route.query.type === "error") {
                    if (localStorage.getItem('send-confirmation-email-on-finish') === 'true') {
                        localStorage.setItem('send-confirmation-email-on-finish', 'false');
                        // this.resetPassword();
                        swal(
                            "Error en el proceso de compra",
                            "Se ha producido un error en el proceso de compra.",
                            "error"
                        );
                    } else {
                        swal(
                            "Error en el proceso de compra",
                            "Se ha producido un error en el proceso de compra.",
                            "error"
                        );
                    }
                    this.$router.push({path: this.$route.path, query: {'': ''}}).then()
                }
                localStorage.setItem("show-transaction-success-alert", "false");
            }
        },

        onChangeSelectTypeDate(event) {
            let me = this;
            var id_date = event.target.value;
            me.array_dates.forEach(function (value, index, array) {
                if (value.id == id_date) {
                    me.array_tickets_select = value.array_tickets;
                    if (id_date == value.id) {
                        me.anticipado = value.anticipado;
                        if (me.anticipado == 1) {
                            document.getElementById("div_select_type_ticket").classList.add("d-none");
                            document.getElementById("div_select_type_ticket").classList.remove("d-flex");
                        }

                        if (me.anticipado == 0) {
                            document.getElementById("div_select_type_ticket").classList.remove("d-none");
                            document.getElementById("div_select_type_ticket").classList.add("d-flex");
                        }
                    }
                    $("#select_date_event").selectpicker("refresh");
                }
            });
        },
        checkCodeEarlyCheck() {
            let me = this;

            if (
                me.select_date_event == 0 ||
                me.select_date_event == undefined ||
                me.code_check == ""
            ) {
                swal("", "Rellena todos los datos", "warning");
                return;
            }

            let params = {
                id_date_event: me.select_date_event,
                code: me.code_check,
            };

            axios
                .post("/check_code_early_check", params)
                .then((response) => {
                    if (response.data.code == 1000) {
                        me.anticipado = 0;
                        document.getElementById("div_select_type_ticket").classList.remove("d-none");
                        document.getElementById("div_select_type_ticket").classList.add("d-flex");
                        me.code_check = "";
                        me.array_dates.forEach(function (value, index, array) {
                            if (value.id == me.select_date_event) {
                                me.array_dates[index].anticipado = 0;
                                me.select_type_ticket = 0;
                            }
                        });
                    } else if (response.data.code == 1004) {
                        swal("", "El código que has introducido no es válido", "warning");
                    } else {
                        swal("", "Rellena todos los datos", "warning");
                    }
                })
                .catch(function (error) {
                });
        },

        //UTILS
        toFixed(x) {
            if (Math.abs(x) < 1.0) {
                var e = parseInt(x.toString().split("e-")[1]);
                if (e) {
                    x *= Math.pow(10, e - 1);
                    x = "0." + new Array(e).join("0") + x.toString().substring(2);
                }
            } else {
                var e = parseInt(x.toString().split("+")[1]);
                if (e > 20) {
                    e -= 20;
                    x /= Math.pow(10, e);
                    x += new Array(e + 1).join("0");
                }
            }
            return x;
        },
        showAlertLogin() {
            swal(
                "",
                "Para realizar una compra debes iniciar sesión o registrarte",
                "warning"
            );

            $(".perfil-hover").parent().click();
        },
        closeModalBuyEvent() {
            console.error('Closing modal...')
            let me = this;
            $(".border-rounded-green").removeClass("selected");
            $(".select-method-pay-card").addClass("selected");
            me.pay_selected = 1;
            me.terms_and_conditions = 0;
            this.user = {
                name: '',
                surnames: '',
                email: '',
                email_confirmation: '',
                birthday: '',
                phone: '',
                zipcode: ''
            }
            this.date_message = '';
            if (this.reloadAfterLogin) {
                window.location.reload()
            }
        },
        openRenamingModal() {
            this.openRenaming = true
        },
        openModalBuyEvent() {
            let me = this;
            $(".border-rounded-green").removeClass("selected");
            $(".select-method-pay-card").addClass("selected");
            me.pay_selected = 1;
            me.terms_and_conditions = 0;
            this.user = {
                name: '',
                surnames: '',
                email: '',
                email_confirmation: '',
                birthday: '',
                phone: '',
                zipcode: ''
            }
        },
        goToTermsAndConditions() {
            $(".modal-backdrop").remove();
        },
        removeSelectedTicket(selected_ticket) {
            this.array_numbered_tickets.push(selected_ticket);
            this.array_numbered_tickets.sort(function (a, b) {
                return a - b;
            });

            const index = this.selected_tickets.indexOf(selected_ticket);
            this.selected_tickets.splice(index, 1);
        },
        setCustomData() {
            this.num_tickets = this.selected_tickets.length;
        },

    },
    mounted() {
        const me = this;
        this.web3 = new Web3(window.ethereum);
        this.id_event = this.$route.params.id;
        this.getInfoEvent();
        console.log("info event ok")
        this.getValueQuery();
        console.log("value query ok")
        //this.getInfoUser();
        let div_select_type_ticket = document.getElementById("div_select_type_ticket");
        if (me.anticipado === 1 && div_select_type_ticket) {
            div_select_type_ticket.classList.add("d-none");
            div_select_type_ticket.classList.remove("d-flex");
        }

        if (me.anticipado === 0 && div_select_type_ticket) {
            div_select_type_ticket.classList.remove("d-none");
            div_select_type_ticket.classList.add("d-flex");
        }
console.log("todo bien")

    },
    updated() {
        let me = this;

        if (me.anticipado == 1) {
            document.getElementById("div_select_type_ticket").classList.add("d-none");
            document.getElementById("div_select_type_ticket").classList.remove("d-flex");
        }

        if (me.anticipado == 0) {
            document.getElementById("div_select_type_ticket").classList.remove("d-none");
            document.getElementById("div_select_type_ticket").classList.add("d-flex");
        }
    },
};
</script>

<style lang="scss" scoped>
.event-page {
    transform: translateY(-7rem);
}

.loading-transaction {
    border: 3px solid white;
    border-top: 3px solid #bbbbbb;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    margin-left: 0.5rem;
    animation: spin 2s ease infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.selected-tickets {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    max-height: 5rem;
    overflow: auto;
}

.custom-image {
    border-radius: 0.5rem;
    object-fit: cover;
    width: 200px;
    height: 200px;
    margin: auto;
    cursor: pointer;
}
</style>

<style>
.input-group-addon.bootstrap-touchspin-postfix.input-group-append {
    flex: 1 1 auto;
}

.center-vertical-td {
    vertical-align: middle !important;
}
.center-horizontal-td {
    text-align: center !important;
}
.touchspin-custom {
    vertical-align: middle;
    margin: auto;
    width: 100%;
}

.touchspin-custom .input-group > :nth-child(1),
.touchspin-custom .input-group > :nth-child(4) {
    width: 40px;
}

.touchspin-custom .input-group > :nth-child(2) {
    width: calc(50% - 120px);
}

/*
.touchspin-custom .input-group :nth-child(2) {
    width: calc(50% - 40px);
}
*/
/*https://stackoverflow.com/questions/9538868/prevent-body-from-scrolling-when-a-modal-is-opened*/
/*body.modal-open {
    overflow: hidden!important;
}*/
</style>

<style lang="scss" scoped>
.facebook-btn {
    border: transparent;
    background-color: #3b5998;
    color: white;
    transition: all 0.3s ease;

    &:focus {
        box-shadow: none;
    }

    &:hover {
        background-color: darken(#3b5998, 10%);
    }
}
</style>
