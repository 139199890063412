var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "row accordion accordion-solid accordion-toggle-plus",
        attrs: { id: "accordionExample" },
      },
      _vm._l(_vm.faqs, function (help, index) {
        return _c(
          "div",
          {
            key: index,
            staticClass: "col-12 col-md-6 card box-general-acordation",
          },
          [
            _c(
              "div",
              {
                staticClass: "card-header",
                attrs: { id: "heading" + help.id },
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "card-title justify-content-between box-question collapsed",
                    attrs: {
                      "data-toggle": "collapse",
                      "data-target": "#a-element-" + help.id,
                    },
                  },
                  [
                    _c("div", { staticClass: "d-flex align-items-center" }, [
                      _c("span", {
                        staticClass:
                          "color-muted font-weight-bold font-size-sm",
                        domProps: { textContent: _vm._s(help.question) },
                      }),
                    ]),
                  ]
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "collapse",
                attrs: {
                  id: "a-element-" + help.id,
                  "data-parent": "#accordionExample",
                },
              },
              [
                _c("div", {
                  staticClass: "card-body box-answer",
                  domProps: { textContent: _vm._s(help.answer) },
                }),
              ]
            ),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }